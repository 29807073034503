import {
  getUniqueRandomId,
  Template,
  TemplateAttachment,
  TemplateExternalPdf,
  templateKeyToCollection,
  WithId,
} from '@hb/shared'
import { doc, DocumentReference, updateDoc } from 'firebase/firestore'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts'
import { useTemplateEditor } from '../contexts/editor'

export interface ExternalPdfTemplateEditorValue {
  template: Template
  externalPdf: TemplateExternalPdf
  updateExternalPdf: (externalPdf: TemplateExternalPdf) => void
  resetExternalPdf: () => void
  externalPdfChanged: boolean
  submitExternalPdfChanges: () => Promise<void>
  selectedAttachmentId: string | null
  onAttachmentSelect: (attachmentId: string | null) => void
  addAttachment: (attachment: TemplateAttachment) => void
  submittingChanges: boolean
  updateAttachment: (attachmentId: string, attachment: TemplateAttachment) => void
  deleteAttachment: (attachmentId: string) => void
}

const ExternalPdfTemplateEditorContext = createContext<ExternalPdfTemplateEditorValue>({
  externalPdf: {
    baseFile: {
      storagePath: '',
      name: '',
      type: '',
      id: '',
      uploadedOn: 0,
    },
    attachments: {},
  },
  template: {
    name: '',
    rank: 0,
  },
  externalPdfChanged: false,
  updateExternalPdf: () => {},
  submittingChanges: false,
  resetExternalPdf: () => {},
  submitExternalPdfChanges: async () => {},
  selectedAttachmentId: null,
  onAttachmentSelect: () => {},
  addAttachment: () => {},
  updateAttachment: () => {},
  deleteAttachment: () => {},
})

export const ExternalPdfTemplateEditorProvider = ({
  children,
  template,
  externalPdf,
}: PropsWithChildren<{
  template: WithId<Template>
  externalPdf: TemplateExternalPdf
}>) => {
  const { type } = useTemplateEditor()
  // const [tempTemplate] = useState({ ...template })
  const [tempExternalPdf, setTempExternalPdf] = useState({ ...externalPdf })
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<string | null>(null)
  const [externalPdfChanged, setExternalPdfChanged] = useState(false)

  const updateExternalPdf = useCallback((updated: TemplateExternalPdf) => {
    setExternalPdfChanged(true)
    setTempExternalPdf(updated)
  }, [])

  const resetExternalPdf = useCallback(() => {
    setExternalPdfChanged(false)
    setTempExternalPdf({ ...externalPdf })
  }, [externalPdf])

  const addAttachment = useCallback((attachment: TemplateAttachment) => {
    setExternalPdfChanged(true)
    setTempExternalPdf(prev => ({
      ...prev,
      attachments: {
        ...prev.attachments,
        [getUniqueRandomId(Object.keys(prev.attachments))]: attachment,
      },
    }))
  }, [])

  const updateAttachment = useCallback((attachmentId: string, attachment: TemplateAttachment) => {
    setExternalPdfChanged(true)
    setTempExternalPdf(prev => ({
      ...prev,
      attachments: {
        ...prev.attachments,
        [attachmentId]: attachment,
      },
    }))
  }, [])

  const deleteAttachment = useCallback((attachmentId: string) => {
    setExternalPdfChanged(true)
    setTempExternalPdf(prev => {
      const attachments = { ...prev.attachments }
      delete attachments[attachmentId]
      return { ...prev, attachments }
    })
  }, [])

  const { showMessage } = useContext(PopUpMessageContext)

  const [submittingChanges, setSubmittingChanges] = useState(false)
  const submitExternalPdfChanges = useCallback(async () => {
    const templateRef = doc(
      db,
      `${templateKeyToCollection[type]}/${template.id}`,
    ) as DocumentReference<Template>

    try {
      setSubmittingChanges(true)
      await updateDoc(templateRef, 'externalPdf', tempExternalPdf)
      showMessage({
        type: 'success',
        text: 'Template updated',
      })
      setExternalPdfChanged(false)
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error updating template',
        subText: err.message,
      })
    }
    setSubmittingChanges(false)
  }, [template, tempExternalPdf, showMessage, type])

  const contextValue = useMemo<ExternalPdfTemplateEditorValue>(
    () => ({
      template,
      externalPdf: tempExternalPdf,
      addAttachment,
      updateAttachment,
      deleteAttachment,
      resetExternalPdf,
      updateExternalPdf,
      submitExternalPdfChanges,
      submittingChanges,
      externalPdfChanged,
      selectedAttachmentId,
      onAttachmentSelect: setSelectedAttachmentId,
    }),
    [
      template,
      tempExternalPdf,
      updateExternalPdf,
      submitExternalPdfChanges,
      resetExternalPdf,
      addAttachment,
      updateAttachment,
      submittingChanges,
      deleteAttachment,
      selectedAttachmentId,
      externalPdfChanged,
    ],
  )

  return (
    <ExternalPdfTemplateEditorContext.Provider value={contextValue}>
      {children}
    </ExternalPdfTemplateEditorContext.Provider>
  )
}

export const useExternalPdfTemplateEditor = () => useContext(ExternalPdfTemplateEditorContext)
