import { FieldMapValue, ShortcutArgs } from '../../types'
import { addressToString, formatDollarValue } from '../data'
import { getDateString } from '../dates'
import { formatPhoneNumber } from '../fields'
import { getAllowedChargesString, getReimbursementString } from './cost'

const getNetworkValue = (path: string, data: FieldMapValue | undefined): string | undefined => {
  switch (path) {
    case 'deductible':
      return data?.deductible ? formatDollarValue(data.deductible) : undefined
    case 'coinsurance':
      return data?.coinsurance ? `${data.coinsurance}%` : undefined
    case 'oopMax':
      return data?.outOfPocketMax ? formatDollarValue(data.outOfPocketMax) : undefined
    default:
      return undefined
  }
}

const getPolicyOwnerValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { assessment, user } = shortcutArgs ?? {}
  const { mergedSignOnData } = assessment ?? {}
  const patientNotPolicyOwner = mergedSignOnData?.policyOwnerRelationship !== 'self'

  const address =
    patientNotPolicyOwner && !mergedSignOnData?.policyOwnerInfo?.policyOwnerSameAddress
      ? mergedSignOnData?.policyOwnerInfo?.policyOwnerAddress
      : mergedSignOnData?.address

  const phone = patientNotPolicyOwner
    ? mergedSignOnData?.policyOwnerInfo?.policyOwnerPhone
    : user?.phone

  const dob = patientNotPolicyOwner ? mergedSignOnData?.policyOwnerInfo?.policyOwnerDob : user?.dob

  const sex = patientNotPolicyOwner ? mergedSignOnData?.policyOwnerInfo?.policyOwnerSex : 'F'
  switch (path) {
    case 'fname':
      return patientNotPolicyOwner
        ? mergedSignOnData?.policyOwnerInfo?.policyOwnerName?.fname
        : user?.fname
    case 'lname':
      return patientNotPolicyOwner
        ? mergedSignOnData?.policyOwnerInfo?.policyOwnerName?.lname
        : user?.lname
    case 'dob':
      return dob ? getDateString(dob, 'short') : undefined
    case 'address':
      return address ? addressToString(address) : undefined
    case 'sex':
      return sex
    case 'address2':
      return address ? addressToString(address, true) : undefined
    case 'phone':
      return phone ? formatPhoneNumber(phone) : undefined
    // return selectedCoverage?.policyOwner?.name
    default:
      return undefined
  }
}

const getInsurerValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { plan } = shortcutArgs ?? {}
  switch (path) {
    case 'name':
      return plan?.insuranceProvider?.name
    default:
      return undefined
  }
}

export const getInsuranceValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  // ex: payments.received.0
  const [, fieldPath, ...rest] = path.split('.')
  const { plan } = shortcutArgs ?? {}
  switch (fieldPath) {
    case 'memberId':
      return plan?.memberId
    case 'allowedCharges':
      return getAllowedChargesString(shortcutArgs)
    case 'reimbursement':
      return getReimbursementString(shortcutArgs)
    case 'inNetwork':
      return getNetworkValue(rest.join('.'), plan?.['in-network'])
    case 'outOfNetwork':
      return getNetworkValue(rest.join('.'), plan?.['out-of-network'])
    case 'policyOwner':
      return getPolicyOwnerValue(rest.join('.'), shortcutArgs)
    case 'insuranceProvider':
      return getInsurerValue(rest.join('.'), shortcutArgs)
    default:
      return undefined
  }
}
