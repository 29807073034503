import { Flex, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  Assessment,
  FieldMapValue,
  InsuranceCoverage,
  OnUploadProgress,
  PopulatedUser,
  USERS,
  USER_INVITES,
  User,
  getSecondaryCoverageField,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import { DocumentReference, doc, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { db, processFieldMapData } from '../../../backend'
import { AppContext } from '../../../contexts'
import { useAuth } from '../../../store'
import { addMetadata } from '../../../utils'
import { SimpleForm } from '../../forms'

export const SecondaryCoverageForm = ({
  user,
  assessmentId,
  patientRef,
  isMedicaid,
  onBack,
}: {
  onBack: () => void
  assessmentId: string
  isMedicaid: boolean
  patientRef: DocumentReference<User>
  user: PopulatedUser
}) => {
  const { secondary } = user.insurancePlans ?? {}

  const field = useMemo(() => getSecondaryCoverageField(isMedicaid), [isMedicaid])

  const { appName } = useContext(AppContext)

  const authUser = useAuth(s => s.authUser)
  const handleSubmit = useCallback(
    async (submitted: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (!authUser) return { [FORM_ERROR]: 'User not authenticated' }
      const isInvite = patientRef.path.startsWith(USER_INVITES)
      try {
        const processed = await processFieldMapData(
          `${isInvite ? USER_INVITES : USERS}/${patientRef.id}/insurancePlans/secondary`,
          field,
          submitted,
          null,
          onUploadProgress,
        )
        const batch = writeBatch(db)
        const plan = {
          ...processed,
          isMedicaid,
        } as InsuranceCoverage
        const assessmentRef = doc(db, ASSESSMENTS, assessmentId) as DocumentReference<Assessment>
        batch.update(patientRef, 'insurancePlans.secondary', addMetadata(plan, appName, true))
        batch.update(assessmentRef, 'additionalPlansConfirmedOn', Date.now())
        batch.update(assessmentRef, 'additionalPlansConfirmedBy', authUser.uid)
        await batch.commit()
        onBack()
        return undefined
      } catch (err: any) {
        console.error(err)
        return { [FORM_ERROR]: err.message ?? 'An error occurred' }
      }
    },
    [authUser, onBack, isMedicaid, field, appName, patientRef, assessmentId],
  )
  return (
    <Flex flexFlow="column" w="100%">
      <Flex px={3} w="100%" align="center">
        <Text fontSize="lg" color="gray.600">
          Secondary Coverage
        </Text>
      </Flex>
      <SimpleForm
        boxProps={{
          boxShadow: 'none',
          borderRadius: '1px solid #cdcdcd',
          p: 0,
        }}
        field={field}
        value={secondary}
        onSubmit={handleSubmit}
      />
    </Flex>
  )
}
