import { ChevronDownIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  DropdownField,
  DropdownOption,
  DropdownOptionGroup,
  DropdownOptionItem,
  filterOptionsBySearch,
  getAllOptions,
  getOptionFromId,
  getOptionFromText,
  isDropdownOptionItem,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import React, {
  CSSProperties,
  FC,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FieldMetaState } from 'react-final-form'
import { ThemeContext } from '../../../../contexts'
import { InputElement, InputProps } from '../../../../types/fields'
import { useStatusColor } from '../Shared/hooks'
import { getScrollableParent } from './utils'

const DropdownSearchBar = forwardRef<
  HTMLInputElement,
  {
    value?: string
    field: DropdownField
    meta: FieldMetaState<any>
    fieldActive?: boolean
    style?: CSSProperties
    searchQuery?: string
    name?: string
    onBlur: () => void
    onFocus: () => void
    onSearchQueryChange: (v: string) => void
  }
>(
  (
    {
      value,
      fieldActive,
      searchQuery,
      onSearchQueryChange,
      onBlur,
      name,
      field,
      meta,
      style,
      onFocus,
    },
    ref,
  ) => {
    const { placeholder } = field ?? {}
    const { theme } = useContext(ThemeContext)
    const boxShadow = useStatusColor(field, meta)
    return (
      <HStack
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        spacing={0}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        w="100%"
        py={theme === 'basic' ? 1 : 2}
        px={2}
        bg="white"
        boxShadow={boxShadow}
        borderTopRadius={6}
        fontSize="sm"
        transition="all 500ms"
        borderBottomRadius={fieldActive ? 0 : 6}
        style={style}>
        <Center
          width={fieldActive || !value ? '24px' : '0px'}
          transition="all 500ms"
          opacity={fieldActive || !value ? 1 : 0}
          overflow="hidden">
          <SearchIcon position="relative" top="1px" color="gray.400" width="14px" />
        </Center>
        <Input
          size="sm"
          // fontSize={theme === 'basic' ? 'sm' : 'md'}
          fontSize="inherit"
          // minH="20px"
          // height='20px'
          minH="0"
          lineHeight={1}
          ref={ref}
          flex={1}
          height="auto"
          onBlur={onBlur}
          name={name}
          p={0}
          transition="all 300ms"
          pl={fieldActive || !value ? 0 : 2}
          sx={{ ':focus': { boxShadow: 'none' } }}
          border="none"
          color="gray.700"
          onFocus={onFocus}
          value={(fieldActive ? searchQuery : value) ?? ''}
          placeholder={placeholder}
          onChange={({ target: { value: updated } }) => onSearchQueryChange(updated)}
        />
      </HStack>
    )
  },
)

const DropdownItemMenuGroup: FC<{
  option: DropdownOptionGroup<any>
  field: DropdownField
  onChange: (v?: string) => void
  index: number
}> = ({ option: { title, options }, ...props }) => (
  <VStack divider={<Divider />} align="flex-start" w="100%" spacing={0}>
    <Text w="100%" py={1} px={2} bg="gray.100" fontSize="sm" fontWeight={600} color="gray.600">
      {title}
    </Text>
    <VStack
      divider={<Divider />}
      borderLeft="4px solid #888"
      spacing={0}
      width="100%"
      align="flex-start">
      {options.map((option, idx) => (
        <DropdownOptionView key={`${idx}`} option={option} {...props} />
      ))}
    </VStack>
  </VStack>
)

const DropdownOptionItemView: FC<{
  option: DropdownOptionItem
  field: DropdownField
  index: number
  onChange: (v: string) => void
}> = ({ option, onChange, field: { renderOption }, index: i }) => {
  const { id, text } = option
  return (
    <Button
      variant="unstyled"
      borderTop={i ? '1px solid #cdcdcd' : 'none'}
      key={id}
      aria-label={text}
      fontWeight={400}
      fontFamily="hero-new"
      w="100%"
      cursor="pointer"
      _hover={{ bg: 'blackAlpha.100' }}
      // sx={{ ':nth-of-type(odd)': { bg: 'rgb(245,245,245)' } }}
      borderRadius="none"
      bg="transparent"
      display="flex"
      textAlign="left"
      h="auto"
      py="0.4rem"
      onBlur={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      px={3}
      justifyContent="flex-start"
      whiteSpace="pre-wrap"
      onPointerDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onMouseDown={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={e => {
        e.preventDefault()
        onChange(id)
      }}>
      <Flex flex={1}>{renderOption ? renderOption(option) : text}</Flex>
    </Button>
  )
}

const DropdownOptionView: FC<{
  option: DropdownOption<any>
  onChange: (v?: string) => void
  field: DropdownField
  index: number
}> = ({ option, ...props }) => {
  if (isDropdownOptionItem(option)) {
    return <DropdownOptionItemView option={option} {...props} />
  }
  return <DropdownItemMenuGroup option={option} {...props} />
}

export interface PopperPosition {
  x: number
  y: number
  height: number
  width: number
}
export const DropdownItemList: FC<{
  options: DropdownOption<any>[]
  field: DropdownField<any>
  onChange: (v: any | undefined) => void
  searchQuery?: string
  popperPosition: PopperPosition | null
  active: boolean
}> = ({ options, field, onChange, searchQuery, active, popperPosition }) => (
  <Portal>
    <VStack
      position="fixed"
      width={`${popperPosition?.width ?? 0}px`}
      top={`${(popperPosition?.y ?? 0) + (popperPosition?.height ?? 0)}px`}
      left={`${popperPosition?.x ?? 0}px`}
      zIndex={2}
      py={0}
      bg="white"
      align="flex-start"
      boxShadow="0 0 4px #00000077"
      minW="50px"
      borderBottomRadius={6}
      opacity={active ? 1 : 0}
      pointerEvents={active ? 'auto' : 'none'}
      transform={active ? 'translateY(0)' : 'translateY(-5px)'}
      transition="opacity 500ms, transform 500ms"
      spacing={0}
      maxHeight="200px"
      overflowY="auto">
      {options.length ? (
        options.map((o, i) => (
          <DropdownOptionView
            key={isDropdownOptionItem(o) ? o.id : o.title}
            // w='100%'
            option={o}
            field={field}
            index={i}
            onChange={v => {
              onChange(v)
            }}
          />
        ))
      ) : (
        <Text color="gray.500" py={2} px={3}>
          {searchQuery ? 'No options matching search' : 'No options'}
        </Text>
      )}
    </VStack>
  </Portal>
)

export const MenuDropdownInput: InputElement<DropdownField> = forwardRef<
  { focus: () => void },
  InputProps<DropdownField>
>(({ input, field, meta, style, disabled }, ref) => {
  const { placeholder, options, searchable } = field
  const { onChange, value, onBlur, onFocus, name } = input
  const { active } = meta ?? {}
  const { theme } = useContext(ThemeContext)

  const [popperPosition, setPopperPosition] = useState<PopperPosition | null>(null)

  const [searchQuery, setSearchQuery] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!active) {
      setSearchQuery(getAllOptions(options).find(o => o.id === value)?.text ?? '')
    } else {
      setSearchQuery('')
    }
  }, [value, options, active])

  const handleBlur = useCallback(() => {
    const curr = inputRef.current?.value ?? ''
    if (curr) {
      const option = getOptionFromId(options, curr) ?? getOptionFromText(options, curr)
      if (option) {
        onChange(option.id)
      }
    }
    onBlur()
    setSearchQuery('')
  }, [onBlur, onChange, options])

  const selectedOption = useMemo<DropdownOptionItem | null>(() => {
    if (!value) return null
    const option = getOptionFromText(options, value)
    return option ?? null
  }, [value, options])

  const filteredOptions = useMemo(() => {
    if (searchable && searchQuery) {
      return filterOptionsBySearch(options, searchQuery)
    }
    return options
  }, [searchQuery, options, searchable])

  const containerRef = useRef<HTMLDivElement>(null)

  const handleOpen = useCallback(() => {
    if (!containerRef.current) return
    if (disabled) return
    const { x, y, height, width } = containerRef.current.getBoundingClientRect()
    setPopperPosition({
      x,
      y,
      height,
      width,
    })
    onFocus()
  }, [onFocus, disabled])

  const boxShadow = useStatusColor(field, meta)

  const handleContentResize = useCallback((e: ResizeObserverEntry) => {
    const { x, y, height, width } = e.target.getBoundingClientRect()
    setPopperPosition({
      x,
      y,
      height,
      width,
    })
  }, [])
  useResizeObserver(containerRef, handleContentResize)

  useEffect(() => {
    if (!active) return () => {}
    if (!containerRef.current) return () => {}
    const scrollableParent = getScrollableParent(containerRef.current)
    if (!scrollableParent) return () => {}
    const handleScroll = () => {
      if (!containerRef.current) return
      const { x, y, height, width } = containerRef.current.getBoundingClientRect()
      setPopperPosition({
        x,
        y,
        height,
        width,
      })
    }
    scrollableParent.addEventListener('scroll', handleScroll)
    return () => {
      scrollableParent.removeEventListener('scroll', handleScroll)
    }
  }, [active])

  useImperativeHandle(ref, () => ({
    focus: handleOpen,
    blur: handleBlur,
  }))

  const getItemKey = useCallback(
    (o: DropdownOption<any>) => {
      if (!isDropdownOptionItem(o)) return o.title
      return field.getKey ? field.getKey(o.id) : o.id
    },
    [field],
  )

  return (
    <HStack
      pointerEvents={disabled ? 'none' : 'auto'}
      ref={containerRef}
      minW="0"
      w="100%"
      spacing={1}>
      <HStack
        onBlur={handleBlur}
        spacing={0}
        minW="0"
        width="100%"
        flex={1}
        position="relative"
        align="center">
        <HStack w="100%" flex={1} spacing={2}>
          {searchable ? (
            <DropdownSearchBar
              onBlur={handleBlur}
              field={field}
              style={style}
              name={name}
              meta={meta}
              onFocus={handleOpen}
              searchQuery={searchQuery}
              ref={inputRef}
              onSearchQueryChange={q => {
                setSearchQuery(q)
                if (!active) {
                  handleBlur()
                }
              }}
              value={value}
              fieldActive={active}
            />
          ) : (
            <Button
              bg="white"
              flex={1}
              // minW='200px'
              fontWeight={400}
              fontSize="sm"
              variant="outline"
              size="sm"
              fontFamily="hero-new"
              py={theme === 'basic' ? 1 : 3}
              px={field.renderOption ? 2 : 4}
              lineHeight="16px"
              whiteSpace="pre-wrap"
              justifyContent="flex-start"
              textAlign="left"
              height="auto"
              onClick={e => {
                e.stopPropagation()
                if (active) {
                  handleBlur()
                } else handleOpen()
              }}
              color={value ? 'blackAlpha.800' : 'blackAlpha.600'}
              borderTopRadius={6}
              borderBottomRadius={active ? 0 : 6}
              transition="all 500ms"
              rightIcon={<ChevronDownIcon />}
              border="none"
              style={{
                boxShadow,
                ...style,
              }}>
              {field.renderOption && selectedOption ? (
                <Box flex={1}>{field.renderOption(selectedOption)}</Box>
              ) : (
                <Text isTruncated={!field.renderOption} minW="0" maxW="100%" flex={1}>
                  {selectedOption?.text ?? placeholder}
                </Text>
              )}
            </Button>
          )}
          {field.optional && value ? (
            <Center>
              <Tooltip label="Clear" placement="top" hasArrow>
                <IconButton
                  size="xs"
                  width="20px"
                  height="20px"
                  minW="0"
                  borderRadius="full"
                  color="gray.600"
                  // top='2rem'
                  bg="gray.50"
                  // zIndex={}
                  border="1px solid #cdcdcd"
                  // boxShadow='md'
                  aria-label="clear"
                  onClick={e => {
                    e.stopPropagation()
                    if (inputRef.current) inputRef.current.value = ''
                    input.onChange(undefined)
                    handleBlur()
                  }}
                  icon={<CloseIcon w={2} />}
                />
              </Tooltip>
            </Center>
          ) : null}
        </HStack>
        <Portal>
          {/* <Box zIndex={2} pointerEvents='none' top={0} left={0} w="100%" h="100%"> */}
          <VStack
            position="absolute"
            width={`${popperPosition?.width ?? 0}px`}
            top={`${(popperPosition?.y ?? 0) + (popperPosition?.height ?? 0)}px`}
            left={`${popperPosition?.x ?? 0}px`}
            zIndex={30}
            py={0}
            bg="white"
            align="flex-start"
            onClick={e => {
              e.stopPropagation()
            }}
            boxShadow="0 0 4px #00000077"
            minW="300px"
            borderBottomRadius={6}
            opacity={active ? 1 : 0}
            pointerEvents={active ? 'auto' : 'none'}
            transform={active ? 'translateY(0)' : 'translateY(-5px)'}
            transition="opacity 500ms, transform 500ms"
            spacing={0}
            maxHeight="200px"
            overflowY="auto">
            {filteredOptions.length ? (
              filteredOptions.map((o, i) => (
                <DropdownOptionView
                  key={getItemKey(o)}
                  // w='100%'
                  option={o}
                  field={field}
                  index={i}
                  onChange={v => {
                    onChange(v)
                    onBlur()
                    inputRef.current?.blur()
                  }}
                />
              ))
            ) : (
              <Text color="gray.500" py={2} px={3}>
                {searchQuery ? 'No options matching search' : 'No options'}
              </Text>
            )}
          </VStack>
          {/* </Box> */}
        </Portal>
      </HStack>
    </HStack>
  )
})
