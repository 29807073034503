import { Box, Flex, HStack, StackProps, Text, useDisclosure, VStack } from '@chakra-ui/react'
import useResizeObserver from '@react-hook/resize-observer'
import React, { FC, ForwardedRef, forwardRef, useCallback, useRef, useState } from 'react'
import { ViewButton } from './Buttons/ViewButton'

type ExpandableProps = StackProps & {
  header: string | FC<{ isOpen: boolean; onClose: () => void }>
  footer?: FC<{ isOpen: boolean; onClose: () => void }>
  headerProps?: StackProps
  nested?: boolean
  alwaysExpanded?: boolean
  closeCallback?: () => void
  openCallback?: () => void
  initExpanded?: boolean
  noAnimate?: boolean
  isOpen?: boolean
  onClose?: () => void
  iconColor?: string
  onOpen?: () => void
}
const ExpandableBody = (
  {
    header,
    children,
    initExpanded = false,
    nested,
    alwaysExpanded,
    headerProps,
    openCallback,
    closeCallback,
    iconColor,
    footer,
    isOpen: isOpenProp,
    onClose: onCloseProp,
    noAnimate,
    onOpen: onOpenProp,
    ...stackProps
  }: ExpandableProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: alwaysExpanded ?? initExpanded,
    isOpen: isOpenProp,
    onClose: onCloseProp,
    onOpen: onOpenProp,
  })

  const [animating, setAnimating] = useState(false)
  const animatingRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const hideContentTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [showContent, setShowContent] = useState(isOpen)
  const handleOpenClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.stopPropagation()
      if (hideContentTimeout.current) {
        clearTimeout(hideContentTimeout.current)
        hideContentTimeout.current = null
      }

      if (animatingRef.current) {
        clearTimeout(animatingRef.current)
        animatingRef.current = null
      }

      if (alwaysExpanded) return
      if (!noAnimate) {
        setAnimating(true)
        animatingRef.current = setTimeout(() => setAnimating(false), 301)
      }
      if (isOpen) {
        onClose()
        hideContentTimeout.current = setTimeout(() => setShowContent(false), 301)
        if (closeCallback) closeCallback()
        return
      }
      setShowContent(true)
      onOpen()
      if (openCallback) openCallback()
    },
    [alwaysExpanded, onOpen, onClose, isOpen, closeCallback, openCallback, noAnimate],
  )

  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleContentResize = useCallback((e: ResizeObserverEntry) => {
    setContentHeight(e.target.scrollHeight)
  }, [])

  useResizeObserver(contentRef, handleContentResize)

  return (
    <VStack ref={ref} spacing={0} w="100%" {...stackProps}>
      <HStack
        borderBottom={isOpen ? '1px solid #cdcdcd' : undefined}
        px={1}
        spacing={0}
        w="100%"
        {...headerProps}>
        <Flex
          onClick={handleOpenClick}
          cursor="pointer"
          userSelect="none"
          aria-label="expand/hide"
          align="center"
          flex={1}>
          {typeof header === 'string' ? (
            <Text fontWeight={600} color="gray.500" flex={1}>
              {header}
            </Text>
          ) : (
            header({ isOpen, onClose })
          )}
        </Flex>
        {alwaysExpanded ? null : (
          <ViewButton color={iconColor} onClick={handleOpenClick} isOpen={isOpen} />
        )}
      </HStack>
      <Box w="100%" borderLeft={nested ? '4px solid #00000033' : undefined}>
        <Box
          transition={
            animating ? `opacity 300ms ease ${isOpen ? 300 : 0}ms, height 300ms ease 0ms` : 'none'
          }
          width="100%"
          height={isOpen ? `${contentHeight}px` : 0}
          overflow="hidden">
          <Box height="auto" w="100%" ref={contentRef}>
            {showContent ? children : null}
          </Box>
        </Box>
      </Box>
      {footer ? footer({ isOpen, onClose }) : null}
    </VStack>
  )
}

export const Expandable = forwardRef<HTMLDivElement, ExpandableProps>(ExpandableBody)
