import { WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/react'
import { CheckboxField, DropdownField, FieldTypes, reactionIcons, TextAreaField } from '@hb/shared'
import React from 'react'

export const alertTextField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Alert',
}

export const alertUrgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'URGENT',
}

export const alertTypeField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Type',
  options: [
    { id: 'assessment', text: 'Pregnancy' },
    { id: 'patient', text: 'Patient' },
  ],
}

export const alertIconOptions = { ...reactionIcons }

export const defaultAlertIcon = <WarningTwoIcon color="red.600" w={4} h={4} />

export const alertAccessLevelField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Access Level',
  options: [
    { id: 'admin', text: 'Admin' },
    { id: 'practice', text: 'Practice' },
  ],
}
export const customIconField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Custom Icon',
  options: Object.keys(alertIconOptions).map(id => ({
    text: id,
    id,
  })),
  renderOption: option => (
    <Flex align="center">
      <Box w="20px" fontSize="lg" mr={2}>
        {reactionIcons[option.id as keyof typeof reactionIcons]}
      </Box>
    </Flex>
  ),
  optional: true,
}
