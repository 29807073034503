import {
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  Fax,
  FileDBValue,
  objectToArray,
  UserGroup,
  userGroupToFileIdPrefix,
} from '@hb/shared'
import { DocumentReference, query, where } from 'firebase/firestore'

import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { combineFilesIntoPdf } from '../../../../backend'
import { FAX_RECEIVED_REF, FAX_SENT_REF } from '../../../../collections/firestoreCollections'
import { useApp } from '../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { ThemeContext } from '../../../../contexts/ThemeContext'
import { useQuery } from '../../../../hooks/backend/useQuery'
import { InsuranceCardsView } from '../../../Assessments/InsuranceCardsView'
import { ActionButton, SolidActionButton } from '../../../Buttons'
import { Expandable } from '../../../Expandable'
import { FaxPreview } from '../../../Faxes/FaxView'
import { FilesListView } from '../../../Files/FilesListView'
import { CollapseHorizontal } from '../../../shared'
import { BoxHeader } from '../../../Text/BoxHeader'
import { RecoverAssessmentFiles } from './RecoverAssessmentFiles'
import { RightUploadFilePopover } from './UploadFilePopover'

const AssessmentFaxes = () => {
  const { assessmentId } = useContext(ProfileContext)
  const sentFaxesQuery = useMemo(
    () => (assessmentId ? query(FAX_SENT_REF, where('assessmentId', '==', assessmentId)) : null),
    [assessmentId],
  )
  const receivedFaxesQuery = useMemo(
    () =>
      assessmentId ? query(FAX_RECEIVED_REF, where('assessmentId', '==', assessmentId)) : null,
    [assessmentId],
  )
  const { data: sentFaxes, loading: loadingSentFaxes } = useQuery<Fax>(sentFaxesQuery)
  const { data: receivedFaxes, loading: loadingReceivedFaxes } = useQuery<Fax>(receivedFaxesQuery)
  const sortedByDate = useMemo(() => {
    const arr = [...objectToArray(sentFaxes ?? {}), ...objectToArray(receivedFaxes ?? {})]
    return arr.sort((a, b) => {
      const aDate = new Date(a.created_at).getTime()
      const bDate = new Date(b.created_at).getTime()
      if (!aDate || !bDate) return 0
      return bDate - aDate
    })
  }, [sentFaxes, receivedFaxes])

  return (
    <Expandable
      initExpanded
      borderRadius={6}
      boxShadow="md"
      px={2}
      py={1}
      bg="white"
      header={() => <BoxHeader>Faxes</BoxHeader>}>
      {loadingReceivedFaxes || loadingSentFaxes ? (
        <HStack>
          <CircularProgress isIndeterminate /> <Text>Loading faxes...</Text>
        </HStack>
      ) : (
        <VStack align="flex-start" py={2}>
          {sortedByDate?.length ? (
            sortedByDate.map(fax => <FaxPreview key={fax.id} fax={fax} />)
          ) : (
            <Text fontStyle="italic" color="gray.600" px={2}>
              No faxes yet
            </Text>
          )}
        </VStack>
      )}
    </Expandable>
  )
}

const AssessmentFilesListView = ({
  docRef,
  files,
  access,
  preview,
  assessmentId,
  AddFileComponent,
  canMerge,
}: {
  docRef: DocumentReference
  files: Record<string, FileDBValue> | undefined
  AddFileComponent?: FC
  preview?: boolean
  access: UserGroup
  assessmentId: string
  canMerge?: boolean
}) => {
  const [selectingForMerge, setSelectingForMerge] = useState(false)
  const [selectedFileIds, setSelectedFileIds] = useState<string[] | null>(null)
  const { processResponse } = useContext(PopUpMessageContext)
  const [expanded, setExpanded] = useState(true)

  const onCancelMerge = useCallback(() => {
    setSelectedFileIds(null)
    setSelectingForMerge(false)
  }, [])

  const onMergeStart = useCallback(() => {
    setSelectedFileIds([])
    setSelectingForMerge(true)
  }, [])

  const { appName } = useApp()

  const [mergingFiles, setMergingFiles] = useState(false)

  const onMergeConfirm = useCallback(async () => {
    if (selectedFileIds?.length) {
      const filePrefix = userGroupToFileIdPrefix(access)
      setMergingFiles(true)
      try {
        await combineFilesIntoPdf({
          assessmentId,
          fileIds: selectedFileIds.map(fId => `${filePrefix}.${fId}`),
          appName,
          fileName: 'Combined Files',
        })
      } catch (err: any) {
        processResponse({ error: err.message })
      } finally {
        setMergingFiles(false)
        setSelectedFileIds(null)
        setSelectingForMerge(false)
      }
    } else {
      processResponse({ error: 'No files selected' })
    }
  }, [selectedFileIds, access, assessmentId, appName, processResponse])

  return (
    <Expandable
      borderRadius={preview ? 0 : 6}
      initExpanded
      boxShadow="md"
      bg="white"
      closeCallback={() => {
        onCancelMerge()
        setExpanded(false)
      }}
      openCallback={() => setExpanded(true)}
      header={() => (
        <BoxHeader py={1} pr={2} w="100%">
          <HStack w="100%">
            <Text fontSize={preview ? 'sm' : 'md'} pl={2}>
              {capitalizeFirstLetter(access)} Files
            </Text>
            {canMerge && expanded && !preview ? (
              <Flex ml="auto" align="center">
                <CollapseHorizontal in={!selectingForMerge} width={150}>
                  <ActionButton
                    px={3}
                    size="xs"
                    onClick={e => {
                      e.stopPropagation()
                      onMergeStart()
                    }}>
                    <Text>Combine files into PDF</Text>
                  </ActionButton>
                </CollapseHorizontal>
                {preview ? null : (
                  <CollapseHorizontal in={selectingForMerge} width={210}>
                    <ActionButton
                      size="xs"
                      colorScheme="gray"
                      isDisabled={mergingFiles}
                      onClick={e => {
                        e.stopPropagation()
                        onCancelMerge()
                      }}>
                      Cancel
                    </ActionButton>
                    <Tooltip
                      label={
                        selectedFileIds && selectedFileIds.length < 2
                          ? 'Select multiple files to merge'
                          : ''
                      }
                      hasArrow>
                      <SolidActionButton
                        ml={2}
                        size="xs"
                        isLoading={mergingFiles}
                        opacity={(selectedFileIds?.length ?? 0) > 1 ? 1 : 0.7}
                        onClick={e => {
                          e.stopPropagation()
                          onMergeConfirm()
                        }}>
                        Combine Selected
                      </SolidActionButton>
                    </Tooltip>
                  </CollapseHorizontal>
                )}
              </Flex>
            ) : null}
          </HStack>
        </BoxHeader>
      )}>
      <FilesListView
        stackProps={{ borderBottomRadius: 6 }}
        docRef={docRef}
        onFileSelect={
          selectingForMerge
            ? (fileId, selected) => {
                setSelectedFileIds(prev => {
                  if (selected) {
                    return prev ? [...prev, fileId] : [fileId]
                  }
                  return prev?.filter(f => f !== fileId) ?? null
                })
              }
            : undefined
        }
        mergingFileIds={selectedFileIds}
        preview={preview}
        AddFileComponent={AddFileComponent}
        mergeLoading={mergingFiles}
        files={files}
      />
    </Expandable>
  )
}

export const AssessmentFiles = ({ preview }: { preview?: boolean }) => {
  const {
    selectedAssessment: { adminRef, practiceRef, populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)

  const { appName } = useApp()
  const { adminFiles, practiceFiles } = selectedAssessment ?? {}

  return (
    <ThemeContext.Provider value={{ placeholderAbove: false }}>
      <Stack divider={preview ? <Divider /> : undefined} w="100%" spacing={preview ? 0 : 4}>
        {preview ? null : <InsuranceCardsView />}
        {appName === 'app' && adminRef && assessmentId ? (
          <AssessmentFilesListView
            docRef={adminRef}
            preview={preview}
            assessmentId={assessmentId}
            files={adminFiles}
            access="admin"
            canMerge
          />
        ) : null}
        {practiceRef && assessmentId && selectedAssessment?.midwifeId ? (
          <AssessmentFilesListView
            docRef={practiceRef}
            preview={preview}
            assessmentId={assessmentId}
            AddFileComponent={appName === 'providers-app' ? RightUploadFilePopover : undefined}
            files={practiceFiles}
            access="practice"
            canMerge
          />
        ) : null}
        {appName === 'app' && !preview ? (
          <>
            <AssessmentFaxes />
            <RecoverAssessmentFiles />
          </>
        ) : null}
      </Stack>
    </ThemeContext.Provider>
  )
}
