import { Center, Flex, IconButton } from '@chakra-ui/react'
import {
  A4_DIMS_PIXELS,
  CustomElement,
  FileDBValue,
  ImageElementOptions,
  Mode,
  UserFieldItem,
  VariableElement,
} from '@hb/shared'
import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ReactEditor, RenderElementProps } from 'slate-react'
import { useFile, UseFileData } from '../../../../hooks/backend/storage/downloadFile'
import { useFilePath } from '../../../../hooks/backend/storage/useFilePath'
import dragPanIcon from '../../../../icons/drag_pan_thin.svg'
import errorIcon from '../../../../icons/error_red.svg'
import imageIcon from '../../../../icons/image.svg'
import notesIcon from '../../../../icons/notes.svg'
import { SvgIcon } from '../../../../icons/SvgIcon'
import { CustomEditor } from '../../../../types/editor'
import { OrientationOverlay } from '../../../Gestures/OrientationOverlay'
import { LoadingImage } from '../../../shared/LoadingImage'
import { TextEditorContext } from '../../context'
import { ImageElementBox } from '../ImageElement'
import { getNestedName } from './utils'

const TemplateResizeOverlay = ({
  node,
  editor,
  onDelete,
}: {
  node: VariableElement
  editor: CustomEditor
  onDelete: () => void
}) => {
  const { baseZoom } = useContext(TextEditorContext)
  const width = useMemo(() => node.imageOptions?.width ?? 100, [node])
  const [scale, setScale] = useState<[number, number]>([1, 1])

  const onMove = useCallback(
    ([x, y]: [number, number]) => {
      const maxX = Math.max(0, A4_DIMS_PIXELS[0] - width)
      const maxY = Math.max(0, A4_DIMS_PIXELS[1] - width)
      requestAnimationFrame(() => {
        const path = ReactEditor.findPath(editor, node)
        editor.setNodes(
          {
            imageOptions: {
              ...node?.imageOptions,
              position: {
                x: Math.max(0, Math.min(x, maxX)),
                y: Math.max(0, Math.min(y, maxY)),
              },
            },
          },
          { at: path },
        )
      })
    },
    [editor, node, width],
  )

  const position = useMemo<[number, number]>(() => {
    const imagePosition = node?.imageOptions?.position
    if (imagePosition) {
      return [imagePosition.x ?? 0, imagePosition.y ?? 0]
    }
    return [0, 0]
  }, [node])

  const initWidth = useRef(width)
  const onResize = useCallback(
    (newScale: [number, number]) => {
      const newWidth = initWidth.current * newScale[0]
      const path = ReactEditor.findPath(editor, node)
      editor.setNodes({ imageOptions: { ...node?.imageOptions, width: newWidth } }, { at: path })
      setScale(newScale)
    },
    [editor, node],
  )

  return (
    <OrientationOverlay
      onMove={node.imageOptions?.position ? onMove : undefined}
      position={position}
      onDelete={onDelete}
      scale={scale}
      zoom={baseZoom}
      constrainAspectRatio
      onResize={onResize}
    />
  )
}

const ImageVariableElementEditOverlay = ({
  node,
  editor,
  options,
  onDelete,
  active: _active,
}: {
  node: CustomElement & UserFieldItem
  editor: CustomEditor
  options?: ImageElementOptions
  onDelete: () => void
  active: boolean
}) => {
  const [mounted, setMounted] = React.useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 10)
  }, [])

  const { position } = options ?? {}

  const onMakeInline = useCallback(() => {
    // unset position from node
    const path = ReactEditor.findPath(editor, node)
    const newOptions = { ...options }
    delete newOptions.position
    editor.setNodes({ imageOptions: newOptions }, { at: path })
  }, [editor, node, options])

  const onMakePositional = useCallback(() => {
    // set position to node
    const path = ReactEditor.findPath(editor, node)
    editor.setNodes({ imageOptions: { ...options, position: { x: 0, y: 0 } } }, { at: path })
  }, [editor, node, options])

  const active = _active && mounted
  return (
    <Flex
      position="absolute"
      top={0}
      w="100%"
      h="100%"
      left={0}
      opacity={active ? 1 : 0}
      pointerEvents={active ? 'auto' : 'none'}
      transition="opacity 0.3s"
      bg="blackAlpha.500">
      <Center w="100%" h="100%" position="relative">
        <TemplateResizeOverlay onDelete={onDelete} node={node as VariableElement} editor={editor} />
        <IconButton
          aria-label="Make inline"
          variant="unstyled"
          borderRadius="full"
          minW="0"
          zIndex={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          minH="0"
          w="20px"
          pos="absolute"
          left="-12px"
          top="-12px"
          boxShadow="1px 1px 3px #00000077"
          h="20px"
          color="gray.600"
          bg="white"
          onClick={position ? onMakeInline : onMakePositional}
          icon={
            position ? (
              <SvgIcon draggable={false} width="16px" opacity={0.5} src={notesIcon} />
            ) : (
              <SvgIcon draggable={false} width="20px" opacity={0.5} src={dragPanIcon} />
            )
          }
        />
      </Center>
      <span className="field-name-tooltip">{getNestedName(node.path)}</span>
    </Flex>
  )
}

export const FileVariableElement = ({
  node,
  editor,
  value,
  mode,
  nodeStyle,
  onPointerDown,
  isSelected,
  onDelete,
  children,
  attributes,
}: PropsWithChildren<{
  node: CustomElement & UserFieldItem
  editor: CustomEditor
  value?: FileDBValue
  mode: Mode
  attributes: RenderElementProps['attributes']
  onPointerDown: (event: React.PointerEvent) => void
  onDelete: () => void
  isSelected: boolean
  nodeStyle?: CSSProperties
}>) => {
  const { path, imageOptions } = node ?? {}
  const { downloadPath, recentlyUploaded } = useFilePath(value)
  const fileArgs = useMemo<UseFileData>(
    () => ({
      path: downloadPath,
      pathLoading: recentlyUploaded,
      value,
    }),
    [downloadPath, recentlyUploaded, value],
  )
  const { url, loading } = useFile(fileArgs)
  const { width = 100, opacity = 1, rotate = 0 } = imageOptions ?? {}
  const placeholderImage = useMemo(() => {
    if (loading) return imageIcon
    return mode === 'Edit' ? imageIcon : errorIcon
  }, [mode, loading])

  const boxStyle = useMemo<CSSProperties>(
    () => ({
      cursor: mode === 'Edit' ? 'pointer' : 'default',
      boxShadow: mode === 'Edit' && !url ? '0 0 0 1px #cdcdcd' : 'none',
      borderRadius: '4px',
      ...nodeStyle,
    }),
    [nodeStyle, mode, url],
  )

  const body = (
    <LoadingImage
      opacity={opacity}
      transform={`rotate(${rotate}deg)`}
      src={url}
      draggable={false}
      placeholderSrc={placeholderImage}
      style={{ width: `${width}px` }}
      loading={loading}
      alt={path}
      width={width}
      height={url ? undefined : width}
    />
  )
  return (
    <ImageElementBox
      style={boxStyle}
      onPointerDown={mode === 'Edit' ? onPointerDown : undefined}
      attributes={attributes}
      options={imageOptions}>
      {body}
      <span style={{ display: 'none' }}>{children}</span>
      {mode === 'Edit' && isSelected ? (
        <ImageVariableElementEditOverlay
          editor={editor}
          options={imageOptions}
          onDelete={onDelete}
          node={node}
          active={isSelected}
        />
      ) : null}
    </ImageElementBox>
  )
}
