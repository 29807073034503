import { Badge, Box, Button, Collapse, Divider, Flex, Text, VStack } from '@chakra-ui/react'
import {
  CoverageEligibilityRequest,
  DateField,
  defaultStageValidate,
  EligibilityBenefit,
  eligibilityServiceTypes,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  formatDollarValue,
  getInsurancePlan,
  InsuranceCoverageId,
  MultipleSelectField,
  practiceField,
  sortByDateKey,
  WithId,
  WithMetadata,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { submitEligibilityRequest } from '../../../../backend'
import {
  PopUpMessageContext,
  ProfileContext,
  ThemeContext,
  ThemeContextData,
  useApp,
  useProfile,
} from '../../../../contexts'
import { ActionLog } from '../../../ActionLog'
import { SolidActionButton } from '../../../Buttons'
import { CollapseError } from '../../../CollapseError'
import { Expandable } from '../../../Expandable'
import { FormElement } from '../../../forms/Input'
import { DefaultModal } from '../../../Modals'

const eligibilityServiceTypesField: MultipleSelectField = {
  type: FieldTypes.MULTIPLE_SELECT,
  placeholder: 'Service Types',
  options: Object.entries(eligibilityServiceTypes)
    .sort(([, a], [, b]) => a.rank - b.rank)
    .map(([key, { name }]) => ({
      id: key,
      text: name,
    })),
}

const serviceDateField: DateField = {
  placeholder: 'Service Date',
  type: FieldTypes.DATE,
  defaultToNow: true,
  isoFormat: true,
}

const baseField: FieldMap = {
  name: 'Eligibility Request',
  children: {
    // insurerId: insuranceProviderField,
    practiceId: practiceField,
    serviceDate: serviceDateField,
    serviceTypes: eligibilityServiceTypesField,
  },
}

const validate = defaultStageValidate(baseField)

const themeData: ThemeContextData = {
  theme: 'detailed',
}

const BenefitView = ({ benefit }: { benefit: EligibilityBenefit }) => {
  const {
    benefitInfoCodeDefinition,
    monetaryAmount,
    textMessages,
    serviceTypeCode,
    serviceTypeCodeDefinition,
    coverageLevelCodeDefinition,
    benefitTimePeriodCodeDefinition,
    inNetwork,
  } = benefit
  const isInNetwork = inNetwork === 'Y'
  return (
    <Flex px={2} py={1} direction="column" justify="center" w="100%">
      <Flex gap={1.5} align="center" w="100%">
        <Text fontSize="lg" lineHeight={1.2}>
          {benefitInfoCodeDefinition}
        </Text>
        <Text fontWeight={600} lineHeight={1}>
          {formatDollarValue(monetaryAmount)}
        </Text>
        <Badge
          ml="auto"
          shadow="md"
          bg={isInNetwork ? 'green.500' : 'gray.50'}
          color={isInNetwork ? 'white' : 'gray.600'}>
          {isInNetwork ? 'In Network' : 'Out of Network'}
        </Badge>
      </Flex>
      <Flex gap={1} p={1} flexFlow="column" w="100%">
        <Text lineHeight={1}>
          {serviceTypeCode} - {serviceTypeCodeDefinition}
        </Text>
        <Text lineHeight={1}>
          {coverageLevelCodeDefinition} - {benefitTimePeriodCodeDefinition}
        </Text>
        <Flex direction="column" w="100%">
          {textMessages?.textMessage.map(({ message }, index) => (
            <Text lineHeight={1.2} key={index} fontSize="sm" color="gray.500">
              {message}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

const EligibilityResponseView = ({
  request,
}: {
  request: WithMetadata<CoverageEligibilityRequest>
}) => {
  const {
    request: { practiceName, insurerName },
    response: { requestErrors, eligibilityBenefits },
    createdBy,
    createdOn,
    createdByGroup,
  } = request
  const errors =
    requestErrors?.requestError && Array.isArray(requestErrors.requestError)
      ? requestErrors.requestError
      : []

  const activeBenefits = useMemo(() => {
    return eligibilityBenefits.eligibilityBenefit
      .filter(b => !!b.monetaryAmount)
      .sort((a, b) => {
        if (b.inNetwork === 'Y' && a.inNetwork === 'N') return 1
        if (a.inNetwork === 'Y' && b.inNetwork === 'N') return -1
        return 0
      })
  }, [eligibilityBenefits])

  return (
    <Expandable
      bg="white"
      initExpanded
      border="1px solid #cdcdcd"
      borderRadius={3}
      header={() => (
        <Flex flexFlow="column" py={1} px={2} w="100%">
          <Text isTruncated lineHeight={1.2} fontWeight={500}>
            {practiceName}
          </Text>
          <Text opacity={0.9} lineHeight={1.2} fontSize="sm" isTruncated fontWeight={600}>
            {insurerName}
          </Text>
        </Flex>
      )}>
      <VStack
        spacing={0}
        divider={<Divider />}
        borderRadius={4}
        direction="column"
        align="flex-start"
        bg="white"
        justify="center"
        w="100%">
        {errors.length ? (
          <Flex py={2} direction="column" align="center" justify="center" w="100%">
            <Text fontSize="sm" fontWeight={600}>
              Request Errors
            </Text>
            {errors.map((error, index) => (
              <Text fontWeight={500} key={`error_${index}`} color="red.600">
                {error.reasonCode} - {error.reasonCodeDefinition}
              </Text>
            ))}
          </Flex>
        ) : null}
        {activeBenefits?.length ? (
          <Flex direction="column" justify="center" w="100%">
            <Flex py={1} px={2}>
              <Text fontWeight={600}>Eligibility Benefits</Text>
            </Flex>
            <VStack borderTop="1px solid #cdcdcd" w="100%" spacing={0} divider={<Divider />}>
              {activeBenefits.map((benefit, index) => (
                <BenefitView key={index} benefit={benefit} />
              ))}
            </VStack>
          </Flex>
        ) : null}
        <Flex p={2} w="100%" borderTop="1px solid #cdcdcd">
          <ActionLog action="Requested" by={createdBy} on={createdOn} group={createdByGroup} />
        </Flex>
      </VStack>
    </Expandable>
  )
}

const PreviousRequestsView = ({
  requests,
}: {
  requests: WithMetadata<WithId<CoverageEligibilityRequest>>[]
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  if (!requests.length) return null
  return (
    <Flex direction="column" w="100%">
      <Button
        onClick={() => setExpanded(!expanded)}
        justifyContent="flex-end"
        pb={1}
        variant="link"
        size="sm"
        fontWeight={600}>
        {expanded ? '-' : '+'} {requests.length} Previous Requests
      </Button>
      <Collapse style={{ width: '100%' }} unmountOnExit in={expanded}>
        <Flex pb={2} w="100%" flexFlow="column" gap={1}>
          {requests.map(request => (
            <EligibilityResponseView key={request.id} request={request} />
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}

export const TestEligibilityRequest = ({ coverageId }: { coverageId: InsuranceCoverageId }) => {
  const { patientRef, selectedAssessment, assessmentId, user } = useContext(ProfileContext)

  const [error, setError] = useState<string | null>(null)
  const { appName } = useApp()

  // const { plans } = selectedAssessment?.populated ?? {}
  const { insurancePlans: plans } = user ?? {}
  const plan = useMemo(
    () => (plans ? getInsurancePlan(plans, coverageId) : null),
    [plans, coverageId],
  )

  const { result, previousRequests } = useMemo(() => {
    const mostRecentRequests = sortByDateKey(plan?.eligibilityRequests ?? {}, 'desc')
    const mostRecentRequest = mostRecentRequests[0]
    return {
      result: mostRecentRequest,
      previousRequests: mostRecentRequests.slice(1),
    }
  }, [plan])
  const [isEditing, setIsEditing] = useState<boolean>(!result)
  const { showMessage } = useContext(PopUpMessageContext)
  const handleSubmit = useCallback(
    (data: FieldMapValue) => {
      if (!assessmentId) {
        return { [FORM_ERROR]: 'No assessment id provided' }
      }
      return submitEligibilityRequest({
        assessmentId,
        appName,
        patientId: patientRef?.id ?? '',
        // coverageId: coverageId || data.coverageId ?? '',
        coverageId,
        practiceId: data.practiceId ?? '',
        // procedureCodes: data.codes,
        serviceTypeCodes: data.serviceTypes,
        serviceDate: data.serviceDate,
        transactionId: null,
        testMode: false,
      })
        .then(() => {
          showMessage({
            text: 'Eligibility request submitted',
            type: 'success',
          })
          setIsEditing(false)
          setError(null)
          return undefined
        })
        .catch(error => {
          showMessage({
            text: 'An error occurred',
            subText: error?.message,
            type: 'error',
          })
          setError(error?.message ?? 'An error occurred')
          return { [FORM_ERROR]: error?.message ?? 'An error occurred' }
        })
    },
    [patientRef, assessmentId, coverageId, appName, showMessage],
  )

  // const plansField = useInsurancePlanField(selectedAssessment?.populated?.plans)

  const initValue = useMemo(() => {
    return {
      insurerId: plans?.primary?.insuranceProviderId ?? '',
      practiceId: selectedAssessment?.populated?.midwifeId ?? '',
    }
  }, [selectedAssessment, plans])

  return (
    <Flex
      bg="gray.100"
      borderBottomRadius={6}
      direction="column"
      align="center"
      justify="center"
      w="100%">
      <Collapse style={{ width: '100%' }} in={!!result && !isEditing}>
        <Flex direction="column" align="center" justify="center" w="100%">
          <Flex px={3} flexFlow="column" maxH="540px" w="100%" overflowY="auto">
            <Text px={2} py={2} fontSize="lg" fontWeight={600}>
              Eligibility Requests
            </Text>
            {result ? (
              <Box pb={2}>
                <EligibilityResponseView request={result} />
              </Box>
            ) : null}
            <PreviousRequestsView requests={previousRequests} />
          </Flex>
          <Flex w="100%" borderTop="1px solid #cdcdcd">
            <SolidActionButton
              borderTopRadius={0}
              borderBottomRadius={6}
              onClick={() => setIsEditing(true)}
              colorScheme="green"
              w="100%">
              New Request
            </SolidActionButton>
          </Flex>
        </Flex>
      </Collapse>
      <Collapse style={{ width: '100%' }} in={!result || !!isEditing}>
        <ThemeContext.Provider value={themeData}>
          <Box bg="gray.50" p={3} w="100%">
            <Form
              onSubmit={handleSubmit}
              initialValues={initValue}
              keepDirtyOnReinitialize={true}
              validate={validate}
              mutators={{ ...(arrayMutators as any) }}>
              {({ submitting, hasValidationErrors, handleSubmit }) => (
                <>
                  {/* {coverageId ? null : <FormElement field={plansField} name="coverageId" />} */}
                  <FormElement field={baseField} name="" />
                  {/* <CptCodesField
                    submitting={submitting}
                    hasValidationErrors={hasValidationErrors}
                  /> */}
                  <CollapseError error={error} />
                  <SolidActionButton
                    onClick={handleSubmit}
                    disabled={submitting || hasValidationErrors}
                    isLoading={submitting}
                    mt={4}
                    w="100%">
                    Submit Request
                  </SolidActionButton>
                </>
              )}
            </Form>
          </Box>
        </ThemeContext.Provider>
      </Collapse>
    </Flex>
  )
}

export const EligibilityRequestPopover = ({ coverageId }: { coverageId: InsuranceCoverageId }) => {
  const { user } = useProfile()
  const { insurancePlans: plans } = user ?? {}
  const [isOpen, setIsOpen] = useState(false)

  const plan = useMemo(
    () => (plans ? getInsurancePlan(plans, coverageId) : null),
    [plans, coverageId],
  )

  const button = useMemo<{ text: string; bg: string; color: string }>(() => {
    const mostRecentRequests = sortByDateKey(plan?.eligibilityRequests ?? {}, 'desc')
    const mostRecentRequest = mostRecentRequests[0]
    if (mostRecentRequest) {
      const benefit = mostRecentRequest.response.eligibilityBenefits?.eligibilityBenefit[0]
      const coverageAmount = benefit?.monetaryAmount
      if (typeof coverageAmount === 'number') {
        const numRequests = mostRecentRequests.length
        return {
          text: `${numRequests} Request${numRequests > 1 ? 's' : ''}`,
          bg: 'green.500',
          color: 'white',
        }
      }
      const error = mostRecentRequest.response.requestErrors?.requestError?.[0]
      if (error) {
        return {
          text: error.reasonCodeDefinition,
          bg: 'red.500',
          color: 'white',
        }
      }
      return {
        text: 'Unknown error',
        bg: 'gray.500',
        color: 'white',
      }
    }
    if (!plan?.memberId)
      return {
        text: 'Member ID required',
        bg: 'gray.500',
        color: 'white',
      }

    return {
      text: 'Submit Eligibility Request',
      bg: 'white',
      color: 'green.500',
    }
  }, [plan])

  return (
    <Box onClick={e => e.stopPropagation()}>
      <SolidActionButton
        onClick={() => setIsOpen(true)}
        border="1px solid"
        textShadow={button.bg === 'white' ? 'none' : '1px 1px 3px #00000077'}
        borderColor={button.bg === 'white' ? '#cdcdcd' : 'transparent'}
        bg={button.bg}
        color={button.color}
        size="xs">
        {button.text.toUpperCase()}
      </SolidActionButton>
      <DefaultModal
        overlayHeader
        size="2xl"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        render={() => <TestEligibilityRequest coverageId={coverageId} />}
      />
    </Box>
  )
}
