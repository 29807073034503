import {
  FieldMap,
  FieldMapValue,
  Form,
  InfoStage,
  OnUploadProgress,
  ShareCorrections,
} from '@hb/shared'
import { FormApi, ValidationErrors } from 'final-form'
import { createContext, FC } from 'react'

export interface FormWizardContextData {
  stage: FieldMap | InfoStage | null
  stageId: string | null
  open: (stageId: string) => void
  storagePath: string | null
  form: Form
  close: () => void
  onSubmitStage: (
    data: FieldMapValue | undefined,
    onUploadProgress: OnUploadProgress,
    form?: FormApi,
  ) => Promise<ValidationErrors>
  data: FieldMapValue
  corrections: (FieldMapValue & { correctionsShared?: ShareCorrections | null }) | null
  readOnly?: boolean
  ReadOnlyFooter?: FC
  adminView?: boolean
  nextStage: string | null
}

export const FormWizardContext = createContext<FormWizardContextData>({
  open: () => {},
  close: () => {},
  form: {
    name: 'ERROR: Outside of form wizard context',
    description: 'ERROR: Outside of form wizard context',
    stages: {},
  },
  adminView: false,
  stage: null,
  stageId: null,
  storagePath: null,
  onSubmitStage: async () => ({ error: 'No id selected' }),
  nextStage: null,
  data: {},
  corrections: null,
})
