import { BaseElement } from 'slate'
import { ShortcutArgs } from '../../types'
import { formatDollarValue } from '../data'
import { getDateString } from '../dates'
import { notUndefinedOrNull } from '../fields'
import { getAuthorizationsValue } from './authorizations'
import { getAllowedCharges, getCostValue, getDepositsString, getReimbursement } from './cost'
import { getInsuranceValue } from './insurance'
import { getMidwifeValue } from './midwife'
import { getPatientValue } from './patient'
import { getPregnancyValue } from './pregnancy'

type ShortcutItem<T = any, Y = any> = (data: T) => Y
const shortcutFunctions: Record<string, ShortcutItem<ShortcutArgs, string | undefined>> = {
  allowedCharges: (args: ShortcutArgs) => {
    const allowed = getAllowedCharges(args)
    if (notUndefinedOrNull(allowed)) {
      return formatDollarValue(allowed.toString())
    }
    return undefined
  },
  reimbursement: (args: ShortcutArgs) => {
    const reimbursement = getReimbursement(args)
    if (notUndefinedOrNull(reimbursement)) {
      return formatDollarValue(reimbursement.toString())
    }
    return undefined
  },
  deposit: (args: ShortcutArgs) => getDepositsString(args),
  insuranceProviderName: (args: ShortcutArgs) => args?.plan?.insuranceProvider?.name,
  shortDate: () => getDateString(new Date(), 'short'),
  longDate: () => getDateString(new Date(), 'long'),
  authRefNumber: (args: ShortcutArgs) => {
    const [mostRecentAuth] = Object.entries(args.assessment?.authorizations ?? {}).sort(
      ([a], [b]) => parseInt(b, 10) - parseInt(a, 10),
    )

    return mostRecentAuth?.[1]?.referenceNumber
  },
  memberId: ({ plan }: ShortcutArgs) => {
    if (!plan) return 'None'
    return plan?.memberId
  },
}
export function getShortcutValue(node: any, shortcutArgs: ShortcutArgs) {
  const { id, path } = node as BaseElement & { id: string; path: string }
  const firstSegment = path.split('.')[0]
  const shortcutFunction = shortcutFunctions[id]
  switch (firstSegment) {
    case 'cost':
      return getCostValue(path, shortcutArgs)
    case 'patient':
      return getPatientValue(path, shortcutArgs)
    case 'pregnancy':
      return getPregnancyValue(path, shortcutArgs)
    case 'midwife':
    case 'practice':
      return getMidwifeValue(path, shortcutArgs)
    case 'insurance':
      return getInsuranceValue(path, shortcutArgs)
    case 'authorizations':
      return getAuthorizationsValue(path, shortcutArgs)
    default:
      if (!shortcutFunction) return undefined
      return shortcutFunction(shortcutArgs)
  }
}
