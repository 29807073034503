import { FaxStatus } from '../types'
import { version, VersionKey } from './version'

export const TELNYX_BASE_URL = 'https://api.telnyx.com/v2/faxes'
export const TELNYX_APP_ID_DEV = '2095295848664532628'
export const TELNYX_APP_ID_PROD = '2095279591500285868'

export const TELNYX_FAX_NUMBER_DEV = '+15205250682'
export const TELNYX_FAX_NUMBER_PROD = '+18773231726'

export const fromNumbers: Record<VersionKey, string> = {
  dev: TELNYX_FAX_NUMBER_DEV,
  staging: TELNYX_FAX_NUMBER_PROD,
  prod: TELNYX_FAX_NUMBER_PROD,
}
export const FROM_NUMBER = fromNumbers[version]

export const pendingFaxStatuses: FaxStatus[] = [
  'initiated',
  'media.processed',
  'media.processing',
  'originated',
  'queued',
  'receiving',
  'sending',
]

export const failedFaxStatuses: FaxStatus[] = ['failed']
