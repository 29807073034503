import { Box, Divider, Text, VStack } from '@chakra-ui/react'
import {
  corporateAddressField,
  FieldMapValue,
  midwivesCollection,
  paymentsAddressField,
  payToAddressField,
  PracticeWithAdmin,
  providerAddressNotesField,
  servicingAddressField,
  WithId,
} from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { DataHeader } from '../DataView/DataHeader'
import { DataView, DataViewList } from '../DataView/DataView'
import { Expandable } from '../Expandable'
import { EditableRow } from '../forms/Input'

const toAddress = (data: FieldMapValue) => ({
  streetAddress: data?.streetAddress ?? '',
  streetAddress2: data?.streetAddress2 ?? '',
  city: data?.city ?? '',
  state: data?.state ?? '',
  zip: data?.zip ?? '',
})

export const ProviderAddressInfo = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const { servicingAddresses, paymentsAddress, corporateAddress, payToAddress, addressNotes } =
    midwife ?? {}
  return (
    <Box borderRadius={4} w="100%">
      <Expandable
        nested
        header={() => (
          <DataHeader>
            <Text py={1} fontSize="sm">
              Addresses
            </Text>
          </DataHeader>
        )}>
        <Box bg="gray.100" p={2} w="100%">
          <VStack borderRadius={4} bg="white" w="100%" align="flex-start" spacing={0}>
            <Box w="100%">
              <DataViewList
                initExpanded
                value={servicingAddresses}
                itemField={servicingAddressField}
                onSubmit={v =>
                  updateItem(midwife?.id ?? '', 'servicingAddresses', v.map(toAddress))
                }
                itemName="Servicing Address"
              />
            </Box>
            <Divider />
            <DataView
              onSubmit={v => updateItem(midwife?.id ?? '', 'paymentsAddress', toAddress(v))}
              updateField={(path, v) => updateItem(midwife?.id ?? '', `paymentsAddress.${path}`, v)}
              field={paymentsAddressField}
              size="sm"
              initExpanded={false}
              data={paymentsAddress}
            />
            <Divider />
            <DataView
              onSubmit={data => updateItem(midwife?.id ?? '', 'corporateAddress', toAddress(data))}
              field={corporateAddressField}
              updateField={(path, v) =>
                updateItem(midwife?.id ?? '', `corporateAddress.${path}`, v)
              }
              size="sm"
              initExpanded={false}
              data={corporateAddress}
            />
            <Divider />
            <DataView
              onSubmit={data => updateItem(midwife?.id ?? '', 'payToAddress', toAddress(data))}
              updateField={(path, v) => updateItem(midwife?.id ?? '', `payToAddress.${path}`, v)}
              field={payToAddressField}
              size="sm"
              initExpanded={false}
              data={payToAddress}
            />
            <Divider />
            <EditableRow
              label="Address Notes"
              field={providerAddressNotesField}
              value={addressNotes}
              onSubmit={data => updateItem(midwife?.id ?? '', 'addressNotes', data)}
            />
          </VStack>
        </Box>
      </Expandable>
    </Box>
  )
}
