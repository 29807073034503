import { PopulatedUser } from '@hb/shared'
import { useMemo } from 'react'

export const useAssessmentAutoTemplate = (user: PopulatedUser | null) => {
  const { insuranceProvider, insurancePlans } = user ?? {}

  return useMemo(() => {
    const { plan } = insurancePlans?.primary ?? {}
    if (plan?.templateId) return plan.templateId
    if (insuranceProvider?.defaultTemplateId) {
      return insuranceProvider.defaultTemplateId
    }
    return undefined
  }, [insurancePlans, insuranceProvider])
}
