import { LocalCollectionState, PracticeWithAdmin } from '@hb/shared'
import { createContext, useContext } from 'react'

export interface PracticeContextData {
  practices?: LocalCollectionState<PracticeWithAdmin>
  practice?: PracticeWithAdmin
  id?: string
}
export const PracticeContext = createContext<PracticeContextData>({
  practices: undefined,
  practice: undefined,
  id: undefined,
})

export const usePracticeContext = () => useContext(PracticeContext)
