import { Flex, Image } from '@chakra-ui/react'
import {
  InfoBox,
  InfoHeader,
  InfoText,
  MyAssessmentShortcut,
  PageContainer,
} from '@hb/shared-frontend'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useImagesLoaded } from '../../hooks/useImagesLoaded'
import patientBubble from './patientbubble.png'
import providerBubble from './providerbubble.png'
import homepageImage from './welcome.svg'

export const useMounted = (duration = 250) => {
  const [mounted, set] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (set) set(true)
    }, duration)
  }, [duration])
  return mounted
}

const HomeView = () => {
  const { loaded, onLoad } = useImagesLoaded(1)
  const mountedPre = useMounted()
  const mounted = loaded && mountedPre
  return (
    <PageContainer opacity={mounted ? 1 : 0}>
      <Flex position="relative" my={12} mt={16} w={[325, 400, 500, 600]}>
        <Image onLoad={onLoad} w="100%" src={homepageImage} />
        <Link style={{ opacity: 1 }} to="/providers">
          <Image
            transform={`scale(${mounted ? 1 : 0.5})`}
            transition="all 750ms cubic-bezier(0.16, 1, 0.3, 1)"
            opacity={mounted ? 1 : 0}
            w="30%"
            transformOrigin="bottom right"
            src={providerBubble}
            filter={'drop-shadow(0 0 3px rgba(0,0,0,0.7))'}
            _hover={{ filter: 'drop-shadow(0 0 6px rgba(0,0,0,0.7))' }}
            pos="absolute"
            top="-10%"
            left="20%"
          />
        </Link>
        <Link style={{ opacity: 1 }} to="/patients">
          <Image
            transform={`scale(${mounted ? 1 : 0.5})`}
            transition="all 1000ms cubic-bezier(0.16, 1, 0.3, 1)"
            opacity={mounted ? 1 : 0}
            w="30%"
            src={patientBubble}
            transformOrigin="bottom left"
            filter={'drop-shadow(0 0 3px rgba(0,0,0,0.7))'}
            _hover={{ filter: 'drop-shadow(0 0 6px rgba(0,0,0,0.7))' }}
            pos="absolute"
            top="2%"
            right="2%"
          />
        </Link>
      </Flex>
      <InfoBox spacing={2}>
        <InfoHeader style={{ textAlign: 'center', fontSize: '20px' }}>Welcome!</InfoHeader>
        <InfoText>
          Hamilton Billing has been around for over a decade, supporting providers and families
          seeking a more personalized and holistic approach to pregnancy. We believe in the
          midwifery model of care and that patients should have access to coverage. Click one of the
          speech bubbles above so we can help!
        </InfoText>
      </InfoBox>
      <MyAssessmentShortcut />
    </PageContainer>
  )
}

export default HomeView
