import { Center, Text, VStack } from '@chakra-ui/react'
import {
  getPlanNextAction,
  getPlansArray,
  InsuranceCoverage,
  NextAction,
  parseNextActionDate,
  WithInsuranceCoverageId,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ProfileContext } from '../../../../contexts'
import { Expandable } from '../../../Expandable'
import { Loading } from '../../../Loading'
import { CoverageNextAction } from '../NextAction'

export const PlanNextActions = () => {
  const { user, assessmentId, patientRef, userId } = useContext(ProfileContext)

  const { insurancePlans: plans, nextActions } = user ?? {}

  const additionalPlansArr = useMemo(
    () => getPlansArray({ additional: plans?.additional }),
    [plans],
  )
  const additionalPlanNextActions = useMemo(
    () =>
      additionalPlansArr
        .reduce<{ nextAction: NextAction; plan: WithInsuranceCoverageId<InsuranceCoverage> }[]>(
          (acc, curr) => {
            const nextAction = getPlanNextAction(nextActions ?? {}, curr.id)
            if (nextAction) acc.push({ nextAction, plan: curr })
            return acc
          },
          [],
        )
        .sort((a, b) => {
          const aDate = parseNextActionDate(a.nextAction?.text)
          const bDate = parseNextActionDate(b.nextAction?.text)
          return aDate - bDate
        }),
    [nextActions, additionalPlansArr],
  )

  return (
    <Expandable
      borderTop="1px solid #cdcdcd"
      style={{ width: '100%' }}
      initExpanded
      headerProps={{ bg: 'white' }}
      header={() => (
        <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
          Coverage Next Actions
        </Text>
      )}>
      {assessmentId && userId && patientRef ? (
        <VStack bg="#efefef" spacing={0} w="100%">
          <CoverageNextAction
            assessmentId={assessmentId}
            coverage={plans?.primary ?? undefined}
            id="primary"
            patientId={userId}
            nextAction={nextActions?.primaryCoverage}
          />
          {plans?.secondary ? (
            <CoverageNextAction
              coverage={plans.secondary}
              assessmentId={assessmentId}
              patientId={userId}
              id="secondary"
              nextAction={nextActions?.secondaryCoverage}
            />
          ) : null}
          {additionalPlanNextActions.map(({ plan, nextAction }) => (
            <CoverageNextAction
              coverage={plan}
              key={plan.id}
              patientId={userId}
              assessmentId={assessmentId}
              id={plan.id}
              nextAction={nextAction}
            />
          ))}
        </VStack>
      ) : (
        <Center>
          <Loading text="Loading insurance plans..." />
        </Center>
      )}
    </Expandable>
  )
}
