import { AnyObject, Collection, CollectionItem } from '@hb/shared'
import { useEffect, useMemo, useState } from 'react'
import { subscribeToCollectionItem } from '../../collections/utils/read'
import { useAppGroup } from './user/useAppGroup'

interface UseCollectionItem<Data extends AnyObject> {
  item: CollectionItem<Data> | null
  loading: boolean
  error: string | null
}

export function useCollectionItem<Data extends AnyObject = AnyObject>(
  collection: Collection<Data> | null,
  itemId?: string | null,
): UseCollectionItem<Data> {
  // const collectionState = useCollection(collection)
  // const { loading, items = [] } = collectionState ?? {}

  // const item = useMemo(
  //   () => (items && itemId ? (items as CollectionItem<Data>[]).find((i) => i.id === itemId) : undefined),
  //   [items, itemId],
  // )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [item, setItem] = useState<CollectionItem<Data> | null>(null)

  const accessLevel = useAppGroup()
  useEffect(() => {
    if (!collection || !itemId || !collection.access.includes(accessLevel)) {
      setLoading(false)
      setError(null)
      setItem(null)
      return () => {}
    }
    return subscribeToCollectionItem(collection, accessLevel, itemId, {
      onData: data => {
        setItem(data)
      },
      onError: newError => {
        setError(newError)
      },
      onLoading: newLoading => {
        setLoading(newLoading)
      },
    })
  }, [collection, accessLevel, itemId])

  return useMemo(() => ({ item, loading, error }), [item, loading, error])
}
