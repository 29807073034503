import { Switch, Text, VStack } from '@chakra-ui/react'
import { cptCodes, DropdownField, FieldTypes, formatDollarValue, TextField } from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { useField } from 'react-final-form'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { ThemeContext } from '../../../../../../contexts/ThemeContext'
import Input, { StandaloneInput } from '../../../../../forms/Input'

const manualEntryField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'CPT Code',
}
export const HCPCField = ({ name, noLabel }: { name: string; noLabel?: boolean }) => {
  const { input, meta } = useField(name, { subscription: { value: true } })
  const { value, onChange } = input
  const [manualEntry, setManualEntry] = useState(false)
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { midwife } = selectedAssessment ?? {}
  const { billedCharges = {} } = midwife ?? {}

  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      searchable: true,
      placeholder: 'CPT Code',
      options: Object.values(cptCodes).map(({ name: groupName, codes }) => ({
        title: groupName,
        options: codes.map(c => ({
          text: `${c.code} - ${c.description}  |  ${formatDollarValue(billedCharges[`_${c.code}`] ?? 0)}`,
          id: c.code,
        })),
      })),
    }),
    [billedCharges],
  )

  return (
    <VStack px={2} w="100%" align="flex-start">
      {noLabel ? null : (
        <Text fontWeight={600} color="gray.500" fontSize="sm">
          CPT Code
        </Text>
      )}
      <Switch
        colorScheme="green"
        color="gray.600"
        isChecked={manualEntry}
        onChange={e => setManualEntry(e.target.checked)}>
        {manualEntry ? 'Manual Entry' : 'Select from List'}
      </Switch>
      {manualEntry ? (
        <ThemeContext.Provider value={{ placeholderAbove: false, theme: 'detailed' }}>
          <Input field={manualEntryField} input={input} meta={meta} />
        </ThemeContext.Provider>
      ) : (
        <StandaloneInput theme="detailed" field={field} value={value} onChange={onChange} />
      )}
    </VStack>
  )
}
