import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { UserGroup } from '@hb/shared'
import React from 'react'

export interface AlternateValue {
  group: UserGroup
  value: string
}

const AlternateValueContent = ({ value, group }: AlternateValue) => (
  <Flex align="center" direction="column">
    <Text fontSize="sm" color="gray.600" lineHeight={1} fontWeight={500}>
      {group === 'patient' ? 'Original' : 'Edited'} answer from{' '}
      {group === 'patient' ? 'patient' : 'Hamilton Billing'}
    </Text>
    <Text>{value ?? 'None'}</Text>
  </Flex>
)

export const AlternateValuePopover = ({ value, group }: AlternateValue) => (
  <Popover strategy="fixed" trigger="hover" placement="top">
    <PopoverTrigger>
      <QuestionOutlineIcon aria-label="Original answer" cursor="pointer" width={4} ml={2} />
    </PopoverTrigger>
    <Portal>
      <PopoverContent w="auto">
        <PopoverBody w="auto" borderRadius={4} bg="gray.50">
          <AlternateValueContent value={value} group={group} />
        </PopoverBody>
        <PopoverArrow />
      </PopoverContent>
    </Portal>
  </Popover>
)
