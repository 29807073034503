import { getAppRole } from '@hb/shared'
import { useMemo } from 'react'
import { underMaintenanceView } from '../components/Views/UnderMaintenanceView'
import { useApp, usePracticeAccess } from '../contexts'
import { useAuth } from '../store'
import { View } from '../types/views'

export const useFilteredViews = (views: View[]) => {
  const { appName, isUnderMaintenance } = useApp()

  const { selectedPracticeId } = usePracticeAccess()
  const claims = useAuth(s => s.claims)
  const authUser = useAuth(s => s.authUser)
  const appRole = useMemo(
    () => getAppRole(appName, authUser?.uid ?? null, claims, selectedPracticeId),
    [appName, claims, selectedPracticeId, authUser],
  )
  return useMemo<View[]>(
    () =>
      isUnderMaintenance
        ? [underMaintenanceView]
        : views.reduce<View[]>((prev, curr) => {
            const hasAccess = !curr.access || (appRole && curr.access.includes(appRole))

            return hasAccess || (!authUser && curr.signIn)
              ? [...prev, { ...curr, onMenu: curr.onMenu && hasAccess === true }]
              : prev
          }, []),
    [appRole, views, authUser, isUnderMaintenance],
  )
}

export const useViewRoutes = (views: View[]) => {
  const allRoutes = useMemo(
    () =>
      views
        .map(({ path, component }) => {
          const paths = Array.isArray(path) ? path : [path]
          return paths.map(p => ({ path: p, component }))
        })
        .flat(),
    [views],
  )
  return allRoutes
}
