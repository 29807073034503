import { get as nestedGet } from 'nested-property'
import { ShortcutArgs } from '../../types'
import { addressToString } from '../data'

export const getPatientValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  // ex: payments.received.0
  const { assessment, user } = shortcutArgs ?? {}
  const [, fieldPath] = path.split('.')
  const { mergedSignOnData } = assessment ?? {}
  const address = mergedSignOnData?.address
  if (!fieldPath) return undefined
  switch (fieldPath) {
    case 'address':
      return address ? addressToString(address) : undefined
    case 'address2':
      return address ? addressToString(address, true) : undefined
    default: {
      return nestedGet(user, fieldPath)
    }
  }
}
