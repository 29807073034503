import { Text, VStack } from '@chakra-ui/react'
import { FieldMapValue } from '@hb/shared'

import React, { useCallback, useContext } from 'react'
import { addAdditionalPatientToInvoice } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { SimpleForm } from '../../forms'
import { DefaultModal } from '../../Modals'
import { additionalPatientField } from './field'

export const AddAdditionalPatientModal = ({
  onClose,
  invoiceId,
}: {
  onClose: () => void
  invoiceId: string
}) => {
  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const onSubmit = useCallback(
    async (data: FieldMapValue) => {
      try {
        const { data: res } = await addAdditionalPatientToInvoice({
          fname: data.fname ?? null,
          lname: data.lname ?? null,
          nickname: data.nickname ?? null,
          edd: data.edd ?? null,
          deliveredOn: data.deliveredOn ?? null,
          additionalPatientId: null,
          invoiceId,
        })
        if (res.success) {
          showSuccess('Additional patient added successfully')
          onClose()
        } else {
          showError(res.error ?? 'Error adding additional patient')
        }
        return res
      } catch (err: any) {
        const errMessage = err?.message ?? 'Error adding additional patient'
        showError(errMessage)
        return { error: errMessage }
      }
    },
    [invoiceId, onClose, showError, showSuccess],
  )
  return (
    <DefaultModal
      overlayHeader
      portalProps={{ appendToParentPortal: true }}
      isOpen
      onClose={onClose}
      render={() => (
        <VStack align="flex-start" spacing={0}>
          <Text px={4} pt={4} fontFamily="Hero-New">
            Add Additional Patient to Invoice
          </Text>
          <SimpleForm theme="detailed" field={additionalPatientField} onSubmit={onSubmit} />
        </VStack>
      )}
    />
  )
}
