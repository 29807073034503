import { ASSESSMENT_INVOICE_SNIPPETS, AssessmentInvoiceSnippet } from '@hb/shared'
import { useMemo } from 'react'
import { useAuth } from '../../../store'
import { useDocument } from '../useDocument'

export const useAssessmentInvoiceSnippets = (assessmentId: string | undefined | null) => {
  const role = useAuth(s => s.role)
  const passedId = useMemo(
    () => (role === 'super-admin' && assessmentId ? assessmentId : null),
    [assessmentId, role],
  )
  const { data, ...passed } = useDocument<Record<string, AssessmentInvoiceSnippet>>(
    ASSESSMENT_INVOICE_SNIPPETS,
    passedId,
  )
  const { id: _id, ...rest } = data ?? {}
  return { data: rest, ...passed }
}
