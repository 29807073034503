import { ArrowForwardIcon, CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  CircularProgress,
  Collapse,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  authenticatorDisplayNameField,
  colors,
  twoFactorCodeField,
  UpdateCallback,
} from '@hb/shared'
import {
  multiFactor,
  MultiFactorSession,
  TotpMultiFactorGenerator,
  TotpSecret,
} from 'firebase/auth'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { signOut, useAuth } from '../../../store'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { StandaloneInput } from '../../forms'
import { useMultiFactorSession } from './hooks'
import { StepNumberSpan } from './StepNumberSpan'

const TotpEnterCode = ({
  onBack,
  onVerify,
}: {
  onBack: () => void
  onVerify: (c: string) => Promise<UpdateCallback>
}) => {
  const [code, setCode] = useState('')

  const [verifyingCode, setVerifyingCode] = useState(false)

  const handleVerify = useCallback(() => {
    setVerifyingCode(true)
    onVerify(code).finally(() => setVerifyingCode(false))
  }, [code, onVerify])

  return (
    <VStack px={1} w="100%" align="flex-start">
      <Text fontFamily="Hero-New" fontSize="md" color="gray.600">
        <StepNumberSpan step={3} />
        Please enter the code on your Authenticator App.
      </Text>
      <StandaloneInput
        theme="detailed"
        field={twoFactorCodeField}
        value={code}
        onChange={setCode}
      />
      <HStack w="100%">
        <ActionButton
          size="sm"
          bg="white"
          border="1px solid #cdcdcd"
          isDisabled={verifyingCode}
          color="gray.500"
          onClick={onBack}
          _hover={{
            bg: 'gray.100',
          }}>
          Back to Step 2
        </ActionButton>
        <ActionButton
          ml="auto"
          size="sm"
          onClick={handleVerify}
          bg={colors.green.hex}
          isLoading={verifyingCode}
          color="white"
          border="none">
          Verify
        </ActionButton>
      </HStack>
    </VStack>
  )
}

const TotpDisplayQrCode = ({
  secret,
  onProceed,
  email,
  onBack,
}: {
  secret: TotpSecret
  email: string
  onProceed: (displayName: string) => void
  onBack: () => void
}) => {
  const [displayName, setDisplayName] = useState('')
  const qrCode = useMemo(() => secret.generateQrCodeUrl(email, 'Hamilton Billing'), [secret, email])

  return (
    <VStack px={1} w="100%" align="flex-start">
      <Center gap={2} flexFlow="column" w="100%">
        <Text
          py={1}
          textAlign="center"
          maxW="400px"
          fontFamily="Hero-New"
          fontSize="md"
          color="gray.600">
          <StepNumberSpan step={2} />
          Scan the QR code below with your authenticator app or enter the code manually.
        </Text>
        <Box p={2} border="1px solid #cdcdcd" borderRadius={4} bg="white" w="100%" maxW="300px">
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={qrCode}
            viewBox={'0 0 256 256'}
          />
        </Box>
        <HStack maxW="100%" pt={1} spacing={1}>
          <Text fontSize="sm" flex={1} minW="0" isTruncated>
            {secret.secretKey}
          </Text>
          <IconButton
            aria-label="Copy secret key"
            icon={<CopyIcon />}
            variant="ghost"
            size="xs"
            onClick={() => {
              navigator.clipboard.writeText(secret.secretKey)
            }}
          />
        </HStack>
        <Text fontFamily="Hero-New" fontSize="sm" color="gray.600">
          After scanning the QR code, click the button below to proceed to verification.
        </Text>
        <StandaloneInput
          theme="detailed"
          field={authenticatorDisplayNameField}
          onChange={v => {
            setDisplayName(v)
          }}
          value={displayName}
        />
        <HStack w="100%">
          <ActionButton
            size="sm"
            bg="white"
            border="1px solid #cdcdcd"
            color="gray.500"
            onClick={onBack}
            _hover={{
              bg: 'gray.100',
            }}>
            Back to Step 2
          </ActionButton>
          <SolidActionButton
            gap={1}
            ml="auto"
            opacity={displayName ? 1 : 0.5}
            onClick={() => {
              if (!displayName) return
              onProceed(displayName)
            }}>
            <Text>Continue to Verification</Text>
            <ArrowForwardIcon w={5} h={5} filter="drop-shadow(1px 1px 3px rgba(0,0,0,0.5))" />
          </SolidActionButton>
        </HStack>
      </Center>
    </VStack>
  )
}

export const TotpEnroll = ({ onBack }: { onBack: () => void }) => {
  const user = useAuth(s => s.enrollingUser)
  const [qrCodeError, setQrCodeError] = useState('')

  const [totpSecretKey, setTotpSecretKey] = useState<TotpSecret | null>(null)
  const [needsRecentLogin, setNeedsRecentLogin] = useState(false)
  const [onVerifyStep, setOnVerifyStep] = useState(false)
  const [secretError, setSecretError] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { processResponse } = useContext(PopUpMessageContext)

  const [loadingQrCode, setLoadingQrCode] = useState(true)
  const handleSessionLoad = useCallback(
    (session: MultiFactorSession) => {
      setSecretError('')
      TotpMultiFactorGenerator.generateSecret(session)
        .then(secret => {
          if (!user?.email) {
            setQrCodeError('No email found')
            return
          }
          setTotpSecretKey(secret)
        })
        .catch(e => {
          // recaptchaVerifier.clear()
          if (e.code === 'auth/requires-recent-login') {
            setNeedsRecentLogin(true)
            processResponse({
              error: 'Please log in again to enroll your phone',
            })
          } else if (e.code === 'auth/maximum-second-factor-count-exceeded') {
            setSecretError(
              'You are limited to 1 authenticator app per account. Please remove an existing one before enrolling a new one.',
            )
          } else {
            console.error(e)
            setSecretError(e?.message)
          }
        })
        .finally(() => setLoadingQrCode(false))
    },
    [processResponse, user],
  )
  useMultiFactorSession(handleSessionLoad)

  const verifyCode = useCallback(
    async (code: string) => {
      if (!user) return processResponse({ error: 'No user' })
      if (!displayName) return processResponse({ error: 'No display name' })
      if (!totpSecretKey) return processResponse({ error: 'No secret key' })
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecretKey,
        code,
      )
      try {
        await multiFactor(user).enroll(multiFactorAssertion, displayName)
        onBack()
        setLoadingQrCode(false)
        await signOut()
        return processResponse({
          success: 'Authenticator enrolled, please sign in again to complete enrollment!',
        })
      } catch (err) {
        const asHttpError = err as { code: string; message: string }
        setLoadingQrCode(false)
        return processResponse({ error: asHttpError.message ?? 'An error occurred' })
      }
    },
    [onBack, processResponse, totpSecretKey, user, displayName],
  )

  const { email } = user ?? {}
  const error = useMemo(() => {
    if (!email) return 'No email found'
    if (needsRecentLogin) return 'Please log in again to enroll your authenticator app'
    return qrCodeError || secretError
  }, [email, needsRecentLogin, qrCodeError, secretError])

  return (
    <Box w="100%">
      <Collapse unmountOnExit in={loadingQrCode} style={{ width: '100%' }}>
        <HStack spacing={1}>
          <CircularProgress isIndeterminate size={6} color="green.500" />
          <Text fontFamily="Open Sans" color="gray.600" fontSize="md">
            Loading QR code...
          </Text>
        </HStack>
      </Collapse>
      <Collapse
        unmountOnExit
        in={!needsRecentLogin && !!totpSecretKey && !loadingQrCode && !onVerifyStep}
        style={{ width: '100%' }}>
        {totpSecretKey && email ? (
          <TotpDisplayQrCode
            onBack={onBack}
            email={email}
            onProceed={disp => {
              setDisplayName(disp)
              setOnVerifyStep(true)
            }}
            secret={totpSecretKey}
          />
        ) : null}
      </Collapse>
      <Collapse unmountOnExit in={needsRecentLogin} style={{ width: '100%' }}>
        <VStack
          border="1px solid #cdcdcd"
          py={2}
          mt={2}
          bg="white"
          px={3}
          borderRadius={4}
          w="100%">
          <Text fontFamily="Open Sans">
            You must have a fresh login session to enroll your phone. Please sign out and then sign
            back in to continue.
          </Text>
          <SolidActionButton
            gap={1}
            onClick={() => {
              setNeedsRecentLogin(false)
              useAuth.getState().recaptchaVerifier.clear()
              signOut()
              onBack()
            }}>
            <Text>Sign Out</Text>
            <ArrowForwardIcon w={5} h={5} filter="drop-shadow(1px 1px 3px rgba(0,0,0,0.5))" />
          </SolidActionButton>
        </VStack>
      </Collapse>
      <CollapseError error={error} />
      <Collapse unmountOnExit in={!loadingQrCode && onVerifyStep} style={{ width: '100%' }}>
        <TotpEnterCode
          onBack={() => setOnVerifyStep(false)}
          onVerify={code => verifyCode(code)}
          // onVerify={() => new Promise((_, rej) => { rej(new Error('Testing')) })}
        />
      </Collapse>
    </Box>
  )
}
