import { ConsentForm, FileDBValue } from '@hb/shared'
import React, { useMemo } from 'react'
import { FileValuePdfView } from '../../PdfView'
import { EditorSize } from '../../RichText'
import { OnSubmitForm } from '../../RichText/DocumentFormProvider'

export const ConsentFormPdfView = ({
  consentForm,
  editorSize,
  onFormSubmit,
}: {
  consentForm: ConsentForm
  editorSize: EditorSize
  onFormSubmit?: OnSubmitForm
}) => {
  const { externalPdf, signedStoragePath, signedOn } = consentForm

  const signedValue = useMemo<FileDBValue | null>(
    () =>
      signedStoragePath && signedOn
        ? { storagePath: signedStoragePath, type: 'application/pdf', uploadedOn: signedOn, id: '' }
        : null,
    [signedStoragePath, signedOn],
  )

  if (signedStoragePath) {
    return (
      <FileValuePdfView value={signedValue} height={editorSize.height} width={editorSize.width} />
    )
  }

  return (
    <FileValuePdfView
      value={externalPdf.baseFile}
      attachments={externalPdf.attachments}
      document={consentForm}
      onFormSubmit={onFormSubmit}
      height={editorSize.height}
      width={editorSize.width}
    />
  )
}
