import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react'
import { defaultStageValidate, newSignOnFields } from '@hb/shared'
import { updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useMe } from '../../../../hooks/backend/useMe'
import { ActionLog } from '../../../ActionLog'
import { ActionConfirm } from '../../../Alerts/ActionConfirm'
import { SolidActionButton } from '../../../Buttons/ActionButton'

export const SkipToSignOn = ({ mobileLayout }: { mobileLayout?: boolean }) => {
  const {
    selectedAssessment: { ref, assessmentData: selectedAssessment },
  } = useContext(ProfileContext)
  const me = useMe()
  const { showMessage } = useContext(PopUpMessageContext)
  const {
    skippedQuestionnaire,
    signedOnDate,
    signOnData,
    questionnaireSkippedBy,
    questionnaireSkippedOn,
    signOnSkippedBy,
    signOnSkippedOn,
  } = selectedAssessment ?? {}
  const { isOpen: skipQOpen, onClose: skipQOnclose, onOpen: skipQonOpen } = useDisclosure()
  const { isOpen: skipSOpen, onClose: skipSOnClose, onOpen: skipSOnOpen } = useDisclosure()

  const signOnComplete = useMemo(() => {
    const errors = defaultStageValidate(newSignOnFields, true)(signOnData)
    return !errors
  }, [signOnData])

  const onSkipQuestionnaire = useCallback(
    async (skipped: boolean) => {
      const onError = (subText: string | undefined) => {
        showMessage({
          type: 'error',
          text: 'Error skipping questionnaire',
          subText,
        })
      }
      const uid = me?.uid
      if (!uid) return onError('not signed in')
      if (!ref) return onError('no assessment selected')
      try {
        await updateDoc(ref, {
          skippedQuestionnaire: skipped,
          questionnaireSkippedBy: skipped ? uid : null,
          questionnaireSkippedOn: skipped ? Date.now() : null,
        })
      } catch (err: any) {
        onError(err.message)
      }
    },
    [ref, showMessage, me],
  )

  const onSkipSignOn = useCallback(
    async (skipped: boolean) => {
      const onError = (subText: string | undefined) => {
        showMessage({
          type: 'error',
          text: 'Error skipping sign on',
          subText,
        })
      }
      const uid = me?.uid
      if (!uid) return onError('not signed in')
      if (!ref) return onError('no assessment selected')
      try {
        await updateDoc(ref, {
          signedOnDate: skipped ? Date.now() : null,
          signOnSkippedBy: skipped ? uid : null,
          signOnSkippedOn: skipped ? Date.now() : null,
        })
      } catch (err: any) {
        onError(err.message)
      }
    },
    [ref, showMessage, me],
  )

  const requiresQuestionnaire = !selectedAssessment?.submittedOn

  return (
    <Flex
      flexFlow={mobileLayout ? 'column' : 'row'}
      ml={mobileLayout ? 0 : 'auto'}
      gap={2}
      align={mobileLayout ? 'center' : 'flex-end'}>
      {requiresQuestionnaire ? (
        <>
          <Flex flexFlow="column">
            <Collapse in={!!questionnaireSkippedBy || !!questionnaireSkippedOn}>
              <Box pb={1}>
                <ActionLog
                  action="Skipped"
                  dateStyle="date"
                  by={questionnaireSkippedBy}
                  on={questionnaireSkippedOn}
                  group="admin"
                />
              </Box>
            </Collapse>
            <SolidActionButton w="100%" ml="auto" size="xs" onClick={skipQonOpen}>
              {skippedQuestionnaire ? 'Require Questionnaire' : 'Skip Questionnaire'}
            </SolidActionButton>
          </Flex>
          <ActionConfirm
            isOpen={skipQOpen}
            confirmText={skippedQuestionnaire ? 'Require questionnaire' : 'Skip to sign on'}
            header="Are you sure?"
            body={
              skippedQuestionnaire
                ? 'This will require the patient to complete the questionnaire'
                : 'This will skip the questionnaire and take the patient straight to the sign-on form'
            }
            onClose={skipQOnclose}
            onConfirm={() => onSkipQuestionnaire(!skippedQuestionnaire)}
          />
        </>
      ) : null}
      {!requiresQuestionnaire || skippedQuestionnaire ? (
        <>
          <Flex flexFlow="column">
            <Collapse in={!!signOnSkippedBy}>
              <Box pb={1}>
                <ActionLog
                  group="admin"
                  action={signOnComplete ? 'Sign on skipped' : 'Skipped'}
                  by={signOnSkippedBy}
                  on={signOnSkippedOn}
                />
              </Box>
            </Collapse>
            {signOnComplete ? null : (
              <SolidActionButton w="100%" ml="auto" size="xs" onClick={skipSOnOpen}>
                {signedOnDate ? 'Require Sign On' : 'Skip Sign-On Form'}
              </SolidActionButton>
            )}
          </Flex>
          <ActionConfirm
            isOpen={skipSOpen}
            confirmText={signedOnDate ? 'Require sign on' : 'Skip Sign-On form'}
            header="Are you sure?"
            body={
              signedOnDate
                ? 'This will require the patient to complete the sign-on form'
                : 'This will skip the sign-on form and take the patient straight to consent forms'
            }
            onClose={skipSOnClose}
            onConfirm={() => onSkipSignOn(!signedOnDate)}
          />
        </>
      ) : null}
    </Flex>
  )
}
