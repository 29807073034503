import { EditIcon } from '@chakra-ui/icons'
import { Checkbox, HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  formatPhoneNumber,
  optIntoTextField,
  phoneField,
  User,
  voicemailOkField,
  WithId,
} from '@hb/shared'

import { FORM_ERROR, ValidationErrors } from 'final-form'
import { doc } from 'firebase/firestore'
import React, { useCallback, useState } from 'react'
import { USERS_REF, useUpdateDoc } from '../../hooks'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../Modals/DefaultModal'

const phoneEditField: FieldMap = {
  name: 'Phone Info',
  children: {
    phone: phoneField,
    voicemailOk: voicemailOkField,
    textOk: optIntoTextField,
  },
}

export const PhoneEdit = ({ user }: { user: WithId<User> | null }) => {
  const { phone, voicemailOk, textOk } = user ?? {}
  const [modalOpen, setModalOpen] = useState(false)
  const updateDoc = useUpdateDoc()
  const onSave = useCallback(
    async (data: FieldMapValue): Promise<ValidationErrors> => {
      if (!user?.id) {
        return { [FORM_ERROR]: 'No user id' }
      }
      const { error } = await updateDoc(doc(USERS_REF, user.id), '', data)
      if (error) return { [FORM_ERROR]: error }
      return undefined
    },
    [updateDoc, user],
  )
  return (
    <VStack w="100%" align="flex-start" spacing={0}>
      <HStack w="100%">
        <Text flex={1} fontSize="xs" color="gray.600" fontFamily="hero-new">
          PHONE
        </Text>
        <IconButton
          aria-label="edit"
          onClick={() => setModalOpen(true)}
          size="xs"
          variant="ghost"
          icon={<EditIcon />}
        />
      </HStack>
      <Text color="gray.800" fontSize="md">
        {phone ? formatPhoneNumber(phone) : 'No phone #'}
      </Text>
      <Checkbox size="sm" isChecked={!!voicemailOk} colorScheme="green">
        Voicemail OK?
      </Checkbox>
      <Checkbox size="sm" isChecked={!!textOk} colorScheme="green">
        Opt into text
      </Checkbox>
      <DefaultModal
        overlayHeader
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        render={() => (
          <SimpleForm
            theme="detailed"
            value={{ phone, voicemailOk, textOk }}
            field={phoneEditField}
            onSubmit={onSave}
          />
        )}
      />
    </VStack>
  )
}
