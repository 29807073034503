import { EmailIcon } from '@chakra-ui/icons'
import { HStack, Text } from '@chakra-ui/react'
import { adminAccess, superAdminAccess, userAccess } from '@hb/shared'
import {
  AdminsView,
  BugReportsView,
  ContactView,
  FaxesView,
  getAdminAssessmentRedirectView,
  getAdminDashboardPaths,
  getProfilePaths,
  InvoicesView,
  RedirectView,
  SignInRedirect,
  SignInView,
  View,
} from '@hb/shared-frontend'
import { TemplatesView } from '@hb/shared-frontend/components/Templates/TemplatesView'
import { AssessmentIcon } from '@hb/shared-frontend/icons'
import assignmentIcon from '@hb/shared-frontend/icons/assignment.svg'
import { SvgIcon } from '@hb/shared-frontend/icons/SvgIcon'
import React from 'react'
import { AdminDashboard } from './Admin/AdminDashboard'
import { InsurersView } from './Admin/Insurers/InsurersView'
import { AdminProvidersView } from './Admin/Midwives/AdminProvidersView'
import { MyConsentForms } from './Views/ConsentForms/MyConsentForms'
import { MyDocuments } from './Views/Documents/MyDocuments'
import FormsView from './Views/Forms/FormsView'
import { FormsViewRedirect } from './Views/Forms/FormsViewRedirect'
import HomeView from './Views/Home/HomeView'
import PatientsView from './Views/Patients/PatientsView'
import ProvidersView from './Views/Providers/ProvidersView'
import ResourcesView from './Views/Resources/ResourcesView'

const invoicingTabs = [
  'pending-approval',
  'current',
  'sent',
  'paid',
  'settings',
  'archived',
  'deleted',
]

export const views: View[] = [
  {
    name: 'Home',
    path: '/',
    component: HomeView,
    onMenu: true,
  },
  {
    name: 'Patients',
    path: '/patients',
    component: PatientsView,
    onMenu: true,
  },
  {
    name: 'Providers',
    path: '/providers',
    component: ProvidersView,
    onMenu: true,
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: ResourcesView,
    onMenu: true,
  },
  {
    name: 'Contact',
    path: '/contact',
    component: ContactView,
    onMenu: true,
  },
  {
    name: 'Sign In',
    path: '/sign-in',
    component: SignInView,
    onMenu: false,
  },
  {
    name: 'Sign in Redirect',
    path: '/sign-in/:inviteId',
    component: SignInRedirect,
  },
  {
    name: (
      <HStack spacing={1}>
        <AssessmentIcon width="16px" opacity={0.6} />
        <Text fontFamily="Open Sans">My Assessment</Text>
      </HStack>
    ),
    id: 'assessment',
    access: userAccess,
    path: ['/pregnancy', '/pregnancy/:assessmentId'],
    signIn: true,
    component: FormsView,
    onMenu: true,
  },
  {
    name: 'Patient Assessment Redirect',
    access: userAccess,
    path: ['/assessment', '/assessment/:assessmentId'],
    component: FormsViewRedirect,
  },
  {
    name: (
      <HStack>
        <EmailIcon width="14px" opacity={0.5} />
        <Text fontFamily="Open Sans">My Documents</Text>
      </HStack>
    ),
    path: ['/documents', '/documents/:documentId'],
    access: userAccess,
    signIn: true,
    component: MyDocuments,
    onMenu: true,
  },
  {
    name: (
      <HStack>
        <SvgIcon src={assignmentIcon} width="16px" opacity={0.6} />
        <Text fontFamily="Open Sans">My Consent Forms</Text>
      </HStack>
    ),
    path: ['/consent-forms', '/consent-forms/:consentFormId'],
    access: userAccess,
    signIn: true,
    component: MyConsentForms,
    onMenu: true,
  },
  {
    name: 'Admin Dashboard',
    path: getAdminDashboardPaths('app'),
    access: adminAccess,
    component: AdminDashboard,
    onMenu: true,
  },
  getAdminAssessmentRedirectView('app'),
  {
    name: 'Templates',
    path: ['/admin/templates', '/admin/templates/:templateId'],
    access: adminAccess,
    component: TemplatesView,
    onMenu: true,
  },
  {
    name: 'Providers',
    path: '/admin/providers',
    access: adminAccess,
    component: AdminProvidersView,
    onMenu: true,
  },
  {
    name: 'Insurers',
    path: '/admin/insurers',
    access: adminAccess,
    component: InsurersView,
    onMenu: true,
  },
  {
    name: 'Admins',
    path: ['/admin/admins', '/admin/admins/:adminId', '/admin/admins/:adminId/:displayedItem'],
    access: adminAccess,
    component: AdminsView,
    onMenu: true,
  },
  {
    name: 'Faxes',
    path: ['/admin/fax', ...getProfilePaths('/admin/fax/:faxType'), '/admin/fax/:faxType/:faxId'],
    access: adminAccess,
    component: FaxesView,
    onMenu: true,
  },
  {
    name: 'Invoices',
    path: [
      '/super-admin/invoices',
      ...invoicingTabs
        .map(tab => [
          `/super-admin/invoices/${tab}`,
          ...getProfilePaths(`/super-admin/invoices/${tab}/:invoiceId`),
          // `/super-admin/invoices/${tab}/:invoiceId/pregnancy/:pregnancyId`,
          // `/super-admin/invoices/${tab}/:invoiceId/pregnancy/:pregnancyId/:assessmentTab`,
          // `/super-admin/invoices/${tab}/:invoiceId/pregnancy/:pregnancyId/:assessmentTab/:assessmentSubTab`,
        ])
        .flat(),
      ...getProfilePaths('/super-admin/invoices/:invoiceId/'),
      // '/super-admin/invoices/:invoiceId/pregnancy/:pregnancyId',
      // '/super-admin/invoices/:invoiceId/pregnancy/:pregnancyId/:assessmentTab',
      // '/super-admin/invoices/:invoiceId/pregnancy/:pregnancyId/:assessmentTab/:assessmentSubTab',
    ],
    access: superAdminAccess,
    onMenu: true,
    component: InvoicesView,
  },
  {
    name: 'Bug Reports',
    path: ['/admin/bug-reports', '/admin/bug-reports/:bugReportId'],
    access: adminAccess,
    component: BugReportsView,
  },
  {
    name: 'Redirect',
    path: '/*',
    component: RedirectView,
  },
]
