import { TimeIcon } from '@chakra-ui/icons'
import { Flex, HStack, StackDivider, Text } from '@chakra-ui/react'
import { Descendant, EditorVersion, PopulatedAssessment } from '@hb/shared'

import * as React from 'react'
import { useMemo } from 'react'
// import { AssessmentInterfaceContext } from '../../contexts/AssessmentContext'

import { ActionLog } from '../../ActionLog'
import { ActionButton } from '../../Buttons/ActionButton'
import { Expandable } from '../../Expandable'

export const AssessmentHistory = ({
  assessment,
  updateText,
}: {
  assessment: PopulatedAssessment
  updateText: (version: EditorVersion, text: Descendant[]) => void
}) => {
  const {
    results,
    sentOn,
    draft,
    draftSavedOn,
    draftEditorVersion,
    editorVersion: resultsVersion,
    draftSavedBy,
    sentBy,
    previousResults,
  } = assessment ?? {}
  const noHistory = useMemo(() => {
    if (!draft && !sentOn && !previousResults?.length) return true
    return false
  }, [draft, sentOn, previousResults])
  if (noHistory) {
    return (
      <Flex align="center" px={2} py={1} borderBottom="1px solid #cdcdcd" gap={2}>
        <TimeIcon w={4} color="gray.500" />
        <Text fontStyle="italic" color="gray.500" fontSize="sm">
          No history
        </Text>
      </Flex>
    )
  }
  return (
    <Expandable
      noAnimate
      borderBottom="1px solid #cdcdcd"
      headerProps={{
        bg: 'white',
        _hover: {
          bg: 'blackAlpha.50',
        },
      }}
      header={() => (
        <Flex gap={1.5} p={1} py={2} align="center" w="100%">
          <TimeIcon w={4} color="gray.500" />
          <HStack gap={1} divider={<StackDivider />}>
            {sentOn ? (
              <Text lineHeight={1} fontWeight={600} color="gray.500" fontSize="sm">
                Sent on {new Date(sentOn).toLocaleDateString()}
              </Text>
            ) : null}
            {previousResults?.length ? (
              <Text lineHeight={1} fontWeight={600} color="gray.500" fontSize="sm">
                {previousResults.length} previous assessment
                {previousResults.length !== 1 ? 's' : ''}
              </Text>
            ) : null}
            {draftSavedOn ? (
              <Text lineHeight={1} fontWeight={600} color="gray.500" fontSize="sm">
                Draft saved on {new Date(draftSavedOn).toLocaleDateString()}
              </Text>
            ) : null}
          </HStack>
        </Flex>
      )}>
      {draftSavedOn || sentOn ? (
        <Flex
          w="100%"
          px={2}
          py={1}
          gap={1}
          align="flex-start"
          bg="gray.50"
          flexFlow="column"
          style={{
            borderTop: '1px solid #dedede',
            borderBottom: '1px solid #dedede',
          }}>
          {draft && draftSavedOn ? (
            <HStack flex={1} minW="0">
              <ActionButton
                fontSize="x-small"
                p={1}
                bg="white"
                minW="36px"
                size="xs"
                onClick={() => updateText(draftEditorVersion ?? 'v1', draft)}>
                VIEW
              </ActionButton>
              <ActionLog
                style={{ width: 'auto' }}
                action="Draft saved"
                on={draftSavedOn}
                group="admin"
                by={draftSavedBy}
              />
            </HStack>
          ) : null}

          {previousResults?.length
            ? previousResults.map(res => (
                <HStack key={res.sentOn} flex={1} minW="0">
                  <ActionButton
                    fontSize="x-small"
                    p={1}
                    minW="36px"
                    bg="white"
                    size="xs"
                    onClick={() => updateText(res.editorVersion ?? 'v1', res.results)}>
                    VIEW
                  </ActionButton>
                  <ActionLog
                    style={{ width: 'auto' }}
                    action="Assessment sent"
                    on={res.sentOn ?? undefined}
                    group="admin"
                    by={res.sentBy ?? undefined}
                  />
                </HStack>
              ))
            : null}
          {sentOn && results ? (
            <HStack justify="flex-end" flex={1} minW="0">
              <ActionButton
                fontSize="x-small"
                p={1}
                size="xs"
                minW="36px"
                bg="white"
                onClick={() => updateText(resultsVersion ?? 'v1', results)}>
                VIEW
              </ActionButton>
              <ActionLog
                style={{ width: 'auto' }}
                action={previousResults?.length ? 'Assessment re-sent' : 'Assessment sent'}
                on={sentOn}
                group="admin"
                by={sentBy}
              />
            </HStack>
          ) : null}
        </Flex>
      ) : null}
    </Expandable>
  )
}
