import { FAQList, InfoText, InlineLink } from '@hb/shared-frontend'
import React from 'react'
import { Link } from 'react-router-dom'
import { PatientsPageLink } from './PatientsPageLink'

export const benefitsAndCoverageList: FAQList = {
  name: 'Benefits and Coverage',
  items: [
    {
      title: 'Will my insurance cover services?',
      body: (
        <>
          <InfoText>
            Generally, most insurance plans will provide some level of coverage, so simply put, yes.
            However - keep in mind that deductibles, coinsurance and/or plan restrictions all factor
            in to create out of pocket expenses for you as the patient/member. There are dozens of
            insurance plans out there, and not all are created equal in terms of coverage - even if
            you go in-network.
          </InfoText>
          <InfoText>
            Really, the best way to estimate what your actual costs would be outside of your plan’s
            coverage is to{' '}
            <Link style={{ opacity: 1 }} to="/pregnancy">
              <InlineLink>get started on an assessment</InlineLink>
            </Link>{' '}
            with us. We can help you understand what costs and coverage really look like for you and
            your family if you sign on for care.
          </InfoText>
        </>
      ),
    },
    {
      title: 'My plan has no out of network benefits.',
      subtitle: 'Does this mean no coverage from my plan?',
      body: (
        <InfoText>
          Not necessarily! Some plans will grant a one-time exception for coverage of a midwife even
          if there are no out of network benefits. Because most of our providers do not
          contract/participate with insurers (are out of network), we would need more details to
          confirm whether or not your specific plan may (or may not) allow for coverage. Typically
          New York based insurance plans that are both fully insured and follow New York state
          mandates will grant this type of exception. To really find out, please go to our{' '}
          <PatientsPageLink /> for a free assessment!
        </InfoText>
      ),
    },
    {
      title: 'Does my midwife take Medicaid?',
      body: (
        <InfoText>
          Midwives can accept some medicaid managed plans because the plan may approve a one-time
          exception for coverage of homebirth due to network deficiency (no available homebirth
          midwives in the plan’s network), even though the provider is non-participating. All
          depends on what medicaid managed plan you have and where you are in pregnancy. *It’s
          always best to contact a midwife as soon as you think you might be pregnant as the earlier
          you are in pregnancy, the more likely it is she can obtain adequate coverage for services.
          For more details, please see our <PatientsPageLink /> to get started on an assessment to
          confirm whether or not your medicaid managed plan will cover services.
        </InfoText>
      ),
    },
    {
      title: 'Can I use my HSA or FSA to cover expenses?',
      body: (
        <InfoText>
          Most of the time, yes you can. However - all depends on the rules your FSA/HSA/HRA
          imposes. Some require an EOB (explanation of benefits) resulting from claim submission -
          which doesn’t occur until after services are performed. This means you may not be able to
          use funds from this type of account until weeks or months after you deliver your newborn.
          Should you sign on for care, our team will advise on what is possible. We can also answer
          questions about this after you submit information to receive an assessment by going to our{' '}
          <PatientsPageLink />.
        </InfoText>
      ),
    },
    {
      title: 'I have heard of an in-network exception covering services in full.',
      subtitle: 'If I get an ‘In-network exception’ for coverage, will the same happen for me?',
      body: (
        <InfoText>
          You may hear many stories out there - from blogs, friends or former patients of a practice
          you are interested in about how plans have covered care in full with an exception on
          board. While these scenarios do exist, they are not always possible and exceptions do not
          always mean full coverage. There are dozens of insurance plans out there, all with
          different rules on how coverage plays out. Furthermore - the insurance landscape changes
          every year. So while last year an exception with a certain plan may have meant full
          coverage, it may be different this year. Our team is dedicated to helping patients obtain
          whatever exceptions are possible/allowed by the insurance plan, while also informing you
          of the coverage risks involved. Please see the <PatientsPageLink /> to get an assessment
          on what might be possible from your plan.
        </InfoText>
      ),
    },
    {
      title: 'My insurer says my midwife is in-network.',
      subtitle: "Does this mean she'll take my insurance?",
      body: (
        <>
          <InfoText>
            Insurance companies often have outdated information in their system regarding a
            provider’s network status. Most of our providers do not participate with insurance
            plans, but may be listed as in-network anyhow. Be sure to contact us if you have
            questions about coverage and costs by either going to our <PatientsPageLink /> to
            receive a free assessment, or by contacting us.
          </InfoText>
        </>
      ),
    },
    {
      title:
        'I have a friend who used the same midwife I am interested in and she had full coverage with the same insurance company I have. Will the same happen for me?',
      body: (
        <InfoText>
          Insurance companies have dozens of plans available with varying rules and/or restrictions
          on coverage. Just because you have the same insurer does not mean you will have the same
          level of coverage. Best to visit our <PatientsPageLink /> for a free assessment so we can
          give you detailed information to plan financially for a birth with your midwife.
        </InfoText>
      ),
    },
  ],
}
