import { Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../../contexts/ProfileContext'

export const NicknameView = () => {
  const { user } = useContext(ProfileContext)
  const { nickname } = user ?? {}
  if (!nickname) return null
  return (
    <Flex align="center" gap={1.5}>
      <Text lineHeight={1} fontWeight={500} color="gray.600">
        Nickname
      </Text>
      <Text lineHeight={1} fontSize="md">
        {nickname}
      </Text>
    </Flex>
  )
}
