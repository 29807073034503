import { get as nestedGet } from 'nested-property'
import { PopulatedAssessment, PopulatedUser, User } from '../types'
import { Assessment } from '../types/assessments/assessment'

export const getCorrectedValue = (
  assessment?: Assessment | PopulatedAssessment | null,
  path?: string,
) => {
  const formData = (assessment as Assessment)?.data ?? (assessment as any)?.formData ?? {}

  return nestedGet(assessment?.corrections, path ?? '') || nestedGet(formData, path ?? '')
}

export const getInsuranceProviderId = (user?: User | null) =>
  user?.insurancePlans?.primary?.insuranceProviderId

export const getPregnancyFormData = (
  user: PopulatedUser | null,
  assessment: PopulatedAssessment | null,
) => {
  const isMedicaid = user?.insuranceInfo?.data?.option === 'medicaid'
  const data = {
    delivery: assessment?.data?.delivery ?? {},
    insuranceInfo: {
      ...user?.insuranceInfo?.data,
      primaryCoverage: isMedicaid ? user?.insurancePlans?.secondary : user?.insurancePlans?.primary,
      medicaidCoverage: isMedicaid
        ? user?.insurancePlans?.primary
        : user?.insurancePlans?.secondary,
    },
  }
  const corrections = {
    delivery: assessment?.corrections?.delivery ?? {},
    insuranceInfo: {
      ...user?.insuranceInfo?.corrections,
      primaryCoverage: isMedicaid ? user?.insurancePlans?.secondary : user?.insurancePlans?.primary,
      medicaidCoverage: isMedicaid
        ? user?.insurancePlans?.primary
        : user?.insurancePlans?.secondary,
    },
  }

  return { formData: data, formCorrections: corrections }
}
