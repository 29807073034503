import { CollectionItem } from '../collections/types/collection'
import {
  AnyObject,
  Assessment,
  DropdownOption,
  DropdownOptionItem,
  FieldMapValue,
  isDropdownOptionItem,
  WithId,
} from '../types'

interface FieldMap {
  name: string
  children: Record<string, any>
}
export function formatDollarValue(value?: string | number | null) {
  if (value === undefined || value === null || value === undefined) return ''
  const asNum = Number(value)
  const isNegative = asNum < 0
  if (Number.isNaN(asNum)) return ''
  if (Math.abs(asNum) < 0.005) return '$0.00'
  const asStr = Math.abs(asNum).toFixed(2)
  const [dollarPortion, centsPortion = ''] = asStr.split('.')
  if (!dollarPortion) return ''
  const withCommas = dollarPortion.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const prefix = isNegative ? '-$' : '$'
  if (centsPortion === '00') return `${prefix}${withCommas}`
  if (centsPortion.length === 1) return `${prefix}${withCommas}.${centsPortion}0`
  return `${prefix}${withCommas}.${centsPortion}`
}
export const omitChild = (stage: FieldMap, key: string) => {
  const { [key]: _, ...restOfChildren } = stage.children
  return { ...stage, children: restOfChildren }
}

export const omitNameField = (stage: FieldMap) => omitChild(stage, 'name')
export const objectToArray = <T, K extends string = 'id'>(
  obj: Record<string, T>,
  keyPropName?: K,
  indexPropName?: keyof T & string,
): Array<T & Record<K, string>> => {
  const mapped = Object.entries(obj).map(
    ([key, val]) => ({ ...val, [keyPropName ?? 'id']: key }) as T & Record<K, string>,
  )
  return indexPropName
    ? mapped.sort((a, b) => ((a[indexPropName] as any) ?? 0) - ((b[indexPropName] as any) ?? 0))
    : mapped
}

export const arrayToObject = <T extends FieldMapValue>(
  arr?: Array<T>,
  keyPropName?: string,
  indexPropName?: string,
) =>
  arr?.reduce(
    (acc, curr, i) => ({
      ...acc,
      [curr[keyPropName ?? 'id']]: indexPropName ? { ...curr, [indexPropName]: i } : curr,
    }),
    {} as Record<string, T>,
  )

export const entriesToObject = <Data extends AnyObject>(
  entries: Array<[string, Data]>,
): Record<string, Data> =>
  entries.reduce<Record<string, Data>>(
    (obj, [key, v]) => ({
      ...obj,
      [key]: v,
    }),
    {},
  )

export const toSearchString = (inStr: string): string =>
  inStr.toLowerCase().replace(/[^\w\d\s]/g, '')

export const padZeros = (num: number, zeros = 2) => {
  let res = `${num}`
  while (res.length < zeros) res = `0${res}`

  return res
}

// const padLeftZeros = (str: string, zeros: number) => {
//   let res = str
//   while (res.length < zeros) res = `0${res}`

//   return res
// }

export const parseNextActionDate = (text?: string | null, fallbackValue = Infinity) => {
  if (!text) return fallbackValue
  const trimmed = text.trim()
  const cutoffIdx = trimmed.indexOf(' ')
  const dateString = trimmed.slice(0, cutoffIdx === -1 ? trimmed.length : cutoffIdx + 1)
  const parts = dateString.split('/').map(p => parseInt(p, 10))
  // eject if something isnt a number
  if (parts.length !== 3 || !parts.reduce((acc, curr) => acc && !Number.isNaN(curr), true)) {
    return fallbackValue
  }
  const [month, day] = parts
  let year = parts[2]
  if (month === undefined || day === undefined || year === undefined) return fallbackValue
  if (year < 1970) {
    if (year < 50) year += 2000
    else if (year > 50) year += 1900
  }

  const date = new Date(
    `${padZeros(year, 4)}-${padZeros(month)}-${padZeros(day)}T00:00:00.000-04:00`,
  )
  return date.getTime()
}

export const sortByDateKey = <T>(
  items?: Record<string | number, T>,
  order: 'asc' | 'desc' = 'asc',
) =>
  Object.entries(items ?? {})
    .sort(
      ([a], [b]) => parseInt(order === 'asc' ? a : b, 10) - parseInt(order === 'asc' ? b : a, 10),
    )
    .map(([id, val]) => ({ ...val, id }))

export const sortByUnixDate = <Id extends keyof Data & string, Data extends Record<Id, number>>(
  items: Array<Data>,
  key: Id,
) => items.sort((a, b) => a[key] - b[key])

export const sortByRank = <T extends AnyObject = AnyObject>(items: Array<CollectionItem<T>>) =>
  items.sort(
    (a, b) =>
      (typeof a.rank === 'number' ? a.rank : items.length) -
      (typeof b.rank === 'number' ? b.rank : items.length),
  )

export const getMostRecentAssessment = (
  data?: Record<string, Assessment>,
): WithId<Assessment> | null => {
  if (!data) return null
  if (!Object.keys(data).length) return null

  const mostRecent = Object.entries(data).sort(([, a], [, b]) => b.createdOn - a.createdOn)[0]
  return mostRecent ? { ...mostRecent[1], id: mostRecent[0] } : null
}

export const getSortedKeys = (data?: Record<string | number, any>) =>
  Object.keys(data ?? {}).sort((a, b) => parseInt(b, 10) - parseInt(a, 10))

export const getMostRecentKey = (data?: Record<string | number, any>) => {
  if (!data) return null
  if (!Object.keys(data).length) return null
  const [mostRecent] = getSortedKeys(data)
  if (!mostRecent) return null
  return parseInt(mostRecent, 10)
}

export const nestedRemoveNaN = (data: Record<string, any>) => {
  const returned = { ...data }
  Object.entries(returned).forEach(([key, val]) => {
    if (typeof val === 'number') {
      if (Number.isNaN(val)) {
        delete returned[key]
      }
    }
    if (typeof val === 'object' && val !== null) {
      if (val instanceof Array) {
        val.forEach(arrVal => {
          if (Number.isNaN(arrVal)) returned[key] = undefined
        })
      } else returned[key] = nestedRemoveNaN(val)
    }
  })
  return returned
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const capitalizeFirstLetterOfEachWord = (str: string) =>
  str.split(' ').map(capitalizeFirstLetter).join(' ')
export const kebabCaseToTitleCase = (str: string) =>
  capitalizeFirstLetterOfEachWord(str.replace(/-/g, ' '))

export const kebabCaseToPascaleCase = (str: string) =>
  str.split('-').map(capitalizeFirstLetter).join('')

export const getAllOptions = (options: DropdownOption[]): Array<DropdownOptionItem> => {
  let opts: Array<DropdownOptionItem> = []
  options.forEach(o => {
    if (!isDropdownOptionItem(o)) {
      opts = [...opts, ...getAllOptions(o.options)]
    } else {
      opts.push(o)
    }
  })

  return opts
}
export const getOptionFromId = (options: DropdownOption[], id: string) =>
  getAllOptions(options).find(o => o.id === id)

export const getOptionFromText = (options: DropdownOption[], text: string) =>
  getAllOptions(options).find(o => o.text === text)

export const formatDropdownValue = (value: string, options?: DropdownOption[]) => {
  if (value === undefined || value === null || value === '') return ''
  if (!options) return 'Error - No options'
  const allOptions = getAllOptions(options)
  const match = allOptions.find(o => o.id === value)
  return match ? match.text : (value ?? '')
}

// convert an address to a string, showing errors for missing fields
export const addressToString = (address: FieldMapValue, multiLine?: boolean): string => {
  const { streetAddress, streetAddress2, city, state, zip } = address
  const missing = []
  if (!streetAddress) missing.push('street address')
  if (!city) missing.push('city')
  if (!state) missing.push('state')
  if (!zip) missing.push('zip code')
  if (missing.length) return `Missing ${missing.join(', ')}`
  let retStr = streetAddress as string
  if (streetAddress2) retStr += ` ${streetAddress2}`
  const zipWithDash =
    typeof zip === 'string' ? zip.slice(0, 5) + (zip.length > 5 ? `-${zip.slice(5)}` : '') : ''
  retStr += multiLine ? '\n' : ', '
  retStr += `${city}, ${state} ${zipWithDash}`
  return retStr
}

export const filterOptionsBySearch = (
  options: DropdownOption[],
  search: string,
): DropdownOption[] => {
  const opts: Array<DropdownOption> = []
  options.forEach(o => {
    if (!isDropdownOptionItem(o)) {
      const filtered = filterOptionsBySearch(o.options, search)
      if (filtered.length) opts.push({ ...o, options: filtered })
    } else if (o.text.toLowerCase().includes(search.toLowerCase())) {
      opts.push(o)
    }
  })

  return opts
}

export const makePlural = (str: string) => {
  if (str.endsWith('y')) return `${str.slice(0, -1)}ies`
  if (str.endsWith('s') || str.endsWith('x')) return `${str}es`
  return `${str}s`
}

export const makeArrayUnique = <T>(arr: T[]) => Array.from(new Set(arr))

export const isAlphaNumeric = (str: string) => /^[a-z0-9]+$/i.test(str)

// get all previous versions of a document, sorted by most recent
export const getPreviousVersions = <
  T extends { previousVersions?: Array<Omit<T, 'previousVersions'>> },
>(
  previous: T | null | undefined,
): Array<Omit<T, 'previousVersions'>> => {
  if (!previous) return []
  const { previousVersions = [], ...previousData } = previous
  return [previousData, ...previousVersions]
}
export const getRandomId = () => `_${Math.random().toString(36).substring(2, 11)}`
export const getUniqueRandomId = (existingIds: string[]) => {
  let id = getRandomId()
  while (existingIds.includes(id)) id = getRandomId()
  return id
}

export const splitTextLines = (text: string) =>
  text
    .split('\n')
    .map(line => line.trim())
    .filter(l => !!l)
export const splitTextParts = (parts: string) =>
  parts
    .split(' ')
    .map(part => part.trim())
    .filter(p => !!p)

export const filterRecord = <T>(record: Record<string, T>, filter: (item: T) => boolean) => {
  const filtered: Record<string, T> = {}
  Object.entries(record).forEach(([key, val]) => {
    if (filter(val)) filtered[key] = val
  })
  return filtered
}

export const filterRecordByKey = <T>(
  record: Record<string, T>,
  filter: (key: string) => boolean,
) => {
  const filtered: Record<string, T> = {}
  Object.entries(record).forEach(([key, val]) => {
    if (filter(key)) filtered[key] = val
  })
  return filtered
}

export const camelCaseToTitleCase = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase())
    .trim()
