import { Box, Text, VStack } from '@chakra-ui/react'
import { Fax, FAX_SENT_COLLECTION, objectToArray, TemplateKey, templateTypeNames } from '@hb/shared'
import { collection, CollectionReference, query, where } from 'firebase/firestore'

import React, { useMemo } from 'react'
import { db } from '../../../../backend'
import { useApp } from '../../../../contexts/AppContext'
import { useAssessmentDocuments } from '../../../../hooks/backend/documents/useAssessmentDocuments'
import { useQuery } from '../../../../hooks/backend/useQuery'
import { Expandable } from '../../../Expandable'
import { DocumentsHeader } from './DocumentsHeader'
import { DocumentsList } from './DocumentsList'

export const AssessmentDocuments = ({
  assessmentId,
  category,
  isInvite,
  adminView,
  patientId,
}: {
  assessmentId: string
  patientId: string
  isInvite: boolean
  category: TemplateKey
  adminView?: boolean
}) => {
  const { data, loading } = useAssessmentDocuments(patientId, isInvite, assessmentId, category)

  const { appName } = useApp()
  const dataArr = useMemo(() => objectToArray(data ?? {}), [data])
  const docTypeName = useMemo(() => templateTypeNames[category], [category])

  const headerText = useMemo(
    () => (adminView ? `Sent ${docTypeName} documents` : `Your ${docTypeName} documents`),
    [adminView, docTypeName],
  )

  const faxesQuery = useMemo(
    () =>
      adminView && appName === 'app'
        ? query(
            collection(db, FAX_SENT_COLLECTION) as CollectionReference<Fax>,
            where('category', '==', category),
            where('assessmentId', '==', assessmentId),
          )
        : null,
    [category, assessmentId, adminView, appName],
  )
  const { data: faxes, loading: loadingFaxes } = useQuery<Fax>(faxesQuery)
  const faxesArr = useMemo(() => objectToArray(faxes ?? {}), [faxes])
  return (
    <Box w="100%" bg="white" boxShadow="md" borderRadius={6}>
      <Expandable
        initExpanded
        header={() => (
          <DocumentsHeader numDocs={dataArr.length + faxesArr.length} loading={loading}>
            {headerText}
          </DocumentsHeader>
        )}>
        <Box py={1}>
          {!loading || loadingFaxes ? (
            <DocumentsList faxes={faxesArr} category={category} documents={dataArr} />
          ) : (
            <Text>{loading ? 'Loading...' : 'Loading faxes...'}</Text>
          )}
        </Box>
      </Expandable>
    </Box>
  )
}

export const AllAssessmentDocuments = (props: {
  patientId: string
  isInvite: boolean
  assessmentId: string
  adminView?: boolean
}) => (
  <VStack spacing={4} w="100%">
    <AssessmentDocuments category="authAppeals" {...props} />
    <AssessmentDocuments category="authInstructions" {...props} />
  </VStack>
)
