import {
  Assessment,
  EditorDraft,
  EditorVersion,
  getMostRecentAssessment,
  getShortcutArgs,
  PopulatedNode,
  TemplateKey,
  UpdateCallback,
  WithId,
} from '@hb/shared'
import React, { forwardRef, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { Descendant } from 'slate'
import { ProfileContext } from '../../../contexts/ProfileContext'
import { usePopulatedAssessment } from '../../../hooks'
import { saveAssessmentDraft, submitAssessmentResults } from '../../../hooks/backend/assessments'
import { useAssessmentAutoTemplate } from '../hooks/useAssessmentAutoTemplate'
import { EditMode, TemplateDataProvider } from './data'
import { EditModeProvider } from './editMode'
import { useTemplateInsurancePlans } from './plan'
import { TemplateViewProvider, TemplateViewRef } from './view'

export interface AssessmentDataProviderProps {
  type: TemplateKey
  editMode: EditMode
}
export const AssessmentDataProvider = ({
  children,
  type,
  editMode,
}: PropsWithChildren<AssessmentDataProviderProps>) => {
  const { selectedPlan } = useTemplateInsurancePlans()
  const { selectedAssessment, assessmentId, user, assessments } = useContext(ProfileContext)

  const mostRecentAssessment = useMemo(
    () => getMostRecentAssessment(assessments ?? {}),
    [assessments],
  )
  const usedAssessment = useMemo<WithId<Assessment> | null>(() => {
    if (assessmentId) {
      const data = assessments?.[assessmentId] ?? null
      return data ? { ...data, id: assessmentId } : null
    }
    return mostRecentAssessment
  }, [assessmentId, assessments, mostRecentAssessment])
  const toBePopulated = useMemo(
    () =>
      !!mostRecentAssessment && mostRecentAssessment.id !== assessmentId
        ? mostRecentAssessment
        : null,
    [mostRecentAssessment, assessmentId],
  )

  const mostRecentPopulated = usePopulatedAssessment(toBePopulated, user)
  const populatedAssessment = useMemo(() => {
    if (assessmentId) return selectedAssessment.populated
    return mostRecentPopulated
  }, [selectedAssessment, mostRecentPopulated, assessmentId])

  const shortcutArgs = useMemo(
    () => getShortcutArgs(user, usedAssessment?.id ?? null, populatedAssessment, selectedPlan),
    [user, selectedPlan, populatedAssessment, usedAssessment],
  )

  return (
    <EditModeProvider editMode={editMode}>
      <TemplateDataProvider type={type} data={shortcutArgs}>
        {children}
      </TemplateDataProvider>
    </EditModeProvider>
  )
}

export const AssessmentEditorProvider = forwardRef<
  TemplateViewRef,
  PropsWithChildren<{ draft?: EditorDraft | null }>
>(({ children, draft }, ref) => {
  const { assessmentId, user } = useContext(ProfileContext)

  const { selectedPlanId } = useTemplateInsurancePlans()
  const onSubmit = useCallback(
    async (text: PopulatedNode[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return submitAssessmentResults({
        assessmentId,
        results: text,
        editorVersion: version,
        coverageId: selectedPlanId,
      })
        .then(res => res.data as UpdateCallback)
        .catch(err => ({ error: err.message }))
      // submit
    },
    [assessmentId, selectedPlanId],
  )

  const onSave = useCallback(
    async (value: Descendant[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return saveAssessmentDraft({
        assessmentId,
        editorVersion: version,
        draft: value,
      })
        .then(res => res.data as UpdateCallback)
        .catch(err => ({ error: err.message }))
    },
    [assessmentId],
  )

  const autoTemplateId = useAssessmentAutoTemplate(user)

  return (
    <TemplateViewProvider
      ref={ref}
      autoTemplateId={autoTemplateId}
      type="assessments"
      draft={draft}
      onSave={onSave}
      onSubmit={onSubmit}>
      <AssessmentDataProvider editMode="document" type="assessments">
        {children}
      </AssessmentDataProvider>
    </TemplateViewProvider>
  )
})
