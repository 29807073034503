import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  GridItem,
  HStack,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  AnyObject,
  assessmentTemplatesCollection,
  colors,
  Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  FileDBValue,
  FileField,
  IdField,
  InfoStage,
  isField,
  isFieldMap,
  isInfoStage,
  isListField,
  ListField,
  OnUploadProgress,
  ShareCorrections,
  templateKeyToCollection,
  UpdateCallback,
  UploadProgress,
} from '@hb/shared'
import { ValidationErrors } from 'final-form'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { set as nestedSet } from 'nested-property'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { processFieldMapData } from '../../backend'
import { useResizeObserver } from '../../hooks'

import { DeleteAlert, DeleteAlertButton } from '../Alerts/DeleteAlert'
import { ViewButton } from '../Buttons/ViewButton'
import { Expandable } from '../Expandable'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { Editable, EditableFileView } from '../forms/Input'
import { DefaultModal } from '../Modals/DefaultModal'
import { AddItemButton } from './AddItemButton'
import {
  DataViewContext,
  DataViewContextData,
  ShareCorrectionsProvider,
  useShareCorrections,
} from './context'
import { DataGrid, GridItemWrapper } from './DataGrid'
import { DataHeader } from './DataHeader'
import { GenericEditModal } from './GenericEditModal'

import { deleteField } from 'firebase/firestore'
import { useCollection } from '../../collections/hooks/useCollection'

import { useScreen } from '../../hooks/useScreen'
import { ShareCorrectionsButton } from '../Assessments/ShareCorrections'
import usePopulatedHints from '../forms/FinalForm/hooks/usePopulatedHints'
import LoadingIcon from '../LoadingIcon'
import { TemplateEditorModal } from '../Templates/TemplateEditModal'
import { DataCell } from './DataCell'
import { LabelCell } from './LabelCell'
import { getRowBackground } from './utils'
export interface FieldViewProps {
  path: string
  id: string
  data?: any
  correction?: any
  parentData?: FieldMapValue
  baseStoragePath?: string | null
  fieldPathSegments?: string[]
  field: Field | ListField
  index: number
}

export interface DefaultFieldViewProps extends FieldViewProps {
  field: Field
}

const LoadingFieldView: React.FC<{ text: string }> = ({ text }) => (
  <GridItem colSpan={2} h={30}>
    <LoadingIcon loading={true} text={text} />
  </GridItem>
)

const ErrorFieldView: React.FC<DefaultFieldViewProps> = ({ index, field, data }) => {
  const { placeholder, collection } = field as IdField<AnyObject>
  return (
    <>
      <LabelCell>{placeholder}</LabelCell>
      <DataCell index={index}>
        Error: item {data} not found in {collection}
      </DataCell>
    </>
  )
}

const TemplateFieldView: React.FC<DefaultFieldViewProps> = ({ data, field, index, path, id }) => {
  const { placeholder } = field
  const { items: templates, loading } = useCollection(assessmentTemplatesCollection, data ?? null)
  const [isOpen, setIsOpen] = useState(false)
  if (templates === undefined) {
    if (loading) return <LoadingFieldView text={'Loading templates...'} />
    return <ErrorFieldView id={id} path={path} index={index} data={data} field={field} />
  }
  return (
    <GridItem
      as={Flex}
      alignItems="center"
      colSpan={2}
      p={2}
      m={1}
      borderRadius={4}
      border="1px solid #cdcdcd"
      fontSize="sm">
      <Text fontWeight="600" color="blackAlpha.700" fontFamily="Comfortaa">
        {placeholder}
      </Text>
      {data === undefined ? (
        <Text ml={4}>
          <i>Not Selected</i>
        </Text>
      ) : null}
      <TemplateEditorModal
        templateId={data}
        templateType="assessments"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <ViewButton
        viewText={data ? 'VIEW / EDIT' : 'CREATE'}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
    </GridItem>
  )
}

const DefaultFieldView: React.FC<DefaultFieldViewProps> = ({
  data,
  parentData,
  correction,
  field,
  index,
  id,
  path,
}) => {
  const { updateField } = useContext(DataViewContext)
  const { hints } = field
  const body = (
    <Editable
      index={index}
      field={field}
      value={data}
      // inGrid
      style={{ width: '100%', background: 'inherit' }}
      parentValue={parentData}
      theme="basic"
      dataCellProps={{ fontSize: '0.9rem' }}
      correction={correction}
      id={id}
      onSubmit={updateField ? v => updateField(path, v) : undefined}
    />
  )

  const populatedHints = usePopulatedHints(hints)

  return (
    <GridItemWrapper>
      {field.type === FieldTypes.CHECKBOX ? (
        <GridItem background="inherit" px={2} colSpan={2}>
          {body}
        </GridItem>
      ) : (
        <>
          <LabelCell
            // sx={{ ':nth-of-type(4n - 3)': { background: 'rgb(245,245,245)' } }}
            background="inherit"
            label={populatedHints?.[0]?.text}>
            {field.placeholder}
          </LabelCell>
          {body}
        </>
      )}
    </GridItemWrapper>
  )
}

const FileFieldView: React.FC<FieldViewProps> = ({
  field,
  index,
  data,
  correction,
  baseStoragePath,
  fieldPathSegments,
  path,
}) => {
  const { updateField } = useContext(DataViewContext)
  const displayed = useMemo(() => {
    if (data && correction) {
      if (data.updatedOn > correction.updatedOn) return data
      return correction
    }
    return correction || data
  }, [data, correction])

  const onChange = useCallback(
    async (value: FileDBValue | null) => {
      if (updateField) {
        return updateField(path, value ?? deleteField())
      }
      return { error: 'Internal error' }
    },
    [updateField, path],
  )

  if (!baseStoragePath) {
    throw new Error('baseStoragePath is required for FileFieldView')
  }

  return (
    <GridItem colSpan={2} bg={getRowBackground(index)}>
      <EditableFileView
        field={field as FileField}
        value={displayed}
        onSubmit={updateField ? onChange : undefined}
        baseStoragePath={baseStoragePath}
        fieldPathSegments={
          fieldPathSegments && Array.isArray(fieldPathSegments) ? fieldPathSegments : []
        }
      />
    </GridItem>
  )
}

const FieldView: React.FC<FieldViewProps> = ({ field, ...props }) => {
  const { updateField, adminView } = useContext(DataViewContext)

  if (isListField(field)) {
    return (
      <GridItemWrapper>
        <GridItem background="inherit" colSpan={2}>
          <DataViewList
            value={props.data}
            adminView={adminView}
            onSubmit={updateField ? v => updateField(props.path, v) : undefined}
            baseStoragePath={props.baseStoragePath}
            fieldPathSegments={props.fieldPathSegments}
            itemField={field.itemFields}
            itemName={field.itemName}
          />
        </GridItem>
      </GridItemWrapper>
    )
  }

  switch (field.type) {
    case FieldTypes.ID:
      if (
        field.collection._type === 'firestoreCollection' &&
        field.collection.refPath === templateKeyToCollection.assessments
      ) {
        return <TemplateFieldView field={field} {...props} />
      }
      return <DefaultFieldView field={field} {...props} />
    // case FieldTypes.DROPDOWN:
    //   return <DropdownOptionFieldView field={field} {...props} />
    case FieldTypes.FILE:
      return <FileFieldView field={field} {...props} />
    default:
      return <DefaultFieldView field={field} {...props} />
  }
}

interface MapViewProps {
  data?: FieldMapValue | null
  corrections?: FieldMapValue & { correctionsShared?: ShareCorrections | null }
  merged?: FieldMapValue
  childrenExpanded?: boolean
  baseStoragePath?: string | null
  fieldPathSegments?: string[]
  path?: string
  depth?: number
  field: FieldMap
  index?: number
}

interface DataViewProps extends MapViewProps {
  initExpanded?: boolean
  alwaysExpanded?: boolean
  initEditing?: boolean
  boxProps?: BoxProps
  adminView?: boolean
  noArrow?: boolean
  onEditingChange?: (editing: boolean) => void
  withEditModal?: boolean
  parentValue?: FieldMapValue
  isCorrecting?: boolean
  noBody?: boolean
  noHeader?: boolean
  size?: 'sm' | 'md' | 'lg'
  toName?: (data: FieldMapValue) => string
  onSubmit?: (data: FieldMapValue, onProgress: OnUploadProgress) => Promise<any>
  onDelete?: () => Promise<UpdateCallback>
  onShareCorrections?: (path: string | null, shared: ShareCorrections | null) => Promise<void>
  validate?: (data?: FieldMapValue) => ValidationErrors
  updateField?: (path: string, value: any) => Promise<UpdateCallback>
}

interface MapChildViewProps {
  parentData?: FieldMapValue
  depth: number
  id: string
  data?: any
  merged?: FieldMapValue
  corrections?: FieldMapValue
  field: Field | FieldMap | ListField | InfoStage
  baseStoragePath?: string | null
  fieldPathSegments?: string[]
  index: number
  path: string
}

const MapChildView: React.FC<MapChildViewProps> = ({
  data,
  parentData,
  field,
  id,
  index,
  depth,
  corrections,
  baseStoragePath,
  fieldPathSegments,
  merged,
  path,
}) => {
  if (isInfoStage(field) || (field.condition && !field.condition(parentData))) {
    return null
  }
  if (isFieldMap(field)) {
    return (
      <MapView
        merged={merged}
        depth={depth}
        path={path}
        corrections={corrections}
        baseStoragePath={baseStoragePath}
        fieldPathSegments={fieldPathSegments}
        data={data}
        index={index}
        field={field}
      />
    )
  }
  return (
    <FieldView
      correction={corrections}
      id={id}
      parentData={parentData}
      baseStoragePath={baseStoragePath}
      fieldPathSegments={fieldPathSegments}
      path={path}
      data={data}
      field={field}
      index={index}
    />
  )
}

const MapView: React.FC<MapViewProps> = ({
  data,
  field,
  merged,
  depth = 0,
  baseStoragePath,
  fieldPathSegments,
  path = '',
  corrections,
}) => {
  const { childrenExpanded } = useContext(DataViewContext)
  const { name, initExpanded } = field
  const [isOpen, setIsOpen] = useState(!!((depth === 0 || childrenExpanded) ?? initExpanded))
  const displayedName = useMemo(() => {
    if (field.toName && data) return field.toName(data)
    return undefined
  }, [data, field])
  return (
    <GridItem
      // border={root ? undefined : '1px solid #cdcdcd'}
      borderLeft={depth ? '2px solid #cdcdcd' : undefined}
      borderBottom={depth === 1 ? '1px solid #cdcdcd' : undefined}
      borderRight={depth === 1 ? '1px solid #cdcdcd' : undefined}
      // ml={depth ? 1 : undefined}
      // mb={depth === 1 ? 2 : 0}
      colSpan={2}
      // mt={2}
      // m={1}
    >
      {depth > 0 ? (
        <Flex
          pl={1}
          pr={2}
          align="center"
          borderTop="1px solid #cdcdcd"
          cursor="pointer"
          aria-label="expand/hide"
          userSelect="none"
          onClick={() => setIsOpen(!isOpen)}
          bg="white"
          _hover={{ bg: 'blackAlpha.50' }}
          // borderBottom={isOpen ? '1px solid #cdcdcd' : undefined}
          // borderBottom='1px solid #cdcdcd'
          // borderRadius='4px'
          // bg={getRowBackground(index)}
          py={1}>
          <Text fontSize="md" fontWeight={600} fontFamily="Open Sans" color="gray.500" ml={1}>
            {data?.name || name}
          </Text>
          {displayedName ? (
            <Text lineHeight={1} fontSize="sm" ml={2} color="gray.700">
              {displayedName}
            </Text>
          ) : null}
          <Flex align="center" gap={1} ml="auto">
            <ViewButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          </Flex>
        </Flex>
      ) : null}
      <Collapse unmountOnExit in={isOpen}>
        <Box>
          <DataGrid bg="white">
            {Object.keys(field.children).map((fId, i) => (
              <MapChildView
                depth={depth + 1}
                corrections={corrections?.[fId]}
                path={`${path ? `${path}.` : ''}${fId}`}
                key={fId}
                id={fId}
                baseStoragePath={baseStoragePath}
                fieldPathSegments={[
                  ...(fieldPathSegments && Array.isArray(fieldPathSegments)
                    ? fieldPathSegments
                    : []),
                  fId,
                ]}
                index={i}
                field={field.children[fId]}
                data={data?.[fId]}
                merged={merged?.[fId]}
                parentData={merged}
              />
            ))}
          </DataGrid>
        </Box>
      </Collapse>
    </GridItem>
  )
}

export const SmallEditButton: React.FC<
  Partial<IconButtonProps> & {
    onClick: () => void
    visible: boolean
  }
> = ({ onClick, visible, ...props }) => (
  <IconButton
    size="xs"
    ml="auto"
    variant="ghost"
    onClick={onClick}
    opacity={visible ? 1 : 0}
    transition="opacity 400ms"
    pointerEvents={visible ? 'auto' : 'none'}
    aria-label="edit"
    icon={<EditIcon />}
    {...props}
  />
)
export const DataView: React.FC<DataViewProps> = ({
  data,
  corrections,
  field,
  onSubmit,
  onDelete,
  initExpanded,
  updateField,
  childrenExpanded,
  size = 'md',
  index,
  noArrow,
  onShareCorrections,
  onEditingChange,
  baseStoragePath,
  adminView,
  fieldPathSegments,
  initEditing,
  validate,
  withEditModal,
  noBody,
  noHeader,
  alwaysExpanded,
  boxProps,
}) => {
  const [expanded, setExpanded] = useState<boolean>(
    alwaysExpanded === true || initExpanded !== false || !!noHeader,
  )
  const { name, toName } = field
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)

  useEffect(() => {
    setExpanded(initExpanded !== false)
  }, [initExpanded])

  const animatingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isAnimating, setAnimating] = useState(false)
  useEffect(() => {
    if (animatingTimeout.current) {
      clearTimeout(animatingTimeout.current)
      animatingTimeout.current = null
    }
    setAnimating(true)
    animatingTimeout.current = setTimeout(() => setAnimating(true), 300)

    return () => {
      if (animatingTimeout.current) clearTimeout(animatingTimeout.current)
    }
  }, [expanded])

  const merged = useMemo(() => merge(cloneDeep(data), cloneDeep(corrections)), [data, corrections])

  const handleSubmit = useCallback(
    async (formData: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (onSubmit) {
        const submitted = formData
        const id = formData?.id || merged?.id
        if (id) submitted.id = id
        const processed = await processFieldMapData(
          baseStoragePath ?? '',
          field,
          submitted,
          data,
          onUploadProgress,
        )
        return onSubmit(processed, onUploadProgress)
          .then((res: UpdateCallback) => res)
          .catch(err => {
            console.error(err)
            return { error: 'Error submitting' }
          })
      }
      return { error: 'No submit function' }
    },
    [onSubmit, merged, data, baseStoragePath, field],
  )

  const displayedTitle = useMemo(() => {
    if (index !== undefined && toName && merged) return toName(merged)
    return name
  }, [toName, name, merged, index])

  const [editModalOpen, setEditModalOpen] = useState(initEditing ?? false)

  const titleFontSize = useMemo(() => {
    switch (size) {
      case 'sm':
        return '0.9rem'
      case 'md':
        return '1rem'
      case 'lg':
        return '1.1rem'
      default:
        return '1rem'
    }
  }, [size])
  const titlePx = useMemo(() => {
    switch (size) {
      case 'sm':
        return 1
      case 'md':
        return 2
      case 'lg':
        return 3
      default:
        return 2
    }
  }, [size])

  const contentRef = useRef<HTMLDivElement>(null)
  const { height: bodyHeight, width } = useResizeObserver(contentRef, 'scroll')
  const contextData = useMemo<DataViewContextData>(
    () => ({
      updateField,
      rootValue: merged,
      adminView: !!adminView,
      childrenExpanded,
      width,
    }),
    [updateField, merged, childrenExpanded, adminView, width],
  )
  const correctionsSharedFromParent = useShareCorrections()
  const shared = useMemo(
    () => corrections?.correctionsShared ?? correctionsSharedFromParent?.share ?? null,
    [corrections, correctionsSharedFromParent],
  )

  return (
    <ShareCorrectionsProvider onShare={onShareCorrections} shared={shared}>
      <DataViewContext.Provider value={contextData}>
        <Box
          border={index !== undefined ? '1px solid #cdcdcd' : undefined}
          borderRadius={4}
          w="100%"
          {...boxProps}>
          {noHeader ? null : (
            <Flex
              align="center"
              // borderTop={size !== 'sm' ? '1px solid #cdcdcd' : undefined}
              borderBottom={expanded ? '1px solid #cdcdcd' : undefined}
              py={1}
              // px={titlePx}
            >
              <Flex
                cursor={alwaysExpanded ? 'default' : 'pointer'}
                onClick={noBody || alwaysExpanded ? undefined : () => setExpanded(!expanded)}
                aria-label="expand/hide"
                flex={1}
                px={titlePx}
                minW="0"
                align="center">
                {index !== undefined ? (
                  <Center mr={2} w="18px" h="18px" bg={colors.green.hex} borderRadius="full">
                    <Text
                      fontWeight={800}
                      textShadow="1px 1px 4px black"
                      height="18px"
                      color="white"
                      fontSize="xs">
                      {index + 1}
                    </Text>
                  </Center>
                ) : null}
                <Flex
                  fontSize={titleFontSize}
                  color="#565656"
                  position="relative"
                  fontWeight={600}
                  fontFamily="Open Sans">
                  {displayedTitle}
                </Flex>
                {onShareCorrections ? (
                  <ShareCorrectionsButton
                    fields={field}
                    onSubmit={v => onShareCorrections(fieldPathSegments?.join('.') ?? null, v)}
                    shared={shared}
                  />
                ) : null}
              </Flex>
              <Stack mr={1} spacing={1} direction="row" ml="auto">
                {onDelete ? (
                  <DeleteAlertButton
                    opacity={expanded || noBody ? 1 : 0}
                    pointerEvents={expanded || noBody ? 'auto' : 'none'}
                    onClick={e => {
                      e.stopPropagation()
                      setDeleteAlertOpen(true)
                    }}
                  />
                ) : null}
                {withEditModal ? (
                  <SmallEditButton
                    size="xs"
                    color={colors.green.hex}
                    bg="white"
                    border={`1px solid ${colors.green.hex}`}
                    visible={expanded}
                    onClick={() => {
                      setEditModalOpen(true)
                      if (onEditingChange) onEditingChange(true)
                    }}
                  />
                ) : null}
                {noBody || alwaysExpanded === true || noArrow ? null : (
                  <ViewButton isOpen={expanded} onClick={() => setExpanded(!expanded)} />
                )}
              </Stack>
            </Flex>
          )}
          <Box
            height={expanded ? `${bodyHeight}px` : 0}
            opacity={expanded ? 1 : 0}
            transition={
              isAnimating
                ? `opacity 300ms ease ${expanded ? 180 : 0}, height 300ms ease 0ms`
                : 'none'
            }
            overflow="hidden"
            w="100%">
            <Box w="100%" ref={contentRef}>
              {noBody || (!expanded && !isAnimating) ? null : (
                <MapView
                  index={0}
                  data={data}
                  fieldPathSegments={fieldPathSegments}
                  baseStoragePath={baseStoragePath}
                  merged={merged}
                  field={field}
                  corrections={corrections}
                />
              )}
            </Box>
          </Box>
          {onSubmit && withEditModal ? (
            <GenericEditModal
              validate={validate}
              onClose={() => {
                if (onEditingChange) onEditingChange(false)
                setEditModalOpen(false)
              }}
              onSubmit={handleSubmit}
              baseStoragePath={baseStoragePath}
              isOpen={editModalOpen}
              data={merged}
              field={field}
            />
          ) : null}
          {onDelete ? (
            <DeleteAlert
              onConfirm={onDelete}
              itemName={name}
              isOpen={deleteAlertOpen}
              onClose={() => setDeleteAlertOpen(false)}
            />
          ) : null}
        </Box>
      </DataViewContext.Provider>
    </ShareCorrectionsProvider>
  )
}

export const DataViewModal: React.FC<DataViewProps & { isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
  ...dataViewProps
}) => (
  <DefaultModal
    overlayHeader
    size="4xl"
    bodyProps={{
      padding: 0,
      paddingLeft: 6,
      paddingRight: 6,
      style: {
        paddingTop: '48px',
        paddingBottom: '24px',
      },
    }}
    isOpen={isOpen}
    onClose={onClose}
    render={() => <DataView {...dataViewProps} />}
  />
)

export const DataViewListNewItemModal = ({
  isOpen,
  onClose,
  field,
  itemName,
  onSubmit,
}: {
  isOpen: boolean
  onClose: () => void
  field: FieldMap | Field
  itemName: string
  onSubmit?: (data: AnyObject, onUploadProgress: OnUploadProgress) => Promise<UpdateCallback>
}) => (
  <DefaultModal
    overlayHeader
    size="xl"
    isOpen={isOpen}
    onClose={onClose}
    render={() => (
      <VStack p={2} spacing={0} align="flex-start" w="100%">
        <Text color="green.500" p={2} fontSize="lg" fontFamily="Comfortaa">
          New {itemName}
        </Text>
        <SimpleForm
          theme="detailed"
          boxProps={{ p: 0, shadow: 'none' }}
          field={field}
          onSubmit={async (data, onProgress) => {
            if (!onSubmit) return { error: 'no onsubmit function' }
            const res = await onSubmit(data, onProgress)
            if (!res.error) onClose()
            return res
          }}
        />
      </VStack>
    )}
  />
)

export const DataViewList = <T extends AnyObject>({
  value,
  onSubmit,
  itemName,
  itemField,
  parentValue,
  baseStoragePath,
  fieldPathSegments,
  adminView,
  noArrow,
  initExpanded,
  stackProps,
  alwaysExpanded,
}: {
  value?: Array<T>
  itemField: FieldMap | Field
  parentValue?: FieldMapValue
  initExpanded?: boolean
  alwaysExpanded?: boolean
  baseStoragePath?: string | null
  fieldPathSegments?: string[]
  adminView?: boolean
  itemName: string
  noArrow?: boolean
  stackProps?: StackProps
  onSubmit?: (
    data: Array<T>,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
  ) => Promise<UpdateCallback>
}) => {
  const { isMobile } = useScreen()
  const updateItem = useCallback(
    async (idx: number, data: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (!onSubmit) return { error: 'No submit function' }
      const newValue = value ? [...value] : []
      newValue[idx] = data as T
      return onSubmit(newValue, onUploadProgress)
    },
    [onSubmit, value],
  )

  const deleteItem = useCallback(
    async (idx: number) => {
      if (!onSubmit) return { error: 'No submit function' }
      const newValue = value ? [...value] : []
      newValue.splice(idx, 1)
      return onSubmit(newValue, () => {})
    },
    [onSubmit, value],
  )

  const [newItemModalOpen, setNewItemModalOpen] = useState(false)
  const itemNamePlural = useMemo(
    () => (itemName.endsWith('s') ? `${itemName}es` : `${itemName}s`),
    [itemName],
  )

  return (
    <GridItemWrapper>
      <Expandable
        alwaysExpanded={alwaysExpanded}
        background="white"
        {...stackProps}
        initExpanded={initExpanded}
        header={({ isOpen }) => (
          <Flex py={1} w="100%">
            <DataHeader
              alignItems={isMobile ? 'flex-start' : 'center'}
              flexFlow={isMobile ? 'column' : 'row'}>
              <HStack>
                <Text>{itemNamePlural}</Text>
                <Center w="22px" h="22px" bg={colors.green.hex} borderRadius="full">
                  <Text
                    fontSize="xs"
                    textShadow="1px 1px 3px #00000077"
                    fontFamily="Hero-New"
                    fontWeight={600}
                    position="relative"
                    top="1px"
                    color="white">
                    {value?.length ?? 0}
                  </Text>
                </Center>
              </HStack>
              {onSubmit ? (
                <AddItemButton
                  ml={isMobile ? 0 : 'auto'}
                  aria-label={`Add ${itemName}`}
                  opacity={isOpen ? 1 : 0}
                  pointerEvents={isOpen ? 'auto' : 'none'}
                  onClick={e => {
                    e.stopPropagation()
                    setNewItemModalOpen(true)
                  }}>
                  + ADD {itemName.toUpperCase()}
                </AddItemButton>
              ) : null}
            </DataHeader>
          </Flex>
        )}>
        <Box w="100%" pb={0.5}>
          <VStack
            borderLeft="1px solid #cdcdcd"
            borderRight="1px solid #cdcdcd"
            borderBottom="1px solid #cdcdcd"
            bg="gray.50"
            borderBottomRadius={4}
            px={2}
            py={1}
            w="100%"
            align="flex-start"
            spacing={1}>
            {value?.length ? (
              value.map((item, idx) => (
                <Box key={idx} overflow="hidden" w="100%" bg="white" borderRadius={4}>
                  {isField(itemField) ? (
                    <Editable
                      onSubmit={
                        onSubmit
                          ? (data, onProgress) => updateItem(idx, data, onProgress ?? (() => {}))
                          : undefined
                      }
                      onDelete={onSubmit ? () => deleteItem(idx) : undefined}
                      baseStoragePath={baseStoragePath}
                      fieldPathSegments={[
                        ...(fieldPathSegments && Array.isArray(fieldPathSegments)
                          ? fieldPathSegments
                          : []),
                        item?.id ?? `${idx}`,
                      ]}
                      parentValue={parentValue}
                      value={item}
                      index={idx}
                      field={itemField}
                    />
                  ) : (
                    <DataView
                      key={`${idx}`}
                      size="sm"
                      field={{
                        ...itemField,
                        name: `${itemName} ${idx + 1}`,
                      }}
                      initExpanded={itemField.initExpanded}
                      index={idx}
                      adminView={adminView}
                      baseStoragePath={baseStoragePath}
                      fieldPathSegments={[
                        ...(fieldPathSegments && Array.isArray(fieldPathSegments)
                          ? fieldPathSegments
                          : []),
                        item?.id ?? `${idx}`,
                      ]}
                      noArrow={noArrow}
                      data={item}
                      onSubmit={
                        onSubmit
                          ? (data, onProgress) =>
                              data ? updateItem(idx, data, onProgress) : deleteItem(idx)
                          : undefined
                      }
                      updateField={(path, updated) => {
                        const newValue = { ...item }
                        nestedSet(newValue, path, updated)
                        return updateItem(idx, newValue, () => {})
                      }}
                      onDelete={onSubmit ? () => deleteItem(idx) : undefined}
                    />
                  )}
                </Box>
              ))
            ) : (
              <Text fontStyle="italic" p={1} color="gray.600">
                No {itemNamePlural}
              </Text>
            )}
          </VStack>
        </Box>
      </Expandable>
      {newItemModalOpen ? (
        <DataViewListNewItemModal
          itemName={itemName}
          isOpen={newItemModalOpen}
          field={itemField}
          onClose={() => setNewItemModalOpen(false)}
          onSubmit={
            onSubmit
              ? (data, onUploadProgress) =>
                  onSubmit(
                    [...(value && Array.isArray(value) ? value : []), data as T],
                    onUploadProgress,
                  )
              : undefined
          }
        />
      ) : null}
    </GridItemWrapper>
  )
}
