import { AnyObject, FieldMapValue, WithId } from '@hb/shared'
import { deleteDoc, doc, DocumentReference, onSnapshot, setDoc } from 'firebase/firestore'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { db } from '../../backend/db'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { UseDocument } from '../../types/data'

const refetch = <T>(
  ref: DocumentReference<T>,
  onData: (data: T | null) => void,
  onError: (message: string) => void,
) =>
  onSnapshot(
    ref,
    res => {
      const data = res.data()
      onData(data ? { ...data, id: res.id } : null)
    },
    err => {
      console.error(err)
      switch (err.code) {
        case 'permission-denied':
          onError('Insufficient permissions')
          break
        case 'unauthenticated':
          onError('Not authenticated')
          break
        default:
          onError('Error getting firestore document')
          break
      }
    },
  )

export const useRefDocument = <T extends AnyObject>(
  ref: DocumentReference<T> | null,
  enabled = true,
) => {
  const { showError, showSuccess } = useContext(PopUpMessageContext)

  const [loading, setLoading] = useState(!!(enabled && ref))
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<WithId<T> | null>(null)

  const handleError = useCallback(
    (message: string) => {
      console.error(`Error getting document: ${ref?.path ?? 'no path'}`)
      setError(message)
      showError(message)
    },
    [showError, ref],
  )

  useEffect(() => {
    if (ref) {
      setLoading(true)
      return refetch(
        ref,
        d => {
          setData(d ? { ...d, id: ref.id } : null)
          setLoading(false)
        },
        handleError,
      )
    }
    setLoading(false)
    setData(null)
    return () => {}
  }, [ref, showError, handleError])

  const onSave = useCallback(
    async (updated: FieldMapValue): Promise<boolean> => {
      if (!ref) {
        showError(`No ref when saving document`)
        return false
      }

      try {
        await setDoc(ref, updated)
      } catch (err: any) {
        showError(`Error saving document: ${err.message}`)
        return false
      }
      showSuccess('Successfully saved!')
      setLoading(false)
      setError(null)
      return true
    },

    [ref, showError, showSuccess],
  )

  const onDelete = useCallback(async (): Promise<boolean> => {
    if (!ref) {
      setError('No collection path when deleting document')
      return false
    }

    try {
      await deleteDoc(ref)
    } catch (err) {
      console.error(err)
      console.trace()
      setLoading(false)
      setError('Error deleting document')
      return false
    }
    showSuccess('Successfully deleted')
    setLoading(false)
    return true
  }, [ref, showSuccess])

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refetch: () => {
        if (!ref) return
        setLoading(true)
        refetch(
          ref,
          d => {
            setData(d ? { ...d, id: ref.id } : null)
            setLoading(false)
          },
          handleError,
        )
      },
      onSave,
      onDelete,
      ref,
    }),
    [data, loading, error, ref, onSave, onDelete, handleError],
  )
}

export const useDocument = <T extends AnyObject>(
  collectionPath: string | null,
  id?: string | null,
  enabled = true,
): UseDocument<T> => {
  const ref = useMemo(
    () =>
      enabled && collectionPath && id
        ? (doc(db, collectionPath, id) as DocumentReference<T>)
        : null,
    [collectionPath, id, enabled],
  )

  return useRefDocument(ref, enabled)
}

export const emptyUseDocument: UseDocument<any> = {
  data: null,
  loading: false,
  error: 'UseDocument not initialized',
  refetch: () => {},
  onSave: async () => false,
  onDelete: async () => false,
  ref: null,
}
