import { Button, Flex } from '@chakra-ui/react'
import { objectToArray, PracticeVisit, WithId } from '@hb/shared'
import React, { JSX, useMemo } from 'react'
import { useProfile } from '../../../../contexts/ProfileContext'
import { Loading } from '../../../Loading'
import { VisitPreview } from '../Charting/VisitView/VisitView'

export const PatientVisitsSummary = () => {
  const {
    goTo: onTabSelect,
    officeVisits: { data, loading },
  } = useProfile()

  const dataArr = useMemo(() => objectToArray(data ?? {}), [data])
  const mostRecentVisit = useMemo(
    () =>
      dataArr.reduce<WithId<PracticeVisit> | null>((acc, visit) => {
        if (!acc) return visit
        return visit.startTime > acc.startTime ? visit : acc
      }, null),
    [dataArr],
  )

  let body: JSX.Element | null = null
  if (loading && !dataArr.length) {
    body = <Loading text="Loading Visits" />
  } else {
    body = (
      <>
        <Button
          variant="link"
          color="gray.600"
          onClick={() => {
            onTabSelect({ entity: { type: 'patient' }, tab: 'visits' })
          }}>
          Office Visits ({dataArr.length})
        </Button>
        {mostRecentVisit ? <VisitPreview visit={mostRecentVisit} /> : null}
      </>
    )
  }

  return (
    <Flex px={2} align="flex-start" py={1} flexFlow="column" w="100%">
      {body}
    </Flex>
  )
}
