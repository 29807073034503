import {
  AdminUserData,
  authAppealsTemplatesCollection,
  authInstructionsTemplatesCollection,
  Claim,
  claimTemplatesCollection,
  clinicalsTemplatesCollection,
  consentFormTemplatesCollection,
  getMidwifeId,
  getMostRecentAssessment,
  getPregnancyFormData,
  invoiceTemplatesCollection,
  midwivesCollection,
  PatientContextData,
  PopulatedAssessment,
  ProfileItem,
  providersCollection,
  TemplateKey,
  User,
  USER_INVITES,
  USERS,
  ViewedTemplate,
} from '@hb/shared'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useAuth } from '../../../store/auth'
import { useCollectionItem } from '../useCollectionItem'
import { useDocument } from '../useDocument'
import { useAssessmentClaims } from './usePatientClaims'
import { usePatientOfficeVisits } from './usePatientVisits'
import { useAssessmentFromId } from './usePopulatedAssessment'
import { usePopulatedUser } from './usePopulatedUser'
import { getEmailVerified } from './userUtils'
import { useUserAssessments } from './useUserAssessments'
import { useUserConsentForms } from './useUserConsentForms'

export const useAssessmentMidwife = (assessment?: PopulatedAssessment) =>
  useCollectionItem(midwivesCollection, getMidwifeId(assessment)).item

export const useAssessmentInsurer = (assessment?: PopulatedAssessment | null) =>
  useCollectionItem(providersCollection, assessment?.patientPlans?.primary?.insuranceProviderId)
    .item

export const useAdminItem = (id?: string) => useDocument<AdminUserData>('users-admin', id).data
export const useUserItem = (id?: string, isInvite?: boolean) =>
  useDocument<User>(isInvite ? USER_INVITES : USERS, id).data

const useUserEmailVerified = (id?: string | null) => {
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)

  const refetch = useCallback(() => {
    if (!id) {
      setVerified(false)
      return
    }
    setLoading(true)
    getEmailVerified(id)
      .then(updated => {
        setVerified(updated)
        setLoading(false)
      })
      .catch((err: any) => {
        processResponse({
          error: err?.message ?? 'Error getting email verified',
        })
        setVerified(false)
        setLoading(false)
      })
  }, [id, processResponse])
  useEffect(refetch, [refetch])

  return { verified, loading, refetch }
}

export const useUserFromId = (
  admin: boolean,
  userId: string | null,
  selectedItem: ProfileItem | null,
): PatientContextData => {
  const claimId = useMemo(
    () => (selectedItem?.type === 'claim' ? selectedItem.id : null),
    [selectedItem],
  )

  const _assessmentId = useMemo(
    () => (selectedItem?.type === 'pregnancy' ? selectedItem.id : null),
    [selectedItem],
  )

  const { data: claim } = useDocument<Claim>('claims', claimId)
  const assessmentId = useMemo(
    () => (claimId ? (claim?.assessmentId ?? null) : _assessmentId),
    [claim, _assessmentId, claimId],
  )
  const { authUser: me, admin: isAdmin } = useAuth()
  const selectedAssessment = useAssessmentFromId(admin, me ? assessmentId : undefined)

  const { populated } = selectedAssessment ?? {}

  const { data: assessments } = useUserAssessments(userId, true)
  const data = usePopulatedUser(userId, false, populated?.midwifeId)

  const mostRecentPregnancy = useMemo(
    () => getMostRecentAssessment(assessments ?? {}),
    [assessments],
  )

  const emailVerification = useUserEmailVerified(
    !data.loading && data.user && !data?.user?.isInvite ? userId : null,
  )
  const insuranceProvider = useAssessmentInsurer(populated)

  const claims = useAssessmentClaims(assessmentId)
  const officeVisits = usePatientOfficeVisits(userId)

  const [viewedTemplateKey, setViewedTemplateKey] = useState<TemplateKey | null>(null)
  const viewedTemplate = useMemo<ViewedTemplate | null>(() => {
    switch (viewedTemplateKey) {
      case 'clinicals':
        return {
          templateType: 'clinicals',
          collection: clinicalsTemplatesCollection,
          id: insuranceProvider?.clinicalsTemplateId,
        }
      case 'authAppeals':
        return {
          templateType: 'authAppeals',
          collection: authAppealsTemplatesCollection,
          id: insuranceProvider?.authAppealsTemplateId,
        }
      case 'authInstructions':
        return {
          templateType: 'authInstructions',
          collection: authInstructionsTemplatesCollection,
          id: insuranceProvider?.authInstructionsTemplateId,
        }
      case 'invoiceAndReceipt':
        return {
          templateType: 'invoiceAndReceipt',
          collection: invoiceTemplatesCollection,
        }
      case 'claims':
        return {
          templateType: 'claims',
          collection: claimTemplatesCollection,
          id: insuranceProvider?.claimTemplateId,
        }
      case 'consentForm':
        return {
          templateType: 'consentForm',
          collection: consentFormTemplatesCollection,
        }
      default:
        return null
    }
  }, [viewedTemplateKey, insuranceProvider])

  const consentForms = useUserConsentForms(userId)

  const { user } = data
  const { formData: pregnancyData, formCorrections: pregnancyCorrections } = useMemo(
    () => getPregnancyFormData(user, populated),
    [user, populated],
  )

  return useMemo<PatientContextData>(
    () => ({
      ...data,
      userId: userId ?? null,
      viewedTemplate,
      setViewedTemplateKey,
      assessments,
      admin: isAdmin,
      mostRecentPregnancy,
      pregnancyData,
      pregnancyCorrections,
      selectedAssessment,
      consentForms,
      emailVerification,
      claims,
      selectedItem,
      officeVisits,
      assessmentId,
    }),
    [
      data,
      userId,
      viewedTemplate,
      assessments,
      isAdmin,
      selectedAssessment,
      pregnancyData,
      mostRecentPregnancy,
      pregnancyCorrections,
      consentForms,
      emailVerification,
      claims,
      officeVisits,
      assessmentId,
      selectedItem,
    ],
  )
}
