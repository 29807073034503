import { AnyObject, CollectionItem } from '@hb/shared'
import { DropResult } from '@hello-pangea/dnd'
import cloneDeep from 'lodash.clonedeep'

export function reorder<T extends AnyObject = AnyObject>(
  items: CollectionItem<T>[],
  dropResult: DropResult,
) {
  const result = cloneDeep(items)
  if (dropResult.destination && dropResult.source) {
    const [removed] = result.splice(dropResult.source.index, 1)
    if (!removed) return result
    result.splice(dropResult.destination.index, 0, removed)
    result.forEach((res, index) => {
      res.rank = index
    })
  }
  return result
}
