import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { Alert, FieldMapValue, getDateString, reactionIcons, UpdateCallback } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { DeleteButton } from '../Buttons'
import { Editable } from '../forms/Input'
import { UserBadge } from '../Users'
import { alertIconOptions, alertTextField, alertUrgentField, defaultAlertIcon } from './fields'

const AlertIconPopover = ({
  icon,
  onChange,
}: {
  icon?: string | null
  onChange: (updated: string | null) => Promise<UpdateCallback>
}) => {
  const [changingTo, setChangingTo] = useState<string | null | undefined>()
  const { processResponse } = useContext(PopUpMessageContext)
  const handleChangeTo = useCallback(
    async (updated: string | null) => {
      setChangingTo(updated)
      try {
        await onChange(updated)
      } catch (err: any) {
        processResponse({ error: err.message ?? 'Error updating alert icon' })
      }
      setChangingTo(undefined)
    },
    [onChange, processResponse],
  )
  return (
    <Popover trigger="hover" strategy="fixed">
      <PopoverTrigger>
        <IconButton
          aria-label="Custom Icon"
          variant="unstyled"
          overflow="hidden"
          _hover={{
            bg: 'blackAlpha.200',
          }}
          borderRadius="full"
          size="xs"
          icon={
            <Center>
              {icon ? (
                <Box fontSize="md">{reactionIcons[icon as keyof typeof reactionIcons]}</Box>
              ) : (
                defaultAlertIcon
              )}
            </Center>
          }
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="auto">
          <PopoverArrow />
          <PopoverBody borderRadius={6} overflow="hidden" p={0}>
            <Flex flexFlow="row wrap" justify="center" bg="gray.50" maxW="180px">
              <IconButton
                aria-label="Clear Icon"
                borderRadius="full"
                variant="unstyled"
                isLoading={changingTo === null}
                size="sm"
                icon={defaultAlertIcon}
                onClick={async () => handleChangeTo(null)}
              />
              {Object.keys(alertIconOptions).map(id => (
                <IconButton
                  key={id}
                  aria-label={id}
                  borderRadius="full"
                  isLoading={changingTo === id}
                  variant="unstyled"
                  size="sm"
                  icon={<Box>{alertIconOptions[id as keyof typeof alertIconOptions]}</Box>}
                  onClick={async () => handleChangeTo(id)}
                />
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const AlertEdit = ({
  id,
  alert,
  onDelete,
  onSubmit,
}: {
  id: string
  alert: string | Alert
  onDelete: () => Promise<UpdateCallback>
  onSubmit: (value: FieldMapValue) => Promise<UpdateCallback>
}) => {
  const text = typeof alert === 'string' ? alert : alert?.text
  const urgent = typeof alert === 'string' ? false : alert?.urgent
  const customIcon = typeof alert === 'string' ? null : alert?.customIcon
  const updatedBy = typeof alert === 'string' ? null : alert?.updatedBy
  return (
    <Box borderRadius={4} overflow="hidden" bg="white" shadow="md" width="100%">
      <HStack spacing={1} borderBottom="1px solid #cdcdcd" px={2} bg="gray.100" w="100%" py={1}>
        <AlertIconPopover
          icon={customIcon}
          onChange={updated =>
            onSubmit(
              typeof alert === 'string'
                ? { text: alert, customIcon: updated }
                : { ...alert, customIcon: updated },
            )
          }
        />
        <Text color="gray.500" fontWeight={600} fontSize="sm">
          {getDateString(parseInt(id, 10), 'short')}
        </Text>
        <HStack ml="auto">
          {updatedBy ? (
            <Box>
              <UserBadge userId={updatedBy} />
            </Box>
          ) : null}
          <DeleteButton itemName="Alert" onDelete={onDelete} />
        </HStack>
      </HStack>
      <Box w="100%" px={1} pb={1}>
        <Editable
          value={text}
          onSubmit={v => onSubmit(typeof alert === 'string' ? { text: v } : { ...alert, text: v })}
          field={alertTextField}
        />
        <Editable
          index={1}
          value={urgent}
          onSubmit={v => {
            const updated =
              typeof alert === 'string' ? { text: alert, urgent: v } : { ...alert, urgent: v }
            return onSubmit(updated)
          }}
          field={alertUrgentField}
        />
      </Box>
    </Box>
  )
}
