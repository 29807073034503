import { Collapse, Flex, Text } from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  DropdownField,
  FieldTypes,
  getHasMultiplePlans,
  getInsurancePlan,
  InsuranceCoverageId,
  medicaidProvidersCollection,
  PopulatedAssessment,
  PopulatedInsurancePlans,
  PopulatedUser,
  providersCollection,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useCollectionItem } from '../../../../../../hooks/backend/useCollectionItem'
import { FormElement } from '../../../../../forms'
import { useInsurancePlanField } from '../../../Assessments/hooks'
import { OtherCoverageDetails } from './OtherCoverageDetails'
import { SubmitClaimMainCoverageDetails } from './PrimaryCoverage'

export const SelectInsurer = ({
  assessment,
  user,
}: {
  assessment: PopulatedAssessment | null
  user: PopulatedUser | null
}) => {
  const { authorizations } = assessment ?? {}
  const { insurancePlans: plans } = user ?? {}

  const {
    input: { value: mainCoverageId },
  } = useField<InsuranceCoverageId>('mainCoverageId', {
    subscription: { value: true },
  })
  const {
    input: { value: otherCoverageId },
  } = useField('otherCoverageId', { subscription: { value: true } })

  const otherCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, otherCoverageId) : null),
    [otherCoverageId, plans],
  )

  const primaryCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, mainCoverageId) : null),
    [mainCoverageId, plans],
  )

  const { item: mainInsurer } = useCollectionItem(
    providersCollection,
    primaryCoverage?.insuranceProviderId,
  )

  const { item: otherInsurer } = useCollectionItem(
    otherCoverage?.isMedicaid ? medicaidProvidersCollection : providersCollection,
    otherCoverage?.insuranceProviderId,
  )

  const hasMultiplePlans = useMemo(() => getHasMultiplePlans(plans ?? {}), [plans])

  const field = useInsurancePlanField(plans)
  const nonSelectedPlans = useMemo<PopulatedInsurancePlans>(() => {
    const otherPlans: PopulatedInsurancePlans = {
      primary: null,
      secondary: null,
      additional: {},
      requests: {},
    }
    if (!hasMultiplePlans) return otherPlans
    if (!mainCoverageId)
      return {
        additional: plans?.additional ?? {},
        primary: plans?.primary ?? null,
        secondary: plans?.secondary ?? null,
        requests: plans?.requests ?? {},
      }

    const hasPrimaryInsurer = !!plans?.primary?.insuranceProviderId
    const hasSecondaryInsurer = !!plans?.secondary?.insuranceProviderId

    const addPrimaryCoverage = () => {
      if (hasPrimaryInsurer) otherPlans.primary = plans?.primary ?? null
    }
    const addSecondaryCoverage = () => {
      if (hasSecondaryInsurer) otherPlans.secondary = plans?.secondary ?? null
    }

    if (mainCoverageId === 'primary') {
      otherPlans.additional = plans?.additional ?? {}
      addSecondaryCoverage()
    } else if (mainCoverageId === 'secondary') {
      otherPlans.additional = plans?.additional ?? {}
      addPrimaryCoverage()
    } else if (mainCoverageId.startsWith('additional')) {
      const planId = mainCoverageId.split('.')[1] ?? ''
      const { [planId]: _, ...additionalPlans } = plans?.additional ?? {}
      otherPlans.additional = additionalPlans
      addPrimaryCoverage()
      addSecondaryCoverage()
    }

    return otherPlans
  }, [mainCoverageId, plans, hasMultiplePlans])

  const otherPlanField = useInsurancePlanField(nonSelectedPlans)

  const authorizationsField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Authorization',
      type: FieldTypes.DROPDOWN,
      optional: true,
      options:
        Object.values(authorizations ?? {}).map(a => ({
          text: `${a.referenceNumber} - ${capitalizeFirstLetter(a.serviceType)}`,
          id: a.referenceNumber,
        })) ?? [],
    }),
    [authorizations],
  )

  return (
    <Flex
      border="1px solid #cdcdcd"
      borderRadius={4}
      align="flex-start"
      bg="white"
      boxShadow="md"
      w="100%"
      px={3}
      my={2}
      flexFlow="column"
      py={2}>
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Insurance Plan
      </Text>
      <FormElement field={field} name="mainCoverageId" />
      <Collapse style={{ width: '100%' }} in={!!mainCoverageId}>
        {!mainInsurer?.abilityId ? (
          <Text px={2} color="red.600" fontSize="md">
            Insurance provider is missing Payer ID (Insurers Page)
          </Text>
        ) : (
          <Text px={2}>Payer ID: {mainInsurer?.abilityId ?? 'None'}</Text>
        )}
      </Collapse>
      <SubmitClaimMainCoverageDetails
        insuranceProvider={mainInsurer}
        coverage={primaryCoverage}
        authorizationsField={authorizationsField}
      />
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Additional Insurance Plan
      </Text>
      {hasMultiplePlans ? (
        <>
          <FormElement name="otherCoverageId" field={otherPlanField} />
          <Collapse style={{ width: '100%' }} in={!!otherCoverageId}>
            {!otherInsurer?.abilityId ? (
              <Text px={2} color="red.600" fontSize="md">
                Insurance provider is missing Payer ID (Insurers Page)
              </Text>
            ) : (
              <Text px={2}>Payer ID: {otherInsurer?.abilityId ?? 'None'}</Text>
            )}
          </Collapse>
          <OtherCoverageDetails
            insuranceProvider={otherInsurer}
            coverage={otherCoverage ?? null}
            authorizationsField={authorizationsField}
          />
        </>
      ) : null}
    </Flex>
  )
}
