import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

export const ContentBox = ({ children, ...props }: FlexProps) => {
  return (
    <Flex bg="white" borderRadius={4} boxShadow="1px 1px 4px #00000077" w="100%" {...props}>
      {children}
    </Flex>
  )
}
