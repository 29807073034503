import {
  Assessment,
  objectToArray,
  PopulatedAssessment,
  PopulatedUserData,
  WithId,
} from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { usePopulatedUser } from '../hooks'
import { usePopulatedAssessment } from '../hooks/backend/user/usePopulatedAssessment'
import { useUserAssessments } from '../hooks/backend/user/useUserAssessments'
import { useAuth } from '../store/auth'

interface UserAssessmentContextData {
  assessments: Record<string, Assessment>
  assessmentsArr: WithId<Assessment>[]
  loading: boolean
  error: string | null
  user: PopulatedUserData
  mostRecent: PopulatedAssessment | null
}
export const UserAssessmentsContext = createContext<UserAssessmentContextData>({
  assessments: {},
  assessmentsArr: [],
  loading: false,
  error: null,
  user: {
    adminPatientRef: null,
    user: null,
    loading: false,
    isInvite: false,
    patientRef: null,
    practicePatientRef: null,
  },
  mostRecent: null,
})

export const UserAssessmentsProvider = ({ children }: PropsWithChildren) => {
  const authUser = useAuth(s => s.authUser)
  const { data: assessments, error, loading } = useUserAssessments(authUser?.uid)
  const mostRecentAssessment = useMemo(() => {
    const mostRecent = Object.entries(assessments ?? {}).sort(([, a], [, b]) =>
      a.createdOn < b.createdOn ? 1 : -1,
    )[0]
    return mostRecent ? { id: mostRecent[0], ...mostRecent[1] } : undefined
  }, [assessments])
  const userData = usePopulatedUser(authUser?.uid)
  const { user, loading: userLoading } = userData
  const mostRecent = usePopulatedAssessment(mostRecentAssessment, user)
  const contextData = useMemo(
    () => ({
      assessments: assessments ?? {},
      assessmentsArr: objectToArray(assessments ?? {}),
      loading: loading || userLoading,
      user: userData,
      error,
      mostRecent,
    }),
    [assessments, loading, error, mostRecent, userLoading, userData],
  )
  return (
    <UserAssessmentsContext.Provider value={contextData}>
      {children}
    </UserAssessmentsContext.Provider>
  )
}

export const useMyAssessments = () => useContext(UserAssessmentsContext)
