import { ArrowForwardIcon, CloseIcon, EmailIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Center,
  CircularProgress,
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  arrayToObject,
  CollectionItem,
  colors,
  getIsAdmin,
  getIsPracticeAdmin,
  getPracticeUsersCollectionPath,
  getUserGroup,
  toSearchString,
  UserGroup,
  userGroupLabels,
  UserRoleItem,
  WithId,
} from '@hb/shared'
import { collection, getDocs, limit, query, QuerySnapshot, where } from 'firebase/firestore'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { db } from '../../backend/db'
import { USER_ROLES_REF } from '../../collections/firestoreCollections'
import { useApp, usePracticeAccess } from '../../contexts'
import { useMe } from '../../hooks/backend/useMe'
import { useDebounce } from '../../hooks/useDebounce'
import { TypingIndicator } from '../../icons/TypingIndicator'
import { ActionButton } from '../Buttons'
import { CollapseHorizontal } from '../shared'
import { getBadgeColor } from './UserBadge'

const SearchResultView = (props: BoxProps) => (
  <HStack
    boxShadow="md"
    spacing={1}
    w="100%"
    py={1}
    px={3}
    borderRadius="full"
    bg="#fcfcfc"
    {...props}
  />
)

// const searchByField: DropdownField = {
//   type: FieldTypes.DROPDOWN,
//   placeholder: 'Search by...',
//   options: [
//     { id: 'fname', text: 'First Name' },
//     { id: 'lname', text: 'Last Name' },
//     { id: 'email', text: 'Email' },
//     { id: 'phone', text: 'Phone' },
//   ],
// }

// type SearchField = 'fname' | 'lname' | 'email' | 'phone'

const filterUsersForGroup = (users: Array<WithId<UserRoleItem>>, group: UserGroup) => {
  switch (group) {
    case 'admin':
      return users.filter(user => getIsAdmin(user))
    case 'practice':
      return users.filter(user => getIsAdmin(user) || getIsPracticeAdmin(user))
    default:
      return users
  }
}
const UserSearchResult = ({
  user,
  onClick,
}: {
  user: WithId<UserRoleItem>
  onClick: () => void
}) => {
  const { fname, lname, email, id, archived } = user
  const [hovered, setHovered] = useState(false)
  const { appName } = useApp()
  const userGroup = useMemo(() => getUserGroup(user), [user])
  const roleText = useMemo(() => userGroupLabels[userGroup], [userGroup])
  const badgeColor = useMemo(() => getBadgeColor(appName, userGroup), [appName, userGroup])
  return (
    <SearchResultView
      position="relative"
      cursor="pointer"
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      _hover={{ background: 'white' }}
      key={id}
      py={2}
      px={4}
      onClick={onClick}>
      <VStack w="100%" spacing={1} align="flex-start">
        <HStack w="100%" spacing={2}>
          <Text lineHeight={1} fontSize="sm" fontFamily="Hero-New">
            {fname} {lname}
          </Text>
          <Badge textShadow="1px 1px 3px #00000099" color="white" bg={badgeColor}>
            {roleText}
          </Badge>
          {archived ? <Badge colorScheme="red">Archived</Badge> : null}
        </HStack>
        <HStack pl="3px">
          <EmailIcon color="gray.400" />
          <Text lineHeight={1} fontSize="sm" color="gray.600">
            {email}
          </Text>
        </HStack>
      </VStack>
      <Center
        pointerEvents="none"
        opacity={hovered ? 0.8 : 0}
        transition="opacity 300ms"
        position="absolute"
        w="60px"
        right="0"
        h="100%">
        <ArrowForwardIcon w={6} h={6} color={colors.green.hex} />
      </Center>
    </SearchResultView>
  )
}

export const SearchUsers = ({
  onSelect,
  onCancel,
  placement,
  isOpen,
  group,
  existing,
  userTypeName = 'user',
  excludeMe,
}: {
  onSelect: (id: string) => void
  isOpen: boolean
  group?: UserGroup
  onCancel?: () => void
  existing?: Array<string>
  placement?: 'top' | 'bottom'
  userTypeName?: string
  excludeMe?: boolean
}) => {
  const [search, setSearch] = useState<string>('')
  const [loadingResults, setLoadingResults] = useState<boolean>(false)
  const [unfiltered, setResults] = useState<Array<WithId<UserRoleItem>> | null>(null)
  const me = useMe()
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  // const [searchBy, setSearchBy] = useState<SearchField>('lname')
  const [error, setError] = useState<string | null>(null)
  const debounced = useDebounce(search, 500)

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
      setResults(null)
      setError(null)
    }
  }, [isOpen])

  const searchQuery = useMemo(() => {
    if (!debounced) return null
    const baseQuery = query(
      USER_ROLES_REF,
      where('stringifiedArray', 'array-contains', toSearchString(debounced)),
      limit(10),
    )
    if (group) {
      if (group === 'admin') return query(baseQuery, where('role', 'in', ['admin', 'super-admin']))
    }
    return baseQuery
  }, [debounced, group])

  const providersSearchQuery = useMemo(() => {
    if (appName === 'app' || !selectedPracticeId) return null
    const baseQuery = query(
      collection(db, getPracticeUsersCollectionPath(selectedPracticeId)),
      where('stringifiedArray', 'array-contains', toSearchString(debounced)),
      limit(10),
    )
    if (group) {
      if (group === 'admin') return query(baseQuery, where('role', 'in', ['admin', 'super-admin']))
    }
    return baseQuery
  }, [debounced, selectedPracticeId, appName, group])

  const onSubmit = useCallback(async () => {
    if (!searchQuery && !providersSearchQuery) return
    setLoadingResults(true)
    try {
      const allDocs = await Promise.all([
        searchQuery ? getDocs(searchQuery) : null,
        providersSearchQuery ? getDocs(providersSearchQuery) : null,
      ])
      const docs = allDocs.filter(d => d) as Array<QuerySnapshot<CollectionItem<UserRoleItem>>>
      const data = docs.map(d => d.docs.map(doc => ({ ...doc.data(), id: doc.id }))).flat()
      const userIds = Array.from(new Set(docs.map(d => d.docs.map(doc => doc.id)).flat()))
      const docsObj = arrayToObject(data) ?? {}
      setResults(userIds.map(id => docsObj[id]).filter(i => !!i))
    } catch (e: any) {
      console.error(e)
      setError(e.message ?? 'Error searching for patients')
    } finally {
      setLoadingResults(false)
    }
  }, [searchQuery, providersSearchQuery])
  const results = useMemo(() => {
    if (!unfiltered) return []
    const f = unfiltered.filter(
      user => !existing?.includes(user.id) && (excludeMe ? user.id !== me?.uid : true),
    )
    return group ? filterUsersForGroup(f, group) : f
  }, [unfiltered, group, existing, excludeMe, me])

  const isTyping = useMemo(() => debounced !== search, [debounced, search])
  useEffect(() => {
    if (!isTyping && debounced && search.length > 3) onSubmit()
  }, [isTyping, debounced, onSubmit, search])

  let body = null
  if (results) {
    body = results.length ? (
      <VStack w="100%" align="flex-start">
        {results.map(user => (
          <UserSearchResult onClick={() => onSelect(user.id)} key={user.id} user={user} />
        ))}
      </VStack>
    ) : (
      <Text px={3} color="gray.600">
        No matches{' '}
      </Text>
    )
  } else if (loadingResults) {
    body = (
      <Flex p={2} w="100%" justifyContent="center">
        <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
        <Text>Getting results...</Text>
      </Flex>
    )
  } else if (error) {
    body = <SearchResultView color="red.600">{error}</SearchResultView>
  } else {
    body = <SearchResultView>Enter a patient name to search</SearchResultView>
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 50)
    }
  }, [isOpen])

  return (
    <Popover isOpen={!!results.length && isOpen} matchWidth strategy="fixed" placement={placement}>
      <PopoverTrigger>
        <HStack spacing={2} w="100%" borderRadius="full">
          <Flex
            borderRadius="full"
            w="100%"
            bg="white"
            boxShadow="inset 0 0 6px #777"
            alignItems="center"
            px={2}
            py="0.15rem"
            borderBottom="1px solid #cdcdcd">
            <Input
              border="0"
              ref={inputRef}
              value={search}
              _focus={{ outline: 'none', border: 'none', boxShadow: 'none' }}
              background="none"
              onChange={e => setSearch(e.target.value)}
              placeholder={`Search ${userTypeName}s`}
              size="sm"
              mr={2}
            />
            <CollapseHorizontal in={isTyping} width={40}>
              <Center w="100px">
                <TypingIndicator style={{ width: '20px', height: '20px' }} />
              </Center>
            </CollapseHorizontal>
            <CollapseHorizontal in={!isTyping} width={40}>
              <ActionButton
                borderRadius="full"
                opacity={0.8}
                border="none"
                bg="none"
                onClick={onSubmit}
                isLoading={loadingResults}
                size="xs">
                <SearchIcon mr={1} />
                {/* Search */}
              </ActionButton>
            </CollapseHorizontal>
          </Flex>
          {onCancel ? (
            <IconButton
              borderRadius="full"
              opacity={0.8}
              aria-label="Close"
              color="gray.600"
              bg="gray.100"
              border="1px solid #cdcdcd"
              onClick={onCancel}
              icon={<CloseIcon w="10px" h="10px" />}
              size="sm"
            />
          ) : null}
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="whitesmoke"
          border="none"
          boxShadow="1px 1px 3px #00000077"
          w="100%"
          p={2}
          overflowY="auto"
          borderRadius="20px"
          maxH="300px">
          <Box w="100%">{body}</Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const SearchUsersPopover = ({
  onSelect,
  icon,
  group,
  excludeMe,
  existing,
  placement,
  triggerText,
}: {
  onSelect: (id: string) => void
  icon: IconButtonProps['icon']
  group?: UserGroup
  existing?: Array<string>
  excludeMe?: boolean
  placement?: PopoverProps['placement']
  triggerText?: string
}) => (
  <Popover placement={placement} closeOnBlur={false}>
    {({ onClose, isOpen }) => (
      <>
        <PopoverTrigger>
          <Box>
            <Tooltip placement="right" hasArrow>
              {triggerText ? (
                <Button
                  bg={colors.green.hex}
                  size="xs"
                  color="white"
                  boxShadow="1px 1px 4px rgba(0,0,0,0.5)"
                  onClick={onClose}
                  gap={1}
                  fontFamily="Open Sans"
                  fontWeight={500}>
                  {icon}
                  {triggerText}
                </Button>
              ) : (
                <IconButton
                  aria-label="Search users"
                  icon={icon}
                  size="xs"
                  bg={colors.green.hex}
                  color="white"
                  boxShadow="1px 1px 4px rgba(0,0,0,0.5)"
                  // variant="ghost"
                />
              )}
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <PopoverContent borderRadius="full" p={0} bg="gray.50" w="400px">
          <PopoverArrow />
          <PopoverBody>
            <SearchUsers
              excludeMe={excludeMe}
              onCancel={onClose}
              isOpen={isOpen}
              group={group}
              existing={existing}
              onSelect={id => {
                onSelect(id)
                onClose()
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </>
    )}
  </Popover>
)
