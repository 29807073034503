import { Box, Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useResizeObserver } from '../../../../../hooks/useResizeObserver'
import { BodyContent } from './Nav/BodyContent'
import { BodyHeader } from './Nav/BodyHeader'
import { PatientInfo } from './Patient/PatientInfo'

export const ProfileBody = ({ height, width }: { width: number; height: number }) => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const bodySize = useResizeObserver(bodyRef, 'content')
  return (
    <Flex height={`${height}px`} width={`${width}px`}>
      <Flex borderRight="1px solid #cdcdcd" w="300px" h="100%">
        <PatientInfo />
      </Flex>
      <Flex h="100%" flex={1} minW="0">
        <Flex w="100%" h="100%" flexFlow="column">
          <BodyHeader />
          <Box ref={bodyRef} w="100%" flex={1} minH={0}>
            <BodyContent size={bodySize} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
