import { createContext } from 'react'
import { FormsViewData } from '../types/views'
import { emptyPopulatedAssessmentInterface } from './AssessmentContext'

export const FormsViewContext = createContext<FormsViewData>({
  step: 'questions',
  selectStep: () => {},
  selected: null,
  open: () => {},
  close: () => {},
  assessmentIndex: null,
  assessmentsLoading: false,
  assessments: null,
  populatedUser: {
    adminPatientRef: null,
    isInvite: false,
    loading: false,
    practicePatientRef: null,
    patientRef: null,
    user: null,
  },
  form: null,
  formData: {},
  formCorrections: null,
  assessment: emptyPopulatedAssessmentInterface,
})
