import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  formatField,
  makeAllFieldsOptional,
  planDesignField,
  signOnFields,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { useApp } from '../../contexts/AppContext'
import { ProfileContext } from '../../contexts/ProfileContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { addMetadata } from '../../utils'
import { DataView } from '../DataView/DataView'
import { SignOnRemindersView } from '../Users/Profile/Assessments/SignOnRemindersView'
import { SkipToSignOn } from '../Users/Profile/Assessments/SkipToSignOn'
import { AssessmentFaxes } from './AssessmentFaxes'
import { InsuranceCardsView } from './InsuranceCardsView'

export const SignOnInfoPanel = ({ mobileLayout }: { mobileLayout: boolean }) => {
  const { appName } = useApp()
  const {
    selectedAssessment: { ref, populated: selectedAssessment },
    assessmentId,
    user,
  } = useContext(ProfileContext)
  const { signOnData, signOnCorrections, insuranceCoverage } = selectedAssessment ?? {}
  const { memberId, 'plan-design-and-state-mandates': planDesignAndStateMandates } =
    user?.insurancePlans?.primary ?? {}
  const { planDesign } = planDesignAndStateMandates ?? {}
  const planDesignText = useMemo(() => {
    if (!planDesign) return ''
    return `${formatField(planDesignField, planDesign)}`
  }, [planDesign])
  const update = useUpdateDoc('Sign on data')
  const optionalSignOnFields = useMemo(() => makeAllFieldsOptional(signOnFields), [])

  return (
    <VStack w="100%">
      <Flex flexFlow={mobileLayout ? 'column' : 'row'} gap={2} align="center" w="100%">
        <Flex w="100%" gap={2} align="center" flex={mobileLayout ? 'unset' : 1}>
          <SignOnRemindersView />
          <HStack
            px={2}
            bg={colors.green.hex}
            flex={1}
            minW="0"
            py={1}
            color="white"
            textShadow="1px 1px 1px rgba(0,0,0,0.5)"
            fontFamily="Comfortaa"
            borderRadius={6}
            boxShadow="md">
            <Text flex={1} minW="0" isTruncated mr="4px" fontWeight={600}>
              {`${insuranceCoverage ?? '(No insurer set)'} Member ID:`}
            </Text>
            <Text fontFamily="Hero-New">
              {typeof memberId === 'string' ? (memberId ?? 'None') : 'None'}
              {planDesignText ? ` - ${planDesignText}` : ''}
            </Text>
          </HStack>
          {appName === 'app' ? <SkipToSignOn mobileLayout={mobileLayout} /> : null}
        </Flex>
      </Flex>
      <Box w="100%" borderRadius={4} p={2} bg="white" boxShadow="md">
        <DataView
          initExpanded
          withEditModal
          adminView
          childrenExpanded
          onSubmit={data =>
            update(ref, 'signOnCorrections', addMetadata(data, appName, !signOnCorrections))
          }
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/signOnCorrections`}
          updateField={(path, value) => update(ref, `signOnCorrections.${path}`, value)}
          field={optionalSignOnFields}
          corrections={signOnCorrections}
          data={signOnData}
        />
      </Box>
      <InsuranceCardsView />
      {appName === 'app' ? <AssessmentFaxes /> : null}
    </VStack>
  )
}
