import { Center, Portal } from '@chakra-ui/react'
import { version } from '@hb/shared'
import {
  AppHeader,
  Container,
  CookieBanner,
  EditableStateProvider,
  LoadingOverlay,
  Menu,
  PopUpMessageOverlay,
  RootModals,
  ScreenContext,
  siteVersions,
  useAuth,
  useFilteredViews,
  UserAssessmentsProvider,
  useViewRoutes,
} from '@hb/shared-frontend'
import { MyThreadsListProvider } from '@hb/shared-frontend/components/Messaging/contexts'
import { MessagesWidget } from '@hb/shared-frontend/components/Messaging/MessagesWidget'
import { useScreen } from '@hb/shared-frontend/hooks/useScreen'
import React, { CSSProperties, useMemo } from 'react'
import { Route, Routes } from 'react-router'
import { views } from './views'

const getContainerStyle = (loading: boolean): CSSProperties => ({
  justifyContent: 'flex-start',
  opacity: loading ? 0 : 1,
  transition: `opacity 333ms ease ${loading ? 0 : 333}ms`,
  boxSizing: 'border-box',
  height: '100vh',
  background: siteVersions[version].background,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})
const App = () => {
  const loading = useAuth(s => s.loading)
  const style = useMemo(() => getContainerStyle(loading), [loading])
  const filteredViews = useFilteredViews(views)
  const routes = useViewRoutes(filteredViews)
  const screen = useScreen()
  const { height } = screen
  return (
    <ScreenContext.Provider value={screen}>
      <UserAssessmentsProvider>
        <EditableStateProvider>
          <Portal>
            <MyThreadsListProvider>
              <Container style={style}>
                <AppHeader views={filteredViews} />
                <Center position="relative" w="100%" height={`${height - 55}px`}>
                  <Routes>
                    {routes.map(({ path, component }) => (
                      <Route key={path} path={path} Component={component} />
                    ))}
                  </Routes>
                </Center>
                <Menu views={filteredViews} />
                <MessagesWidget />
              </Container>
              {/* <UserPanel /> */}
              {/* <TestButton /> */}
              <RootModals />
              <LoadingOverlay
                style={{ fontSize: '21px', fontFamily: 'Comfortaa' }}
                height={100}
                loading={loading}
                text="Hamilton Billing"
                withSignOut
              />
              <CookieBanner />
            </MyThreadsListProvider>
          </Portal>
          <PopUpMessageOverlay />
        </EditableStateProvider>
      </UserAssessmentsProvider>
    </ScreenContext.Provider>
  )
}

export default App
