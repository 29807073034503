import {
  getInsurancePlan,
  InsuranceCoverage,
  InsuranceCoverageId,
  // InsurancePlans,
  isInsuranceCoverageRequest,
  PopulatedInsurancePlans,
} from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { ProfileContext } from '../../../contexts/ProfileContext'

interface TemplateInsurancePlansContextData {
  insurancePlans: PopulatedInsurancePlans | undefined
  selectPlan: (planId: InsuranceCoverageId) => void
  selectedPlan: InsuranceCoverage | null
  selectedPlanId: InsuranceCoverageId
}

export const InsurancePlansTemplateContext = createContext<TemplateInsurancePlansContextData>({
  insurancePlans: { primary: null, secondary: null, additional: {}, requests: {} },
  selectedPlan: null,
  selectPlan: () => {},
  selectedPlanId: 'primary',
})

const useTemplateInsurancePlansData = (): TemplateInsurancePlansContextData => {
  const { user } = useContext(ProfileContext)

  const { insurancePlans } = user ?? {}

  const [selectedPlanId, setPlanId] = useState<InsuranceCoverageId>('primary')
  const selectedPlan = useMemo(() => {
    if (!insurancePlans) return null
    const plan = getInsurancePlan(insurancePlans, selectedPlanId)
    if (!plan || isInsuranceCoverageRequest(plan)) return null
    return plan
  }, [insurancePlans, selectedPlanId])

  return useMemo<TemplateInsurancePlansContextData>(
    () => ({
      insurancePlans,
      selectPlan: (id: InsuranceCoverageId) => {
        setPlanId(id)
      },
      selectedPlanId,
      selectedPlan,
    }),
    [insurancePlans, selectedPlanId, selectedPlan],
  )
}

export const TemplateInsurancePlansProvider = ({ children }: PropsWithChildren) => {
  const contextData = useTemplateInsurancePlansData()
  return (
    <InsurancePlansTemplateContext.Provider value={contextData}>
      {children}
    </InsurancePlansTemplateContext.Provider>
  )
}

export const useTemplateInsurancePlans = () => useContext(InsurancePlansTemplateContext)
