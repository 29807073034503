import { ValidationErrors } from '../types/data'
import { Form } from '../types/forms/form'
import { FieldMapValue } from '../types/utils/common'
import { FormStatus } from '../types/utils/medical'
import { defaultStageValidate, isInfoStage } from './fields'

export const defaultFormValidate =
  (form: Form, ignoreNowRequirement?: boolean) =>
  (values?: FieldMapValue): ValidationErrors => {
    const errors: Record<string, any> = {}
    const stageNames = Object.keys(form.stages)
    for (const stageName of stageNames) {
      const stage = form.stages[stageName]
      if (stage && !isInfoStage(stage)) {
        const validate = stage.validate ?? defaultStageValidate(stage, ignoreNowRequirement)
        const stageErrors = validate(values?.[stageName])
        if (stageErrors) errors[stageName] = stageErrors
      }
    }

    return Object.keys(errors).length ? errors : undefined
  }

export const getFormStatus = (form: Form | null, data?: FieldMapValue): FormStatus => {
  if (!form) return 'incomplete'
  const errors = defaultFormValidate(form, true)(data)
  if (errors) {
    const numErrors = Object.keys(errors).length
    const numRequired = Object.keys(form.stages).filter(
      sName => form.stages[sName] && !isInfoStage(form.stages[sName]),
    ).length
    if (form && numErrors) {
      if (numErrors === numRequired) {
        return 'incomplete'
      }
      return 'in progress'
    }
  }
  return 'complete'
}
