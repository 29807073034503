import { Box, Flex } from '@chakra-ui/react'
import React, { JSX, useMemo } from 'react'

const COLUMN_WIDTH = 400
const COLUMN_PADDING = 20

export const CardsView = ({
  width,
  elements,
  columnWidth = COLUMN_WIDTH,
}: {
  width: number
  elements: JSX.Element[]
  columnWidth?: number
}) => {
  const { numCols, colWidth } = useMemo(() => {
    const cols = Math.max(1, Math.floor(width / columnWidth))
    return {
      numCols: cols,
      colWidth: Math.floor(width / cols) - COLUMN_PADDING,
    }
  }, [width, columnWidth])

  const cols = useMemo(() => {
    const cols: Array<Array<JSX.Element>> = []
    for (let i = 0; i < numCols; i++) {
      cols.push([])
    }
    elements.forEach((element, i) => {
      cols[i % numCols].push(element)
    })
    return cols
  }, [numCols, elements])

  return (
    <Flex py={3} justify="space-evenly" width={`${width}px`}>
      {cols.map((col, i) => (
        <Flex key={`col-${i}`} gap={2} flexFlow="column" width={`${colWidth}px`}>
          {col.map((e, j) => (
            <Box w="100%" key={`col-${i}-el-${j}`}>
              {e}
            </Box>
          ))}
        </Flex>
      ))}
    </Flex>
  )
}
