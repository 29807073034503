import { Claim } from '@hb/shared'
import React, { useMemo } from 'react'
import { DataView } from '../../../../../DataView'
import { abilityDataToFormData } from '../../utils'
import { getSubmittedAbilitySubmissionField } from '../fields'

export const ClaimSubmissionForm = ({ claim }: { claim: Claim | undefined }) => {
  const formData = useMemo(
    () => abilityDataToFormData(claim?.submittedArgs ?? {}),
    [claim?.submittedArgs],
  )

  const field = useMemo(() => getSubmittedAbilitySubmissionField(formData), [formData])

  return <DataView initExpanded data={formData} field={field} />
}
