import { USERS } from '../collections/names'
import {
  type TemplateCollectionId,
  type TemplateKey,
  type TemplateKeyToCollectionId,
} from '../types/templates'

export const templateCollectionToDocumentType: Record<TemplateCollectionId, TemplateKey> = {
  authAppealsTemplates: 'authAppeals',
  authInstructionsTemplates: 'authInstructions',
  authTemplates: 'authorizations',
  invoicesReceiptsTemplates: 'invoiceAndReceipt',
  claimTemplates: 'claims',
  clinicalsTemplates: 'clinicals',
  templates: 'assessments',
  consentFormTemplates: 'consentForm',
}

export const templateTypeNames: Record<TemplateKey, string> = {
  authAppeals: 'auth appeal',
  consentForm: 'consent form',
  authorizations: 'authorization',
  authInstructions: 'auth instruction',
  invoiceAndReceipt: 'invoices / receipt',
  claims: 'claims',
  clinicals: 'clinical',
  assessments: 'assessment',
}

export const templateCollectionToName: Record<TemplateCollectionId, string> = {
  authTemplates: templateTypeNames.authorizations,
  consentFormTemplates: templateTypeNames.consentForm,
  authAppealsTemplates: templateTypeNames.authAppeals,
  authInstructionsTemplates: templateTypeNames.authInstructions,
  invoicesReceiptsTemplates: templateTypeNames.invoiceAndReceipt,
  claimTemplates: templateTypeNames.claims,
  clinicalsTemplates: templateTypeNames.clinicals,
  templates: templateTypeNames.assessments,
}

export const templateKeyToCollection: {
  [Key in TemplateKey]: TemplateKeyToCollectionId[Key]
} = {
  authAppeals: 'authAppealsTemplates',
  consentForm: 'consentFormTemplates',
  authInstructions: 'authInstructionsTemplates',
  authorizations: 'authTemplates',
  invoiceAndReceipt: 'invoicesReceiptsTemplates',
  claims: 'claimTemplates',
  clinicals: 'clinicalsTemplates',
  assessments: 'templates',
}

const documentTypes: TemplateKey[] = [
  'authAppeals',
  'authInstructions',
  'invoiceAndReceipt',
  'claims',
]
export const isDocumentType = (templateKey: TemplateKey): boolean =>
  documentTypes.includes(templateKey)

export const getDocumentSentPdfPath = (
  patientId: string,
  templateType: TemplateKey,
  now: string,
): string => {
  return `${USERS}/${patientId}/${templateType}/${now}-sent.pdf`
}

export const getDocumentSignedPdfPath = (
  patientId: string,
  templateType: TemplateKey,
  sentOn: string,
): string => {
  return `${USERS}/${patientId}/${templateType}/${sentOn}-signed.pdf`
}
