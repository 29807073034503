import { FieldMap, Form } from '../../types/forms'
import callDetails from './callDetails'
import callInDirections from './callInDirections'
import inNetwork from './inNetwork'
// import maximumReimbursableCharges from './maximumReimbursableCharges'
import outOfNetwork from './outOfNetwork'
import planCoversMidwives from './planCoversMidwives'
import { planDesignAndStateMandatesField } from './planDesignAndStateMandates'
// import reimbursementOption from './reimbursementOption'
export const callIn: Form = {
  name: 'Questions for your Insurer',
  path: 'insuranceInfo.primaryCoverage',
  pathName: 'Primary Coverage',
  description:
    "We'll guide you though a call with your insurance provider to get more information on your coverage",
  stages: {
    directions: callInDirections,
    'in-network': inNetwork,
    'out-of-network': outOfNetwork,
    // these two were moved to out of network
    // 'reimbursement-option': reimbursementOption,
    // 'maximum-reimbursable-charges': maximumReimbursableCharges,
    'plan-covers-midwives': planCoversMidwives,
    'plan-design-and-state-mandates': planDesignAndStateMandatesField,
    'call-details': callDetails,
  },
}

export const callInField: FieldMap = {
  name: 'Call In Questions',
  children: callIn.stages,
}
