import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import laptopIcon from '../../../icons/laptop.svg'
import { SettingsContainer } from './Container'
import { NotificationSoundSelect } from './NotificationSoundSelect'
import { NotificationsVolumeControl } from './NotificationVolumeSettings'

export const WebNotificationSettings = () => {
  return (
    <SettingsContainer>
      <Flex align="center" gap={1.5}>
        <Image src={laptopIcon} w="20px" opacity={0.7} />
        <Text fontFamily="Open Sans" fontSize="sm" color="gray.600">
          Site Notifications
        </Text>
      </Flex>
      <NotificationsVolumeControl />
      <NotificationSoundSelect topic="messaging" />
    </SettingsContainer>
  )
}
