import { PracticeVisit, VISITS } from '@hb/shared'
import { collection, CollectionReference, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend'
import { useApp } from '../../../contexts'
import { useSelectedPractice } from '../auth'
import { useQuery } from '../useQuery'

export const usePatientOfficeVisits = (patientId: string | null | undefined) => {
  const { appName } = useApp()

  const { id: practiceId } = useSelectedPractice()

  const queryRef = useMemo(() => {
    if (!patientId) return null
    if (appName === 'providers-app' && !practiceId) return null
    const visitsCollection = collection(db, VISITS) as CollectionReference<PracticeVisit>
    let q = query(
      visitsCollection,
      where('patientId', '==', patientId),
      where('assessmentId', '==', null),
    )
    if (appName === 'providers-app') {
      q = query(q, where('practiceId', '==', practiceId))
    }
    return q
  }, [patientId, appName, practiceId])
  return useQuery(queryRef)
}
