import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  FileDBValue,
  getPatientFileField,
  getPlansArray,
  insuranceCardBackField,
  insuranceCardFrontField,
} from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { ProfileContext } from '../../contexts/ProfileContext'
import { FileView } from '../forms'

const getInsuranceCardField = (id: string) => {
  if (id.endsWith('front')) {
    return insuranceCardFrontField
  } else {
    return insuranceCardBackField
  }
}

const FilePreview = ({
  file,
  onClick,
  onDelete,
  id,
}: {
  file: FileDBValue
  assessmentId: string
  onClick?: () => void
  onDelete?: () => void
  id: string
}) => {
  const field = useMemo(() => {
    const [firstSegment] = id.split('.')
    switch (firstSegment) {
      case 'files':
        return getPatientFileField(id.substring(6))
      case 'adminFiles':
        return getPatientFileField(id.substring(11))
      case 'practiceFiles':
        return getPatientFileField(id.substring(14))
      default:
        return getInsuranceCardField(id)
    }
  }, [id])

  return (
    <HStack
      bg="white"
      position="relative"
      _hover={onClick ? { bg: 'blackAlpha.100' } : undefined}
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      aria-label={file?.name}
      spacing={0}
      h="auto"
      w="100%"
      align="center">
      <HStack minW="0" flex={1}>
        <FileView field={field} data={file} />
      </HStack>
      {onDelete ? (
        <Button
          position="absolute"
          h="30px"
          w="30px"
          variant="ghost"
          size="xs"
          right={1}
          _hover={{ bg: 'blackAlpha.100' }}
          top={1}
          colorScheme="red"
          onClick={onDelete}>
          <DeleteIcon w={3} h={3} color="red.600" />
        </Button>
      ) : null}
    </HStack>
  )
}

export const AdditionalFilesField = ({ fields }: FieldArrayRenderProps<string, any>) => {
  const { selectedAssessment, assessmentId, user } = useContext(ProfileContext)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { insurancePlans: plans } = user ?? {}
  const { adminFiles, practiceFiles, files } = selectedAssessment?.populated ?? {}

  const allFiles = useMemo(() => {
    const plansArr = getPlansArray(plans ?? {})
    const cardImages = plansArr.reduce<Record<string, FileDBValue>>((acc, plan) => {
      if (plan.insuranceCard?.front) {
        acc[`${plan.id}.insuranceCard.front`] = plan.insuranceCard.front
      }
      if (plan.insuranceCard?.back) {
        acc[`${plan.id}.insuranceCard.back`] = plan.insuranceCard.back
      }
      return acc
    }, {})

    let res: Record<string, FileDBValue> = Object.entries(files ?? {}).reduce<
      Record<string, FileDBValue>
    >((acc, [id, data]) => ({ ...acc, [`files.${id}`]: { ...data } }), {})
    res = {
      ...res,
      ...Object.entries(adminFiles ?? {}).reduce<Record<string, FileDBValue>>(
        (acc, [id, data]) => ({ ...acc, [`adminFiles.${id}`]: { ...data } }),
        {},
      ),
      ...Object.entries(practiceFiles ?? {}).reduce<Record<string, FileDBValue>>(
        (acc, [id, data]) => ({ ...acc, [`practiceFiles.${id}`]: { ...data } }),
        {},
      ),
    }
    return { ...cardImages, ...res }
  }, [files, adminFiles, practiceFiles, plans])

  const hasFiles = useMemo(() => Object.keys(allFiles).length > 0, [allFiles])

  return (
    <VStack pt={2} spacing={2} w="100%" align="flex-start">
      <HStack justify="space-between" w="100%">
        <Text color="gray.600" fontFamily="Hero-New" fontSize="sx">
          Additional Files
        </Text>
        <Popover
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          onOpen={onOpen}
          strategy="fixed">
          <PopoverTrigger>
            <Button size="xs" bg={colors.green.hex} color="white">
              + Add File
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent maxH="500px" h="auto" overflowY="auto" w="auto">
              <PopoverCloseButton borderRadius="full" />
              <PopoverBody width="auto" p={0}>
                <VStack p={hasFiles ? 2 : 1}>
                  {assessmentId && hasFiles ? (
                    Object.entries(allFiles).map(([key, value]) => (
                      <FilePreview
                        onClick={() => {
                          fields.push(key)
                          onClose()
                        }}
                        key={key}
                        assessmentId={assessmentId}
                        file={value}
                        id={key}
                      />
                    ))
                  ) : (
                    <Text color="gray.600" pl={2} pr={9}>
                      No files associated with this assessment
                    </Text>
                  )}
                </VStack>
              </PopoverBody>
              <PopoverArrow />
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
      <VStack
        border="1px solid #cdcdcd"
        borderRadius={6}
        overflow="hidden"
        spacing={0}
        divider={<Divider />}
        pointerEvents="auto"
        w="100%"
        align="flex-start">
        {files && assessmentId && fields?.length ? (
          fields.map((fieldId, index) => (
            <Field
              key={fieldId}
              name={fieldId}
              render={({ input: { value } }) => (
                <FilePreview
                  onDelete={() => fields.remove(index)}
                  assessmentId={assessmentId}
                  file={allFiles?.[value]}
                  id={value}
                />
              )}
            />
          ))
        ) : (
          <Text color="gray.600" px={2} py={1}>
            No files added
          </Text>
        )}
      </VStack>
    </VStack>
  )
}
