import { CircularProgress, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { colors, ConsentForm, objectToArray, WithId } from '@hb/shared'
import React, { useMemo } from 'react'
import { useUserConsentForms } from '../../../../hooks/backend/user/useUserConsentForms'
import { DocumentsHeader } from '../Documents/DocumentsHeader'
import { DocumentsList } from '../Documents/DocumentsList'

// const separateDocumentsByAssessment = <T extends AssessmentDocument>(documents: WithId<T>[]) =>
//   documents.reduce(
//     (acc, doc) => {
//       const { assessmentId } = doc
//       if (!acc[assessmentId]) {
//         acc[assessmentId] = []
//       }
//       acc[assessmentId].push(doc)
//       return acc
//     },
//     {} as Record<string, WithId<T>[]>,
//   )

// .reduce(
//   (acc, [assessmentId, docs]) => ({
//     ...acc,
//     [assessmentId]: separateDocumentsByType(docs),
//   }),
//     {} as Record<
//       string,
//       Record<AssessmentDocumentType, WithId<AssessmentDocument>[]>
//     >,
// )

const PatientConsentFormsList = ({ documents }: { documents?: WithId<ConsentForm>[] }) => {
  // const typeKeys = useMemo(() => Object.keys(consent forms ?? {}), [consent forms])

  return (
    <VStack
      // sx={{ ':nth-of-type(4n - 3)': { background: 'rgb(245,245,245)' } }}
      px={2}
      pt={1}
      pb={2}
      align="flex-start"
      w="100%"
      spacing={0}>
      <HStack justify="space-between" w="100%">
        <HStack py={1} spacing={1}>
          <Image src="/images/assessment.svg" height="20px" opacity={0.6} />
          <Text
            fontSize="md"
            position="relative"
            top="1px"
            fontFamily="Comfortaa"
            fontWeight={600}
            color="gray.600">
            Consent Forms
          </Text>
        </HStack>
      </HStack>
      <DocumentsList
        // key={type}
        // collection={typeToCollection[type as AssessmentDocumentType]}
        documents={documents}
      />
    </VStack>
  )
}

export const PatientConsentForms = ({
  patientId,
  adminView,
}: {
  patientId: string
  adminView?: boolean
}) => {
  const { data, loading } = useUserConsentForms(patientId)
  const dataArr = useMemo(() => objectToArray(data ?? {}), [data])

  // const noneText = showAllAssessments
  // ? 'No assessments yet'
  // : 'No consent forms yet'

  return (
    <Flex borderBottomRadius={6} w="100%" boxShadow="md" bg="gray.50" borderRadius={6}>
      <VStack spacing={0} w="100%">
        <DocumentsHeader
          numDocs={dataArr.length}
          loading={loading}
          stackProps={{ borderBottom: '1px solid #cdcdcd' }}>
          {adminView ? 'Patient Consent Forms' : 'Your Consent Forms'}
        </DocumentsHeader>
        {!loading ? (
          <PatientConsentFormsList documents={dataArr} />
        ) : (
          <HStack bg="white" p={3} borderRadius={6} boxShadow="md" w="100%">
            <CircularProgress color={colors.green.hex} isIndeterminate size={5} />
            <Text fontSize="md" fontFamily="Open Sans" color="gray.600">
              {adminView ? 'Loading patient consent forms...' : 'Loading your consent forms...'}
            </Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
