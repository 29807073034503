import { FieldTypes } from '../../constants/FieldTypes'
import { Stage } from '../../types/forms/fields'

const callDetails: Stage = {
  name: 'Call Details',
  children: {
    agentName: {
      type: FieldTypes.TEXT,
      placeholder: 'Agent Name',
      hints: [{ text: 'Please document your call:' }],
    },
    callReferenceNumber: {
      type: FieldTypes.TEXT,
      placeholder: 'Call Reference Number',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Notes...',
      optional: true,
    },
    callDate: {
      type: FieldTypes.DATE,
      placeholder: 'Call Date',
      defaultToNow: true,
    },
  },
}

export default callDetails
