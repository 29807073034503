import { deleteField, DocumentReference, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'

export const useUpdateDoc = (itemName = 'patient') => {
  const { processResponse } = useContext(PopUpMessageContext)
  return useCallback(
    async (ref: DocumentReference | null | undefined, path: string, data: any) => {
      if (!ref) {
        return processResponse({ error: 'Error - ref is null or undefined' })
      }

      const curr = (await getDoc(ref)).data()

      if (!curr) {
        await setDoc(ref, {}, { merge: true })
      }

      const promise = path ? updateDoc(ref, path, data) : updateDoc(ref, data)
      return promise
        .then(() => processResponse({ success: `Updated ${itemName}!` }))
        .catch(err => {
          console.error(err)
          return processResponse({ error: `Error updating ${itemName}` })
        })
    },
    [processResponse, itemName],
  )
}

export const useDeleteDocData = (itemName = 'patient') => {
  const { processResponse } = useContext(PopUpMessageContext)
  return useCallback(
    async (ref: DocumentReference | null | undefined, path: string) => {
      if (!ref) {
        return processResponse({ error: 'Error - ref is null or undefined' })
      }
      return updateDoc(ref, path, deleteField())
        .then(() => processResponse({ success: `Deleted ${itemName}!` }))
        .catch(err => {
          console.error(err)
          return processResponse({ error: `Error deleting ${itemName}` })
        })
    },
    [processResponse, itemName],
  )
}
