import { useEffect, useMemo, useState } from 'react'
import { useContentWidth } from './useContentWidth'

export const useScreen = () => {
  // const { width = 0, height = 0 } = useResizeObserver(bodyRef, 'border')
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)
  const [height, setHeight] = useState(typeof window !== 'undefined' ? window.innerHeight : 0)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const contentWidth = useContentWidth(width)
  const isMobile = useMemo(() => width < 800, [width])
  return useMemo(
    () => ({
      height,
      width,
      contentWidth,
      contentHeight: isMobile ? height : height - 60,
      isMobile,
    }),
    [height, width, contentWidth, isMobile],
  )
}
