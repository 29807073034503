import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Center, Flex, HStack, Text } from '@chakra-ui/react'
import { AssessmentDocumentArgs, Template, UpdateCallback, WithId } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { sendAssessmentDocument } from '../../../hooks/backend/documents/utils'
import { useFile } from '../../../hooks/backend/storage/downloadFile'
import { PdfView } from '../../PdfView'
import { SubmitText } from '../../RichText'
import { useTemplateData, useTemplateView } from '../contexts'
import { PdfViewSubmitButton } from '../EditorSubmitButton'
import { TemplateMenu } from '../TemplateMenu'

export const ExternalPdfViewHeader = ({
  onBack,
  // templateType,
  // template,
  submitText,
}: {
  onBack?: () => void
  // templateType: TemplateKey
  // template: WithId<Template> | null
  submitText: SubmitText
}) => {
  const {
    shortcutArgs: { assessmentId, assessment, user },
  } = useTemplateData()
  const {
    type: templateType,
    template: { data: template },
    setManualTemplateId,
  } = useTemplateView()

  const { processResponse } = useContext(PopUpMessageContext)
  const [submitLoading, setSubmitLoading] = useState(false)
  const handleSubmit = useCallback(
    async (documentName: string): Promise<UpdateCallback> => {
      const patientId = assessment?.patientId
      const templateId = template?.id
      if (!assessmentId) return processResponse({ error: 'No assessmentId' })
      if (!patientId) return processResponse({ error: 'No patientId' })
      if (!templateId) return processResponse({ error: 'No templateId' })

      const args: AssessmentDocumentArgs = {
        assessmentId,
        patientId: assessment.patientId,
        type: templateType,
        text: [],
        templateId,
        name: documentName,
      }
      setSubmitLoading(true)
      try {
        await sendAssessmentDocument({ document: args })
        setSubmitLoading(false)
        return processResponse({ success: 'Document sent' })
      } catch (e: any) {
        console.error(e)
      }
      setSubmitLoading(false)
      return processResponse({ error: 'Failed to send document' })
    },
    [assessment, templateType, assessmentId, template, processResponse],
  )

  return (
    <HStack
      borderBottom="1px solid #cdcdcd"
      align="center"
      width="100%"
      p={2}
      zIndex={4}
      bg="white">
      <Box minW="0" flex={1}>
        <TemplateMenu
          insuranceProviderId={user?.insuranceProvider?.id}
          onChange={(t: WithId<Template>) => setManualTemplateId(t.id)}
          template={template}
          templateType={templateType}
        />
      </Box>
      <PdfViewSubmitButton
        template={template}
        templateType={templateType}
        submitLoading={submitLoading}
        handleSubmit={handleSubmit}
        submitText={submitText}
      />
      <Flex gap={1}>
        {onBack ? (
          <Button
            variant="outline"
            onClick={onBack}
            size="xs"
            bg="gray.50"
            color="gray.500"
            alignItems="center"
            gap={1}>
            <CloseIcon position="relative" top="1px" w={2} />
            <Text>Close</Text>
          </Button>
        ) : null}
      </Flex>
    </HStack>
  )
}

export const ExternalPdfTemplateView = ({
  width,
  height,
  header,
  // manualTemplateSelectedOn,
  template,
  submitText,
  // templateType,
  // setManualTemplateId,
  onClose,
}: {
  width: number
  height: number
  // manualTemplateSelectedOn: React.MutableRefObject<number>
  template: WithId<Template> | null
  // templateType: TemplateKey
  // setManualTemplateId: (id: string) => void
  header?: React.ReactNode | null
  submitText: SubmitText
  onClose?: () => void
}) => {
  const { externalPdf } = template ?? {}
  const { url } = useFile({ path: externalPdf?.baseFile.storagePath })

  if (!externalPdf) {
    return (
      <Center w={`${width}px`} h={`${height}px`}>
        <Box>External PDF not found</Box>
      </Center>
    )
  }

  return (
    <Box overflow="hidden" width={`${width}px`} height={`${height}px`}>
      <PdfView attachments={externalPdf?.attachments} height={height} width={width} url={url}>
        <Box w="100%">
          {header}
          <ExternalPdfViewHeader onBack={onClose} submitText={submitText} />
        </Box>
      </PdfView>
    </Box>
  )
}
