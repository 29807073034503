import {
  AdminAssessmentData,
  arrayToObject,
  Assessment,
  ASSESSMENT_SNIPPETS,
  ASSESSMENTS,
  ASSESSMENTS_ADMIN,
  AssessmentSnippet,
  getCorrectedValue,
  getPracticeAssessmentCollectionPath,
  midwivesCollection,
  populateAssessment,
  PopulatedAssessmentInterface,
  PopulatedUser,
  PracticeAssessmentData,
  providersCollection,
} from '@hb/shared'
import { useMemo } from 'react'
import { useCollection } from '../../../collections/hooks/useCollection'
import { useApp } from '../../../contexts/AppContext'
import { useAuth } from '../../../store/auth'
import { useDocument } from '../useDocument'
import { usePopulatedUser } from './usePopulatedUser'

export const usePopulatedAssessment = (
  assessment: Assessment | undefined | null,
  user: PopulatedUser | null,
  snippet?: AssessmentSnippet | null,
  adminData?: AdminAssessmentData | null,
  practiceData?: PracticeAssessmentData,
) => {
  const practices = useCollection(midwivesCollection)
  const practicesRecord = useMemo(
    () =>
      arrayToObject(practices?.items && Array.isArray(practices.items) ? practices.items : []) ??
      {},
    [practices],
  )

  return useMemo(
    () =>
      assessment && user
        ? populateAssessment(user, assessment, practicesRecord, snippet, adminData, practiceData)
        : null,
    [assessment, snippet, adminData, practicesRecord, practiceData, user],
  )
}

export const useAssessmentFromId = (
  admin: boolean,
  id: string | undefined | null,
): PopulatedAssessmentInterface => {
  const { authUser } = useAuth()
  // const waitingForAdmin = useMemo(() => !admin, [admin])
  const { appName } = useApp()
  const authId = useMemo(() => (authUser ? id : null), [id, authUser])
  const { data: assessment, ref, loading } = useDocument<Assessment>(ASSESSMENTS, authId)
  const adminId = useMemo(
    () => (appName === 'app' && admin && authUser ? id : null),
    [authUser, id, admin, appName],
  )
  const {
    data: snippet,
    ref: snippetRef,
    loading: snippetLoading,
  } = useDocument<AssessmentSnippet>(ASSESSMENT_SNIPPETS, adminId)

  const midwifeId = useMemo(
    () => (assessment ? getCorrectedValue(assessment, 'delivery.midwifeId') : null),
    [assessment],
  )
  const {
    data: practiceData,
    ref: practiceRef,
    loading: practiceLoading,
  } = useDocument<PracticeAssessmentData>(
    midwifeId ? getPracticeAssessmentCollectionPath(midwifeId) : null,
    admin ? id : null,
  )

  const {
    data: adminData,
    ref: adminRef,
    loading: adminLoading,
  } = useDocument<AdminAssessmentData>(ASSESSMENTS_ADMIN, adminId)

  const insurers = useCollection(providersCollection)
  const user = usePopulatedUser(assessment?.patientId ?? null)

  const populated = usePopulatedAssessment(
    assessment ?? undefined,
    user?.user ?? null,
    snippet ?? undefined,
    adminData ?? undefined,
    practiceData ?? undefined,
  )

  const practices = useCollection(midwivesCollection)
  const { loading: insurersLoading } = insurers ?? {}
  const { loading: midwivesLoading } = practices ?? {}

  return useMemo(
    () => ({
      isPopulated: true,
      ref,
      id: id ?? '',
      snippetRef,
      assessmentData: assessment,
      adminRef,
      practiceRef,
      practiceData,
      adminData,
      snippetData: snippet,
      loading: !!(
        loading ||
        snippetLoading ||
        adminLoading ||
        insurersLoading ||
        midwivesLoading ||
        practiceLoading
      ),
      populated,
    }),
    [
      populated,
      adminRef,
      snippetRef,
      ref,
      assessment,
      adminLoading,
      practiceLoading,
      snippet,
      adminData,
      practiceData,
      practiceRef,
      loading,
      snippetLoading,
      insurersLoading,
      id,
      midwivesLoading,
    ],
  )
}
