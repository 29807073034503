import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { JSX, PropsWithChildren } from 'react'
import { ContentBox } from '../../../../../Containers/ContentBox'

export const PreviewBox = ({
  children,
  title,
  onGo,
  maxH = 340,
  fixed,
  header,
  h,
}: PropsWithChildren<{
  title: string
  maxH?: number
  h?: number | string
  header?: JSX.Element
  onGo?: () => void
  fixed?: boolean
}>) => {
  const text = (
    <Text fontSize="md" opacity={0.8} fontFamily="Hero-New" color="gray.900" fontWeight={500}>
      {title}
    </Text>
  )
  return (
    <ContentBox overflow="hidden" w="100%" flexFlow="column">
      <Flex bg="white" borderBottom="1px solid #cdcdcd" px={2.5} py={1} w="100%">
        {onGo ? (
          <Button
            variant="link"
            onClick={onGo}
            fontWeight={500}
            fontSize="md"
            fontFamily="Hero-New">
            <Flex align="center" gap={1}>
              {text}
              <ExternalLinkIcon opacity={0.8} w={3.5} />
            </Flex>
          </Button>
        ) : (
          text
        )}
        {header ? (
          <Box flex={1} minW="0">
            {header}
          </Box>
        ) : null}
      </Flex>
      <Flex
        w="100%"
        bg="gray.50"
        overflowY={fixed ? 'hidden' : 'auto'}
        h={h}
        maxH={fixed ? 'unset' : `${maxH}px`}>
        {children}
      </Flex>
    </ContentBox>
  )
}
