import { TempTimeValue } from './types'

export const timeStringFromDate = (_date: Date | number) => {
  const date = typeof _date === 'number' ? new Date(_date) : _date
  const hrs = date.getHours()
  const mins = date.getMinutes()
  const ampm = hrs < 12 ? 'AM' : 'PM'
  const h = hrs % 12
  return `${h}:${mins.toString().padStart(2, '0')} ${ampm}`
}

export const deconstructTimeString = (time?: string) => {
  if (!time) return { hours: 0, minutes: 0, amPm: 'AM' }
  const [h, m] = time?.split(':') ?? []
  const [minutes, amPm] = m.split(' ')
  return {
    hours: parseInt(h, 10),
    minutes: parseInt(minutes, 10),
    amPm,
  }
}

export const constructTimeString = (time: TempTimeValue) => {
  const { hours, minutes, amPm } = time
  return `${hours}:${minutes.toString().padStart(2, '0')} ${amPm.toUpperCase()}`
}
