import { FieldTypes } from '../../constants/FieldTypes'
import { PlanDesignOption } from '../../types'
import {
  BooleanField,
  CheckboxField,
  DropdownField,
  DropdownOptionItem,
  Stage,
} from '../../types/forms'
import { FieldLabels, FieldMapValue } from '../../types/utils/common'
import { stateField } from '../shared'

const planDesignLabels: FieldLabels = [
  {
    text: 'Is my plan ‘fully insured’ or ‘self-funded’?',
    style: { display: 'block', marginBottom: 10 },
  },
  {
    text: 'Fully insured',
    style: { fontWeight: 500, color: 'black' },
  },
  {
    text: ' (sometimes called commercially insured) typically means the insurance company follows state mandates in the state where the plan is based. ',
    style: { color: 'black' },
  },
  {
    text: '',
    style: { display: 'block' },
  },
  {
    text: 'Self-funded',
    style: { fontWeight: 500, color: 'black' },
  },
  {
    text: ' means a company, school, union or other institution governs rules on the plan',
    style: { color: 'black' },
  },
  {
    text: '',
    style: { display: 'block' },
  },
  {
    text: 'On rare occasions plans are ',
    style: { color: 'black' },
  },
  {
    text: 'split-funded, which means BOTH self-funded AND fully insured.',
    style: { fontWeight: 500, color: 'black' },
  },
]

export const planDesignOptions: DropdownOptionItem<PlanDesignOption>[] = [
  {
    id: 'fully-insured',
    text: 'Fully Insured',
  },
  {
    id: 'split-funded',
    text: 'Split-Funded',
  },
  {
    id: 'self-funded',
    text: 'Self-Funded',
  },
]
export const planDesignField: DropdownField<PlanDesignOption> = {
  type: FieldTypes.DROPDOWN,
  options: planDesignOptions,
  placeholder: 'Plan Design',
  labels: planDesignLabels,
}

const followsStateMandatesLabels: FieldLabels = [
  {
    text: 'Does my plan follow New York state mandates?',
    style: {
      marginBottom: 10,
      display: 'block',
    },
  },
  {
    text: `If they don’t understand the question, ask if your
    plan `,
    style: {
      color: 'black',
    },
  },
  {
    text: ' follows New York State Insurance law. ',
    style: {
      color: 'black',
      fontWeight: 500,
    },
  },
  {
    text: `Or ask
    for a supervisor to clarify - agents often have difficulty clarifying or answering this question.`,
    style: {
      color: 'black',
    },
  },
]

export const stateMandatesNotesField: DropdownField = {
  ...stateField,
  placeholder: 'State my plan is based in',
  optional: true,
  labels: [
    {
      text: 'Please confirm which state my home plan is based out of',
    },
  ],
}

export const followsStateMandatesField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  placeholder: 'Plan follows state mandates',
  labels: followsStateMandatesLabels,
}

export const followStateMandatesCheckbox: CheckboxField = {
  ...followsStateMandatesField,
  type: FieldTypes.CHECKBOX,
}

export const planDesignAndStateMandatesField: Stage = {
  name: 'Plan Design & State Mandates',
  calculate: [
    {
      field: 'confirmFollowsStateMandates',
      updates: {
        followsStateMandates: (confirmValue: any, allValues: any) => {
          if (allValues && confirmValue) return true
          if (allValues && confirmValue === false) return false
          return allValues && (allValues as FieldMapValue).followsStateMandates
        },
        confirmFollowsStateMandates: (confirmValue: any) => {
          if (!confirmValue) return undefined
          return true
        },
      },
    },
    {
      field: 'confirmDoesNotFollowStateMandates',
      updates: {
        followsStateMandates: (confirmValue: any, allValues: any) => {
          if (allValues && confirmValue === false) return true
          if (allValues && confirmValue === true) return false
          return allValues && (allValues as FieldMapValue).followsStateMandates
        },
        confirmDoesNotFollowStateMandates: (confirmValue: any) => {
          if (!confirmValue) return undefined
          return true
        },
      },
    },
  ],
  children: {
    planDesign: planDesignField,
    followsStateMandates: followsStateMandatesField,
    confirmFollowsStateMandates: {
      type: FieldTypes.BOOLEAN,
      placeholder: 'Confirm follows state mandates',
      labels: [
        {
          text: `Typically, self funded plans do not follow
        state mandates. Could you please double
        check if my plan follows state mandates?`,
        },
        {
          text: `If they don’t understand the question, ask if
        your plan follows New York State Insurance
        law. Or ask for a supervisor to clarify - agents often have difficulty clarifying or answering this question.`,
          style: {
            color: 'black',
            marginTop: 10,
            display: 'block',
          },
        },
      ],
      yesText: 'I have confirmed that my plan follows state mandates',
      noText: 'My plan does not follow state mandates',
      condition: value =>
        value?.planDesign &&
        value.planDesign === 'self-funded' &&
        value.followsStateMandates === true,
    },
    confirmDoesNotFollowStateMandates: {
      type: FieldTypes.BOOLEAN,
      placeholder: 'Confirm that plan does not follow state mandates',
      yesText: 'I have confirmed that my plan does not follow state mandates',
      noText: 'My plan does follow state mandates',
      condition: value =>
        value?.planDesign &&
        value.planDesign !== 'self-funded' &&
        value.followsStateMandates === false,
      labels: [
        {
          text: `Typically, fully insured/split-funded plans are
        required to follow state mandates. Could
        you please double check if my plan follows
        state mandates?`,
        },
        {
          text: `If they don’t understand the question, ask if
        your plan follows New York State Insurance
        law. Or ask for a supervisor to clarify - agents often have difficulty clarifying or answering this question.`,
          style: {
            color: 'black',
            marginTop: 10,
            display: 'block',
          },
        },
      ],
    },
    // state my plan is based in - TODO: change this to a different prop name (planState)
    followsStateMandatesNote: stateMandatesNotesField,
    notes: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Notes...',
      labels: [
        {
          text: "If your plan does not follow New York State mandates, please ask the agent 'under which state does my plan follow insurance law'? ",
        },
      ],
      condition: value =>
        value?.planDesign &&
        value.planDesign !== 'self-funded' &&
        value.followsStateMandates === false &&
        value.confirmDoesNotFollowStateMandates,
    },
  },
}
