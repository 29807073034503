import { Box, Center } from '@chakra-ui/react'
import { reactionIcons } from '@hb/shared'
import React from 'react'
import { defaultAlertIcon } from './fields'
export const AlertIcon = ({ icon, filter }: { icon?: string | null; filter?: string }) => (
  <Center filter={filter}>
    {icon ? (
      <Box fontSize="md">{reactionIcons[icon as keyof typeof reactionIcons]}</Box>
    ) : (
      defaultAlertIcon
    )}
  </Center>
)
