import { Assessment, InsuranceProvider } from '@hb/shared'
import { useMemo } from 'react'
import { getCorrectedValue } from './userUtils'

export const useInsuranceCoverageText = (
  assessment?: Assessment | null,
  insuranceProvider?: InsuranceProvider,
) =>
  useMemo(() => {
    if (!assessment) return 'None'
    const option = getCorrectedValue(assessment, 'insurance-info.option')
    if (option === 'no-coverage') return 'No coverage'
    const insuranceProviderName = insuranceProvider?.name ?? '(No Insurer)'
    const medicaidPlan = getCorrectedValue(assessment, 'insurance-info.medicaidPlanName')
    if (option === 'medicaid') {
      if (insuranceProvider) return `${insuranceProviderName || medicaidPlan} Medicaid`
      if (medicaidPlan) return `Medicaid - ${medicaidPlan}`
      return 'Medicaid'
    }
    return insuranceProviderName
  }, [assessment, insuranceProvider])
