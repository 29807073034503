import { ShortcutArgs, Template, TemplateKey, UpdateCallback, WithId } from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { TemplateDataProvider } from './data'
import { EditModeProvider } from './editMode'

interface TemplateEditorContextValue {
  type: TemplateKey
  isLoading: boolean
  onSubmit: (template: Omit<Template, 'rank'> & { id?: string }) => Promise<UpdateCallback>
  onUpdateField: (field: string, value: any) => Promise<UpdateCallback>
  template: WithId<Template> | null
}

const TemplateEditorContext = createContext<TemplateEditorContextValue>({
  type: 'assessments',
  onUpdateField: () => Promise.resolve({}),
  onSubmit: () => Promise.resolve({}),
  isLoading: false,
  template: null,
})

export const TemplateEditorProvider = ({
  children,
  onSubmit,
  type,
  template,
  isLoading,
  onUpdateField,
  shortcutArgs,
}: PropsWithChildren<{
  shortcutArgs: ShortcutArgs
  type: TemplateKey
  onSubmit: (updated: Omit<Template, 'rank'>) => Promise<UpdateCallback>
  onUpdateField: (field: string, value: any) => Promise<UpdateCallback>
  template: WithId<Template> | null
  isLoading?: boolean
}>) => {
  const contextValue = useMemo<TemplateEditorContextValue>(
    () => ({
      type,
      onSubmit,
      template,
      onUpdateField,
      isLoading: isLoading ?? false,
    }),
    [type, onSubmit, template, isLoading, onUpdateField],
  )

  return (
    <EditModeProvider editMode="template">
      <TemplateDataProvider data={shortcutArgs} type={type}>
        <TemplateEditorContext.Provider value={contextValue}>
          {children}
        </TemplateEditorContext.Provider>
      </TemplateDataProvider>
    </EditModeProvider>
  )
}

export const useTemplateEditor = () => useContext(TemplateEditorContext)
