import { InsuranceCoverage, PopulatedAssessment, PopulatedUser, ShortcutArgs } from '../../types'

export const getShortcutArgs = (
  user: PopulatedUser | null | undefined,
  assessmentId: string | null,
  assessment?: PopulatedAssessment | null,
  selectedPlan?: InsuranceCoverage | null,
): ShortcutArgs => {
  const midwife = assessment?.midwife
  const prm = assessment?.payments?.prm ?? midwife?.prm
  const retainer =
    Object.values(assessment?.payments?.due ?? {}).find(p => p.type === 'retainer')?.amount ??
    assessment?.midwife?.retainer
  const deposits = Object.values(assessment?.payments?.due ?? {}).filter(p => p.type === 'deposit')

  return {
    assessmentId,
    user: user ?? undefined,
    assessment,
    plan: selectedPlan,
    prm,
    retainer,
    deposits,
    practice: assessment?.midwife,
  }
}
