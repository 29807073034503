import { Button, Flex, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useProfile } from '../../../../contexts/ProfileContext'

export const PatientInsuranceSummary = () => {
  const { goTo: onTabSelect, user } = useProfile()

  const { insurancePlans } = user ?? {}

  const primaryCoverageText = useMemo(() => {
    const { primary } = insurancePlans ?? {}
    if (!primary) return 'No Primary Insurance'
    return `${primary.insuranceProvider?.name ?? 'No insurer'} - ${primary.memberId ?? 'No Member ID'}`
  }, [insurancePlans])

  const secondaryCoverageText = useMemo(() => {
    const { secondary } = insurancePlans ?? {}
    if (!secondary) return 'No Secondary Insurance'
    return `${secondary.insuranceProvider?.name ?? 'No insurer'} - ${secondary.memberId ?? 'No Member ID'}`
  }, [insurancePlans])

  const numPotentialPlans = useMemo(() => {
    const { additional } = insurancePlans ?? {}
    return Object.keys(additional ?? {}).length
  }, [insurancePlans])

  return (
    <Flex align="flex-start" px={2} py={1} w="100%" flexFlow="column">
      <Button
        variant="link"
        color="gray.600"
        onClick={() => onTabSelect({ entity: { type: 'patient' }, tab: 'insurance' })}>
        Insurance Info
      </Button>
      <Text color="gray.700">{primaryCoverageText}</Text>
      {insurancePlans?.secondary ? (
        <Text fontSize="sm" color="gray.700">
          {secondaryCoverageText}
        </Text>
      ) : null}
      <Text fontSize="sm" color="gray.700">
        {numPotentialPlans ? `${numPotentialPlans} Other Plans` : 'No Other Plans'}
      </Text>
    </Flex>
  )
}
