import { EligibilityServiceTypeCodes } from './EligibilityServiceTypeCodes'

export const eligibilityServiceTypes: Partial<
  Record<EligibilityServiceTypeCodes, { name: string; rank: number }>
> = {
  [EligibilityServiceTypeCodes.HEALTH_BENEFIT_PLAN_COVERAGE]: {
    name: 'Health Benefit Plan Coverage',
    rank: 0,
  },
  [EligibilityServiceTypeCodes.PROFESSIONAL_PHYSICIAN_VISIT_OFFICE]: {
    name: 'Professional Physician Visit - Office',
    rank: 1,
  },
  [EligibilityServiceTypeCodes.MATERNITY]: {
    name: 'Maternity',
    rank: 2,
  },
  [EligibilityServiceTypeCodes.DIAGNOSTIC_MEDICAL]: {
    name: 'Diagnostic Medical',
    rank: 3,
  },
  [EligibilityServiceTypeCodes.FAMILY_PLANNING]: {
    name: 'Family Planning',
    rank: 4,
  },
  [EligibilityServiceTypeCodes.GYNECOLOGICAL]: {
    name: 'Gynecological BT',
    rank: 5,
  },
  [EligibilityServiceTypeCodes.OBSTETRICAL_GYNECOLOGICAL]: {
    name: 'Obstetrical/Gynecological BV',
    rank: 6,
  },
  [EligibilityServiceTypeCodes.ABORTION]: {
    name: 'Abortion',
    rank: 7,
  },
}
