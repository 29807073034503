import { midwivesCollection } from '../collections'
import { FieldTypes } from '../constants/FieldTypes'
import { IdField } from '../types/forms/fields'
import { PracticeWithAdmin } from '../types/providers'

export const practiceField: IdField<PracticeWithAdmin> = {
  type: FieldTypes.ID,
  collection: midwivesCollection,
  placeholder: 'Practice',
}
