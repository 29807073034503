import { FieldTypes, Shortcut, ShortcutMap } from '@hb/shared'

const createListShortcutMap = (
  itemName: string,
  itemShortcutMap: ShortcutMap | Shortcut,
  numItems = 3,
): ShortcutMap => {
  const children: Record<string, Shortcut | ShortcutMap> = {}
  for (let i = 0; i < numItems; i += 1) {
    children[`${i}`] = { ...itemShortcutMap, name: `${itemName} ${i + 1}` }
  }
  return {
    name: `${itemName}s`,
    shortcut: true,
    children,
  }
}

const collaborativePhysicianShortcutMap: ShortcutMap = {
  name: 'Collaborative Physician',
  shortcut: true,
  children: {
    name: {
      name: 'Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    npi: {
      name: 'NPI',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

const affiliatedHospitalShortcutMap: ShortcutMap = {
  name: 'Affiliated Hospital',
  shortcut: true,
  children: {
    name: {
      name: 'Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    npi: {
      name: 'NPI',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    phone: {
      name: 'Phone',
      shortcut: true,
      type: FieldTypes.PHONE,
    },
    address: {
      name: 'Address',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    address2: {
      name: 'Address 2',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

export const midwifeNameItems: ShortcutMap = {
  name: 'Name',
  shortcut: true,
  children: {
    fname: {
      name: 'Referring First Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    lname: {
      name: 'Referring Last Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    name: {
      name: 'Practice Name',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

const midwifeNPIItems: ShortcutMap = {
  name: 'NPI',
  shortcut: true,
  children: {
    referringNpi: {
      name: 'Referring NPI',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    billingNpi: {
      name: 'Billing NPI',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

const billingTaxIdShortcut: Shortcut = {
  name: 'Billing Tax ID',
  shortcut: true,
  type: FieldTypes.TEXT,
}

const midwifeAddressItems: ShortcutMap = {
  name: 'Addresses',
  shortcut: true,
  children: {
    claimsAddress: {
      name: 'Claims Address',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    corporateAddress: {
      name: 'Corporate Address',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    servicingAddress: {
      name: 'Servicing Address 1',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
  },
}

const collaborativePhysiciansShortcutMap = createListShortcutMap(
  'Collaborative Physician',
  collaborativePhysicianShortcutMap,
)

const affiliatedHospitalsShortcutMap = createListShortcutMap(
  'Affiliated Hospital',
  affiliatedHospitalShortcutMap,
)

const acceptedPaymentMethodsShortcut: Shortcut = {
  name: 'Accepted Payment Methods',
  shortcut: true,
  type: FieldTypes.TEXTAREA,
}

const licensesShortcutMap: ShortcutMap = createListShortcutMap('License', {
  name: 'License',
  shortcut: true,
  type: FieldTypes.TEXT,
})

const practiceLogoShortcut: Shortcut = {
  name: 'Logo',
  shortcut: true,
  type: FieldTypes.FILE,
}

const medicaidShortcutMap: ShortcutMap = {
  name: 'Medicaid',
  shortcut: true,
  children: {
    referringMedicaidIds: createListShortcutMap('Referring Medicaid ID', {
      name: 'Referring Medicaid ID',
      shortcut: true,
      type: FieldTypes.TEXT,
    }),
    groupMedicaidIds: createListShortcutMap('Group Medicaid ID', {
      name: 'Group Medicaid ID',
      shortcut: true,
      type: FieldTypes.TEXT,
    }),
  },
}

export const midwifeItems: ShortcutMap = {
  name: 'Midwife',
  shortcut: true,
  children: {
    name: midwifeNameItems,
    logo: practiceLogoShortcut,
    npi: midwifeNPIItems,
    billingTaxId: billingTaxIdShortcut,
    addresses: midwifeAddressItems,
    collaborativePhysicians: collaborativePhysiciansShortcutMap,
    affiliatedHospitals: affiliatedHospitalsShortcutMap,
    acceptedPaymentMethods: acceptedPaymentMethodsShortcut,
    licenses: licensesShortcutMap,
    medicaid: medicaidShortcutMap,
  },
}

export const practiceItems: ShortcutMap = {
  name: 'Practice',
  shortcut: true,
  children: {
    name: midwifeNameItems,
    logo: practiceLogoShortcut,
    addresses: midwifeAddressItems,
  },
}
