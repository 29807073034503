import { Box, BoxProps, usePopoverContext } from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DataGridContext, ScreenContext } from '../../contexts'

export const GridItemPreviewContent: React.FC<
  { previewId: string; placement?: 'left' | 'right' } & BoxProps
> = ({ children, previewId, ...props }) => {
  const { preview } = useContext(DataGridContext)
  const { width } = useContext(ScreenContext)

  const [mounting, setMounting] = useState(false)
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null)
  // const [{ x, y }, setPos] = useState({ x: 0, y: 0 })
  const { isOpen } = usePopoverContext()

  const pos = useMemo(() => {
    if ((preview?.position.x ?? 0) > width - 300) {
      return { x: width - 380, y: preview?.position.y ?? 0 + 10 }
    }
    return preview?.position ?? { x: 0, y: 0 }
  }, [width, preview])
  // useEffect(() => {
  //   if (preview) {
  //     setPos(preview.position)
  //   }
  // }, [preview, itemHeight, placement, x, y])
  useEffect(() => {
    if (timer.current) clearTimeout(timer.current)
    if (preview) {
      setMounting(true)
      timer.current = setTimeout(() => {
        setMounting(false)
      }, 100)
    }
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [preview])
  const active = useMemo(
    () => isOpen && !mounting && !!preview?.item.includes(previewId),
    [preview, previewId, isOpen, mounting],
  )
  const transform = useMemo(() => `translate(${pos.x}px, ${pos.y}px)`, [pos])
  return (
    <Box
      border="1px solid #cdcdcd"
      pointerEvents={active ? 'auto' : 'none'}
      borderRadius={4}
      top={0}
      left={0}
      boxShadow="md"
      opacity={active ? 1 : 0}
      zIndex={1}
      transform={transform}
      transition="all 333ms"
      position="fixed"
      w="300px"
      bg="white"
      {...props}>
      {active ? children : null}
    </Box>
  )
}
