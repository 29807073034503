import { forwardRef, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { PopUpMessageContext } from '../../contexts'
import { useCopied } from '../CopyTooltip'

export const buttonProps: Partial<IconButtonProps> = {
  bg: 'blackAlpha.50',
  _hover: { bg: 'blue.200' },
}
export const DisplayButtonBase = forwardRef((props: IconButtonProps, ref) => (
  <IconButton ref={ref} height={[6, 8]} flex={1} {...buttonProps} {...props} />
))

export const DisplayButton = (props: IconButtonProps & { text: string }) => {
  const { text } = props
  const { copied, onClick } = useCopied((props.text ?? '').toString())
  const { showSuccess } = useContext(PopUpMessageContext)
  useEffect(() => {
    if (copied) {
      showSuccess(`Copied ${text?.toString()}`)
    }
  }, [text, copied, showSuccess])
  return (
    <Tooltip placement="top" label={(props.text ?? '').toString()}>
      <DisplayButtonBase onClick={onClick} {...props} />
    </Tooltip>
  )
}
