import React, { useMemo } from 'react'
import { useApp } from '../../contexts'
import { useAuth } from '../../store/auth'
import { SiteSettingsModal } from '../Settings/SiteSettings'
import { ContactFormModal } from './Contact/ContactFormModal'
import { EmailVerificationModal } from './EmailVerificationModal'
import { InvitePatientModal } from './InvitePatientModal'
import { TwoFactorAuthModal } from './MultiFactor/TwoFactorAuthModal'
import { NameEntryModal } from './NameEntryModal'
import { PushNotificationsSettingsModal } from './PushNotificationsSettingsModal'

export const RootModals = () => {
  const claims = useAuth(s => s.claims)
  const admin = useAuth(s => s.admin)
  const { appName } = useApp()
  const canInvite = useMemo(() => admin || appName === 'providers-app', [admin, appName])
  return (
    <>
      <PushNotificationsSettingsModal />
      <NameEntryModal />
      {claims?.email_verified ? null : <EmailVerificationModal />}
      {canInvite ? <InvitePatientModal /> : null}
      <TwoFactorAuthModal />
      <ContactFormModal />
      <SiteSettingsModal />
    </>
  )
}
