import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  AdminAssessmentData,
  Alert,
  Assessment,
  AssessmentSnippet,
  ASSESSMENTS_ADMIN,
  ASSESSMENT_SNIPPETS,
  CheckboxField,
  colors,
  defaultStageValidate,
  Field,
  FieldMapValue,
  FieldTypes,
  followStateMandatesCheckbox,
  getAlertText,
  getAssessmentName,
  getCorrectedValue,
  getCurrentlyOnMedicaidPlan,
  getDateString,
  getNestedUserFields,
  getPracticeAssessmentCollectionPath,
  isField,
  mergeAssessmentData,
  planDesignField,
  PopulatedAssessment,
  PopulatedUser,
  stateMandatesNotesField,
} from '@hb/shared'
import inNetwork, {
  checkboxDeductibleBasedOnCalendarYearField,
} from '@hb/shared/src/fields/callIn/inNetwork'
import { maximumReimbursableChargesField } from '@hb/shared/src/fields/callIn/maximumReimbursableCharges'
import { reimbursementOptionField } from '@hb/shared/src/fields/callIn/reimbursementOption'
import { doc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ASSESSMENTS_REF } from '../../collections/firestoreCollections'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess'
import { usePopulatedAssessment } from '../../hooks'
import { updateAssessmentField } from '../../hooks/backend/assessments'
import { useDocument } from '../../hooks/backend/useDocument'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { ActionLog } from '../ActionLog'
import { SolidActionButton } from '../Buttons'
import { CopyId } from '../CopyId'
import { Editable, EditableRow } from '../forms/Input'
import { PreviewSpan } from '../PreviewSpan'
import { StatusButton as StatusBadge } from '../Status/StatusButton'
import { AssessmentResultsLogItem } from './AssessmentResultsLogItem'

const deductibleCountsCheckbox: CheckboxField = {
  ...(inNetwork.children.deductibleCountsTowardOOPM as CheckboxField),
  type: FieldTypes.CHECKBOX,
}

const noBenefitsCheckbox: CheckboxField = {
  placeholder: 'No out of network benefits',
  type: FieldTypes.CHECKBOX,
}

const AlertsView = ({
  alerts,
  miscarried,
}: {
  alerts?: Record<number, string | Alert>
  miscarried?: boolean
}) => (
  <VStack my={2} border="1px solid #990000" borderRadius={3} spacing={0} align="flex-start">
    <Text pl={2} bg="red.600" color="white" width="100%" fontSize="sm" mb={1} fontWeight="bold">
      ALERTS
    </Text>
    <HStack
      spacing={1}
      divider={<StackDivider />}
      align="flex-start"
      flexFlow="row wrap"
      px={1}
      pb={1}>
      {miscarried ? (
        <Text bg="red.500" color="white" fontSize="sm" px={2} borderRadius={4} fontWeight="bold">
          MISCARRIED
        </Text>
      ) : null}
      {Object.entries(alerts ?? {}).map(([date, alert]) => (
        <Flex key={date} px={1} align="center">
          <Text mr={2} color="gray.700" fontSize="sm" fontWeight={600}>
            {getDateString(parseInt(date, 10), 'short')}
          </Text>
          <Text>{getAlertText(alert)}</Text>
        </Flex>
      ))}
    </HStack>
  </VStack>
)

const HoverSummary = ({
  assessment,
  patient,
  assessmentId,
}: {
  assessment: Assessment
  patient: PopulatedUser
  assessmentId: string
}) => {
  const coverage = patient?.insurancePlans?.primary

  const mergedData = useMemo(() => mergeAssessmentData(assessment), [assessment])
  const coveragePath = useMemo(() => {
    const isMedicaid = getCurrentlyOnMedicaidPlan(mergedData)
    return isMedicaid ? 'insurance-info.medicaidCoverage' : 'insurance-info.primaryCoverage'
  }, [mergedData])

  const noBenefits = useMemo(
    () => getCorrectedValue(assessment, `${coveragePath}.out-of-network.noBenefits`),
    [assessment, coveragePath],
  )

  const { processResponse } = useContext(PopUpMessageContext)
  const handleUpdate = useCallback(
    (path: string, updated: FieldMapValue) =>
      updateAssessmentField(assessmentId, path, updated)
        .then(() => processResponse({ success: 'Answers updated!' }))
        .catch(err => processResponse({ error: err.message })),
    [assessmentId, processResponse],
  )

  const [editingSide, setEditingSide] = useState<'right' | 'left' | null>(null)
  const onLeftOpen = useCallback(() => {
    setEditingSide('left')
  }, [])

  const onRightOpen = useCallback(() => {
    setEditingSide('right')
  }, [])

  const onEditClose = useCallback(() => {
    setEditingSide(null)
  }, [])

  const { leftWidth, rightWidth } = useMemo(() => {
    if (editingSide === 'left') {
      return { leftWidth: '600px', rightWidth: '0px' }
    }
    if (editingSide === 'right') {
      return { leftWidth: '0px', rightWidth: '600px' }
    }
    return { leftWidth: '300px', rightWidth: '300px' }
  }, [editingSide])
  return (
    <VStack overflow="hidden" borderRadius={6} maxW="600px" spacing={0} divider={<Divider />}>
      <HStack divider={<StackDivider />} align="flex-start" spacing={0}>
        <VStack
          display={editingSide === 'right' ? 'none' : 'flex'}
          w={leftWidth}
          fontWeight={400}
          spacing={0}
          align="flex-start">
          <HStack w="100%" borderBottom="1px solid #cdcdcd" bg={colors.green.hex}>
            <Text pl={2} color="white" fontWeight={600}>
              In Network
            </Text>
          </HStack>
          <EditableRow
            small
            index={0}
            adminView
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={v => handleUpdate(`${coveragePath}.in-network.deductible`, v)}
            field={inNetwork.children.deductible as Field}
            label="Deductible"
            value={coverage?.['in-network']?.deductible}
            correction={coverage?.['in-network']?.deductible}
          />
          <EditableRow
            small
            index={1}
            adminView
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={v => handleUpdate(`${coveragePath}.in-network.coinsurance`, v)}
            field={inNetwork.children.coinsurance as Field}
            label="Coinsurance"
            value={coverage?.['in-network']?.coinsurance}
            correction={coverage?.['in-network']?.coinsurance}
          />
          <EditableRow
            small
            index={2}
            adminView
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            onSubmit={v => handleUpdate(`${coveragePath}.in-network.outOfPocketMax`, v)}
            field={inNetwork.children.outOfPocketMax as Field}
            label="Out of pocket max"
            value={coverage?.['in-network']?.outOfPocketMax}
            correction={coverage?.['in-network']?.outOfPocketMax}
          />
          <Editable
            index={3}
            openCallback={onLeftOpen}
            adminView
            closeCallback={onEditClose}
            field={deductibleCountsCheckbox}
            value={coverage?.['in-network']?.deductibleCountsTowardOOPM}
            correction={coverage?.['in-network']?.deductibleCountsTowardOOPM}
            onSubmit={v => handleUpdate(`${coveragePath}.in-network.deductibleCountsTowardOOPM`, v)}
          />
          <EditableRow
            index={4}
            label="Maximum reimbursable charges"
            adminView
            openCallback={onLeftOpen}
            closeCallback={onEditClose}
            field={maximumReimbursableChargesField.children.amount as Field}
            value={coverage?.['out-of-network']?.maximumReimbursableCharges?.amount}
            correction={coverage?.['out-of-network']?.maximumReimbursableCharges?.amount}
            onSubmit={v =>
              handleUpdate(`${coveragePath}.out-of-network.maximumReimbursableCharges.amount`, v)
            }
          />
        </VStack>
        <VStack
          display={editingSide === 'left' ? 'none' : 'flex'}
          w={rightWidth}
          fontWeight={400}
          spacing={0}
          align="flex-start">
          <HStack w="100%" borderBottom="1px solid #cdcdcd" bg={colors.green.hex}>
            <Text pl={2} color="white" fontWeight={600}>
              Out of Network
            </Text>
          </HStack>
          <Editable
            index={0}
            openCallback={onRightOpen}
            closeCallback={onEditClose}
            adminView
            field={noBenefitsCheckbox}
            value={coverage?.['out-of-network']?.noBenefits}
            correction={coverage?.['out-of-network']?.noBenefits}
            style={{ width: '100%' }}
            onSubmit={v => handleUpdate(`${coveragePath}.out-of-network.noBenefits`, v)}
          />
          {noBenefits ? null : (
            <>
              <EditableRow
                small
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                adminView
                index={1}
                onSubmit={v => handleUpdate(`${coveragePath}.out-of-network.deductible`, v)}
                field={inNetwork.children.deductible as Field}
                label="Deductible"
                value={coverage?.['out-of-network']?.deductible}
              />
              <EditableRow
                small
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                adminView
                index={2}
                onSubmit={v => handleUpdate(`${coveragePath}.out-of-network.coinsurance`, v)}
                field={inNetwork.children.coinsurance as Field}
                label="Coinsurance"
                value={coverage?.['out-of-network']?.coinsurance}
              />
              <EditableRow
                small
                index={3}
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                adminView
                onSubmit={v => handleUpdate(`${coveragePath}.out-of-network.outOfPocketMax`, v)}
                field={inNetwork.children.outOfPocketMax as Field}
                label="Out of pocket max"
                value={coverage?.['out-of-network']?.outOfPocketMax}
                correction={coverage?.['out-of-network']?.outOfPocketMax}
              />
              <Editable
                index={4}
                openCallback={onRightOpen}
                closeCallback={onEditClose}
                adminView
                field={deductibleCountsCheckbox}
                value={coverage?.['out-of-network']?.deductibleCountsTowardOOPM}
                onSubmit={v =>
                  handleUpdate(`${coveragePath}.out-of-network.deductibleCountsTowardOOPM`, v)
                }
              />
            </>
          )}

          <Editable
            index={5}
            openCallback={onRightOpen}
            adminView
            closeCallback={onEditClose}
            field={checkboxDeductibleBasedOnCalendarYearField}
            value={coverage?.['in-network']?.deductibleBasedOnCalendarYear}
            onSubmit={v =>
              handleUpdate(`${coveragePath}.in-network.deductibleBasedOnCalendarYear`, v)
            }
          />
        </VStack>
      </HStack>
      <VStack w="100%" fontWeight={400} spacing={0} align="flex-start">
        <HStack w="100%" borderBottom="1px solid #cdcdcd" bg={colors.green.hex}>
          <Text color="white" pl={2} fontWeight={600}>
            Plan Design
          </Text>
        </HStack>
        <EditableRow
          small
          index={6}
          adminView
          label="Plan Design"
          field={planDesignField}
          value={coverage?.['plan-design-and-state-mandates']?.planDesign}
          onSubmit={v =>
            handleUpdate(`${coveragePath}.plan-design-and-state-mandates.planDesign`, v)
          }
        />
        <Editable
          small
          index={7}
          adminView
          field={followStateMandatesCheckbox}
          value={coverage?.['plan-design-and-state-mandates']?.followsStateMandates}
          onSubmit={v =>
            handleUpdate(`${coveragePath}.plan-design-and-state-mandates.followsStateMandates`, v)
          }
        />
        <EditableRow
          label="State mandates notes"
          small
          adminView
          index={8}
          field={stateMandatesNotesField}
          value={coverage?.['plan-design-and-state-mandates']?.followsStateMandatesNote}
          onSubmit={v =>
            handleUpdate(
              `${coveragePath}.plan-design-and-state-mandates.followsStateMandatesNote`,
              v,
            )
          }
        />
        <EditableRow
          label="Reimbursement option"
          small
          index={9}
          adminView
          field={reimbursementOptionField}
          value={coverage?.['out-of-network']?.reimbursementOption?.optionText}
          onSubmit={v =>
            handleUpdate(`${coveragePath}.out-of-network.reimbursementOption.optionText`, v)
          }
        />
      </VStack>
    </VStack>
  )
}

const AssessmentActions = ({
  assessment,
  adminData,
}: {
  assessment: Assessment | null
  adminData: AdminAssessmentData | null
}) => {
  const {
    submittedOn,
    sentOn,
    submittedBy,
    answersUpdatedOn,
    answersUpdatedBy,
    correctionsUpdatedBy,
    correctionsUpdatedOn,
    answersUpdatedByGroup,
    submittedByGroup,
    patientId,
    resultsViewedOn,
    previousResults,
    resultsInsurerId,
    sentBy,
  } = assessment ?? {}
  const { draftSavedBy, draftSavedOn } = adminData ?? {}
  return (
    <VStack spacing={1} align="flex-start">
      {resultsViewedOn ? (
        <ActionLog action="Assessment viewed" group="patient" by={patientId} on={resultsViewedOn} />
      ) : null}
      {sentOn ? (
        <AssessmentResultsLogItem
          insurerId={resultsInsurerId}
          sentOn={sentOn}
          group="admin"
          sentBy={sentBy}
        />
      ) : null}
      {draftSavedBy || draftSavedOn ? (
        <ActionLog action="Draft saved" group="admin" on={draftSavedOn} by={draftSavedBy} />
      ) : null}
      {previousResults?.length
        ? previousResults.map(res => (
            <AssessmentResultsLogItem
              key={`${res.sentOn}`}
              insurerId={res.insuranceProviderId}
              group="admin"
              sentOn={res.sentOn}
              sentBy={res.sentBy}
            />
          ))
        : null}
      {correctionsUpdatedBy || correctionsUpdatedOn ? (
        <ActionLog
          action="Corrections updated"
          // group={correctionsUpdatedByGroup ?? 'admin'}
          group="admin"
          on={correctionsUpdatedOn}
          by={correctionsUpdatedBy}
        />
      ) : null}
      {answersUpdatedBy || answersUpdatedOn ? (
        <ActionLog
          group={answersUpdatedByGroup}
          action="Answers updated"
          on={answersUpdatedOn}
          by={answersUpdatedBy}
        />
      ) : null}
      {submittedBy || submittedOn ? (
        <ActionLog
          action="Answers submitted"
          group={submittedByGroup ?? (submittedBy === patientId ? 'patient' : 'admin')}
          on={submittedOn}
          by={submittedBy}
        />
      ) : null}
    </VStack>
  )
}

const AssessmentMissingFields = ({
  user,
  assessment,
  mergedData,
}: {
  user: PopulatedUser | null
  assessment: PopulatedAssessment | null
  mergedData: FieldMapValue
}) => {
  const nestedUserFields = useMemo(
    () => getNestedUserFields(true, user, assessment ?? null),
    [assessment, user],
  )
  const missingFields = useMemo(() => {
    const missing = defaultStageValidate(nestedUserFields, true)(mergedData)
    if (missing) {
      const missingNames = Object.keys(missing).map(key => {
        const child = nestedUserFields.children[key]
        return isField(child) ? child.placeholder : child.name
      })
      return missingNames
    }
    return []
  }, [mergedData, nestedUserFields])
  return (
    <Box>
      <PreviewSpan
        style={{
          width: '100%',
          fontWeight: 600,
          color: colors.red.hex,
        }}>
        Questionnaire Incomplete:
      </PreviewSpan>
      {missingFields ? (
        <Box>
          {missingFields.map(fieldName => (
            <Text key={fieldName} style={{ color: colors.red.hex }}>
              {fieldName}
            </Text>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

export const AssessmentPreview: React.FC<{
  user: PopulatedUser
  id: string
  assessment: Assessment | null
  admin?: boolean
  selected?: boolean
  preview?: boolean
  onSelect: () => void
  buttonText?: string
}> = ({ assessment, onSelect, id, buttonText = 'Open', selected, preview, user }) => {
  const assessmentRef = useMemo(() => doc(ASSESSMENTS_REF, id), [id])

  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const update = useUpdateDoc('pregnancy')
  const { data: adminData } = useDocument<AdminAssessmentData>(
    ASSESSMENTS_ADMIN,
    appName === 'providers-app' ? null : id,
  )
  const { data: assessmentSnippet } = useDocument<AssessmentSnippet>(
    appName === 'providers-app'
      ? getPracticeAssessmentCollectionPath(selectedPracticeId ?? '')
      : ASSESSMENT_SNIPPETS,
    id,
  )
  const populatedAssessment = usePopulatedAssessment(assessment, user, assessmentSnippet, adminData)
  const { archivedOn } = assessment ?? {}
  const { alerts, miscarried } = adminData ?? {}

  const hasAlerts = useMemo(() => alerts && !!Object.keys(alerts).length, [alerts])

  const mergedData = useMemo(() => mergeAssessmentData(assessment ?? undefined), [assessment])

  const edd = useMemo(() => mergedData?.delivery?.edd, [mergedData])

  const { status, deliveredOn } = assessmentSnippet ?? {}

  const deliveredOnText = useMemo(() => {
    if (!deliveredOn) return 'No'
    if (deliveredOn === Infinity) return 'Transfer'
    return getDateString(deliveredOn, 'short')
  }, [deliveredOn])
  return (
    <Flex
      flexFlow="column"
      align="flex-start"
      position="relative"
      w="100%"
      borderRadius={3}
      px={preview ? 2 : 3}
      py={preview ? 1 : 2}
      border="1px solid"
      bg="white"
      borderColor={selected ? '#cdcdcd' : 'transparent'}
      boxShadow={selected ? 'inset 0 0 6px rgb(255, 180, 180)' : '1px 1px 4px #00000066'}>
      <HStack px={2} w="100%" mb={0} fontWeight={600} justify="space-between">
        <Editable
          onSubmit={v => update(assessmentRef, 'name', v)}
          value={assessment ? getAssessmentName(assessment) : 'Loading...'}
          field={{ type: FieldTypes.TEXT, placeholder: 'Assessment Name' }}
          style={{
            flex: 1,
            minWidth: 0,
          }}
          dataCellProps={{
            fontSize: '16px',
            fontFamily: 'hero-new',
            minWidth: 0,
            textOverflow: 'ellipsis',
            maxW: '100%',
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flex: 1,
            fontWeight: 500,
          }}
        />
        {archivedOn ? (
          <Badge colorScheme="red" fontSize="xs" fontWeight="bold">
            ARCHIVED {getDateString(archivedOn, 'short')}{' '}
          </Badge>
        ) : null}
        {appName === 'app' ? (
          <Popover
            strategy="fixed"
            placement="bottom-end"
            isLazy
            closeOnBlur={false}
            trigger="hover">
            <PopoverTrigger>
              <SolidActionButton
                size={preview ? 'xs' : 'sm'}
                variant="solid"
                onClick={onSelect}
                ml="auto">
                {buttonText}
              </SolidActionButton>
            </PopoverTrigger>
            <PopoverContent boxShadow="1px 1px 6px #00000077" width="auto">
              <PopoverArrow />
              {assessment ? (
                <HoverSummary assessmentId={id} assessment={assessment} patient={user} />
              ) : (
                <HStack>
                  <CircularProgress isIndeterminate size={5} />
                  <Text>Loading...</Text>
                </HStack>
              )}
            </PopoverContent>
          </Popover>
        ) : (
          <SolidActionButton size="sm" variant="solid" onClick={onSelect} ml="auto">
            {buttonText}
          </SolidActionButton>
        )}
      </HStack>
      {hasAlerts || miscarried ? <AlertsView miscarried={miscarried} alerts={alerts} /> : null}
      <Box pb={2} w="100%">
        <StatusBadge type="assessment" status={status ?? 'awaiting-questionnaire'} />
        <Box px={2} pb={2}>
          <HStack align="center">
            <Text fontWeight="bold" color="gray.500" fontSize="sm">
              EDD
            </Text>
            <Text lineHeight={1}>{edd ? getDateString(edd, 'short') : 'None'}</Text>
          </HStack>
          <HStack align="center">
            <Text fontWeight="bold" color="gray.500" fontSize="sm">
              DELIVERED
            </Text>
            <Text lineHeight={1}>{deliveredOnText}</Text>
          </HStack>
          {preview ? null : (
            <>
              {status !== 'incomplete' ? (
                <AssessmentActions assessment={assessment} adminData={adminData} />
              ) : (
                <AssessmentMissingFields
                  user={user}
                  assessment={populatedAssessment}
                  mergedData={mergedData}
                />
              )}
            </>
          )}
        </Box>
        {/* {appName === 'app' && !preview ? (
          <Box w="100%">
            <CollapsibleInsurancePlansView />
          </Box>
        ) : null} */}
        {preview ? null : <CopyId item={{ id }} />}
      </Box>
    </Flex>
  )
}

export const BasicPregnancyPreview = ({
  pregnancy,
  onSelect,
}: {
  pregnancy: Assessment
  onSelect: () => void
}) => {
  const { archivedOn } = pregnancy
  const edd = useMemo(
    () => pregnancy?.corrections?.delivery?.edd ?? pregnancy?.data?.delivery?.edd,
    [pregnancy],
  )

  return (
    <Flex
      flexFlow="column"
      align="flex-start"
      position="relative"
      w="100%"
      borderRadius={3}
      bg="white"
      boxShadow="1px 1px 4px #00000066">
      <HStack
        align="flex-start"
        px={3}
        pt={1.5}
        w="100%"
        mb={0}
        fontWeight={600}
        justify="space-between">
        <Text position="relative" bottom="2px">
          {getAssessmentName(pregnancy)}{' '}
        </Text>
        {archivedOn ? (
          <Badge colorScheme="red" fontSize="xs" fontWeight="bold">
            ARCHIVED {getDateString(archivedOn, 'short')}{' '}
          </Badge>
        ) : null}
        <SolidActionButton size="xs" onClick={onSelect} ml="auto">
          View
        </SolidActionButton>
      </HStack>
      <Box px={2} w="100%">
        <Box px={2} pb={2}>
          <HStack align="center">
            <Text fontWeight="bold" color="gray.500" fontSize="sm">
              EDD
            </Text>
            <Text lineHeight={1}>{edd ? getDateString(edd, 'short') : 'None'}</Text>
          </HStack>
        </Box>
      </Box>
    </Flex>
  )
}
