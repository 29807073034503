import {
  AdminAssessmentData,
  AdminUserData,
  arrayToObject,
  getFullName,
  getLogInfo,
  getReverseName,
  LogInfo,
  MIDWIVES,
  PopulatedUser,
  PopulatedUserData,
  populateInsurancePlans,
  PracticeUserData,
  providersCollection,
  User,
  USER_INVITES,
  USER_ROLES,
  UserInvite,
  USERS,
  USERS_ADMIN,
} from '@hb/shared'
import 'firebase/firestore'
import { useMemo } from 'react'
import { useCollection } from '../../../collections'
import { useApp } from '../../../contexts/AppContext'
import { useAuth } from '../../../store/auth'
import { useDocument } from '../useDocument'
import { usePatientClaims } from './usePatientClaims'

export const useLogInfo = (log?: AdminAssessmentData['log']) =>
  useMemo<LogInfo>(() => getLogInfo(log), [log])
export function usePopulatedUser(
  id?: string | null,
  disabled?: boolean,
  practiceId?: string,
): PopulatedUserData {
  const { authUser, loading: authLoading, admin } = useAuth()
  const { appName } = useApp()
  const { items: insurers } = useCollection(providersCollection)
  const userId = useMemo(() => (authUser ? id : null), [authUser, id])
  const { data: usersItem, loading: uLoading, ref: usersItemRef } = useDocument<User>(USERS, userId)
  const {
    data: adminItem,
    loading: aLoading,
    ref: adminPatientRef,
  } = useDocument<AdminUserData>(USERS_ADMIN, appName === 'providers-app' || !admin ? null : userId)
  const {
    data: inviteItem,
    loading: iLoading,
    ref: inviteRef,
  } = useDocument<UserInvite>(USER_INVITES, userId)

  const {
    data: practiceItem,
    loading: practiceItemLoading,
    ref: practicePatientRef,
  } = useDocument<PracticeUserData>(
    `${MIDWIVES}/${practiceId}/${USER_ROLES}`,
    practiceId ? userId : null,
  )

  const loading = useMemo(() => {
    if (authLoading || aLoading || uLoading || iLoading || practiceItemLoading) {
      return true
    }
    return false
  }, [authLoading, aLoading, uLoading, iLoading, practiceItemLoading])
  const {
    item,
    ref: patientRef,
    isInvite,
  } = useMemo(() => {
    if (uLoading || disabled) return { item: null, ref: null, isInvite: false }
    if (usersItem) {
      return { item: usersItem, ref: usersItemRef, isInvite: false }
    }
    return { item: inviteItem, ref: inviteRef, isInvite: true }
  }, [usersItem, inviteItem, disabled, inviteRef, uLoading, usersItemRef])
  const { data: claims } = usePatientClaims(id)
  const user = useMemo<PopulatedUser | null>(() => {
    if (!id || !item) return null
    const insurancePlans = populateInsurancePlans(
      item.insurancePlans ?? {},
      arrayToObject(insurers) ?? {},
    )
    return {
      ...item,
      ...adminItem,
      patientNote: appName === 'providers-app' ? practiceItem?.patientNote : adminItem?.patientNote,
      adminAlerts: adminItem?.alerts,
      practiceAlerts: practiceItem?.alerts,
      isInvite,
      insurancePlans,
      insuranceProvider: insurancePlans?.primary?.insuranceProvider ?? null,
      name: getFullName(item),
      reverseName: getReverseName(item),
      claims: claims ?? undefined,
      id,
    }
  }, [item, adminItem, claims, id, isInvite, practiceItem, appName, insurers])
  return useMemo(
    () => ({
      user,
      loading,
      isInvite,
      patientRef,
      practicePatientRef,
      adminPatientRef,
    }),
    [user, loading, patientRef, adminPatientRef, practicePatientRef, isInvite],
  )
}
