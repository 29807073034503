import { AppName } from '@hb/shared'

// const claimPaths = [
//   '/claims',
//   '/claims/:claimId',
//   '/claims/:claimId/:displayedItem',
//   '/claims/:claimId/:displayedItem/:subItem',
// ]

// export const pregnancyPaths = [
//   '/pregnancies',
//   '/pregnancies/:pregnancyId',
//   '/pregnancies/:pregnancyId/:displayedItem',
//   '/pregnancies/:pregnancyId/:displayedItem/:subItem',
// ]

const patientPaths = [
  '/:baseItemType',
  '/:baseItemType/:baseItemId',
  '/:baseItemType/:baseItemId/:selectedItemType',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId/:tabName',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId/:tabName/:subTabName',
  // '/:baseItemType/:baseItemId/:displayedItem/:subItem',
  // ...pregnancyPaths.map(path => `/patients/:patientId${path}`),
]

export const getProfilePaths = (basePath: string) => {
  const subPaths = patientPaths.map(path => `${basePath}${path}`)
  return basePath ? [basePath, ...subPaths] : subPaths
}

export const getAdminDashboardPaths = (appName: AppName) =>
  getProfilePaths(appName === 'app' ? '/admin' : '')
