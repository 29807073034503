import { Box, CircularProgress, HStack, Text } from '@chakra-ui/react'
import {
  Assessment,
  ASSESSMENTS,
  colors,
  DropdownField,
  FieldTypes,
  getAssessmentName,
  getPracticeAssessmentCollectionPath,
  UpdateCallback,
} from '@hb/shared'

import { collection, Query, query, where } from 'firebase/firestore'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { db } from '../../backend/db'
import { AppContext } from '../../contexts/AppContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { useQuery } from '../../hooks/backend/useQuery'
import { StandaloneInput } from '../forms/Input'
import { InputRef } from '../forms/Input/types'

export const SelectPatientAssessment = ({
  patientId,
  onSelect,
  openOnMount,
}: {
  patientId?: string | null
  openOnMount?: boolean
  onSelect: (assessmentId: string, assessment: Assessment) => Promise<UpdateCallback>
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { appName } = useContext(AppContext)
  const { selectedPracticeId } = usePracticeAccess()
  const queryRef = useMemo(() => {
    if (!patientId) return null
    if (appName === 'app') {
      return query(
        collection(db, ASSESSMENTS),
        where('patientId', '==', patientId),
      ) as Query<Assessment>
    }
    if (!selectedPracticeId) return null
    return query(
      collection(db, getPracticeAssessmentCollectionPath(selectedPracticeId)),
      where('patientId', '==', patientId),
    ) as Query<Assessment>
  }, [patientId, appName, selectedPracticeId])
  const { data: assessments, loading: assessmentsLoading } = useQuery<Assessment>(queryRef)
  const field = useMemo<DropdownField>(
    () => ({
      type: FieldTypes.DROPDOWN,
      placeholder: 'Select a pregnancy',
      options: Object.entries(assessments ?? {}).map(([id, assessment]) => ({
        text: getAssessmentName(assessment),
        id,
      })),
    }),
    [assessments],
  )

  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (openOnMount) {
      inputRef.current?.focus()
    }
  }, [openOnMount])

  const onSubmit = useCallback(
    async (id: string, assessment: Assessment) => {
      setSubmitting(true)
      try {
        await onSelect(id, assessment)
      } catch (e: any) {
        console.error(e)
      } finally {
        setSubmitting(false)
      }
    },
    [onSelect],
  )

  let body = null
  if (submitting) {
    body = (
      <HStack p={2} w="100%">
        <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
        <Text>Submitting changes...</Text>
      </HStack>
    )
  } else if (assessmentsLoading) {
    body = (
      <HStack p={2} w="100%">
        <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
        <Text>Getting pregnancies...</Text>
      </HStack>
    )
  } else {
    body = (
      <StandaloneInput
        field={field}
        theme="detailed"
        ref={inputRef}
        onChange={id => {
          const assessment = assessments?.[id]
          if (assessment) onSubmit(id, assessment)
        }}
        // value={}
      />
    )
  }
  return <Box>{body}</Box>
}
