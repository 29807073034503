import { DragHandleIcon, EditIcon } from '@chakra-ui/icons'
import { Box, CircularProgress, Flex, IconButton, Spinner, Text } from '@chakra-ui/react'
import {
  AnyObject,
  Collection,
  CollectionItem,
  FieldMap,
  FieldMapValue,
  getCollectionId,
  LocalCollectionState,
  makePlural,
  UpdateCallback,
} from '@hb/shared'

import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import React, { createContext, useMemo, useState } from 'react'
import { reorderCollection, useCollection } from '../../collections'
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext'
import { useCollectionItem } from '../../hooks/backend/useCollectionItem'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import loadingIcon from '../../icons/loading.svg'
import { AddItem } from '../AddItem'
import { DeleteButton } from '../Buttons/DeleteButton'
import { DataView } from '../DataView'
import { EditedProps } from '../EditHeader'
import { ItemEdit } from '../ItemEdit'
import { DefaultModal } from '../Modals/DefaultModal'

export interface AddItemProps {
  loading: boolean
  itemName: string
  onAdd: (data: AnyObject) => Promise<UpdateCallback>
}

export type BaseOnAddItem = (data: FieldMapValue) => Promise<UpdateCallback>
export type OnAddItem = (data: FieldMapValue, fallback: BaseOnAddItem) => Promise<UpdateCallback>

export interface EditCollectionProps<T extends AnyObject> {
  width: number
  data: LocalCollectionState<T>
  name: string
  collection: Collection<T>
  itemPlaceholder?: string
  itemName: string
  ItemComponent?: React.FC<EditedProps<T>>
  onOpenItem?: (id: string) => void
  RenderAddItem?: React.FC<AddItemProps>
  onAdd?: OnAddItem
  alwaysExpanded?: boolean
  initExpanded?: boolean
}

interface NewEditCollectionProps<T extends AnyObject> {
  name: string
  collection: Collection<T>
  renderPreview?: (data: T) => React.ReactNode
  itemField: FieldMap
  itemName?: string
}

const CollectionContext = createContext<{
  itemField?: FieldMap
  state?: LocalCollectionState<any>
}>({
  itemField: undefined,
  state: undefined,
})

export const CollectionItemView = <T extends AnyObject>({
  id,
  item,
  renderPreview,
  onDelete,
  itemName,
  onEditClick,
  index,
  canReorder,
}: {
  id: string
  item: CollectionItem<T>
  index: number
  itemName: string
  onDelete?: () => Promise<UpdateCallback>
  renderPreview?: (data: T, open?: () => void) => React.ReactNode
  onEditClick?: () => void
  canReorder?: boolean
}) => (
  <Draggable isDragDisabled={!canReorder} draggableId={id} key={id} index={index}>
    {(provided, snapshot) => (
      <Flex
        ref={provided.innerRef}
        {...provided.draggableProps}
        borderBottom="1px solid #cdcdcd"
        align="center"
        px={3}
        py={1}
        // px={5}
        width="100%"
        // bg={snapshot.isDragging ? 'white' : getRowBackground(index)}
        shadow={snapshot.isDragging ? 'md' : undefined}>
        {canReorder ? (
          <Flex ml={1} mr={3} {...provided.dragHandleProps}>
            <DragHandleIcon
              cursor="pointer"
              width={3}
              height={3}
              opacity={0.6}
              _hover={{ opacity: 0.8 }}
            />
          </Flex>
        ) : null}
        <Flex maxW="80%" flex={1} align="center">
          {renderPreview ? renderPreview(item, onEditClick) : (item?.name ?? '')}
        </Flex>
        <Flex align="center" ml="auto">
          <IconButton
            mr={2}
            onClick={onEditClick}
            aria-label="edit"
            size="sm"
            variant="ghost"
            icon={<EditIcon />}
          />
          {onDelete ? <DeleteButton itemName={itemName} onDelete={onDelete} /> : null}
        </Flex>
      </Flex>
    )}
  </Draggable>
)

export const CollectionList = <T extends AnyObject>({
  reorder,
  items,
  itemName = 'Item',
  renderPreview,
  onEditClick,
  onDeleteItem,
  collection,
}: {
  reorder?: ((result: DropResult) => Promise<any>) | ((result: DropResult) => void)
  items?: CollectionItem<T>[] | null
  onEditClick?: (idStr: string) => void
  onDeleteItem?: (item: T) => Promise<UpdateCallback>
  collection: Collection<T>
  renderPreview?: (item: T, open?: () => void) => React.ReactNode
  itemName?: string
}) => {
  const collectionId = useMemo(() => getCollectionId(collection), [collection])
  let body = <img src={loadingIcon} style={{ height: 25, margin: 5 }} />
  if (items) {
    if (items.length) {
      body = (
        <Droppable droppableId={`droppable_${collectionId}`}>
          {provided => (
            <Box ref={provided.innerRef} bg="white" {...provided.droppableProps}>
              {items.map((item, index: number) => (
                <CollectionItemView
                  onEditClick={onEditClick ? () => onEditClick(item.id) : undefined}
                  onDelete={onDeleteItem ? () => onDeleteItem(item) : undefined}
                  item={item}
                  itemName={itemName}
                  renderPreview={renderPreview}
                  index={index}
                  id={item.id}
                  key={item.id}
                  canReorder={!!reorder}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      )
    } else {
      body = (
        <Text
          style={{
            padding: '10px',
            fontStyle: 'italic',
            fontSize: '14px',
          }}>
          {`No ${itemName ? `${itemName.toLowerCase()}s` : 'items'} have been created`}
        </Text>
      )
    }
  }
  return <DragDropContext onDragEnd={reorder ?? (() => {})}>{body}</DragDropContext>
}

const theme: ThemeContextData = { theme: 'basic' }

export const NewEditCollection = <T extends AnyObject>({
  collection,
  name,
  itemField,
  renderPreview,
  itemName = 'item',
}: NewEditCollectionProps<T>) => {
  const collectionId = useMemo(() => getCollectionId(collection), [collection])
  const collectionState = useCollection(collection)
  const { items, loading } = collectionState ?? {}
  const { addItem, updateItem } = useUpdateCollection(collection)

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>()

  const { item } = useCollectionItem(collection, selectedItemId)

  const contextData = useMemo(
    () => ({ state: collectionState, itemField }),
    [itemField, collectionState],
  )

  return (
    <CollectionContext.Provider value={contextData}>
      <ThemeContext.Provider value={theme}>
        <Box p={2}>
          <Text py={1} px={3} fontWeight={600} fontSize="lg">
            {name}
          </Text>
          {collection ? (
            <>
              <CollectionList<T>
                items={items}
                renderPreview={renderPreview}
                itemName={itemName}
                collection={collection}
                onEditClick={() => setSelectedItemId(collectionId)}
                reorder={result => reorderCollection(collection, result)}
              />
              <AddItem
                loading={loading}
                itemName={itemName}
                onAdd={n =>
                  addItem({ name: n })
                    .then(() => ({ success: `Created ${itemName}` }))
                    .catch((err: any) => ({
                      error: err.message ?? `Error creating ${itemName}`,
                    }))
                }
              />
            </>
          ) : (
            <Flex>
              <Spinner mr={4} size="sm" />
              <Text>Loading...</Text>
            </Flex>
          )}
        </Box>
        <DefaultModal
          isOpen={!!selectedItemId}
          onClose={() => setSelectedItemId(undefined)}
          overlayHeader
          render={() => (
            <Box p={6}>
              <DataView
                onSubmit={
                  selectedItemId
                    ? data => updateItem(selectedItemId, '', data)
                    : async () => ({ error: 'No selected item' })
                }
                data={item ?? {}}
                field={itemField}
              />
            </Box>
          )}
        />
      </ThemeContext.Provider>
    </CollectionContext.Provider>
  )
}

const EditCollectionBody = <T extends AnyObject>({
  data,
  ItemComponent,
  width,
  itemPlaceholder = 'Item name',
  itemName,
  alwaysExpanded,
  isLoading,
  initExpanded,
  collection,
  onOpenItem,
}: EditCollectionProps<T> & { isLoading: boolean }) => {
  const { items } = data
  let body = <img src={loadingIcon} style={{ height: 25, margin: 5 }} />
  if (items) {
    if (items.length) {
      body = (
        <Droppable droppableId={`droppable_${itemName}`}>
          {provided => (
            <Box
              ref={provided.innerRef}
              borderBottom="1px solid #cdcdcd"
              style={{
                flex: 1,
                width: '100%',
                overflowY: 'auto',
              }}>
              {items.length || !isLoading ? (
                items.map((item, index: number) => (
                  <Draggable draggableId={item.id} key={item.id} index={index}>
                    {(p, snapshot) => (
                      <ItemEdit<T>
                        onOpen={onOpenItem ? () => onOpenItem(item.id) : undefined}
                        placeholder={itemPlaceholder}
                        last={index === items.length - 1}
                        initExpanded={initExpanded}
                        alwaysExpanded={alwaysExpanded}
                        collection={collection}
                        Component={ItemComponent}
                        itemName={itemName}
                        provided={p}
                        snapshot={snapshot}
                        size="large"
                        width={width}
                        item={item}
                        key={item.id}
                      />
                    )}
                  </Draggable>
                ))
              ) : (
                <Flex gap={2} justify="center" align="center" h="100%">
                  {isLoading ? (
                    <>
                      <CircularProgress size={6} color="green.500" isIndeterminate />
                      <Text>Loading...</Text>
                    </>
                  ) : (
                    <Text>No {makePlural(itemName)}</Text>
                  )}
                </Flex>
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      )
    } else {
      body = isLoading ? (
        <Flex gap={2} justify="center" p={3} align="center" h="100%">
          <CircularProgress size={6} color="green.500" isIndeterminate />
          <Text>Loading...</Text>
        </Flex>
      ) : (
        <Text
          style={{
            padding: '0.5rem 1.5rem',
            fontStyle: 'italic',
            fontSize: '14px',
          }}>
          {`No ${itemName ? `${itemName.toLowerCase()}s` : 'items'} have been created`}
        </Text>
      )
    }
  }

  return body
}

const EditCollection = <T extends AnyObject>(props: EditCollectionProps<T>) => {
  const { itemName, data, name, collection, RenderAddItem, onAdd } = props
  const { loading } = data
  const { addItem } = useUpdateCollection(collection, onAdd)
  return (
    <ThemeContext.Provider value={{ theme: 'detailed' }}>
      <Flex flexFlow="column" bg="white" h="100%" width="100%">
        <h2
          style={{
            background: '#f8f8f8',
            borderBottom: '1px solid #cdcdcd',
            fontSize: '18px',
            padding: '0.5rem 1rem',
            margin: 0,
          }}>
          {name}
        </h2>
        <DragDropContext onDragEnd={v => reorderCollection(collection, v)}>
          <EditCollectionBody isLoading={loading} {...props} />
        </DragDropContext>
        {RenderAddItem ? (
          <RenderAddItem loading={loading} itemName={itemName} onAdd={v => addItem(v)} />
        ) : (
          <AddItem loading={loading} itemName={itemName} onAdd={n => addItem({ name: n })} />
        )}
      </Flex>
    </ThemeContext.Provider>
  )
}

export default EditCollection
