import { AssessmentDocument, TemplateKey, USERS, USER_INVITES } from '@hb/shared'
import { collection, CollectionReference, orderBy, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend/db'
import { useQuery } from '../useQuery'

export const useAssessmentDocuments = (
  patientId: string,
  isInvite: boolean,
  assessmentId: string,
  type: TemplateKey,
) => {
  const ref = useMemo(
    () =>
      query(
        collection(
          db,
          isInvite ? USER_INVITES : USERS,
          patientId,
          'documents',
        ) as CollectionReference<AssessmentDocument>,
        where('assessmentId', '==', assessmentId),
        where('type', '==', type),
        orderBy('sentOn', 'desc'),
      ),
    [assessmentId, patientId, type, isInvite],
  )
  return useQuery<AssessmentDocument>(ref)
}
