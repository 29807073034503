import { DropdownField, FieldTypes } from '@hb/shared'
import { useMemo } from 'react'
import { adminAccessLevelOptions } from '../../../components/Messaging/fields'
import { useApp } from '../../../contexts/AppContext'

export const useAccessLevelField = (placeholder = 'Access Level') => {
  const { appName } = useApp()
  return useMemo<DropdownField | null>(
    () =>
      appName === 'providers-app'
        ? null
        : {
            placeholder,
            options:
              // appName === 'providers-app'
              // ? practiceAccessLevelOptions
              adminAccessLevelOptions,
            type: FieldTypes.DROPDOWN,
          },
    [appName, placeholder],
  )
}
