import {
  Button,
  Divider,
  Flex,
  Img,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { getDateTimeString, maintenanceInfo } from '@hb/shared'
import React from 'react'
import maintenanceIcon from '../../icons/build_circle.svg'

export const MaintenanceSoonView = () => {
  if (!maintenanceInfo) return null

  const { startTime, expectedEndTime, reason } = maintenanceInfo

  return (
    <Flex zIndex={2} pos="relative">
      <Popover trigger="hover" strategy="fixed" isLazy>
        <PopoverTrigger>
          <Button borderRadius="full" pl={2} size="sm" bg="red.400" color="white">
            <Flex filter="drop-shadow(1px 1px 3px #00000066)" gap={1}>
              <Img src={maintenanceIcon} w="20px" filter="brightness(250%)" />
              <Text>Maintenance Soon</Text>
            </Flex>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <Flex p={2} flexFlow="column">
              <Text lineHeight={1} fontWeight={500}>
                The site will be under maintenance soon for {reason}
              </Text>
              <Divider my={2} />
              <Text fontSize="sm" color="blackAlpha.800">
                {`Maintenance will start on ${getDateTimeString(startTime)} and is expected to end on ${getDateTimeString(expectedEndTime)}`}
              </Text>
            </Flex>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  )
}
