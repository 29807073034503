import { HStack, Text, VStack } from '@chakra-ui/react'
import {
  midwivesCollection,
  PracticeWithAdmin,
  providerPrmField,
  providerPrmNotesField,
  providerRetainerField,
  providerRetainerNotesField,
  WithId,
} from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { DataHeader } from '../DataView/DataHeader'
import { Expandable } from '../Expandable'
import { EditableRow } from '../forms/Input'

export const ProviderPrmRetainerInfo = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <Expandable
      headerProps={{ background: 'white' }}
      bg="white"
      header={() => (
        <DataHeader>
          <Text>PRM/Retainer</Text>
        </DataHeader>
      )}>
      <HStack spacing={0} align="flex-start" w="100%">
        <VStack spacing={0} borderRight="1px solid #cdcdcd" align="flex-start" bg="white" flex={1}>
          <EditableRow
            label="PRM"
            stackProps={{ bg: 'rgb(245,245,245)' }}
            onSubmit={v => updateItem(midwife?.id ?? '', 'prm', v)}
            value={midwife?.prm}
            field={providerPrmField}
          />
          <EditableRow
            stackProps={{ style: { background: 'white' } }}
            label="PRM Notes"
            onSubmit={v => updateItem(midwife?.id ?? '', 'prmNotes', v)}
            value={midwife?.prmNotes}
            field={providerPrmNotesField}
          />
        </VStack>
        <VStack minH="100%" spacing={0} align="flex-start" flex={1}>
          <EditableRow
            label="Retainer"
            stackProps={{ bg: 'rgb(245,245,245)' }}
            onSubmit={v => updateItem(midwife?.id ?? '', 'retainer', v)}
            value={midwife?.retainer}
            field={providerRetainerField}
          />
          <EditableRow
            label="Retainer Notes"
            onSubmit={v => updateItem(midwife?.id ?? '', 'retainerNotes', v)}
            value={midwife?.retainerNotes}
            field={providerRetainerNotesField}
          />
        </VStack>
      </HStack>
    </Expandable>
  )
}
