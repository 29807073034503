import { Comment } from '@hb/shared'
import { deleteField, DocumentReference, getDoc, writeBatch } from 'firebase/firestore'
import { get as nestedGet } from 'nested-property'
import { auth } from '../../backend/auth'
import { db } from '../../backend/db'

export const editComment = async (text: string, docRef: DocumentReference, fieldPath: string) => {
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }
  const now = Date.now()
  const batch = writeBatch(db)
  batch.update(docRef, `${fieldPath}.text`, text)
  batch.update(docRef, `${fieldPath}.updatedBy`, uid)
  batch.update(docRef, `${fieldPath}.updatedOn`, now)
  batch.update(docRef, `${fieldPath}.editedBy`, uid)
  batch.update(docRef, `${fieldPath}.editedOn`, now)
  return batch
    .commit()
    .then(() => ({ success: 'Edited comment' }))
    .catch(err => ({ error: err.message }))
}

export const deleteComment = async (docRef: DocumentReference, fieldPath: string) => {
  const uid = auth.currentUser?.uid
  if (!uid) {
    throw new Error('User not authenticated')
  }

  const doc = await getDoc(docRef)
  const data = doc.data()

  const currComment = nestedGet(data, fieldPath) as Comment | undefined

  if (currComment?.createdBy !== uid) {
    throw new Error('User not authorized to delete comment')
  }

  const batch = writeBatch(db)
  if (Object.keys(currComment?.replies ?? {}).length) {
    batch.update(docRef, `${fieldPath}.deletedOn`, Date.now())
    batch.update(docRef, `${fieldPath}.deletedBy`, uid)
  } else {
    batch.update(docRef, fieldPath, deleteField())
  }

  return batch
    .commit()
    .then(() => ({ success: 'Deleted comment' }))
    .catch(err => ({ error: err.message }))
}
