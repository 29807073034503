import { FieldMap, FieldMapValue, FormElement, UserGroup } from '@hb/shared'
import { DocumentReference, updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { ProfileContext, useApp } from '../../contexts'
import { SimpleForm } from '../forms'
import { DefaultModal } from '../Modals'
import {
  alertAccessLevelField,
  alertTextField,
  alertTypeField,
  alertUrgentField,
  customIconField,
} from './fields'

export const AddAlertModal = ({
  onClose,
  accessLevel,
  type,
}: {
  onClose: () => void
  accessLevel?: UserGroup
  type?: 'assessment' | 'patient'
}) => {
  const { selectedAssessment, practicePatientRef, adminPatientRef } = useContext(ProfileContext)
  const { adminRef, practiceRef } = selectedAssessment ?? {}
  const { appName } = useApp()
  const field = useMemo<FieldMap>(() => {
    const children: Record<string, FormElement> = {}
    if (appName === 'app' && !accessLevel) {
      children.access = alertAccessLevelField
    }
    if (selectedAssessment && !type) {
      children.type = alertTypeField
    }
    children.text = alertTextField
    children.urgent = alertUrgentField
    children.customIcon = customIconField

    return {
      name: 'New Alert',
      children,
    }
  }, [appName, selectedAssessment, accessLevel, type])

  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      const usedAccessLevel = accessLevel ?? data.access
      const assessmentRef =
        appName === 'providers-app' || usedAccessLevel === 'practice' ? practiceRef : adminRef
      const patientRef =
        appName === 'providers-app' || usedAccessLevel === 'practice'
          ? practicePatientRef
          : adminPatientRef
      const updatedRef: DocumentReference | null =
        (type ?? data.type) === 'assessment' ? assessmentRef : patientRef
      if (!updatedRef) {
        return { error: 'No patient or assessment reference' }
      }
      return updateDoc(updatedRef, `alerts.${Date.now().toString()}`, {
        text: data.text,
        urgent: data.urgent || false,
        customIcon: data.customIcon ?? null,
      })
        .then(() => {
          onClose()
          return { success: 'Added alert' }
        })
        .catch(() => ({ error: 'Error adding alert' }))
    },
    [
      accessLevel,
      adminPatientRef,
      adminRef,
      appName,
      onClose,
      practicePatientRef,
      practiceRef,
      type,
    ],
  )
  return (
    <DefaultModal
      isOpen
      onClose={onClose}
      overlayHeader
      render={() => (
        <SimpleForm
          theme="detailed"
          boxProps={{ bg: 'gray.50' }}
          field={field}
          onSubmit={handleSubmit}
        />
      )}
    />
  )
}
