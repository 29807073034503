import { Box, BoxProps, Input, InputProps, NumberInput, NumberInputField } from '@chakra-ui/react'
import {
  DropdownField,
  DropdownOptionItem,
  Field,
  fieldFormat,
  FieldFormatter,
  FieldTypes,
  NumberField,
} from '@hb/shared'
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { useCollections } from '../../../store/collections'
import { DropdownItemList, PopperPosition } from './Select/MenuDropdownInput'

const numberFieldTypes = [FieldTypes.NUMBER, FieldTypes.DOLLAR_AMOUNT]

const TextInputBody = forwardRef<
  HTMLInputElement,
  {
    value: string | undefined
    onChange: (v?: string) => void
    inputField: Field
    inputProps?: InputProps
    handleOpen: () => void
    handleClose: () => void
  }
>(({ handleClose, handleOpen, inputField, onChange, value, inputProps }, ref) => (
  <Input
    ref={ref}
    value={value ?? ''}
    size="sm"
    max={(inputField as NumberField).max}
    min={(inputField as NumberField).min}
    onChange={e => {
      onChange(e.target.value)
    }}
    onFocus={e => {
      e.preventDefault()
      handleOpen()
      e.target.select()
    }}
    onBlur={handleClose}
    placeholder={inputField.placeholder}
    {...inputProps}
  />
))

const NumberInputBody = forwardRef<
  HTMLInputElement,
  {
    value: number | undefined
    onChange: (v?: number) => void
    inputField: Field
    inputProps?: InputProps
    handleOpen: () => void
    handleClose: () => void
  }
>(({ handleClose, handleOpen, inputField, onChange, value, inputProps }, ref) => {
  const asNumberField = inputField as NumberField
  const format: FieldFormatter = inputField.format ?? fieldFormat[inputField.type] ?? (v => v)
  return (
    <NumberInput
      min={asNumberField.min}
      max={asNumberField.max}
      clampValueOnBlur={false}
      step={asNumberField.step}
      onChange={(_, v) => {
        let clamped = asNumberField.max === undefined ? v : Math.min(asNumberField.max, v)
        clamped = asNumberField.min === undefined ? clamped : Math.max(asNumberField.min, clamped)
        onChange(Number.isNaN(clamped) ? undefined : clamped)
      }}
      value={value}
      format={v => format(v, inputField, {})}
      size="sm">
      <NumberInputField
        onFocus={e => {
          e.preventDefault()
          handleOpen()
          e.target.select()
        }}
        value={value}
        onBlur={handleClose}
        placeholder={inputField.placeholder}
        ref={ref}
        {...(inputProps as any)}
      />
    </NumberInput>
  )
})

export const InputMenu = <T extends number | string = string>({
  options,
  onOpen,
  onClose,
  isOpen,
  onChange,
  inputField,
  inputProps,
  boxProps,
  value,
}: {
  options: DropdownOptionItem<T>[]
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  onChange: (v?: T) => void
  boxProps?: BoxProps
  inputProps?: InputProps
  inputField: Field
  value?: T
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClose = () => {
    onClose()
    inputRef.current?.blur()
  }

  const [popperPosition, setPopperPosition] = useState<PopperPosition | null>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const handleOpen = useCallback(() => {
    if (!containerRef.current) return
    const { x, y, height, width } = containerRef.current.getBoundingClientRect()
    setPopperPosition({
      x,
      y,
      height,
      width,
    })
    inputRef.current?.focus()
    onOpen()
  }, [onOpen])
  const dropdownField = useMemo<DropdownField<T>>(
    () => ({
      type: FieldTypes.DROPDOWN,
      options,
      placeholder: inputField.placeholder,
    }),
    [inputField, options],
  )

  const collections = useCollections()
  const formatted = useMemo(() => {
    const format: FieldFormatter = inputField.format ?? fieldFormat[inputField.type] ?? (v => v)
    return format(value, inputField, collections)
  }, [inputField, value, collections])

  const type = useMemo(
    () => (numberFieldTypes.includes(inputField.type) ? 'number' : 'text'),
    [inputField.type],
  )

  return (
    <Box position="relative" ref={containerRef} {...boxProps}>
      {type === 'number' ? (
        <NumberInputBody
          ref={inputRef}
          value={value as number}
          onChange={v => onChange(v as T)}
          inputField={inputField}
          handleOpen={handleOpen}
          inputProps={inputProps}
          handleClose={handleClose}
        />
      ) : (
        <TextInputBody
          ref={inputRef}
          value={formatted as string}
          onChange={v => onChange(v as T)}
          inputField={inputField}
          inputProps={inputProps}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      )}
      <DropdownItemList
        active={isOpen}
        options={options}
        popperPosition={popperPosition}
        field={dropdownField}
        onChange={onChange}
      />
    </Box>
  )
}
