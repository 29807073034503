import { ArrowUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  Collapse,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  colors,
  getCoveragePath,
  getCoverageStatus,
  getPlanNextActionPath,
  notesField,
  TextAreaField,
  USERS_ADMIN,
} from '@hb/shared'
import { deleteField, doc, writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../../backend/db'
import { makeCoveragePrimary } from '../../../backend/functions'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { ActionConfirm } from '../../Alerts/ActionConfirm'
import { ActionButton, DeleteButton, SolidActionButton } from '../../Buttons'
import { StandaloneInput } from '../../forms/Input'
import { useCoverageView } from './context'

const messageField: TextAreaField = {
  ...notesField,
  optional: false,
  placeholder: 'Message to patient',
}

const ConfirmSetPrimaryAlert = ({
  // assessmentId,
  onBack,
}: {
  onBack: () => void
  // assessmentId: string | null
}) => {
  const { coverage, coverageId, patientRef } = useCoverageView()
  const { appName } = useApp()
  const { showMessage } = useContext(PopUpMessageContext)
  const callInComplete = useMemo(() => {
    const newStatus = getCoverageStatus('primary', coverage)
    return !newStatus?.incomplete.includes('call-in')
  }, [coverage])
  const [sendEmail, setSendEmail] = useState(!callInComplete)
  const [message, setMessage] = useState('')

  const handleSetPrimary = useCallback(async () => {
    try {
      const {
        data: { success },
      } = await makeCoveragePrimary({
        appName,
        assessmentId: null,
        coverageId,
        patientId: patientRef.id,
        label: 'primary',
        sendEmail,
        message,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error setting coverage as primary',
        subText: err.message,
      })
    }
  }, [coverageId, showMessage, sendEmail, message, patientRef, appName])
  return (
    <ActionConfirm
      body={
        <Flex gap={2} pt={2} w="100%" flexFlow="column">
          <Text fontWeight={600} fontSize="lg" color="gray.600">
            Update primary coverage?
          </Text>
          <Text lineHeight={1}>Are you sure you want to set this coverage as primary?</Text>

          {callInComplete ? null : (
            <Flex
              border="1px solid #cdcdcd"
              px={3}
              mt={2}
              bg="gray.50"
              pb={3}
              borderRadius={4}
              flexFlow="column"
              w="100%">
              <Text fontSize="sm" mt={2}>
                This coverage will require a call-in request to be completed. Should we send an
                email to the patient with a link to the call-in request?
              </Text>
              <Flex
                mt={2}
                bg="white"
                p={3}
                align="center"
                borderRadius={4}
                boxShadow="1px 1px 4px #00000044">
                <Checkbox
                  isChecked={sendEmail || false}
                  value=""
                  onChange={e => setSendEmail(e.target.checked)}
                  colorScheme="green">
                  <Text
                    color={sendEmail ? colors.green.hex : 'gray.500'}
                    fontSize="sm"
                    fontFamily="Hero-New"
                    lineHeight={1}>
                    Send call-in request email
                  </Text>
                </Checkbox>
              </Flex>
            </Flex>
          )}
          <Collapse style={{ width: '100%' }} in={!!sendEmail}>
            <StandaloneInput
              value={message}
              theme="detailed"
              onChange={e => setMessage(e.target ? e.target.value : e)}
              field={messageField}
            />
          </Collapse>
        </Flex>
      }
      confirmText="Set as primary"
      isOpen
      onClose={onBack}
      onConfirm={handleSetPrimary}
    />
  )
}

export const CoverageActions = () => {
  const { patientRef, coverageId } = useCoverageView()
  const { appName } = useApp()
  const handleDelete = useCallback(async () => {
    const adminRef = doc(db, `${USERS_ADMIN}/${patientRef.id}`)
    const batch = writeBatch(db)
    batch.update(patientRef, getCoveragePath(coverageId), deleteField())
    const nextActionPath = getPlanNextActionPath(coverageId)
    batch.update(adminRef, nextActionPath, deleteField())
    await batch.commit()
  }, [patientRef, coverageId])

  const { showMessage } = useContext(PopUpMessageContext)
  const [loading, setLoading] = useState(false)
  const isSecondary = useMemo(() => coverageId === 'secondary', [coverageId])
  const handleSetSecondary = useCallback(async () => {
    try {
      setLoading(true)
      const {
        data: { success },
      } = await makeCoveragePrimary({
        // assessmentId,
        appName,
        patientId: patientRef.id,
        coverageId,
        assessmentId: null,
        label: isSecondary ? null : 'secondary',
        message: '',
        sendEmail: false,
      })
      showMessage({
        type: 'success',
        text: 'Coverage updated',
        subText: success,
      })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: `Error ${isSecondary ? 'unsetting' : 'setting'} coverage as secondary`,
        subText: err.message,
      })
    }
    setLoading(false)
  }, [coverageId, showMessage, patientRef, isSecondary, appName])

  const [confirmSettingPrimary, setConfirmSettingPrimary] = useState(false)

  return (
    <Flex align="center" w="100%" px={2} py={1}>
      <Popover isLazy strategy="fixed" placement="right">
        <PopoverTrigger>
          <ActionButton pl={3} gap={1} bg="white" size="xs">
            <ArrowUpIcon w={4} h={4} />
            <Text>SET AS...</Text>
          </ActionButton>
        </PopoverTrigger>
        <PopoverContent py={2} px={3} w="260px">
          <Text lineHeight={1} fontWeight={600} mb={2} color="gray.600">
            Set coverage as...
          </Text>
          <Flex gap={2} w="100%" flexFlow="column">
            <SolidActionButton size="sm" onClick={() => setConfirmSettingPrimary(true)}>
              Main (primary) coverage
            </SolidActionButton>
            <SolidActionButton
              size="sm"
              bg={isSecondary ? 'red.500' : 'green.500'}
              onClick={handleSetSecondary}
              isLoading={loading}>
              {isSecondary ? 'Unset as secondary' : 'Secondary'}
            </SolidActionButton>
          </Flex>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
      <Box ml="auto">
        <DeleteButton
          text="Delete coverage"
          size="sm"
          itemName="coverage"
          onDelete={() => handleDelete()}
        />
      </Box>
      {confirmSettingPrimary ? (
        <ConfirmSetPrimaryAlert onBack={() => setConfirmSettingPrimary(false)} />
      ) : null}
    </Flex>
  )
}
