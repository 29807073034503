import { ShortcutArgs } from '../../types'

export const getAuthorizationsValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
): string | undefined => {
  // ex: payments.received.0
  const [, authIndexStr] = path.split('.')
  if (authIndexStr === undefined) return undefined
  const authIndex = parseInt(authIndexStr, 10)
  if (Number.isNaN(authIndex)) return undefined
  const { assessment } = shortcutArgs ?? {}
  const { authorizations } = assessment ?? {}
  const authKeys = Object.keys(authorizations ?? {})
  const authKey = authKeys[authIndex]
  if (!authKey) return undefined
  const auth = authorizations?.[authKey]
  if (!auth) return undefined
  const { referenceNumber } = auth
  return referenceNumber
}
