import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  Switch,
  Text,
} from '@chakra-ui/react'
import React, { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext } from '../../../contexts'
import { useMyRoleItem } from '../../../hooks/backend/auth/useMyRoleItem'
import mobilePhoneIcon from '../../../icons/mobile_phone.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { useAppModals } from '../../../store'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { Loading } from '../../Loading'
import { CollapseHorizontal } from '../../shared'
import { ThreadsListContext } from '../contexts'
import { disableMessagingWebPushNotifications, enableMessagingWebPushNotifications } from '../utils'
import { SettingsContainer } from './Container'

export const MessagingPushNotificationsSettings = ({
  onClosePopover,
}: {
  onClosePopover?: () => void
}) => {
  const { data: roleItem } = useMyRoleItem()
  const { webPushNotifications, webPushNotificationsToken } = roleItem ?? {}
  const { onClose } = useContext(ThreadsListContext)

  const [updateLoading, setUpdateLoading] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)

  const onEnable = useCallback(() => {
    setUpdateLoading(true)
    enableMessagingWebPushNotifications()
      .then(() => {
        setUpdateLoading(false)
      })
      .catch(err => {
        console.error(err)
        processResponse({
          error: 'Failed to enable push notifications',
        })
        setUpdateLoading(false)
      })
  }, [processResponse])

  const onDisable = useCallback(() => {
    setUpdateLoading(true)
    disableMessagingWebPushNotifications()
      .then(() => {
        setUpdateLoading(false)
      })
      .catch(err => {
        console.error(err)
        processResponse({
          error: 'Failed to disable push notifications',
        })
        setUpdateLoading(false)
      })
  }, [processResponse])

  const onToggle = useCallback(() => {
    if (webPushNotifications?.messaging) {
      onDisable()
    } else {
      onEnable()
    }
  }, [onEnable, onDisable, webPushNotifications])
  return (
    <SettingsContainer>
      <Flex w="100%" align="center">
        <Flex align="center" gap={1} flex={1}>
          <SvgIcon src={mobilePhoneIcon} opacity={0.7} />
          <Text fontFamily="Open Sans" fontSize="sm" color="gray.600">
            Push Notifications
          </Text>
        </Flex>
        <Flex align="center">
          <CollapseHorizontal width={30} in={updateLoading}>
            <CircularProgress isIndeterminate size={5} color="green.500" />
          </CollapseHorizontal>
          <Switch
            colorScheme="green"
            isDisabled={updateLoading}
            isChecked={!!webPushNotifications?.messaging && !!webPushNotificationsToken}
            onChange={onToggle}
          />
        </Flex>
      </Flex>
      <Center>
        <Button
          onClick={() => {
            onClose()
            if (onClosePopover) onClosePopover()
            useAppModals.getState().pushNotificationsSettings.open()
          }}
          size="sm"
          variant="link"
          gap={1}>
          <Text>Push Notification Settings</Text>
          <ExternalLinkIcon />
        </Button>
      </Center>
    </SettingsContainer>
  )
}

export const PushNotificationsEnablePrompt = () => {
  const [updateLoading, setUpdateLoading] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const onEnable = useCallback(() => {
    setUpdateLoading(true)
    enableMessagingWebPushNotifications()
      .then(() => {
        setUpdateLoading(false)
      })
      .catch(err => {
        console.error(err)
        console.error(err.code)
        processResponse({
          error: 'Failed to enable push notifications',
        })
        setUpdateLoading(false)
      })
  }, [processResponse])

  const onDisable = useCallback(() => {
    setUpdateLoading(true)
    disableMessagingWebPushNotifications()
      .then(() => {
        setUpdateLoading(false)
      })
      .catch(err => {
        console.error(err)
        processResponse({
          error: 'Failed to disable push notifications',
        })
        setUpdateLoading(false)
      })
  }, [processResponse])

  return (
    <Center
      border="1px solid #cdcdcd"
      borderRadius={6}
      flexFlow="column"
      p={3}
      bg="gray.50"
      h="auto"
      w="400px"
      maxW="100%">
      <Text fontFamily="Hero-New" color="gray.700">
        Enable push notifications for messages?
      </Text>
      <Text fontSize="sm" color="gray.500">
        This can always be changed in the Settings Menu!
      </Text>
      <Collapse in={!updateLoading} style={{ width: '100%' }}>
        <HStack justify="center" w="100%" pt={2}>
          <ActionButton onClick={onDisable} size="sm" bg="white">
            No
          </ActionButton>
          <SolidActionButton onClick={onEnable} size="sm">
            Yes
          </SolidActionButton>
        </HStack>
      </Collapse>
      <Collapse in={updateLoading} style={{ width: '100%' }}>
        <Loading stackProps={{ pt: 2, width: '100%', justify: 'center' }} text="Updating..." />
      </Collapse>
    </Center>
  )
}
