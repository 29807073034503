import { Flex, FlexProps } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ScreenContext } from '../../contexts/ScreenContext'
import { useContentWidth } from '../../hooks'

export const PageContainer = ({
  children,
  fullWidth,
  ...props
}: FlexProps & { fullWidth?: boolean }) => {
  const { width } = useContext(ScreenContext)
  const contentWidth = useContentWidth(width, 1200)
  return (
    <Flex
      w="100%"
      align="center"
      transition="opacity 500ms"
      h="100%"
      direction="column"
      overflowY="auto"
      px={[4, 6, 8]}
      {...props}>
      <Flex
        align="center"
        flexFlow="column"
        py={4}
        position="relative"
        maxW="100%"
        height="100%"
        width={`${fullWidth ? width : contentWidth}px`}>
        {children}
      </Flex>
    </Flex>
  )
}
