import { AssessmentsList, FaxesView, InvoicesView, useAppRole } from '@hb/shared-frontend'
import React from 'react'
import { useParams } from 'react-router'

const AdminDashboardContent = () => {
  const { baseItemType } = useParams<{ baseItemType?: string }>()
  switch (baseItemType) {
    case 'faxes':
      return <FaxesView />
    default:
      return <AssessmentsList />
  }
}

const SuperAdminDashboardContent = () => {
  const { baseItemType } = useParams<{ baseItemType?: string }>()
  switch (baseItemType) {
    case 'invoices':
      return <InvoicesView />
    case 'faxes':
      return <FaxesView />
    default:
      return <AssessmentsList />
  }
}

export const AdminDashboard = () => {
  const appRole = useAppRole()

  return appRole === 'super-admin' ? <SuperAdminDashboardContent /> : <AdminDashboardContent />
}
