import {
  AdminUserData,
  FieldMapValue,
  getCoverageLabel,
  getCoveragePath,
  InsuranceCoverage,
  InsuranceCoverageId,
  User,
  USERS,
  USERS_ADMIN,
  WithId,
} from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { collection, doc, DocumentReference, updateDoc } from 'firebase/firestore'
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { db } from '../../../backend'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { addMetadata } from '../../../utils'
import { CoverageStageProps } from './types'

export interface CoverageViewContextValue {
  coverageId: InsuranceCoverageId
  coverage: InsuranceCoverage | undefined
  isMedicaid: boolean
  optional?: boolean
  propPath: string
  // assessmentId: string // | null
  // patientId: string
  patientRef: DocumentReference<User>
  adminPatientRef: DocumentReference<AdminUserData>
  isNew?: boolean
  handleSubmit: (data: FieldMapValue) => Promise<ValidationErrors>
  baseStoragePath: string
  adminView?: boolean
}

export const CoverageViewContext = createContext<CoverageViewContextValue>({
  // assessmentId: '',
  coverageId: 'primary',
  baseStoragePath: '',
  // patientId: '',
  patientRef: doc(db, USERS, '__ERROR__') as DocumentReference<User>,
  adminPatientRef: doc(db, USERS_ADMIN, '__ERROR__') as DocumentReference<AdminUserData>,
  coverage: undefined,
  handleSubmit: async () => ({}),
  isMedicaid: false,
  propPath: '',
})

export const CoverageViewProvider = ({
  children,
  coverage,
  patientRef,
  id,
  adminView,
  request,
}: PropsWithChildren<CoverageStageProps>) => {
  const { showMessage } = useContext(PopUpMessageContext)

  const coveragePath = useMemo(() => getCoveragePath(id), [id])
  const baseStoragePath = useMemo(() => {
    // replace periods with slashes
    return `${patientRef.path}/${coveragePath}`.replace(/\./g, '/')
  }, [patientRef, coveragePath])
  const propPath = useMemo(() => getCoveragePath(id), [id])

  const adminPatientRef = useMemo(
    () => doc(collection(db, USERS_ADMIN), patientRef.id),
    [patientRef],
  )

  const { appName } = useApp()

  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!patientRef) {
        showMessage({
          text: 'An error occurred',
          subText:
            'Please send us a message on the contact page and we will get back to you as soon as possible',
          type: 'error',
        })
        return { [FORM_ERROR]: 'An error occurred' }
      }
      try {
        const submitted: FieldMapValue = { ...coverage, ...data }
        if (request && !coverage?.fromRequest) submitted.fromRequest = request
        if (request?.withCallInForm) {
          submitted.callInRequests = {
            [Date.now()]: addMetadata(
              {
                on: Date.now(),
                by: 'admin',
                notes: `From coverage request | ${request.message}`,
              },
              appName,
              true,
            ),
            ...submitted.callInRequests,
          }
        }
        const withMetadata = addMetadata(
          submitted,
          appName,
          !(coverage as InsuranceCoverage)?.createdOn,
        )
        await updateDoc(patientRef, propPath, withMetadata)
        showMessage({
          type: 'success',
          text: `${getCoverageLabel(id)} coverage updated!`,
        })
      } catch (err: any) {
        showMessage({
          text: 'An error occurred',
          subText:
            err.message ||
            'Please send us a message on the contact page and we will get back to you as soon as possible',
          type: 'error',
        })
        return {
          [FORM_ERROR]:
            err.message ||
            'An error occurred. Please send us a message on the contact page and we will get back to you as soon as possible',
        }
      }
      return undefined
    },
    [patientRef, showMessage, propPath, coverage, appName, request, id],
  )

  const contextData = useMemo<CoverageViewContextValue>(
    () => ({
      coverage: coverage as WithId<InsuranceCoverage> | undefined,
      coverageId: id,
      isMedicaid: coverage?.type === 'medicaid',
      propPath,
      adminPatientRef,
      patientRef,
      baseStoragePath,
      adminView,
      handleSubmit,
    }),
    [coverage, propPath, adminView, handleSubmit, baseStoragePath, id, patientRef, adminPatientRef],
  )

  return <CoverageViewContext.Provider value={contextData}>{children}</CoverageViewContext.Provider>
}

export const useCoverageView = () => useContext(CoverageViewContext)
