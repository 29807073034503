import { Text, VStack } from '@chakra-ui/react'
import React, { useContext, useMemo } from 'react'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { Expandable } from '../../../Expandable'
import { ClaimNextAction } from '../NextAction'

export const ClaimNextActions = () => {
  const data = useContext(ProfileContext)
  const {
    claims: { data: claims },
  } = data

  const hasClaims = useMemo(() => Object.keys(claims ?? {}).length > 0, [claims])

  return (
    <Expandable
      style={{ width: '100%' }}
      headerProps={{ bg: 'white' }}
      initExpanded
      header={() => (
        <Text fontWeight={600} color="#777" w="100%" px={2} py={1}>
          Claim Next Actions{hasClaims ? '' : ' (No claims)'}
        </Text>
      )}>
      <VStack spacing={0} w="100%">
        {Object.entries(claims ?? {}).map(([claimId, claim]) => (
          <ClaimNextAction
            key={claimId}
            claim={claim}
            claimId={claimId}
            w="100%"
            py={1}
            px={1}
            borderTop="1px solid #cdcdcd"
          />
        ))}
      </VStack>
    </Expandable>
  )
}
