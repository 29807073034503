import { ArrowBackIcon, EditIcon, TimeIcon } from '@chakra-ui/icons'
import { Button, Center, Flex, Text } from '@chakra-ui/react'
import {
  BaseInsuranceCoverage,
  colors,
  getDateTimeString,
  getFullName,
  getNewAdditionalPlanId,
  InsuranceCoverageId,
  InsuranceCoverageRequest,
  OmitMetadata,
  User,
  USERS,
  WithId,
  WithInsuranceCoverageId,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { deleteField, DocumentReference, updateDoc } from 'firebase/firestore'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDocument, useRefDocument } from '../../../hooks/backend/useDocument'
import { useAuth } from '../../../store/auth'
import { DeleteButton } from '../../Buttons'
import { SolidActionButton } from '../../Buttons/ActionButton'
import { DefaultModal } from '../../Modals/DefaultModal'
import { UserBadge } from '../../Users/UserBadge'
import { CoverageStage } from './CoverageStage'
import { EditCoverageRequestModal } from './EditPlanRequestModal'

const DeleteRequest = ({
  patientRef,
  requestId,
}: {
  patientRef: DocumentReference<User>
  requestId: string
}) => {
  const handleDelete = useCallback(() => {
    return updateDoc(patientRef, `insurancePlans.requests.${requestId}`, deleteField())
  }, [requestId, patientRef])
  return <DeleteButton onDelete={handleDelete} itemName="request" />
}

// const DeleteRequest = ({
//   assessmentId,
//   requestId,
// }: {
//   assessmentId: string
//   requestId: string
// }) => {
//   const handleDelete = useCallback(() => {
//     const ref = doc(db, `${ASSESSMENTS}/${assessmentId}`)
//     return updateDoc(ref, `additionalPlans.${requestId}`, deleteField())
//   }, [requestId, assessmentId])
//   return <DeleteButton onDelete={handleDelete} itemName="request" />
// }

const EditRequest = ({
  request,
  patientRef,
}: {
  request: WithId<InsuranceCoverageRequest>
  patientRef: DocumentReference<User>
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <SolidActionButton gap={1} size="xs" onClick={() => setIsOpen(true)}>
        <EditIcon filter="1px 1px 4px #00000055" />
        <Text>Edit Request</Text>
      </SolidActionButton>
      {isOpen ? (
        <EditCoverageRequestModal
          patientRef={patientRef}
          onClose={() => setIsOpen(false)}
          request={request}
        />
      ) : null}
    </>
  )
}

export const CompleteCoverageRequestModal = ({
  onClose,
  request,
  patient,
  patientRef,
  // assessment,
}: {
  onClose: () => void
  request: InsuranceCoverageRequest
  patient: User | null
  patientRef: DocumentReference<User>
}) => {
  const [initData, setInitData] = useState<OmitMetadata<
    WithInsuranceCoverageId<BaseInsuranceCoverage>
  > | null>(null)
  const { additional: additionalPlans } = patient?.insurancePlans ?? {}

  const newCoverageId = useMemo<InsuranceCoverageId>(
    () => `additional.${getNewAdditionalPlanId(additionalPlans)}`,
    [additionalPlans],
  )

  const onInit = useCallback(
    (type: 'medicaid' | 'non-medicaid') => {
      const init: OmitMetadata<WithInsuranceCoverageId<BaseInsuranceCoverage>> = {
        id: newCoverageId,
        isMedicaid: type === 'medicaid',
        insuranceProviderId: '',
      }
      setInitData(init)
    },
    [newCoverageId],
  )

  const contentRef = useRef<HTMLDivElement>(null)
  const [contentWidth, setContentWidth] = useState<number>(0)
  const handleContentResize = useCallback((e: ResizeObserverEntry) => {
    setContentWidth(e.contentRect.width)
  }, [])
  useResizeObserver(contentRef, handleContentResize)

  return (
    <DefaultModal
      isOpen
      overlayHeader
      contentProps={{
        bg: colors.green.hex,
      }}
      onClose={() => {
        if (initData) {
          setInitData(null)
        } else {
          onClose()
        }
      }}
      size="xl"
      render={() => (
        <Flex ref={contentRef} w="100%" flexFlow="column">
          {initData ? (
            <Flex flexFlow="column" w="100%">
              <Flex py={3} px={4} bg="gray.100" w="100%">
                <Button onClick={() => setInitData(null)} gap={1} size="sm" variant="link">
                  <ArrowBackIcon />
                  <Text>Back to Coverage Type Selection</Text>
                </Button>
              </Flex>
              <CoverageStage
                // assessment={assessment}
                request={request}
                inModal
                patientRef={patientRef}
                id={newCoverageId}
                width={contentWidth}
                // assessmentId={assessmentId}
                coverage={initData}
              />
            </Flex>
          ) : (
            <Flex py={3} px={4} bg="gray.100" w="100%" gap={2} flexFlow="column">
              <Text fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
                Additional Coverage
              </Text>
              <Text px={1} fontSize="md" color="gray.600">
                Is your additional coverage through Medicaid?
              </Text>
              <Flex w="100%" gap={2} flexFlow={['column', 'column', 'row']}>
                <SolidActionButton
                  flex={['unset', 'unset', 1]}
                  onClick={() => onInit('non-medicaid')}>
                  Non-Medicaid
                </SolidActionButton>
                <SolidActionButton flex={['unset', 'unset', 1]} onClick={() => onInit('medicaid')}>
                  Medicaid
                </SolidActionButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
    />
  )
}

export const CoverageRequestView = ({
  request,
  adminView,
  patientRef,
}: {
  request: WithId<InsuranceCoverageRequest>
  adminView?: boolean
  patientRef: DocumentReference<User>
}) => {
  const [isCompleting, setIsCompleting] = useState(false)

  const authUser = useAuth(s => s.authUser)
  const { data: patient } = useRefDocument<User>(patientRef)
  const { data: requester } = useDocument(USERS, request.requestedBy)
  const requestedByName = useMemo(() => getFullName(requester), [requester])
  const patientName = useMemo(() => getFullName(patient), [patient])
  if (!authUser) {
    return (
      <Center w="100%" p={3}>
        <Text>Not Signed In</Text>
      </Center>
    )
  }

  return (
    <Flex
      boxShadow="1px 1px 4px #00000055"
      py={2}
      px={3}
      borderRadius={6}
      background="white"
      w="100%">
      <Flex w="100%" gap={2} flexFlow="column">
        <Flex w="100%" align="center">
          <Text fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
            Insurance Coverage {request.withCallInForm ? 'with Call-in' : ''} Request
          </Text>
          {adminView ? (
            <Flex gap={2} ml="auto">
              <DeleteRequest patientRef={patientRef} requestId={request.id} />
              <EditRequest patientRef={patientRef} request={request} />
            </Flex>
          ) : null}
        </Flex>
        <Flex
          borderRadius={6}
          bg="gray.50"
          border="1px solid #cdcdcd"
          flexFlow="column"
          px={3}
          py={2}>
          {request.message ? (
            <Text lineHeight={1} fontWeight={600} fontSize="sm" color="gray.600">
              Message from {requestedByName ?? 'admin'}:
            </Text>
          ) : null}
          <Text fontSize="md" color="gray.600">
            {request.message ?? 'Please complete the coverage form for your additional plan'}
          </Text>
        </Flex>
        <Flex py={1} gap={2} align="center">
          <UserBadge userGroup="admin" userId={patientRef.id} />
          <Text lineHeight={1} color="gray.600">
            Request sent on {getDateTimeString(request.requestedOn)}
          </Text>
        </Flex>
        {authUser.uid === patientRef.id && !adminView ? (
          <Flex gap={1} flexFlow="column" w="100%">
            <SolidActionButton onClick={() => setIsCompleting(true)}>
              Complete Coverage Form
            </SolidActionButton>
          </Flex>
        ) : (
          <Flex
            justify="center"
            borderRadius={5}
            border="1px solid #cdcdcd"
            bg="gray.50"
            p={1}
            gap={1}
            w="100%"
            align="center">
            <TimeIcon />
            <Text>Awaiting completion by {patientName}</Text>
          </Flex>
        )}
      </Flex>

      {isCompleting ? (
        <CompleteCoverageRequestModal
          request={request}
          patient={patient}
          patientRef={patientRef}
          onClose={() => setIsCompleting(false)}
        />
      ) : null}
    </Flex>
  )
}
