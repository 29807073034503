// export const medicaidProvidersCollection: Collection<
//   CollectionDataTypes,
//   'medicaidProviders'
// > = {
//   id: 'medicaidProviders',
//   name: 'Medicaid Insurers',
//   ref: firestoreQuery(
//     INSURANCE_PROVIDERS_REF,
//     where('hasMedicaidOption', '==', true),
//   ),
//   access: patientAccess,
//   defaultSort: 'name',

import { PracticeConsentForm } from '../../types'
import { UserGroup } from '../../types/shared/db'
import { Template, TemplateKey } from '../../types/templates'
import { templateKeyToCollection } from '../../utils/documents'
import { adminCollectionAccess, practiceCollectionAccess } from '../constants'
import { MIDWIVES } from '../names'
import { Collection } from '../types/collection'

const createTemplatesCollection = (
  id: TemplateKey,
  name: string,
  practiceId?: string,
  access: UserGroup[] = adminCollectionAccess,
): Collection<Template> => ({
  id: templateKeyToCollection[id],
  _type: 'firestoreCollection',
  name,
  refPath: practiceId
    ? `${MIDWIVES}/${practiceId}/${templateKeyToCollection[id]}`
    : templateKeyToCollection[id],
  access,
  defaultSort: 'name',
})

export const getPracticeConsentFormsCollection = (
  practiceId: string,
): Collection<PracticeConsentForm> => ({
  id: templateKeyToCollection.consentForm,
  _type: 'firestoreCollection',
  name: 'Consent Forms',
  refPath: `${MIDWIVES}/${practiceId}/consent-forms`,
  access: practiceCollectionAccess,
  defaultSort: 'name',
})

export const assessmentTemplatesCollection = createTemplatesCollection(
  'assessments',
  'Assessment Templates',
)
export const authTemplatesCollection = createTemplatesCollection(
  'authorizations',
  'Authorization Templates',
)
export const authAppealsTemplatesCollection = createTemplatesCollection(
  'authAppeals',
  'Auth Appeals Templates',
)
export const authInstructionsTemplatesCollection = createTemplatesCollection(
  'authInstructions',
  'Auth Instructions Templates',
)
export const claimTemplatesCollection = createTemplatesCollection('claims', 'Claim Templates')
export const clinicalsTemplatesCollection = createTemplatesCollection(
  'clinicals',
  'Clinical Templates',
)
export const invoiceTemplatesCollection = createTemplatesCollection(
  'invoiceAndReceipt',
  'Invoice / Receipt Templates',
)

export const consentFormTemplatesCollection = createTemplatesCollection(
  'consentForm',
  'Consent Form Templates',
  undefined,
  practiceCollectionAccess,
)
