import { EmailIcon } from '@chakra-ui/icons'
import { Checkbox, Collapse, Flex, Text, Tooltip, VStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useMemo } from 'react'
import { useAppModals, useAuth } from '../../store'
import { ActionButton } from '../Buttons/ActionButton'

export const EmailEdit = () => {
  const emailVerification = useAppModals(s => s.emailVerification)
  const { authUser, user } = useAuth()
  const { emailVerified } = authUser ?? {}

  const verifyingEmail = useMemo(() => authUser && !emailVerified, [authUser, emailVerified])

  return (
    <VStack align="flex-start" spacing={0}>
      <Flex w="100%" fontSize="sm">
        <Text color="gray.600">{user ? user.email : ''}</Text>
        <Tooltip placement="left" label={emailVerified ? 'Email Verified!' : 'Verify Email'}>
          <Flex
            onClick={() => {
              if (!emailVerified) {
                emailVerification.open()
              }
            }}
            ml="auto"
            gap={1}>
            <Text fontStyle="italic" color={emailVerified ? colors.green.hex : 'gray.500'}>
              {emailVerified ? 'Verified!' : 'Verify'}
            </Text>
            <Checkbox size="sm" colorScheme="green" isChecked={emailVerified} />
          </Flex>
        </Tooltip>
      </Flex>
      <Collapse in={!!verifyingEmail}>
        <ActionButton
          borderRadius={0}
          onClick={() => emailVerification.open()}
          px={4}
          width="100%"
          height={10}
          mt={0}
          border="none"
          fontSize="md">
          <EmailIcon color={colors.green.hex} mr={2} /> Verify Email
        </ActionButton>
      </Collapse>
    </VStack>
  )
}
