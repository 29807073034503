import { PreviousVersion } from '../data'
import { type ProviderAuthentication } from '../providers'
import { NextAction } from '../utils'

// TODO: Add location info
export interface Appointment {
  patientId: string
  practiceId: string
  assessmentId: string
  startTime: number
  endTime: number
  description: string
  archivedOn: number | null
  archivedBy: string | null
  createdOn: number
  createdBy: string
  updatedOn: number
  updatedBy: string
}

interface VisitSentFax {
  sentOn: number
  sentBy: string
  sentByGroup: string
  sentToNumber: string
  notes: string
  storagePath: string
}

export interface PracticeVisit {
  type:
    | 'encounter'
    | 'consultation'
    | 'antepartum'
    | 'confirmation-of-pregnancy'
    | 'problem-visit'
    | 'postpartum'
    | 'newborn'
    | 'labor-and-delivery'
  patientId: string
  assessmentId: string | null
  assessmentName: string | null
  assessmentEdd: number | null
  startTime: number
  endTime: number
  gravida: number
  para: Array<number>
  keywords?: string
  currentPregnancy:
    | 'singleton-detected'
    | 'singleton-detected-fhr'
    | 'singleton-confirmed-ultrasound'
  history: Array<
    'none' | 'no-other-surgical-history' | 'prior-pregnancy-vaginal' | 'no-prior-pregnancy'
  >
  historyReview: 'low-risk' | 'low-risk-no-contraindication' | 'further-evaluation-needed'
  nextAction: NextAction | null
  nextActionDate: number | null
  historyDetails: string
  practiceId: string
  deliveryPlan: 'spontaneous-no-induction' | 'homebirth-pending'
  backupDeliveryPlan?: 'transfer' | 'transfer-if-symptoms'
  deliveryPlanDetails: string
  hospital: string
  riskAssessment: 'low' | 'info-required'
  patientFirst: string
  patientLast: string
  authentication: ProviderAuthentication
  searchString: string
  signedPdfStoragePath: string | null
  allergyNotes?: string
  notes?: string
  previousVersions: Array<PreviousVisit>
  sentFaxes?: Array<VisitSentFax>
}

export type PreviousVisit = PreviousVersion<PracticeVisit, 'nextActionDate' | 'nextAction'>
export const isPreviousVisit = (visit: PracticeVisit | PreviousVisit): visit is PreviousVisit =>
  typeof (visit as PracticeVisit).previousVersions === 'undefined'

export type SubmittedVisitData = {
  date: string
  startTime: string
  endTime: string
  patientId: string
  practiceId: string
  assessmentId: string | null
  assessmentName: string | null
  assessmentEdd: number | null
} & Pick<
  PracticeVisit,
  | 'gravida'
  | 'para'
  | 'type'
  | 'history'
  | 'currentPregnancy'
  | 'keywords'
  | 'deliveryPlan'
  | 'backupDeliveryPlan'
  | 'deliveryPlanDetails'
  | 'hospital'
  | 'historyDetails'
  | 'historyReview'
  | 'riskAssessment'
  | 'allergyNotes'
  | 'notes'
>

export type PracticeVisitDraft = SubmittedVisitData & {
  searchString: string
  patientFirst: string
  patientLast: string
}

export type PracticeVisitDraftSortKey = 'date' | 'patientLast'

export type PracticeVisitSortKey = 'startTime' | 'endTime' | 'duration' | 'patientLast' | 'type'
