import { Flex, HStack, Spinner, StackDivider, Text, VStack } from '@chakra-ui/react'
import {
  AbilityClaim,
  Claim,
  colors,
  formatDropdownValue,
  getClaimIsProcessing,
  getDateString,
  getDateTimeString,
} from '@hb/shared'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getClaim } from '../../../../hooks/backend/claims'
import { useFormattedValue } from '../../../../hooks/useFormattedValue'

import { CopyId } from '../../../CopyId'
import { AbilityClaimContent } from './Ability/AbilityClaim'
import { serviceTypeField } from './Ability/fields'
import { ClaimInfo } from './ClaimInfo'
import { status } from './fields'

const useFetchedClaim = (id: string, testMode: boolean) => {
  const [data, setData] = useState<AbilityClaim | null>(null)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const lastFetched = useRef(data)
  const refetch = useCallback(async () => {
    if (lastFetched.current && !getClaimIsProcessing(lastFetched.current)) {
      return
    }
    setFetching(true)
    try {
      const { data: fetched } = await getClaim({ abilityId: id, testMode })
      setData(fetched ?? null)
    } catch (err: any) {
      console.error(err?.message)
      if (err?.response) console.error(err?.response.data)
      setError(err?.message ?? 'Error occurred fetching claim')
    }
    setFetching(false)
  }, [id, testMode])
  useEffect(() => {
    refetch()
    const interval = setInterval(
      () => {
        refetch()
      },
      2 * 60 * 1000,
    )
    return () => clearInterval(interval)
  }, [refetch])
  return { data, fetching, error }
}

const ClaimBeingValidated = ({ claim }: { claim: Claim }) => (
  <HStack spacing={4} py={2} px={5}>
    <Spinner color={colors.green.hex} />
    <VStack spacing={0} align="flex-start">
      <Text color="gray.700">Claim is being validated by Inovalon</Text>
      {claim.validationCheckedOn ? (
        <Text color="gray.500" fontSize="sm">
          VALIDATION CHECKED {getDateTimeString(claim.validationCheckedOn)}
        </Text>
      ) : null}
    </VStack>
  </HStack>
)

const LegacyClaimContent = ({ claim }: { claim: Claim }) => {
  const {
    serviceType: legacyServiceType,
    serviceTypeOther,
    serviceDates,
    status: claimStatus,
    submittedOn,
    billedCharges,
  } = claim?.legacy ?? {}

  const { value: serviceType } = useFormattedValue(serviceTypeField, legacyServiceType)

  return (
    <>
      <ClaimInfo header="SERVICE TYPE">
        <Text isTruncated>
          {legacyServiceType === 'other' ? `OTHER - ${serviceTypeOther}` : (serviceType ?? 'NONE')}
        </Text>
      </ClaimInfo>
      {/* <ClaimInfo header='ID'>
              <Text>{claim.TransID ?? 'NONE'}</Text>
            </ClaimInfo> */}
      <ClaimInfo header="STATUS">
        <Text isTruncated>
          {claimStatus ? formatDropdownValue(claimStatus, status.options) : 'None'}
        </Text>
      </ClaimInfo>
      <ClaimInfo header="SERVICE DATES">
        <Text isTruncated>{serviceDates ?? 'None'}</Text>
      </ClaimInfo>
      <ClaimInfo header="BILLED CHARGES">
        <Text isTruncated>{billedCharges ?? 'None'}</Text>
      </ClaimInfo>
      <ClaimInfo header="SUBMISSION DATE">
        <Text isTruncated>{submittedOn ? getDateString(submittedOn, 'short') : 'None'}</Text>
      </ClaimInfo>
    </>
  )
}

const LegacyClaimView = ({ claim, onClick }: { claim: Claim; id: string; onClick: () => void }) => (
  <Flex
    onClick={onClick}
    borderRadius={4}
    cursor="pointer"
    minH="100%"
    flex={1}
    px={2}
    // w='100%'
    _hover={{ bg: 'rgba(255,199,188,0.2)' }}
    border="1px solid #cdcdcd">
    {claim.abilityClaimStatus === 'submitting' ? (
      <ClaimBeingValidated claim={claim} />
    ) : (
      <LegacyClaimContent claim={claim} />
    )}
  </Flex>
)

const AbilityClaimPreview = ({
  abilityId,
  onClick,
  systemClaim,
  testMode,
}: {
  testMode?: boolean
  abilityId: string
  systemClaim: Claim
  onClick: () => void
}) => {
  const { data, fetching, error } = useFetchedClaim(abilityId, !!testMode)

  return (
    <HStack
      minH="100%"
      onClick={onClick}
      w="100%"
      borderRadius={4}
      cursor="pointer"
      _hover={{ bg: 'rgba(255,199,188,0.2)' }}
      border="1px solid #cdcdcd"
      divider={<StackDivider />}
      px={3}>
      {fetching ? (
        <HStack w="100%" p={2}>
          <Spinner color={colors.green.hex} />
          <Text ml={2} color="gray.500">
            Loading claim from ability
          </Text>
        </HStack>
      ) : (
        <AbilityClaimContent
          preview
          systemClaimId={systemClaim.id}
          systemClaim={systemClaim}
          error={error}
          claim={data}
        />
      )}
    </HStack>
  )
}

export const ClaimView = ({
  claim,
  id,
  onClick,
}: {
  claim: Claim
  id: string
  onClick: () => void
}) => {
  const body = claim.abilityId ? (
    <AbilityClaimPreview
      testMode={claim.testMode}
      systemClaim={claim}
      abilityId={claim.abilityId}
      onClick={onClick}
    />
  ) : (
    <LegacyClaimView claim={claim} id={id} onClick={onClick} />
  )

  return (
    <HStack spacing={2} w="100%" align="flex-start">
      <Flex bg="white" borderRadius={6} minW="0" overflow="hidden" position="relative" flex={1}>
        {body}
      </Flex>
      <Flex height="40px" width="25px" position="relative">
        <CopyId bottom="unset" top="0.25rem" left="0" right="unset" item={{ id }} />
      </Flex>
    </HStack>
  )
}
