import { Center, CircularProgress, Collapse, HStack, Text, VStack } from '@chakra-ui/react'
import { CheckboxField, colors, FieldTypes, formatPhoneNumber, TextField } from '@hb/shared'
import { PhoneAuthProvider, PhoneMultiFactorGenerator, PhoneMultiFactorInfo } from 'firebase/auth'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { auth } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { signInCallback, useAuth } from '../../../store'
import { SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { StandaloneInput } from '../../forms'

const codeField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Verification Code',
}

const autoSendCodeField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Automatically send code when I sign in',
}

export const PhoneVerify = ({ factor }: { factor: PhoneMultiFactorInfo }) => {
  const [code, setCode] = useState('')
  const [verifying, setVerifying] = useState(false)
  const [error, setError] = useState('')
  const [sendingCode, setSendingCode] = useState(false)
  const [verificationId, setVerificationId] = useState('')

  const [{ automaticallySendSmsCode }, setCookie] = useCookies(['automaticallySendSmsCode'])

  const initSendCode = useRef(!!automaticallySendSmsCode)

  const { processResponse } = useContext(PopUpMessageContext)

  const sendCode = useCallback(() => {
    const session = useAuth.getState().multiFactorResolver?.session
    if (!session) {
      processResponse({ error: 'Error - No session found' })
      return
    }
    setSendingCode(true)
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    phoneAuthProvider
      .verifyPhoneNumber(
        {
          multiFactorHint: factor,
          session,
        },
        useAuth.getState().recaptchaVerifier,
      )
      .then(v => {
        setVerificationId(v)
      })
      .catch(e => {
        console.error(e)
        console.error(e.code)
        processResponse({ error: e.message ?? 'Error sending code' })
      })
      .finally(() => setSendingCode(false))
  }, [factor, processResponse])

  const initSendCodeFunc = useRef(sendCode)
  useEffect(() => {
    if (initSendCode.current) {
      initSendCodeFunc.current()
    }
  }, [])

  const handleVerify = useCallback(() => {
    setVerifying(true)
    setError('')
    const resolver = useAuth.getState().multiFactorResolver
    const cred = PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    resolver
      ?.resolveSignIn(multiFactorAssertion)
      .then(credential => {
        setVerifying(false)
        setVerificationId('')
        setCode('')
        signInCallback(credential.user)
      })
      .catch(e => {
        setVerifying(false)
        setError(e.message ?? 'An error occurred')
      })
  }, [code, verificationId])

  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        handleVerify()
      }
    }

    window.addEventListener('keydown', onEnter)
    return () => window.removeEventListener('keydown', onEnter)
  }, [handleVerify])

  return (
    <VStack px={1} w="100%" align="flex-start">
      <Collapse unmountOnExit in={!verificationId && !sendingCode} style={{ width: '100%' }}>
        <VStack spacing={1} align="flex-start" w="100%">
          <Text fontFamily="Hero-New" fontSize="md" color="gray.600">
            We'll send a verification code to {formatPhoneNumber(factor.phoneNumber)}.
          </Text>
          <SolidActionButton w="100%" size="sm" isLoading={sendingCode} onClick={sendCode}>
            Send Verification Code
          </SolidActionButton>
          <StandaloneInput
            field={autoSendCodeField}
            value={automaticallySendSmsCode}
            onChange={v => {
              setCookie('automaticallySendSmsCode', v)
            }}
          />
        </VStack>
      </Collapse>
      <Collapse unmountOnExit style={{ width: '100%' }} in={sendingCode}>
        <Center w="100%">
          <CircularProgress size={6} color={colors.green.hex} isIndeterminate />
          <Text fontFamily="Hero-New" fontSize="md" color="gray.600" ml={2}>
            Sending verification code...
          </Text>
        </Center>
      </Collapse>
      <Collapse unmountOnExit in={!!verificationId} style={{ width: '100%' }}>
        <VStack align="flex-start" w="100%" p={1}>
          <Text>Enter the code sent to your phone:</Text>
          <StandaloneInput theme="detailed" field={codeField} value={code} onChange={setCode} />
          <CollapseError error={error} />
          <HStack w="100%">
            <SolidActionButton isLoading={verifying} ml="auto" onClick={handleVerify}>
              Verify
            </SolidActionButton>
          </HStack>
        </VStack>
      </Collapse>
    </VStack>
  )
}
