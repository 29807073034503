import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { set as nestedSet } from 'nested-property'
import {
  APPOINTMENTS,
  ASSESSMENTS,
  ASSESSMENTS_ADMIN,
  CONSENT_FORMS,
  getPracticeAssessmentCollectionPath,
  MIDWIVES,
} from '../collections'
import { colors } from '../constants'
import { planDesignField } from '../fields/callIn/planDesignAndStateMandates'
import { stateOptions } from '../fields/shared'
import { assessmentFields, getNestedUserFields } from '../fields/userFields'
import {
  AdminAssessmentData,
  Assessment,
  AssessmentForm,
  AssessmentFormAlias,
  AssessmentFormKey,
  AssessmentFormSnippet,
  AssessmentSnippet,
  AssessmentStage,
  AssessmentStatus,
  BaseInsuranceCoverage,
  Claim,
  FieldMap,
  FieldMapValue,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  isInsuranceCoverageRequest,
  Log,
  NextAction,
  PopulatedAssessment,
  PopulatedInsurancePlans,
  PopulatedUser,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PracticeWithAdmin,
  RedFlagRecord,
  User,
  UserGroup,
  WithId,
} from '../types'
import { objectToArray, toSearchString } from './data'
import { getDateString } from './dates'
import {
  defaultStageValidate,
  formatField,
  getFieldIsRedFlagged,
  isFieldMap,
  isInfoStage,
  isListField,
} from './fields'
import { getFormStatus } from './forms'
import { getCoverageText, getHasMedicaidCoverage, getNewAdditionalPlanId } from './insurance'
import { getCorrectedValue, getPregnancyFormData } from './populated'

export const getUrgentString = (log: Log | undefined | null) => {
  if (log) {
    const logKeys = Object.keys(log)
    for (const logKey of logKeys) {
      const logEntry = log[parseInt(logKey, 10)]
      if (!logEntry) continue
      if (logEntry.urgent) return `From log - ${logEntry.text}`
    }
  }
  return undefined
}

export const getUrgentSort = (adminData?: AdminAssessmentData | Claim) => {
  const { urgentColor, urgentReason, log } = adminData ?? {}
  const logUrgent = getUrgentString(log)
  return urgentColor ?? (urgentReason || logUrgent ? colors.red.hex : 'zzz')
}

export const getLogInfo = (log?: Log | null) => {
  const logKeys = log ? Object.keys(log).map(key => parseInt(key, 10)) : undefined
  let lastUpdatedKey
  let lastCreatedKey
  let lastUpdated
  let lastCreated
  if (log) {
    lastUpdatedKey = logKeys?.sort(
      (a: number, b: number) => (log[b]?.updatedOn ?? b) - (log[a]?.updatedOn ?? a),
    )[0]
    lastCreatedKey = logKeys?.sort((a, b) => b - a)[0]
    if (lastUpdatedKey) lastUpdated = log[lastUpdatedKey]
    if (lastCreatedKey) lastCreated = log[lastCreatedKey]
  }

  return {
    lastCreated,
    lastUpdated,
    lastCreatedKey,
    lastUpdatedKey,
  }
}

export const getAvailableForms = (
  admin: boolean,
  user: PopulatedUser | null,
  assessment: PopulatedAssessment | null,
) => {
  const forms: AssessmentFormSnippet[] = []
  let currentFormId: AssessmentFormKey | null = 'introduction'
  while (currentFormId) {
    const form: AssessmentForm | AssessmentFormAlias | undefined = assessmentFields[currentFormId]
    // const option = user?.insuranceInfo?.data?.option
    const { formData } = getPregnancyFormData(user, assessment)
    if (form) {
      if (typeof form !== 'string') {
        const status = getFormStatus(form, formData)
        forms.push({ id: currentFormId, status })
        if (status !== 'complete') {
          currentFormId = null
        } else if (form.getNextFormId) {
          currentFormId = form.getNextFormId(admin, user, assessment)
        } else {
          currentFormId = null
        }
      } else {
        forms.push({ id: currentFormId, status: 'info' })
        currentFormId = null
      }
    } else if (currentFormId) {
      forms.push({ id: currentFormId, status: 'info' })
      currentFormId = null
    } else {
      console.error(`Can't find form with id: ${currentFormId}`)
      currentFormId = null
    }
  }

  return forms
}

export const getCurrentlyOnMedicaidPlan = (data?: FieldMapValue) =>
  data?.['insurance-info']?.option === 'medicaid' ||
  (data?.['insurance-info']?.option === 'two-plans' &&
    !!data['insurance-info']?.medicaidCoverage?.currentlyOnMedicaidPlan)

export const getOnlyOnMedicaidPlan = (data?: FieldMapValue) =>
  data?.['insurance-info']?.option === 'medicaid'

export const getPrimaryCoverage = (data?: FieldMapValue): BaseInsuranceCoverage | null => {
  const coverage = data?.['insurance-info']?.primaryCoverage
  if (!coverage) return null
  return {
    ...coverage,
    isMedicaid: false,
  }
}

export const getMedicaidCoverage = (data?: FieldMapValue): BaseInsuranceCoverage | null => {
  const coverage = data?.['insurance-info']?.medicaidCoverage
  if (!coverage) return null
  return {
    ...coverage,
    isMedicaid: true,
  }
}

export const getSecondaryCoverage = (data?: FieldMapValue) =>
  data?.['insurance-info']?.secondaryCoverage

export interface LegacyInsurancePlans {
  additionalPlans: Record<string, BaseInsuranceCoverage>
  requests: Record<string, InsuranceCoverageRequest>
  mainCoverage: BaseInsuranceCoverage | null
  secondaryCoverage: BaseInsuranceCoverage | null
  secondaryOldId: 'primaryCoverage' | 'medicaidCoverage' | `additionalPlans.${string}` | null
}
export const getPregnancyInsurancePlans = (
  assessment: any | null | undefined,
  // insurers?: Record<string, InsuranceProvider>,
): LegacyInsurancePlans => {
  if (!assessment) {
    return {
      // isMedicaid: false,
      // primaryCoverage: null,
      // medicaidCoverage: null,
      additionalPlans: {},
      requests: {},
      mainCoverage: null,
      secondaryCoverage: null,
      secondaryOldId: null,
      // primaryCoverage: null,
      // secondaryCoverage: null,
    }
  }

  const mergedData = mergeAssessmentData(assessment)
  const additionalPlansArr = objectToArray(assessment?.additionalPlans ?? {}) as Array<
    WithId<BaseInsuranceCoverage> | WithId<InsuranceCoverageRequest>
  >

  const hasMedicaid = getHasMedicaidCoverage(mergedData)
  const isMedicaid = hasMedicaid && getCurrentlyOnMedicaidPlan(mergedData)
  const primaryCoverage = getPrimaryCoverage(mergedData)

  // TODO: Medicaid should not be included unless specified in the future
  // const medicaidCoverage = hasMedicaid ? getMedicaidCoverage(mergedData) : null
  // For now, we are always including it
  const medicaidCoverage = getMedicaidCoverage(mergedData)

  const mainCoverage = isMedicaid ? medicaidCoverage : primaryCoverage

  // if a plan is set as secondary, set secondary to that plan
  // else, secondary defaults to medicaid if main is non-medicaid and medicaid is set, and to non-medicaid if main is medicaid
  // if neither is set, secondary is null
  const {
    additionalPlans,
    requests,
    additionalPlanSetAsSecondary,
    additionalPlanSetAsSecondaryId,
  } = additionalPlansArr.reduce(
    (acc, plan) => {
      if (isInsuranceCoverageRequest(plan)) {
        acc.requests[plan.id] = plan
        return acc
      }
      if ((plan as any).label === 'secondary' && (plan.memberId || plan.insuranceProviderId)) {
        acc.additionalPlanSetAsSecondary = {
          ...plan,
        }
        acc.additionalPlanSetAsSecondaryId = plan.id
        return acc
      }
      acc.additionalPlans[plan.id] = {
        ...plan,
      }

      return acc
    },
    {
      additionalPlanSetAsSecondary: null as BaseInsuranceCoverage | null,
      additionalPlanSetAsSecondaryId: null as string | null,
      additionalPlans: {} as Record<string, BaseInsuranceCoverage>,
      requests: {} as Record<string, InsuranceCoverageRequest>,
    },
  )

  // if no additional plan set as secondary, set secondary to medicaid if main is non-medicaid and medicaid is set, and to non-medicaid if main is medicaid
  const secondaryCoverage =
    additionalPlanSetAsSecondary ?? (isMedicaid ? primaryCoverage : medicaidCoverage) ?? null
  // else, if main is medicaid, add non-medicaid to additional plans and vice versa
  if (additionalPlanSetAsSecondary) {
    const unselectedPlan = isMedicaid ? primaryCoverage : medicaidCoverage
    if (unselectedPlan) {
      const newKey = getNewAdditionalPlanId(additionalPlans)
      additionalPlans[newKey] = unselectedPlan
    }
  }

  return {
    // isMedicaid,
    mainCoverage: mainCoverage ? { ...mainCoverage, isMedicaid } : null,
    secondaryOldId: additionalPlanSetAsSecondaryId
      ? `additionalPlans.${additionalPlanSetAsSecondaryId}`
      : isMedicaid
        ? 'primaryCoverage'
        : 'medicaidCoverage',
    // primaryCoverage,
    secondaryCoverage:
      secondaryCoverage?.memberId || secondaryCoverage?.insuranceProviderId
        ? secondaryCoverage
        : null,
    // medicaidCoverage,
    additionalPlans,
    requests,
  }
}

export const getMidwifeId = (data?: FieldMapValue) => data?.delivery?.midwifeId

export const mergeData = <T extends FieldMapValue>(data: T, corrections: T) =>
  merge({}, data, corrections)

export const mergeAssessmentData = (assessment?: Assessment) =>
  merge({}, assessment?.data, assessment?.corrections)

export const mergeAssessmentSignOnData = (assessment?: Assessment) =>
  merge({}, assessment?.signOnData, assessment?.signOnCorrections)

export const getInsuranceCoverageText = (user: User, plans: PopulatedInsurancePlans | null) => {
  if (!plans) return 'zzz'
  const { insuranceInfo } = user ?? {}
  const { primary } = plans
  const option = insuranceInfo?.corrections?.option ?? insuranceInfo?.data?.option
  if (option === 'no-coverage') return 'No coverage'
  return getCoverageText(primary, primary?.insuranceProvider)
}

export const getAssessmentStatus = (
  assessment: PopulatedAssessment,
  user: PopulatedUser | null,
  adminData?: AdminAssessmentData | PopulatedAssessment | null,
): AssessmentStatus => {
  if (assessment) {
    const { formData, formCorrections } = getPregnancyFormData(user, assessment)
    const data = mergeData(formData, formCorrections)
    if (assessment.resultsViewedOn) return 'viewed'
    if (assessment.sentOn) return 'sent'
    if (adminData?.draftSavedOn) return 'draft-saved'
    if (assessment.submittedOn) return 'submitted'
    if (data) {
      const fields = getNestedUserFields(true, user, assessment)
      const formStatus = defaultStageValidate(fields, true)(data)
      const answersComplete = !(formStatus && Object.keys(formStatus).length)
      return answersComplete ? 'questions-complete' : 'incomplete'
    }
  }
  return 'awaiting-questionnaire'
}

export const getLogSnippet = (log?: Log | null): Log | null => {
  const { lastCreated, lastCreatedKey } = getLogInfo(log)
  return lastCreated && lastCreatedKey ? { [lastCreatedKey]: lastCreated } : null
}

// 0 awaiting questionnaire
// 1 questions complete, awaiting assessment
// 2 assessment sent, awaiting sign on by patient
// 3 awaiting sign on by midwife
// 4 signed on
export const getInquiryRank = ({
  assessment,
  signedOnByPractice,
}: {
  assessment?: Assessment | null
  signedOnByPractice: boolean
}) => {
  const { sentOn, submittedOn, signedOnDate } = assessment ?? {}
  let inquiryRank = 4
  if (signedOnByPractice) inquiryRank = 3
  else if (submittedOn) {
    inquiryRank = 1
    if (sentOn) {
      inquiryRank = 4
      if (signedOnDate) {
        inquiryRank = 2
      }
    }
  }

  return inquiryRank
}

const populateAssessmentData = (
  data: FieldMapValue | undefined,
  providers: Record<string, PracticeWithAdmin>,
  // insurers: Record<string, InsuranceProvider>,
) => {
  if (!data) return undefined
  const populated = cloneDeep(data)
  const midwifeId = data.delivery?.midwifeId
  if (populated.delivery?.midwife) delete populated.delivery.midwife
  if (populated['insurance-info']?.primaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].primaryCoverage.insuranceProvider
  }
  if (populated['insurance-info']?.secondaryCoverage?.insuranceProvider) {
    delete populated['insurance-info'].secondaryCoverage.insuranceProvider
  }
  if (populated['insurance-info']?.medicaidCoverage?.insuranceProvider) {
    delete populated['insurance-info'].medicaidCoverage.insuranceProvider
  }
  // const primaryInsurerId = data['insurance-info']?.primaryCoverage?.insuranceProviderId
  // const secondaryInsurerId = data['insurance-info']?.secondaryCoverage?.insuranceProviderId
  // const medicaidInsurerId = data['insurance-info']?.medicaidCoverage?.insuranceProviderId

  if (midwifeId && providers[midwifeId]) {
    nestedSet(populated, 'delivery.midwife', providers[midwifeId])
  }
  // if (primaryInsurerId && insurers[primaryInsurerId]) {
  //   nestedSet(
  //     populated,
  //     'insurance-info.primaryCoverage.insuranceProvider',
  //     insurers[primaryInsurerId],
  //   )
  // }
  // if (secondaryInsurerId && insurers[secondaryInsurerId]) {
  //   nestedSet(
  //     populated,
  //     'insurance-info.secondaryCoverage.insuranceProvider',
  //     insurers[secondaryInsurerId],
  //   )
  // }
  // if (medicaidInsurerId && insurers[medicaidInsurerId]) {
  //   nestedSet(
  //     populated,
  //     'insurance-info.medicaidCoverage.insuranceProvider',
  //     insurers[medicaidInsurerId],
  //   )
  // }

  return populated
}

export const getRedFlaggedFields = (
  fieldMap: FieldMap,
  values?: FieldMapValue,
): RedFlagRecord | undefined => {
  if (!values) return undefined
  const { children } = fieldMap
  let redFlagged: RedFlagRecord = {}
  const childKeys = Object.keys(children)
  for (const key of childKeys) {
    const child = children[key]
    if (child && !isInfoStage(child) && !isListField(child)) {
      if (isFieldMap(child)) {
        const childRedFlagged = getRedFlaggedFields(child, values[key])
        if (childRedFlagged) redFlagged = { ...redFlagged, ...childRedFlagged }
      } else {
        const childRedFlaggedReason = getFieldIsRedFlagged(child, values[key])
        if (childRedFlaggedReason) {
          redFlagged[key] = {
            reason: childRedFlaggedReason,
            header: child.placeholder,
          }
        }
      }
    }
  }
  return Object.keys(redFlagged).length !== 0 ? redFlagged : undefined
}

export const getAssessmentRedFlags = (
  user: PopulatedUser,
  assessment: Partial<PopulatedAssessment>,
) => {
  let redFlagged: RedFlagRecord = {}
  // if no unsent assessments, no red flags
  if (assessment.sentOn) return undefined
  // if (assessment.insuranceProvider?.redFlagged) {
  //   redFlagged.insuranceProvider = {
  //     header: assessment.insuranceProvider.name,
  //     reason: assessment.insuranceProvider.redFlaggedReason ?? 'Insurance provider red-flagged',
  //   }
  // }

  if (assessment.midwife?.redFlagged) {
    redFlagged.midwife = {
      header: assessment.midwife.name ?? 'Error getting midwife name',
      reason: assessment.midwife.redFlaggedReason ?? 'Midwife red-flagged',
    }
  }

  redFlagged = {
    ...redFlagged,
    ...getRedFlaggedFields(
      getNestedUserFields(true, user, assessment as PopulatedAssessment),
      assessment.mergedData,
    ),
  }

  return Object.keys(redFlagged).length ? redFlagged : undefined
}
// export const getSelectedCoverage = (
//   mergedData: FieldMapValue,
//   insurers: Record<string, InsuranceProvider>,
// ) => {
//   const isMedicaid = getCurrentlyOnMedicaidPlan(mergedData)
//   const coverage = isMedicaid ? getMedicaidCoverage(mergedData) : getPrimaryCoverage(mergedData)
//   if (coverage) {
//     coverage.insuranceProvider =
//       insurers[coverage.insuranceProviderId] || coverage.insuranceProvider
//   }

//   return coverage
// }
export const populateAssessment = (
  user: PopulatedUser,
  assessment: Assessment,
  providers: Record<string, PracticeWithAdmin>,
  assessmentSnippet?: AssessmentSnippet | null,
  adminAssessmentData?: AdminAssessmentData | null,
  practiceAssessmentData?: PracticeAssessmentData,
): PopulatedAssessment => {
  const populatedData = populateAssessmentData(assessment.data, providers)
  const populatedCorrections = populateAssessmentData(assessment.corrections, providers)

  const mergedData = mergeAssessmentData(assessment)
  const mergedSignOnData = mergeAssessmentSignOnData(assessment)
  let stages: (AssessmentStage | PracticeAssessmentStage)[] = []
  if (practiceAssessmentData) stages = practiceAssessmentData.stages
  else if (adminAssessmentData)
    stages =
      adminAssessmentData.stages && Array.isArray(adminAssessmentData.stages)
        ? adminAssessmentData.stages
        : []
  const midwifeId = getMidwifeId(mergedData)
  const payments = adminAssessmentData?.payments ?? practiceAssessmentData?.payments
  const populated: Omit<PopulatedAssessment, 'availableForms'> = {
    ...assessment,
    ...practiceAssessmentData,
    delivery: assessmentSnippet?.delivery ?? practiceAssessmentData?.delivery ?? undefined,
    archivedOn: assessment.archivedOn ?? null,
    stages,
    // plans: getPregnancyInsurancePlans(assessment, insurers),
    midwifeId: practiceAssessmentData?.midwifeId ?? midwifeId,
    data: populatedData,
    corrections: populatedCorrections,
    payments,
  }

  const midwife = midwifeId ? providers[midwifeId] : undefined
  if (midwife) {
    mergedData.delivery.midwife = midwife
  }
  const urgent = getUrgentString(adminAssessmentData?.log)
  const withoutRedFlags: Omit<PopulatedAssessment, 'redFlags'> = {
    ...populated,
    ...assessmentSnippet,
    ...adminAssessmentData,
    archivedOn:
      (practiceAssessmentData ? practiceAssessmentData.archivedOn : assessment.archivedOn) ?? null,
    status: getAssessmentStatus(populated, user, adminAssessmentData),
    adminAlerts: adminAssessmentData?.alerts,
    practiceAlerts: practiceAssessmentData?.alerts,
    mergedData,
    mergedSignOnData,
    signedOnDate: assessment.signedOnDate ?? null,
    // selectedCoverage,
    // plans,
    // insuranceProvider,
    urgent,
    assessmentNote: adminAssessmentData?.assessmentNote ?? practiceAssessmentData?.assessmentNote,
    sharedNote: assessmentSnippet?.sharedNote ?? practiceAssessmentData?.sharedNote,
    sharedPaymentNote:
      assessmentSnippet?.sharedPaymentNote ?? practiceAssessmentData?.sharedPaymentNote,
    midwife,
    midwifeId: midwifeId ?? undefined,
    receivedPayments: objectToArray(payments?.received ?? {}),
    duePayments: objectToArray(payments?.due ?? {}),
    files: assessment.files ?? {},
    adminFiles: adminAssessmentData?.files ?? {},
    practiceFiles: practiceAssessmentData?.files ?? {},
  }
  return {
    ...withoutRedFlags,
    redFlags: getAssessmentRedFlags(user, withoutRedFlags),
  } as PopulatedAssessment
}

export const getRedFlagText = (redFlags: RedFlagRecord | undefined) =>
  redFlags ? Object.values(redFlags)[0]?.reason : 'zzz'

export const stringifyNextAction = (
  nextAction: NextAction | null | undefined,
  coverage?: InsuranceCoverage | null,
) => {
  if (!nextAction) return ''
  const { text } = nextAction
  const parts: string[] = []
  if (text) parts.push(text)
  const insuranceProviderName = coverage?.insuranceProvider?.name
  if (insuranceProviderName) parts.push(insuranceProviderName)
  return parts.map(p => toSearchString(p)).join('|')
}

export const stringifySnippet = (
  snippet: Partial<AssessmentSnippet>,
  // plans: PopulatedInsurancePlans,
) => {
  const {
    phone = 'zzz',
    email = 'zzz',
    fname = 'zzz',
    lname = 'zzz',
    nextActionText = 'zzz',
    midwifeName = 'zzz',
    insuranceCoverage = 'zzz',
    partnerEmail,
    assessmentName = 'zzz',
    stages = [],
    status = 'incomplete',
    nickname,
    partnerName,
    deliveredOn,
    authRefNumbers,
    additionalAuthRefNumbers,
    additionalClaimsRefNumbers,
    claims,
    patientPlans,
    patientNextActions,
    dob,
    hasOverduePayment,
  } = snippet ?? {}
  const searchable = [
    fname,
    lname,
    midwifeName,
    insuranceCoverage,
    partnerName,
    partnerEmail,
    assessmentName,
    nickname,
    Object.keys(claims ?? {}).join('|'),
    authRefNumbers?.join('|') ?? '',
    patientNextActions?.primaryCoverage
      ? stringifyNextAction(patientNextActions.primaryCoverage, patientPlans?.primary)
      : '',
    patientNextActions?.secondaryCoverage
      ? stringifyNextAction(patientNextActions.secondaryCoverage, patientPlans?.secondary)
      : '',
    Object.entries(patientNextActions?.additionalPlans ?? {})
      .filter(t => !!t)
      .map(([planId, v]) => stringifyNextAction(v, patientPlans?.additional[planId]))
      .join('|'),
    deliveredOn ? getDateString(deliveredOn, 'short') : '',
    additionalAuthRefNumbers?.map(r => r.referenceNumber).join('|') ?? '',
    additionalClaimsRefNumbers?.map(r => r.referenceNumber).join('|') ?? '',
    email,
    phone,
    dob ? getDateString(dob, 'short') : '',
    hasOverduePayment ? 'overdue payment' : '',
    nextActionText,
    stages.join('|'),
    status,
  ]

  return toSearchString(searchable.filter(s => !!s && s !== 'zzz').join('|'))
}

export const getPracticeAppointmentsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${APPOINTMENTS}`

// export const getPracticeVisitDraftsCollectionPath = (midwifeId: string) =>
//   `${MIDWIVES}/${midwifeId}/${VISIT_DRAFTS}`
export const getPracticeConsentFormsCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${CONSENT_FORMS}`

export const getAssessmentName = (
  assessment: Assessment | PracticeAssessmentData | PopulatedAssessment | AssessmentSnippet | null,
  assessmentSnippet?: AssessmentSnippet,
) => {
  const edd = assessmentSnippet?.edd ?? getCorrectedValue(assessment as Assessment, 'delivery.edd')
  const assessmentName =
    (assessment as Assessment)?.name ??
    (assessment as PracticeAssessmentData)?.assessmentName ??
    assessmentSnippet?.assessmentName
  const enteredName = assessmentName && assessmentName !== 'zzz' ? assessmentName : ''
  return (
    enteredName ||
    `${enteredName ? `${enteredName} | ` : ''} EDD: ${
      edd ? getDateString(edd, 'short') : 'Awaiting'
    }`
  )
}

const getStateAcronym = (state: string) => stateOptions.find(s => state === s.text)?.id

export const getPlanState = (coverage: InsuranceCoverage | null | undefined) => {
  const planStateValue = coverage?.['plan-design-and-state-mandates']?.followsStateMandatesNote
  const completeAcronymMatch = stateOptions.map(s => s.id).find(s => planStateValue === s)
  if (completeAcronymMatch) return completeAcronymMatch

  const fullStateMatch = stateOptions
    .map(s => s.text.toLowerCase())
    .find(s => planStateValue?.toLowerCase().includes(s))
  const fullStateMatchAcronym = fullStateMatch ? getStateAcronym(fullStateMatch) : null
  if (fullStateMatchAcronym) return fullStateMatchAcronym

  // get value without punctuation
  const stateValueNoPunctuation = planStateValue?.replace(/[^a-zA-Z]/g, '')
  const stateAcronymMatch = stateOptions
    .map(s => s.id)
    .find(s => stateValueNoPunctuation?.toLowerCase().split(' ').includes(s.toLowerCase()))

  return stateAcronymMatch ?? null
}

// export const getSnippetPlanState = (assessment: PopulatedAssessment) => {
//   const mergedData = mergeAssessmentData(assessment)

//   const isMedicaidPlan = getCurrentlyOnMedicaidPlan(mergedData)

//   // account for legacy value which is note which may contain the state or abbreviation
//   const selectedCoverage = getSelectedCoverage(mergedData, {})
//   return getPlanState(selectedCoverage ? { ...selectedCoverage, isMedicaid: isMedicaidPlan } : null)
// }

export const getSnippetPlanCoverageType = (user: PopulatedUser | null) => {
  const planCoverageTypeValue =
    user?.insurancePlans?.primary?.['plan-design-and-state-mandates']?.planDesign

  return formatField(planDesignField, planCoverageTypeValue) ?? null
}

export const getSnippetIsMarketplacePlan = (assessment: PopulatedAssessment) => {
  const isMedicaidPlan = getCurrentlyOnMedicaidPlan(assessment.mergedData)
  const planOption = getCorrectedValue(assessment, 'insurance-info.option')
  return !isMedicaidPlan && planOption !== undefined
}

export const getSnippetPartnerEmail = (assessment: PopulatedAssessment) =>
  assessment.mergedSignOnData?.hasPartner
    ? assessment.mergedSignOnData.partnerContact?.email
    : undefined

export const getSnippetPartnerName = (assessment: PopulatedAssessment) =>
  assessment.mergedSignOnData?.hasPartner
    ? assessment.mergedSignOnData.partnerContact?.name
    : undefined

export const getBlankAssessment = (
  createdBy: string,
  createdByGroup: UserGroup,
  patientId: string,
  midwifeId?: string | null,
): Assessment => ({
  archivedOn: null,
  createdOn: Date.now(),
  patientId,
  midwifeId: midwifeId ?? null,
  data: midwifeId ? { delivery: { midwifeId } } : {},
  createdBy,
  createdByGroup,
})
export const getAssessmentFilesCollection = (
  firstArg: string,
  practiceId: string | undefined | null,
) => {
  switch (firstArg) {
    case 'files':
      return ASSESSMENTS
    case 'adminFiles':
      return ASSESSMENTS_ADMIN
    case 'practiceFiles':
      if (!practiceId) throw new Error('No practice ID provided when getting practice file')
      return getPracticeAssessmentCollectionPath(practiceId)
    default:
      throw new Error(`Invalid file id: ${firstArg} - should start with insurance-info or files`)
  }
}

export const getCoverageConfirmationRequired = (
  assessment: Assessment | PopulatedAssessment | undefined | null,
) => {
  const {
    additionalPlansConfirmedBy,
    additionalPlansConfirmedOn,
    additionalPlansRequestedBy,
    additionalPlansRequestedOn,
    questionnaireSkippedOn,
  } = assessment ?? {}

  if (questionnaireSkippedOn) return false
  if (!additionalPlansConfirmedBy || !additionalPlansConfirmedOn) return true
  // if any plan requires call in and does not have it, return true

  if (additionalPlansRequestedBy && additionalPlansRequestedOn) {
    if (additionalPlansRequestedOn > additionalPlansConfirmedOn) return true
  }

  return !(!additionalPlansRequestedBy || !additionalPlansRequestedOn)
}
