import { FieldTypes } from '../../constants/FieldTypes'
import { Stage } from '../../types/forms/fields'

const planCoversMidwives: Stage = {
  name: 'Midwife Coverage',
  validate: v => {
    if (!v?.coversMidwives && !v?.notes) {
      return {
        notes: 'Please document what the agent says in the notes',
      }
    }
    return undefined
  },
  children: {
    coversMidwives: {
      type: FieldTypes.BOOLEAN,
      optional: true,
      placeholder: 'Plan covers midwives and/or birthing centers',
      labels: [
        {
          text: 'Does my plan cover midwives and/or birthing centers?',
        },
      ],
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
      labels: [
        {
          text: 'I would like to know any and all language on coverage for midwives indicated in my plan document - can you look this up and read back to me?',
        },
      ],
      hints: [
        {
          text: "Please document everything the representative tells you in the 'notes' box below. If there is no language in your plan, indicate as much!",
        },
      ],
    },
  },
}

export default planCoversMidwives
