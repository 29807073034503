import { ASSESSMENTS, CLAIMS, PatientContextData, ProfileItem, VISITS } from '@hb/shared'
import { doc, DocumentReference } from 'firebase/firestore'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { db } from '../backend'
import { isProfileBaseItemType, ProfileVersion, toProfileItemType } from '../components'
import { isSubTabGroup, SubTab, SubTabGroup } from '../components/shared/Nav/types'
import {
  getBaseItemTabs,
  // getTabsV1,
  // getTabsV2,
  patientTab,
} from '../components/Users/Profile/Nav/tabs'
import { useRefDocument } from '../hooks'
import { emptyUseQuery } from '../hooks/backend/useQuery'
import { useUserFromId } from '../hooks/backend/user/useUserFromId'
import { useApp } from './AppContext'
import { emptyPopulatedAssessmentInterface } from './AssessmentContext'

export type ProfileEntity = { type: 'patient' } | { type: 'pregnancy'; id: string }
// export type ProfileTabPath = UserProfileTabName | `${UserProfileTabName}/${SubTabName}` | null
export interface ProfilePathData {
  entity: ProfileEntity
  tab: string | null
}

export type ProfileGoTo = (path: ProfilePathData) => void
export type GetTabPath = (path: ProfilePathData) => string
interface ProfileNav {
  profileVersion: 'v1' | 'v2'
  baseItem: ProfileItem | null
  selectedItem: ProfileItem | null
  // selectItem: (item: ProfileItem | null) => void
  tab: SubTab | null
  subTabs: Array<SubTab | SubTabGroup>
  subTab: SubTab | null
  userId: string | null
  // tabName: UserProfileTabName | null
  // subTab: PregnancyTabName | PatientTabName | null
  getTabPath: GetTabPath
  goTo: ProfileGoTo
  tabs: Array<SubTab | SubTabGroup>
}

type ProfileContextData = PatientContextData & ProfileNav
export const ProfileContext = createContext<ProfileContextData>({
  user: null,
  isInvite: false,
  profileVersion: 'v1',
  userId: null,
  viewedTemplate: null,
  adminPatientRef: null,
  baseItem: null,
  selectedItem: null,
  subTabs: [],
  loading: false,
  consentForms: {
    data: null,
    error: null,
    loading: false,
  },
  patientRef: null,
  emailVerification: { verified: false, loading: false, refetch: () => {} },
  admin: false,
  setViewedTemplateKey: () => {},
  assessments: null,
  officeVisits: emptyUseQuery,
  claims: { data: null, error: null, loading: false },
  selectedAssessment: emptyPopulatedAssessmentInterface,
  mostRecentPregnancy: null,
  assessmentId: null,
  practicePatientRef: null,
  tab: patientTab,
  pregnancyCorrections: {},
  pregnancyData: {},
  subTab: null,
  getTabPath: () => '',
  goTo: () => {},
  tabs: [],
})

export interface UseProfileArgs {
  // tabName: UserProfileTabName | null
  // subTabName: SubTabName | null
  // onTabSelect: (path: ProfilePathData) => void
  basePath: string
  // baseItem: ProfileItem | null
  profileVersion: 'v1' | 'v2'
}

interface ProfileNavParams {
  baseItemType: string
  baseItemId: string
  selectedItemType: string
  selectedItemId: string
  tabName: string
  subTabName: string
}

const getProfileItem = (
  itemType: string | undefined,
  itemId: string | undefined,
): ProfileItem | null => {
  if (!itemType || !itemId) return null
  // if (isProfileItemType(itemType)) return { type: itemType, id: itemId }
  if (isProfileBaseItemType(itemType)) return { type: toProfileItemType(itemType), id: itemId }
  return null
}

const getSelectedItem = (
  profileVersion: ProfileVersion,
  userId: string | null,
  selectedItemType: string | undefined,
  selectedItemId: string | undefined,
): ProfileItem | null => {
  // if (profileVersion === 'v2' && selectedItemType === 'patient' && userId)
  // return { type: 'patient', id: userId }
  return getProfileItem(selectedItemType, selectedItemId)
}

const baseItemPaths: Record<ProfileItem['type'], string> = {
  patient: 'patients',
  pregnancy: 'pregnancies',
  claim: 'claims',
  visit: 'visits',
}
const getBaseItemPath = (item: ProfileItem) => {
  return `${baseItemPaths[item.type]}/${item.id}`
}

const getEntityPath = (entity: ProfileEntity) => {
  if (entity.type === 'patient') return 'patient'
  return `${baseItemPaths[entity.type]}/${entity.id}`
}

interface BaseItem {
  patientId: string
}
const getBaseItemRef = (baseItem: ProfileItem | null): DocumentReference<BaseItem> | null => {
  if (!baseItem) return null
  switch (baseItem.type) {
    case 'claim':
      return doc(db, CLAIMS, baseItem.id) as DocumentReference<BaseItem>
    case 'pregnancy':
      return doc(db, ASSESSMENTS, baseItem.id) as DocumentReference<BaseItem>
    case 'visit':
      return doc(db, VISITS, baseItem.id) as DocumentReference<BaseItem>
    default:
      return null
  }
}

/*
  paths can be:
  '/:baseItemType',
  '/:baseItemType/:baseItemId',
  '/:baseItemType/:baseItemId/:selectedItemType',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId/:tabName',
  '/:baseItemType/:baseItemId/:selectedItemType/:selectedItemId/:tabName/:subTabName',

  baseItemType is always a base type
  baseItemId is always a base id
  selectedItemType is either a base type or a tab name
  selectedItemId is either a base id or a sub tab name, or a tab name if selectedItemType is 'patient'
  tabName is a tab name, or a sub tab name if selectedItemType is 'patient'
  subTabName is always a sub tab name
*/

/*
  pregnancies/:pregnancyId/pregnancies/:pregnancyId/:tab/:subTab
  pregnancies/:pregnancyId/patient/:tab/:subTab
  pregnancies/:pregnancyId/:tab/:subTab
*/
const getRealTabName = (
  profileVersion: ProfileVersion,
  tabs: Array<SubTab | SubTabGroup>,
  selectedItemType: string | undefined,
  selectedItemId: string | undefined,
  tabName: string | undefined,
) => {
  if (selectedItemType === 'patient' && profileVersion === 'v1') return selectedItemId
  if (selectedItemType && tabs.find(t => t.path === selectedItemType)) return selectedItemType
  return tabName ?? null
}

const getRealSubTabName = (
  profileVersion: ProfileVersion,
  tabs: Array<SubTab | SubTabGroup>,
  selectedItemType: string | undefined,
  selectedItemId: string | undefined,
  tabName: string | undefined,
  subTabName: string | undefined,
) => {
  if (tabs.length === 0) return null
  if (selectedItemType === 'patient' && profileVersion === 'v1')
    return tabName && tabs.find(t => t.path === tabName) ? tabName : null

  if (subTabName) return tabs.find(t => t.path === subTabName) ? subTabName : null
  if (selectedItemId) return tabs.find(t => t.path === selectedItemId) ? selectedItemId : null
  return null
}

const useResolveParams = (
  profileVersion: ProfileVersion,
): {
  baseItem: ProfileItem | null
  selectedItem: ProfileItem | null
  tabs: Array<SubTab | SubTabGroup>
  tab: SubTab | SubTabGroup | null
  subTabs: Array<SubTab | SubTabGroup>
  subTab: SubTab | null
  userId: string | null
} => {
  const { appName } = useApp()
  const {
    baseItemType,
    baseItemId,
    selectedItemType,
    selectedItemId,
    tabName: paramTabName,
    subTabName: paramSubTabName,
  } = useParams<Partial<ProfileNavParams>>()

  const baseItem = useMemo(
    () => getProfileItem(baseItemType, baseItemId),
    [baseItemType, baseItemId],
  )
  const baseItemRef = useMemo(() => getBaseItemRef(baseItem), [baseItem])
  const { data: baseItemData } = useRefDocument(baseItemRef)

  const userId = useMemo(() => {
    if (!baseItem) return null
    if (baseItem.type === 'patient') return baseItem.id
    return baseItemData?.patientId ?? null
  }, [baseItem, baseItemData])

  return useMemo(() => {
    const selectedItem =
      getSelectedItem(profileVersion, userId, selectedItemType, selectedItemId) ?? baseItem
    // const tabs =
    //   profileVersion === 'v2' ? getTabsV2(appName, selectedItem) : getTabsV1(appName, selectedItem)
    const tabs = getBaseItemTabs(appName, selectedItem, profileVersion)
    const realTabName =
      getRealTabName(profileVersion, tabs, selectedItemType, selectedItemId, paramTabName) ?? null
    const tab = tabs.find(t => t.path === realTabName) ?? null
    const subTabs = tab && isSubTabGroup(tab) ? tab.children : []
    const realSubTabName = getRealSubTabName(
      profileVersion,
      subTabs,
      selectedItemType,
      selectedItemId,
      paramTabName,
      paramSubTabName,
    )
    const subTab =
      tab && isSubTabGroup(tab) ? (tab.children.find(t => t.path === realSubTabName) ?? null) : null
    return { baseItem, selectedItem, tab, subTab, tabs, userId, subTabs }
  }, [
    appName,
    userId,
    baseItem,
    selectedItemType,
    selectedItemId,
    paramTabName,
    paramSubTabName,
    profileVersion,
  ])
}

const useProfileNav = (profileVersion: ProfileVersion, basePath: string): ProfileNav => {
  const { baseItem, selectedItem, tab, subTab, tabs, userId, subTabs } =
    useResolveParams(profileVersion)

  const getTabPath = useCallback(
    ({ entity, tab }: ProfilePathData) => {
      if (!baseItem) return basePath
      const isBaseItem =
        !!baseItem &&
        entity.type === 'pregnancy' &&
        baseItem.type === 'pregnancy' &&
        entity.id === baseItem.id

      const baseItemPath = getBaseItemPath(baseItem)
      const entityPath = isBaseItem ? baseItemPath : `${baseItemPath}/${getEntityPath(entity)}`
      if (!tab) return `${basePath}/${entityPath}`
      return `${basePath}/${entityPath}/${tab}`
    },
    [basePath, baseItem],
  )

  const navigate = useNavigate()
  const goTo = useCallback<ProfileGoTo>(data => navigate(getTabPath(data)), [getTabPath, navigate])

  return {
    profileVersion,
    getTabPath,
    userId,
    subTabs,
    baseItem,
    selectedItem,
    goTo,
    tab,
    tabs,
    subTab,
  }
}

export const useProfileData = (args: UseProfileArgs): ProfileContextData => {
  const { basePath, profileVersion } = args

  const navData = useProfileNav(profileVersion, basePath)
  const { selectedItem, userId } = navData
  const userData = useUserFromId(true, userId, selectedItem)

  return useMemo(
    () => ({
      ...userData,
      ...navData,
    }),
    [userData, navData],
  )
}

export const useProfile = () => useContext(ProfileContext)
