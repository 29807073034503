import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ProfileContext } from '../../contexts'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { Expandable } from '../Expandable'
import { AdditionalPlansView } from './AdditionalPlansView'
import { CoverageStage } from './Coverage'

export const AdminInsurancePlansView = ({ preview }: { preview?: boolean }) => {
  const { user, patientRef } = useContext(ProfileContext)
  const { nextActions, insurancePlans: plans } = user ?? {}

  const { ref, width } = useMobileLayout()
  if (!patientRef) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select a patient to see plans</Text>
      </Flex>
    )
  }

  return (
    <Flex ref={ref} w="100%" py={preview ? 0 : 2} flexFlow="column">
      <Flex w="100%" gap={preview ? 1 : 2} flexFlow="column" px={preview ? 1 : 3} py={2}>
        <CoverageStage
          id="primary"
          patientRef={patientRef}
          adminView
          width={width}
          nextAction={nextActions?.primaryCoverage}
          coverage={plans?.primary}
        />
      </Flex>
      <AdditionalPlansView adminView patientRef={patientRef} preview={preview} user={user} />
    </Flex>
  )
}

export const CollapsibleInsurancePlansView = () => (
  <Expandable
    borderRadius={6}
    headerProps={{ bg: 'gray.50', borderRadius: 4 }}
    border="1px solid #cdcdcd"
    header={() => (
      <Flex py={1} px={2} align="center" justify="space-between" w="100%">
        <Text fontSize="md" color="gray.600" fontWeight={500}>
          Insurance Plans
        </Text>
      </Flex>
    )}>
    <Box px={1} w="100%">
      <AdminInsurancePlansView preview />
    </Box>
  </Expandable>
)
