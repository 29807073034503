import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { useSpring, useTransform } from 'framer-motion'
import React, { JSX, useCallback, useContext, useEffect, useState } from 'react'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { MBox } from '../../../Motion/MBox'
import { MFlex } from '../../../Motion/MFlex'

const DRAG_HANDLE_WIDTH = 8

interface ResizableState {
  percent: number
}
type ResizableProps = Omit<FlexProps, 'width'> & {
  leftChild: (width: number) => JSX.Element
  rightChild: (width: number) => JSX.Element
  localStorageId?: string
  width: number
}
export const Resizable = ({
  leftChild,
  rightChild,
  width,
  localStorageId,
  ...rest
}: ResizableProps) => {
  const { assessmentId } = useContext(ProfileContext)
  const [showRight, setShowRight] = useState(true)
  const [isDragging, setIsDragging] = useState(false)
  const [mounted, setMounted] = useState(false)

  const storedState = useLocalStorage<ResizableState>(localStorageId)

  const x = useSpring(storedState?.percent ? width * storedState.percent : 1080, {
    duration: isDragging ? 0 : 500,
  })
  const leftWidth = useTransform(x, val => val - DRAG_HANDLE_WIDTH / 2)
  const rightWidth = useTransform(x, val => width - val - DRAG_HANDLE_WIDTH / 2)

  const [sideWidths, setSideWidths] = useState({
    left: x.get() - DRAG_HANDLE_WIDTH,
    right: width - (x.get() - DRAG_HANDLE_WIDTH),
  })

  const updateSideWidths = useCallback(() => {
    setTimeout(() => {
      const onSet = () => {
        const newX = x.get()
        setSideWidths({
          left: newX + 0.5 * DRAG_HANDLE_WIDTH,
          right: width - (newX + 0.5 * DRAG_HANDLE_WIDTH),
        })
      }
      if (x.getVelocity()) {
        onSet()
        setTimeout(() => updateSideWidths(), 33)
        return
      }
      onSet()
    }, 33)
  }, [x, width])

  const rOpacity = useTransform(x, val => Math.min(1, Math.max(0, (width - 150 - val) / 250)))
  useEffect(() => {
    if (width && !mounted) {
      setMounted(true)
      updateSideWidths()
    }
  }, [width, mounted, x, updateSideWidths])

  useEffect(() => {
    if (assessmentId && mounted && showRight) {
      x.set(storedState?.percent ? storedState.percent * width : Math.max(width - 600, width * 0.5))
    } else x.set(width)
    updateSideWidths()
  }, [showRight, width, mounted, assessmentId, x, updateSideWidths, storedState])
  return (
    <Flex width={`${width}px`} height="100%" bg="#efefef" {...rest} position="relative">
      <MBox
        style={{ width: leftWidth, height: '100%', position: 'absolute', left: 0, zIndex: 2 }}
        // ref={ref}
      >
        {leftChild(sideWidths.left)}
      </MBox>
      {assessmentId ? (
        <MBox
          title="drag to resize"
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => {
            setIsDragging(false)
            updateSideWidths()
            if (localStorageId)
              localStorage.setItem(localStorageId, JSON.stringify({ percent: x.get() / width }))
          }}
          position="absolute"
          dragElastic={0}
          dragMomentum={false}
          drag="x"
          dragConstraints={{
            left: 300,
            right: showRight ? width - 325 : width,
          }}
          bg={colors.green.hex}
          style={{ zIndex: 2, x, left: `${-DRAG_HANDLE_WIDTH / 2}px` }}
          cursor="col-resize"
          h="100%"
          w={`${DRAG_HANDLE_WIDTH}px`}
          sx={{
            transition: 'background 0.2s',
          }}
          _hover={{ bg: 'gray.300' }}
          _active={{ bg: 'gray.400' }}>
          <Text
            top={0}
            right="100%"
            borderBottom="1px solid #cdcdcd"
            borderLeft="1px solid #cdcdcd"
            borderRadius="0 0 0 4px"
            p={1}
            pl={3}
            pr={3}
            color="#999"
            whiteSpace="nowrap"
            fontSize="xs"
            fontWeight="600"
            position="absolute"
            background="white"
            cursor="pointer"
            onClick={() => setShowRight(!showRight)}>
            {showRight ? '- LOG' : '+ LOG'}
          </Text>
        </MBox>
      ) : null}
      <MFlex
        position="absolute"
        h="100%"
        style={{
          width: rightWidth,
          opacity: rOpacity,
          zIndex: 1,
          right: '0px',
          top: 0,
          overflow: 'hidden',
        }}
        overflow="hidden">
        <Box h="100%" position="relative" w="100%">
          {rightChild(sideWidths.right)}
        </Box>
      </MFlex>
    </Flex>
  )
}
