import React, { createContext, PropsWithChildren, useContext } from 'react'

export type EditMode = 'template' | 'document'

export const EditModeContext = createContext<EditMode>('document')

export const EditModeProvider = ({
  editMode,
  children,
}: PropsWithChildren<{
  editMode: EditMode
}>) => {
  return <EditModeContext.Provider value={editMode}>{children}</EditModeContext.Provider>
}

export const useEditMode = () => useContext(EditModeContext)
