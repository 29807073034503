import { BaseElement } from 'slate'
import { type FieldTypes } from '../constants'
import { AnyObject } from '../types'
import { FileDBValue } from '../types/data'
import { type Field } from '../types/forms/fields'

export type PopulatedNode = CustomElement & Partial<UserFieldItem>
export type SpanElement = BaseElement & {
  type: 'span'
  header?: never
  isInline?: true
  color?: string
  fontSize?: number
  children: (CustomText | CustomElement)[]
}

export interface ImageElementOptions {
  width?: number
  opacity?: number
  rotate?: number
  position?: { x: number; y: number } | null
}

export type ImageElement = BaseElement & {
  type: 'image'
  value: FileDBValue
  options: ImageElementOptions
}

export type Mode = 'View' | 'Edit'

export type VariableElement = BaseElement & {
  type: 'variable'
  dataType: FieldTypes
  path: string
  id: string
  name: string
  optional?: boolean
  shortcut?: true
  isInline?: true
  imageOptions?: ImageElementOptions
  children: CustomText[]
}

export type TemplateFieldElement<F extends Field = Field> = BaseElement & {
  type: 'field'
  id: string
  field: F
}

export type LinkElement = BaseElement & {
  type: 'link'
  url: string
}

export interface NestedFieldItem {
  name: string
  id: string
  optional?: boolean
  shortcut?: boolean
  [key: string]: any
}

export interface NestedField {
  name: string
  id: string
  items?: NestedFieldItem[]
  folders?: NestedField[]
}
export interface UserFieldItem extends NestedFieldItem {
  type: string
  text?: string
  path: string
}

export type CustomElement =
  | SpanElement
  | VariableElement
  | TemplateFieldElement
  | ImageElement
  | LinkElement

// Font ids based off of pdk-lib fonts, custom fonts not yet supported
export type FontId =
  // Fonts from pdf-lib
  'Courier' | 'Helvetica' | 'Times-Roman'
// To be added later - custom fonts
// https://www.npmjs.com/package/pdf-lib#embed-font-and-measure-text

export interface Font {
  name: string
  id: FontId
  hasOblique?: boolean
  hasBold?: boolean
  hasItalic?: boolean
}

export interface FormattedText {
  type?: never
  header?: true
  text: string
  bold?: true
  italic?: true
  id?: string
  color?: string
  font?: FontId
  fontSize?: number
  justify?: 'left' | 'right' | 'center'
  url?: string
}

export type CustomText = FormattedText

export type Descendant = CustomElement | CustomText
export const isText = (node: CustomElement | CustomText): node is CustomText =>
  !(node as CustomText).type

export type EditorVersion = 'v1' | 'v2'

export type EditorDraft<AdditionalData = AnyObject> = {
  text: Descendant[]
  editorVersion: EditorVersion
} & AdditionalData
