import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../store/auth'

export const MyAssessmentShortcut = () => {
  const authUser = useAuth(s => s.authUser)
  return authUser ? (
    <Link to="/pregnancy">
      <Button
        fontFamily="Comfortaa"
        bg="whiteAlpha.700"
        _hover={{ bg: 'whiteAlpha.800' }}
        color="gray.600"
        shadow="md"
        size="sm"
        position="absolute"
        top={5}
        right={5}>
        My Assessment <ArrowForwardIcon ml={1} />
      </Button>
    </Link>
  ) : null
}
