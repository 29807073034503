import { Collapse, Flex, Tab, TabList, TabProps } from '@chakra-ui/react'
import { colors } from '@hb/shared'

import useResizeObserver from '@react-hook/resize-observer'
import React, { useContext, useMemo, useRef } from 'react'
import { ScreenContext } from '../../../../../contexts'
import { ProfileContext, useProfile } from '../../../../../contexts/ProfileContext'
import { MenuTabList } from '../../../../shared/Nav/MenuTabList'
import { AssessmentHeaderRow } from './AssessmentHeader'
import { PatientHeaderRow } from './PatientHeader'

const ProfileTab = (props: TabProps) => {
  const { isMobile } = useContext(ScreenContext)
  return (
    <Tab
      py={isMobile ? 1 : 0}
      minW="30px"
      color="gray.600"
      borderRadius="full"
      h="32px"
      fontSize={['xs', 'sm']}
      lineHeight={1}
      _selected={{
        background: colors.pink.hex,
        fontWeight: 600,
        color: '#333',
      }}
      {...props}
    />
  )
}

const DesktopHeaderTabs = () => {
  const { tabs } = useContext(ProfileContext)

  return (
    <TabList
      height="40px"
      px={2}
      py={1}
      gap={0.5}
      overflowX="auto"
      overflowY="hidden"
      bg="gray.50"
      maxW="100%">
      {tabs.map(tab => (
        <ProfileTab key={tab.path} name={tab.path}>
          {tab.name}
        </ProfileTab>
      ))}
    </TabList>
  )
}

const MobileHeaderTabs = () => {
  const { goTo: onTabSelect, tabs, subTab, tab } = useProfile()
  const path = useMemo(() => {
    const tabName = tab?.path ?? ''
    if (!tabName) return ''
    return subTab ? `${tabName}/${subTab.path}` : tabName
  }, [subTab, tab])
  return (
    <Flex borderBottom="1px solid #cdcdcd" bg={`${colors.green.hex}33`} w="100%">
      <MenuTabList
        path={path}
        onSelect={t => onTabSelect({ tab: t, entity: { type: 'patient' } })}
        tabs={tabs}
      />
    </Flex>
  )
}

export const ProfileHeader = ({
  onResize,
  width,
}: {
  onResize: (size: { height: number; width: number }) => void
  width: number
}) => {
  const { isMobile } = useContext(ScreenContext)
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const contentRef = useRef<HTMLDivElement>(null)
  useResizeObserver(contentRef, ({ contentRect }) => {
    onResize({ height: contentRect.height, width: contentRect.width })
  })
  return (
    <Flex ref={contentRef} flexFlow="column" width="100%" zIndex={2} top={0}>
      <PatientHeaderRow width={width} />
      <Collapse
        style={{ width: '100%', maxWidth: '100%', display: 'flex' }}
        in={!!selectedAssessment}>
        <AssessmentHeaderRow width={width} />
      </Collapse>
      {isMobile ? <MobileHeaderTabs /> : <DesktopHeaderTabs />}
    </Flex>
  )
}
