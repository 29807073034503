import { Center, Flex, Img, Text } from '@chakra-ui/react'
import { getDateTimeString, maintenanceInfo } from '@hb/shared'
import React from 'react'
import maintenanceIcon from '../../icons/build_circle.svg'
import { View } from '../../types'
export const UnderMaintenanceView = () => {
  return (
    <Center w="100%" h="100%">
      <Flex
        maxW="600px"
        borderRadius={6}
        bg="white"
        boxShadow="md"
        align="center"
        flexFlow="column">
        <Flex
          w="100%"
          justify="center"
          borderBottom="1px solid #cdcdcd"
          gap={2}
          p={2}
          flexFlow="row"
          align="center">
          <Img src={maintenanceIcon} w="32px" />
          <Text fontFamily="Hero-New" fontSize="2xl">
            Under Maintenance
          </Text>
        </Flex>
        <Flex p={3} flexFlow="column" align="center" gap={2}>
          <Text fontSize="xl">Sorry for the inconvenience!</Text>
          <Text textAlign="center" fontSize="lg">
            The site is currently under maintenance for {maintenanceInfo?.reason} which is expected
            to end {maintenanceInfo ? getDateTimeString(maintenanceInfo.expectedEndTime) : 'soon'}.
          </Text>
        </Flex>
      </Flex>
    </Center>
  )
}
export const underMaintenanceView: View = {
  component: UnderMaintenanceView,
  name: 'Under Maintenance',
  path: '/*',
}
