import {
  Box,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { Alert, WithId } from '@hb/shared'
import { deleteField } from 'firebase/firestore'
import React, { useContext, useMemo } from 'react'
import { useApp } from '../../../../../../contexts/AppContext'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { useUpdateDoc } from '../../../../../../hooks'
import { AlertIcon } from '../../../../../Alerts/AlertIcon'
import { AlertsView } from '../../../../../Alerts/AlertsView'

export const PatientAlertPopover = ({ access }: { access: 'practice' | 'admin' }) => {
  const { user, adminPatientRef, practicePatientRef } = useContext(ProfileContext)

  const patientAlertsRef = useMemo(
    () => (access === 'admin' ? adminPatientRef : practicePatientRef),
    [access, adminPatientRef, practicePatientRef],
  )
  const { adminAlerts: adminPatientAlerts, practiceAlerts: practicePatientAlerts } = user ?? {}
  const patientAlerts = useMemo(
    () => (access === 'admin' ? adminPatientAlerts : practicePatientAlerts),
    [access, adminPatientAlerts, practicePatientAlerts],
  )

  const { appName } = useApp()

  const update = useUpdateDoc('alert')
  const sortedAlerts = useMemo<WithId<Partial<Alert>>[]>(() => {
    return Object.entries(patientAlerts ?? {})
      .sort(([a], [b]) => parseInt(a, 10) - parseInt(b, 10))
      .reduce<WithId<Partial<Alert>>[]>((acc, [, curr], idx) => {
        if (typeof curr === 'string') {
          return [...acc, { text: curr, id: `text-alert-${idx}` }]
        }
        return [...acc, { ...curr, id: `alert-${idx}` }]
      }, [])
  }, [patientAlerts])

  return (
    <Center flexFlow="column" px={2} py={1} w="100%">
      {sortedAlerts.length > 0 ? (
        <Text opacity={0.8} fontWeight={600}>
          Patient Alerts{appName === 'app' ? ` - ${access === 'admin' ? 'HB' : 'Practice'}` : ''} (
          {sortedAlerts.length})
        </Text>
      ) : null}
      <Popover closeOnBlur={false} strategy="fixed" trigger="hover" isLazy placement="bottom">
        <PopoverTrigger>
          <HStack
            // w='100%'
            py={1}
            px={2}
            borderRadius={4}
            maxW="100%"
            cursor="pointer"
            justify="center"
            bg="whiteAlpha.800"
            overflow="hidden"
            minW="200px"
            spacing={2}>
            <HStack divider={<StackDivider />} w="100%" spacing={1}>
              {sortedAlerts.length > 0 ? (
                sortedAlerts.map(a => (
                  <HStack flex={1} key={a.id} spacing={1.5}>
                    <AlertIcon icon={a.customIcon} />
                    <Text
                      flex={1}
                      minW="0"
                      textAlign="center"
                      isTruncated
                      color="gray.600"
                      fontWeight={600}
                      whiteSpace="nowrap">
                      {a.text}
                    </Text>
                  </HStack>
                ))
              ) : (
                <HStack w="100%" opacity={0.7} spacing={1.5}>
                  <AlertIcon filter="grayscale(100%)" />
                  <Text
                    flex={1}
                    minW="0"
                    textAlign="center"
                    color="gray.600"
                    fontWeight={500}
                    whiteSpace="nowrap">
                    No Patient Alerts
                  </Text>
                </HStack>
              )}
            </HStack>
          </HStack>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody borderRadius={6} overflow="hidden" p={0}>
              <HStack bg="red.500" px={2} py={1}>
                <Text color="white" textShadow="1px 1px 3px #00000077" fontWeight={600}>
                  Patient Alerts
                  {appName === 'app' ? ` - ${access === 'admin' ? 'HB' : 'Practice'}` : ''}
                </Text>
                <PopoverCloseButton color="gray.50" />
              </HStack>
              <Stack bg="gray.50" spacing={0}>
                <Box borderTop="1px solid #cdcdcd" py={2} px={3}>
                  <AlertsView
                    type="patient"
                    // popoverPlacement="right"
                    accessLevel={access}
                    alerts={patientAlerts}
                    onDelete={key => update(patientAlertsRef, `alerts.${key}`, deleteField())}
                    onSubmit={(key, data) => update(patientAlertsRef, `alerts.${key}`, data)}
                  />
                </Box>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Center>
  )
}

/*
  if app name is app,
    if user has no admin or practice alerts, return admin alerts
    if user has admin alerts, return admin alerts
    if user has practice alerts, return practice alerts
    if user has both admin and practice alerts, return both
*/
export const PatientAlerts = () => {
  const { user } = useContext(ProfileContext)
  const { appName } = useApp()
  const { adminAlerts: adminPatientAlerts, practiceAlerts: practicePatientAlerts } = user ?? {}
  const hasPracticeAlerts = useMemo(
    () => Object.keys(practicePatientAlerts ?? {}).length > 1,
    [practicePatientAlerts],
  )

  const hasAdminAlerts = useMemo(
    () => Object.keys(adminPatientAlerts ?? {}).length > 1,
    [adminPatientAlerts],
  )

  if (appName === 'providers-app') return <PatientAlertPopover access="practice" />
  return (
    <Flex py={0.5} flexFlow="column" gap={1}>
      {!hasPracticeAlerts || hasAdminAlerts ? <PatientAlertPopover access="admin" /> : null}
      {hasPracticeAlerts ? <PatientAlertPopover access="practice" /> : null}
    </Flex>
  )
}
