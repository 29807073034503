import { Box, Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ScreenContext } from '../../../../../contexts'
import { useApp } from '../../../../../contexts/AppContext'
import { ProfileContext, useProfile } from '../../../../../contexts/ProfileContext'
import { AssessmentSummary } from '../../../../Assessments'
import { AdminInsurancePlansView } from '../../../../Assessments/InsurancePlansView'
import { LoadingOverlay } from '../../../../LoadingOverlay'
import { AssessmentAuthorizationsTab } from '../../Authorizations/AssessmentAuthorizations'
import { ProfileVisits } from '../../Charting/Visits'
import { AssessmentClaims } from '../../Claims/AssessmentClaims'
import { ProfileConsentForms } from '../../ConsentForms/ProfileConsentForms'
import { AssessmentFiles } from '../../Files/AssessmentFiles'
import { AssessmentLog } from '../../Log/AssessmentLog'
import { Resizable } from '../../Log/Resizable'
import { PatientSummary } from '../../Patient/PatientSummary'
import { AssessmentPaymentsTab } from '../../Payments/AssessmentPayments'
import { ProfileHeader } from './ProfileHeader'

const ProfileTabsPanel = ({ width, height }: { width: number; height: number }) => {
  const { appName } = useApp()
  const { assessmentId, userId, assessments } = useContext(ProfileContext)
  const numAssessments = Object.keys(assessments ?? {}).length
  const mobileLayout = useMemo(() => width < 769, [width])
  return (
    <TabPanels
      overflowY="auto"
      style={{ width: 'auto' }}
      bg="#f3f3f3"
      height={`${height}px`}
      // height={contentHeight}
    >
      <TabPanel bg="gray.100" minH="100%" px={0}>
        <PatientSummary />
      </TabPanel>
      {numAssessments ? (
        <TabPanel bg="gray.100" minH="100%" p={0}>
          <AssessmentSummary width={width} />
        </TabPanel>
      ) : null}
      <TabPanel p={mobileLayout ? 0 : 3}>
        <Box w="100%" bg="white" boxShadow="1px 1px 4px #00000055" borderRadius={6}>
          <AdminInsurancePlansView />
        </Box>
      </TabPanel>
      {assessmentId ? (
        <TabPanel py={mobileLayout ? 1 : 0} px={mobileLayout ? 1 : 2}>
          <AssessmentPaymentsTab />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentAuthorizationsTab />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentClaims />
        </TabPanel>
      ) : null}
      {/* {appName === 'app' ? ( */}
      {assessmentId ? (
        <TabPanel>
          <AssessmentFiles />
        </TabPanel>
      ) : null}
      <TabPanel h="100%" p={0}>
        {userId ? (
          <ProfileVisits onlyStandaloneVisits={false} assessmentId={null} />
        ) : (
          <LoadingOverlay text="Loading visits..." />
        )}
      </TabPanel>
      {/* ) : null} */}
      <TabPanel p={1}>
        <ProfileConsentForms />
      </TabPanel>
    </TabPanels>
  )
}

export const ProfileBody = ({ height, width }: { height: number; width: number }) => {
  const { goTo, tabs, tab, selectedAssessment } = useProfile()
  const { appName } = useApp()
  const index = useMemo(() => {
    const match = tabs.findIndex(t => t.path === tab?.path)
    return match >= 0 ? match : 0
  }, [tabs, tab])

  const { isMobile } = useContext(ScreenContext)
  const [headerHeight, setHeaderHeight] = useState(0)
  const contentHeight = useMemo(
    () => (isMobile ? height : height - headerHeight),
    [height, isMobile, headerHeight],
  )

  const goToTab = useCallback(
    (i: number) => {
      const tab = tabs[i]
      if (!tab) return
      goTo({
        tab: tab.path,
        entity: { type: 'pregnancy', id: selectedAssessment.id },
      })
    },
    [goTo, tabs, selectedAssessment],
  )

  return (
    <Flex height={height} flexFlow="column">
      <Tabs
        index={index}
        onChange={i => goToTab(i)}
        style={{
          height: `${height}px`,
          background: 'rgba(255,255,255,0.9)',
        }}
        isLazy>
        <ProfileHeader
          width={width}
          onResize={size => {
            setHeaderHeight(size.height)
          }}
        />
        {isMobile || appName === 'providers-app' ? (
          <ProfileTabsPanel height={contentHeight} width={width} />
        ) : (
          <Resizable
            width={width}
            height={contentHeight}
            localStorageId="user-profile-resize"
            leftChild={leftWidth => <ProfileTabsPanel height={contentHeight} width={leftWidth} />}
            rightChild={() => (
              <AssessmentLog
                // width={rightWidth}
                maxHeight={contentHeight}
              />
            )}
          />
        )}
      </Tabs>
    </Flex>
  )
}
