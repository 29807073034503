import {
  arrayToObject,
  getCoverageStatus,
  getPlansArray,
  InsuranceCoverage,
  InsuranceCoverageRequest,
  populateInsurancePlans,
  providersCollection,
  WithId,
} from '@hb/shared'
import { useMemo } from 'react'
import { useCollection } from '../../collections'
import { useAuth } from '../../store'
import { View } from '../../types/views'

export const useNestedViews = (views: View[]) =>
  useMemo(() => {
    const publicViews = []
    const userViews = []
    const adminViews = []
    const superAdminViews = []
    for (const v of views) {
      if (v.onMenu) {
        if (v.access) {
          if (v.access.includes('user')) {
            userViews.push(v)
          } else if (v.access.includes('admin')) {
            adminViews.push(v)
          } else if (v.access.includes('super-admin')) {
            superAdminViews.push(v)
          }
        } else {
          publicViews.push(v)
        }
      }
    }

    return {
      publicViews,
      userViews,
      adminViews,
      superAdminViews,
    }
  }, [views])

type PendingRequests = Array<InsuranceCoverage | WithId<InsuranceCoverageRequest>>
export const useMyPendingCallInRequests = (): PendingRequests => {
  const user = useAuth(s => s.user)
  const { insurancePlans } = user ?? {}
  const { items: insurers } = useCollection(providersCollection)
  const populated = useMemo(
    () => populateInsurancePlans(insurancePlans ?? {}, arrayToObject(insurers) ?? {}),
    [insurancePlans, insurers],
  )

  return useMemo(() => {
    const { requests } = populated
    const plansArr = getPlansArray(populated ?? {})

    const hasCallInRequest = (plan: InsuranceCoverage) => {
      const planHasRequest = !!Object.keys(plan.callInRequests ?? {}).length
      const status = getCoverageStatus('additional', plan)
      return !!(planHasRequest && status?.incomplete.length)
    }
    return [
      ...Object.entries(requests ?? {}).map(([id, request]) => ({
        ...request,
        id,
      })),
      ...plansArr.filter(hasCallInRequest),
    ]
  }, [populated])
}
