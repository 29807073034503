import { Box, Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useProfile } from '../../../../../../contexts'
import { useCreateAssessment } from '../../../../../../hooks/backend/assessments'
import { useResizeObserver } from '../../../../../../hooks/useResizeObserver'
import { AssessmentsView } from '../../../../../Assessments/AssessmentSummary'
import { SolidActionButton } from '../../../../../Buttons'
import { LoadingOverlay } from '../../../../../LoadingOverlay'
import { AddAssessmentAlert } from '../../../Assessments/AddAssessmentAlert'
import { Visits } from '../../../Charting/Visits'
import { CardsView } from '../Containers/CardsView'
import { PreviewBox } from '../Containers/PreviewBox'

const PregnanciesPreview = () => {
  const { addLoading, confirmingNewAssessment, createAssessment, setConfirmingNewAssessment } =
    useCreateAssessment()
  return (
    <PreviewBox
      header={
        <Flex justify="flex-end" w="100%">
          <AddAssessmentAlert
            isOpen={confirmingNewAssessment}
            onConfirm={createAssessment}
            onCancel={() => setConfirmingNewAssessment(false)}
          />
          <SolidActionButton
            size="xs"
            ml="auto"
            onClick={() => setConfirmingNewAssessment(true)}
            isLoading={addLoading}>
            + New Pregnancy
          </SolidActionButton>
        </Flex>
      }
      title="Pregnancies">
      <Box w="100%">
        <AssessmentsView preview />
      </Box>
    </PreviewBox>
  )
}

const VisitsPreview = () => {
  const { userId, assessmentId } = useProfile()
  return (
    <PreviewBox fixed h="325px" title="Visits">
      {userId ? (
        <Visits
          onlyStandaloneVisits={false}
          preview
          patientId={userId}
          assessmentId={assessmentId ?? null}
        />
      ) : (
        <LoadingOverlay text="Loading visits..." />
      )}
    </PreviewBox>
  )
}

export const PatientControlPanel = () => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(bodyRef, 'content')
  return (
    <Flex bg="#dedede" ref={bodyRef} w="100%" h="100%" flexFlow="column">
      <CardsView width={width} elements={[<PregnanciesPreview />, <VisitsPreview />]} />
    </Flex>
  )
}
