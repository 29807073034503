import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { ScreenContext } from '../../contexts/ScreenContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { DefaultModal } from '../Modals/DefaultModal'
import { NameEditModal } from '../Users/Profile/Patient/NameEditModal'
import { AdminColorPopover } from './AdminColorPopover'
import { AdminCalendlyLink } from './Calendly/AdminCalendlyLink'
import { adminProfileTabNames } from './constants'
import { AdminProfileContext } from './context'
import usePopulatedAdmin from './hooks'
import { ProfilePicturePopover } from './ProfilePicturePopover'
import { AdminProfileProps } from './types'

const ProfileTab = (props: TabProps) => (
  <Tab
    py={1}
    color="gray.600"
    borderRadius="full"
    _selected={{
      background: colors.pink.hex,
      fontWeight: 600,
      color: '#333',
    }}
    {...props}
  />
)
export const AdminProfile = ({ onClose, onTabSelect, userId, tab }: AdminProfileProps) => {
  const data = usePopulatedAdmin(userId)
  const { loading, user, userRef, userRoleRef } = data
  const { nickname, name } = user ?? {}

  const tabIndex = useMemo(() => (tab ? adminProfileTabNames.indexOf(tab) : 0), [tab])

  const { height, width: sWidth } = useContext(ScreenContext)
  const { modalHeight, contentHeight, width } = useMemo(
    () => ({
      modalHeight: height - 100,
      contentHeight: height - 220,
      width: sWidth - 50,
    }),
    [height, sWidth],
  )
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const update = useUpdateDoc()
  return (
    <DefaultModal
      isOpen={!!userId}
      closeOnOverlayClick={false}
      overlayHeader
      render={() => (
        <AdminProfileContext.Provider value={data}>
          {user && !loading ? (
            // <Stack
            //   spacing={[4, 4, 4, 4, 2]}
            //   direction={['column', 'column', 'column', 'column', 'row']}
            // >
            <Flex flexFlow="column">
              <HStack
                spacing={1}
                borderBottom="1px solid #cdcdcd"
                bg="gray.100"
                w="100%"
                py={1}
                px={4}>
                {userRoleRef ? <AdminColorPopover /> : null}
                <Box pl={2}>
                  <ProfilePicturePopover canEdit userId={userId} />
                </Box>
                <Tooltip placement="top" hasArrow label="Edit name">
                  <Button
                    onClick={() => setNameModalOpen(true)}
                    variant="link"
                    fontSize="lg"
                    mx={2}
                    color="gray.700">
                    {name ?? 'No name entered'}
                    {nickname ? ` (${nickname})` : ''}
                  </Button>
                </Tooltip>
                <Text
                  borderRadius={4}
                  bg={colors.green.hex}
                  fontSize="sm"
                  color="white"
                  p={1}
                  lineHeight={1}
                  fontWeight="bold"
                  fontFamily="Comfortaa">
                  ADMIN
                </Text>
              </HStack>
              <Tabs
                index={tabIndex}
                onChange={i => onTabSelect(adminProfileTabNames[i])}
                style={{
                  height: `${contentHeight}px`,
                  background: 'rgba(255,255,255,0.9)',
                }}>
                <TabList
                  height={50}
                  p={1}
                  overflowX="auto"
                  overflowY="hidden"
                  bg="gray.50"
                  maxW="100%">
                  <ProfileTab>Calendly Link</ProfileTab>
                  {/* <ProfileTab>Sent Assessments</ProfileTab> */}
                </TabList>
                <TabPanels
                  overflowY="auto"
                  style={{ width: 'auto' }}
                  height="100%"
                  // height={contentHeight}
                >
                  <TabPanel bg="gray.100" minH="100%">
                    <AdminCalendlyLink />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          ) : (
            // </Stack>
            <Center color="gray.600" px={2} py={4} bg="#FFC7BC">
              <Spinner mr={2} />
              <Text>Loading...</Text>
            </Center>
          )}
          <NameEditModal
            userType="admin"
            value={user}
            isOpen={nameModalOpen}
            onClose={() => setNameModalOpen(false)}
            onSubmit={v => update(userRef, '', v)}
          />
        </AdminProfileContext.Provider>
      )}
      size="6xl"
      // size='6xl'
      onClose={onClose}
      contentProps={{
        // m: '50px',
        maxH: modalHeight,
        height: modalHeight,
        maxWidth: '100%',
        width,
        // height: modalHeight,
        bg: '#efefef',
        overflow: 'hidden',
      }}
      bodyProps={{
        height: modalHeight,
        width,
        maxHeight: modalHeight,
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        // overflowY: 'auto',
      }}
    />
  )
}
