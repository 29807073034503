import { BellIcon } from '@chakra-ui/icons'
import { Box, Center, HStack, StackProps, Text, Tooltip } from '@chakra-ui/react'
import { colors, MessageThread } from '@hb/shared'
import React from 'react'
import { useMe } from '../../../hooks/backend/useMe'
import { ProfilePicturePopover } from '../../Admins/ProfilePicturePopover'

export const ThreadMembersPreview = ({
  thread,
  noTooltips,
  ...stackProps
}: StackProps & {
  thread: MessageThread | null
  noTooltips?: boolean
}) => {
  const { subscribers = [] } = thread ?? {}
  const me = useMe()
  const showBellIcon = !subscribers.length
  return (
    <HStack
      // bg={
      //   showBellIcon ? 'transparent' : 'whiteAlpha.700'
      // }
      py="0.25rem"
      px={1}
      borderRadius="full"
      spacing={1}
      justify="center"
      position="relative"
      {...stackProps}>
      {showBellIcon ? (
        <Box>
          <BellIcon
            w="20px"
            h="20px"
            color={me?.uid && subscribers.includes(me.uid) ? 'whiteAlpha.500' : 'blackAlpha.500'}
          />
        </Box>
      ) : (
        subscribers.slice(0, 3).map(sub => (
          <Box position="relative" key={sub}>
            <ProfilePicturePopover size={7} noPopover={noTooltips} userId={sub} />
          </Box>
        ))
      )}
      {subscribers.length > 3 ? (
        <Tooltip textShadow="1px 1px 3px black" placement="top" hasArrow label="More members...">
          <Center
            borderRadius="full"
            height="24px"
            width="24px"
            bg={colors.green.hex}
            boxShadow="1px 1px 4px rgba(0,0,0,0.5)"
            color="white"
            zIndex={1}>
            <Text position="relative" bottom="4px">
              ...
            </Text>
          </Center>
        </Tooltip>
      ) : null}
    </HStack>
  )
}
