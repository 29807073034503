import { baseMidwifeFields } from '../../collections'
import { VariableElement } from '../../editor/types'
import { getInsuranceCoverageField } from '../../fields'
import { getAssessmentFieldFromPath } from '../../fields/userFields'
import { Field } from '../../types'
import { ShortcutArgs } from '../../types/admin/assessments'

import { getCurrentlyOnMedicaidPlan } from '../assessments'
import { getDateString } from '../dates'
import { formatPhoneNumber, getFieldFromPath } from '../fields'
import { getShortcutValue } from './shortcuts'
import { getFieldValue } from './utils'

export function getTemplateValue(node: VariableElement, shortcutArgs: ShortcutArgs) {
  const { path, dataType } = node ?? {}
  const { user, assessment, plan } = shortcutArgs
  if (!path) return undefined
  if (path === 'patient.fname' || path === 'fname') {
    return user?.fname
  }
  if (path === 'patient.lname' || path === 'lname') {
    return user?.lname
  }
  if (path === 'patient.dob' || path === 'dob') {
    return user?.dob ? getDateString(user.dob, 'short') : undefined
  }
  if (path === 'patient.phone' || path === 'phone') {
    return user?.phone ? formatPhoneNumber(user.phone) : undefined
  }
  if (node.shortcut) {
    return getShortcutValue(node, shortcutArgs)
  }
  if (node.path.startsWith('delivery.midwife')) {
    const subPath = node.path.split('.').slice(2).join('.')
    const field = getFieldFromPath(baseMidwifeFields, subPath) ?? {
      type: dataType,
      placeholder: '',
    }
    return getFieldValue(field as Field, subPath, assessment?.midwife)
  }
  if (node.path.startsWith('insurance-info.primaryCoverage')) {
    const subPath = node.path.split('.').slice(2).join('.')
    const field = getFieldFromPath(
      getInsuranceCoverageField(
        getCurrentlyOnMedicaidPlan(shortcutArgs.assessment?.mergedData),
        true,
        true,
      ),
      subPath,
    ) ?? { type: dataType, placeholder: '' }
    return getFieldValue(field as Field, subPath, plan ?? {})
  }
  const field = getAssessmentFieldFromPath(node.path) ?? {
    type: dataType,
    placeholder: '',
  }
  return getFieldValue(field as Field, node.path, assessment?.mergedData)

  // return undefined
}
