import {
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  adminRoles,
  colors,
  getPracticeUsersCollectionPath,
  objectToArray,
  PRACTICE_ACCESS,
  PracticeAccess,
  PracticeInvoice,
  PracticeUserRoleItem,
  WithId,
} from '@hb/shared'
import { collection, Query, query, where } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db, sendInvoice } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { useDocument, useQuery } from '../../../hooks'
import { PracticeAccessView, PracticeUserBadge } from '../../Practices'

export const ConfirmSendInvoiceAlert = ({
  isOpen,
  practiceId,
  invoiceId,
  onClose,
}: {
  isOpen: boolean
  practiceId: string
  invoiceId: string
  onClose: () => void
}) => {
  const adminsQuery = useMemo(
    () =>
      query(
        collection(db, getPracticeUsersCollectionPath(practiceId)),
        where('role', 'in', adminRoles),
      ) as Query<PracticeUserRoleItem>,
    [practiceId],
  )
  const { data: practiceAdmins } = useQuery(adminsQuery)
  const { data: practiceAccess } = useDocument<PracticeAccess>(PRACTICE_ACCESS, practiceId, isOpen)

  const [sendTo, setSendTo] = useState(practiceAccess?.sendInvoicesTo ?? null)

  const { showError, showSuccess } = useContext(PopUpMessageContext)

  const [isSending, setIsSending] = useState(false)
  const onSendInvoice = useCallback(async () => {
    try {
      setIsSending(true)
      await sendInvoice({ invoiceId, sendTo })
      showSuccess('Invoice sent successfully')
      setIsSending(false)
      onClose()
    } catch (err: any) {
      showError(err?.message ?? 'Error sending invoice')
      setIsSending(false)
    }
  }, [showError, showSuccess, onClose, invoiceId, sendTo])
  const usersArr = useMemo(() => objectToArray(practiceAdmins ?? {}), [practiceAdmins])
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      trapFocus={false}
      portalProps={{ appendToParentPortal: true }}
      isCentered
      motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="xl" fontFamily="Comfortaa" pt={2}>
            Send Invoice
          </Text>
          {practiceAccess?.users ? (
            <>
              <Text>This invoice will be sent to:</Text>
              <VStack spacing={1}>
                {usersArr.length ? (
                  usersArr.map((user, i) => (
                    <PracticeUserBadge
                      index={i}
                      selected={sendTo === user.id}
                      select={() => setSendTo(user.id)}
                      key={user.id}
                      user={user}
                    />
                  ))
                ) : (
                  <Text color="gray.600" fontStyle="italic" p={2}>
                    No users added to practice access
                  </Text>
                )}
                <Button
                  onClick={() => setSendTo(null)}
                  color="white"
                  bg={sendTo === null ? colors.green.hex : 'blackAlpha.500'}
                  _hover={{ bg: 'green.300' }}
                  w="100%">
                  Do not send notification email
                </Button>
              </VStack>
            </>
          ) : (
            <VStack w="100%" align="flex-start">
              <Text>Add a practice admin to send this invoice</Text>
              <PracticeAccessView practiceId={practiceId} />
            </VStack>
          )}
          <HStack my={2} justify="flex-end" w="100%">
            <Button mr={2} size="sm" onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <Button
              size="sm"
              isLoading={isSending}
              color="white"
              onClick={onSendInvoice}
              bg="green.400">
              <Text>Send Invoice</Text>
              <Image ml={2} filter="invert(100%)" width="19px" src="/images/svg/send.svg" />
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const SendInvoiceButton = ({ invoice }: { invoice: WithId<PracticeInvoice> }) => {
  const [confirmSend, setConfirmSend] = useState(false)
  return (
    <>
      <Button
        onClick={() => setConfirmSend(true)}
        ml="auto"
        borderRadius="full"
        bg={colors.green.hex}
        size="sm">
        <Text fontFamily="Hero-New" color="white" fontSize="sm">
          Send Invoice
        </Text>
        <Image ml={2} filter="invert(100%)" width="19px" src="/images/svg/send.svg" />
      </Button>
      <ConfirmSendInvoiceAlert
        isOpen={confirmSend}
        invoiceId={invoice.id}
        practiceId={invoice.practiceId}
        onClose={() => {
          setConfirmSend(false)
        }}
      />
    </>
  )
}
