import { useMemo } from 'react'

export const getSelectedItem = <T extends { id: string }>(
  items?: T[] | null,
  id?: string | null,
) => (id ? (items?.find(item => item.id === id) ?? null) : null)

export function useSelectedItem<T extends { id: string }>(
  items?: T[] | null,
  id?: string | null,
): T | null {
  return useMemo(() => getSelectedItem<T>(items, id), [id, items])
}
