import { InfoIcon } from '@chakra-ui/icons'
import { Box, BoxProps, IconButton, IconButtonProps } from '@chakra-ui/react'
import { WithId } from '@hb/shared'
import React from 'react'
import { CopyTooltip } from './CopyTooltip'

const CopyIdBody = ({
  item,
  buttonProps,
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
}) => (
  <CopyTooltip
    placement="left"
    bg="gray.600"
    prefix="ID:"
    label={item ? item.id : 'nothing to copy'}>
    <IconButton
      size="sm"
      w={6}
      h={6}
      minW={0}
      borderRadius="full"
      variant="ghost"
      aria-label="ID"
      icon={<InfoIcon color="gray.500" />}
      {...buttonProps}
    />
  </CopyTooltip>
)

export const CopyId = ({
  item,
  buttonProps,
  ...props
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
} & BoxProps) => (
  <Box position="absolute" bottom="0.25rem" right="0.25rem" {...props}>
    <CopyIdBody item={item} buttonProps={buttonProps} />
  </Box>
)

export const RelativeCopyId = ({
  item,
  buttonProps,
  ...props
}: {
  item: WithId | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
} & BoxProps) => (
  <Box position="relative" {...props}>
    <CopyIdBody item={item} buttonProps={buttonProps} />
  </Box>
)
