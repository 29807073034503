import { Box, BoxProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { forwardRef } from 'react'

const MBox = motion.create(Box as any)
export const GrowFadeBox = forwardRef<HTMLElement, BoxProps>((props, ref) => (
  <MBox
    ref={ref}
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    {...props}
  />
))
