export function getScrollableParent(element: HTMLElement | null): HTMLElement | null {
  let curr = element
  while (curr) {
    const style = window.getComputedStyle(curr)
    const { overflowY } = style
    const isScrollableY = overflowY === 'auto' || overflowY === 'scroll' || overflowY === 'hidden'

    if (isScrollableY && curr.scrollHeight > curr.clientHeight) {
      return curr
    }

    curr = curr.parentElement
  }

  return document.body
}
