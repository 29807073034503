import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { flattenBilledCharges, formatDollarValue, NewClaimService } from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { ActionButton } from '../../../../../Buttons/ActionButton'
import { DeleteButton } from '../../../../../Buttons/DeleteButton'
import { Expandable } from '../../../../../Expandable'
import { Condition } from '../../../../../forms/FinalForm/Condition'
import { FormElement } from '../../../../../forms/Input'

import {
  customChargeField,
  serviceField,
  shouldUseCustomCharge as shouldUseCustomChargeField,
} from '../fields'
import { DiagnosisPointersInput } from './DiagnosisPointerInput'
import { HCPCField } from './HCPCField'

const customChargeCondition = (v: any) => !!v?.shouldUseCustomCharge
const ServiceCharge = ({ name }: { name: string }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { midwife } = selectedAssessment ?? {}
  const { billedCharges } = midwife ?? {}

  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const { HCPC, Units, shouldUseCustomCharge, customCharge } = value ?? {}
  const providerCharge = useMemo<number | undefined>(
    () => billedCharges?.[`_${HCPC}`],
    [billedCharges, HCPC],
  )
  const serviceChargeError = useMemo(() => {
    if (!midwife) return 'No midwife assigned for this assessment'
    if (!HCPC) return 'No CPT code selected'
    if (!providerCharge) return `${midwife.name} has no charge for ${HCPC}`
    return null
  }, [midwife, providerCharge, HCPC])

  const totalChargeString = useMemo(() => {
    const perUnitString = shouldUseCustomCharge
      ? `Custom charge for ${HCPC}: ${formatDollarValue(customCharge)}`
      : `Provider charge for ${HCPC}: ${formatDollarValue(providerCharge)}`
    const usedCharge = shouldUseCustomCharge ? customCharge : providerCharge
    if (!usedCharge) return perUnitString
    if (!Units || Number.isNaN(Units)) return ''
    return `${perUnitString} * ${Units} Units = ${formatDollarValue(usedCharge * Units)}`
  }, [providerCharge, Units, HCPC, customCharge, shouldUseCustomCharge])
  return (
    <VStack px={2} pb={2} spacing={0} align="flex-start" w="100%">
      <Text fontSize="sm" fontWeight={600} color="gray.600">
        Charge
      </Text>
      <FormElement name={`${name}.shouldUseCustomCharge`} field={shouldUseCustomChargeField} />
      <Condition basePath={name} condition={v => !customChargeCondition(v)}>
        {serviceChargeError ? (
          <Text color="orange.600">{serviceChargeError}</Text>
        ) : (
          <Flex gap={2} align="center">
            <Text fontSize="sm" fontWeight={600} color="gray.600">
              Practice charge for {HCPC}:
            </Text>
            <Text color="gray.600">{formatDollarValue(providerCharge)}</Text>
          </Flex>
        )}
      </Condition>
      <Condition basePath={name} condition={customChargeCondition}>
        <FormElement name={`${name}.customCharge`} field={customChargeField} />
      </Condition>
      <Text>{totalChargeString}</Text>
    </VStack>
  )
}

const ServiceInput = ({
  name,
  index,
  onDelete,
}: {
  name: string
  index: number
  onDelete: () => void
}) => (
  <Expandable
    initExpanded
    border="1px solid #cdcdcd"
    borderRadius={4}
    boxShadow="1px 1px 4px 0 rgba(0,0,0,0.4)"
    spacing={0}
    align="flex-start"
    w="100%"
    header={() => (
      <HStack py={2} px={2} justify="space-between" align="center" w="100%">
        <Text fontFamily="Comfortaa" color="gray.600">
          Service {index + 1}
        </Text>
        <DeleteButton noConfirm onDelete={onDelete} itemName="Service" />
      </HStack>
    )}>
    <VStack py={3} px={2} w="100%" spacing={0}>
      <HCPCField name={`${name}.HCPC`} />
      <FormElement active field={serviceField} name={name} />
      <ServiceCharge name={name} />
      <DiagnosisPointersInput name={name} />
    </VStack>
  </Expandable>
)

export const Services = () => {
  const { fields } = useFieldArray<Partial<NewClaimService>>('Services', {
    subscription: {
      value: true,
    },
  })
  const { value } = fields ?? {}

  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { midwife } = selectedAssessment ?? {}
  const { billedCharges } = midwife ?? {}
  const flattenedBilledCharges = useMemo(() => flattenBilledCharges(billedCharges), [billedCharges])

  const totalCharge = useMemo(
    () =>
      ((value && Array.isArray(value) ? value : []) as Array<NewClaimService>).reduce(
        (acc, { HCPC, Units, shouldUseCustomCharge, customCharge }) => {
          const providerCharge = flattenedBilledCharges[`_${HCPC}`]
          if (!Units) return acc
          const charge = shouldUseCustomCharge ? customCharge : providerCharge
          if (typeof charge !== 'number' || Number.isNaN(charge)) return acc
          return acc + charge * Units
        },
        0,
      ),
    [value, flattenedBilledCharges],
  )
  return (
    <VStack px={3} pb={3} align="flex-start" w="100%">
      <Text fontWeight={600} color="gray.500" fontSize="md">
        {fields.length ?? 0} Service{fields.length === 1 ? '' : 's'} | Total Charge:{' '}
        {formatDollarValue(totalCharge)}
      </Text>
      {fields.map((name, idx) => (
        <ServiceInput index={idx} onDelete={() => fields.remove(idx)} key={name} name={name} />
      ))}
      <ActionButton size="xs" onClick={() => fields.push({})}>
        + Add Service
      </ActionButton>
    </VStack>
  )
}
