import { ArrowDownIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  FieldTypes,
  fnameField,
  getMidwifeFullName,
  lnameField,
  midwivesCollection,
  PracticeWithAdmin,
  UpdateCallback,
} from '@hb/shared'
import {
  AddItemButton,
  DefaultModal,
  deleteItem,
  GenericEditModal,
  PageContainer,
  reorderCollection,
  useCollection,
  useUpdateCollection,
} from '@hb/shared-frontend'
import { CollectionList } from '@hb/shared-frontend/components/EditCollection/EditCollection'
import { MidwifeInfo } from '@hb/shared-frontend/components/Practices/MidwifeInfo'
import { DropResult } from '@hello-pangea/dnd'
import React, { useCallback, useMemo, useState } from 'react'

const newProviderField: FieldMap = {
  name: 'New Provider',
  children: {
    // email: emailField,
    fname: { ...fnameField, placeholder: 'Referring provider first name' },
    lname: { ...lnameField, placeholder: 'Referring provider last name' },
    name: {
      type: FieldTypes.TEXT,
      placeholder: 'Practice name',
    },
  },
}

export const AdminProvidersView: React.FC = () => {
  const practices = useCollection(midwivesCollection)
  const { loading, items: midwives } = practices ?? {}
  const [selectedMidwifeId, setSelectedMidwifeId] = useState<string | undefined>()
  const [reorderLoading, setReorderLoading] = useState(false)
  const newItemModal = useDisclosure()
  const onReorder = useCallback(async (r: DropResult) => {
    if (r.source.index === r.destination?.index) return { success: 'no need' }
    setReorderLoading(true)
    try {
      await reorderCollection(midwivesCollection, r)
      setReorderLoading(false)
      return { success: 'Reordered' }
    } catch (err: any) {
      setReorderLoading(false)
      return { error: `Error reordering: ${err?.message}` }
    }
  }, [])
  const [sortByName, setSortByName] = useState(false)

  const sorted = useMemo(
    () =>
      [...(midwives && Array.isArray(midwives) ? midwives : [])].sort((a, b) =>
        a.name < b.name ? -1 : 1,
      ),
    [midwives],
  )

  const { addItem } = useUpdateCollection(midwivesCollection)

  const handleAddProvider = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> =>
      addItem(data).then(res => {
        newItemModal.onClose()
        return res
      }),
    [newItemModal, addItem],
  )
  return (
    <PageContainer>
      <VStack spacing={0} align="flex-start" bg="white" overflowY="auto" height="100%" width="100%">
        <Flex
          align="center"
          py={2}
          px={4}
          position="sticky"
          zIndex={2}
          top="0"
          fontWeight={600}
          bg="gray.50"
          width="full"
          borderBottom="1px solid #cdcdcd">
          <Text color="blackAlpha.600" fontSize="lg" fontFamily="Comfortaa">
            Midwives
          </Text>
          <Button
            onClick={() => {
              setSortByName(!sortByName)
            }}
            minW="0"
            minH="0"
            fontWeight={400}
            h="auto"
            p={3}
            ml={2}
            lineHeight="16px"
            variant="ghost"
            color={sortByName ? 'green.500' : 'gray.500'}
            fontSize="xs">
            A - Z <ArrowDownIcon ml={2} fontSize="sm" />
          </Button>

          {reorderLoading ? <CircularProgress color="green" size={6} isIndeterminate /> : null}
          <Tooltip placement="top" label="Add Midwife">
            <AddItemButton itemName="midwife" ml="auto" onClick={newItemModal.onOpen} />
          </Tooltip>
        </Flex>

        <Box w="100%" bg="gray.100" flex={1} pos="relative">
          {midwives ? (
            <CollectionList<PracticeWithAdmin>
              onEditClick={(id: string) => {
                setSelectedMidwifeId(id)
              }}
              onDeleteItem={item => deleteItem(midwivesCollection, item.id)}
              collection={midwivesCollection}
              itemName="Provider"
              renderPreview={(item, onClick) => (
                <HStack bg="white" w="100%">
                  <Button
                    maxW="90%"
                    justifyContent="flex-start"
                    fontFamily="Comfortaa"
                    color="gray.600"
                    variant="link"
                    onClick={onClick}>
                    <Text isTruncated>{getMidwifeFullName(item)}</Text>
                  </Button>
                  {item.isInactive ? (
                    <span>
                      <Tooltip label="Inactive Practices will not show on the patient side of the site, and will not generate clearing house fee invoices">
                        <Badge marginLeft="0.5rem" colorScheme="orange">
                          INACTIVE
                        </Badge>
                      </Tooltip>
                    </span>
                  ) : null}
                </HStack>
              )}
              reorder={!sortByName ? onReorder : undefined}
              items={sortByName ? sorted : midwives}
            />
          ) : (
            <Flex p={4}>
              {loading ? (
                <Flex>
                  <Spinner mr={3} /> Loading...
                </Flex>
              ) : (
                'No Midwives'
              )}
            </Flex>
          )}
        </Box>
        <GenericEditModal
          field={newProviderField}
          isOpen={newItemModal.isOpen}
          onClose={newItemModal.onClose}
          onSubmit={handleAddProvider}
        />
        <DefaultModal
          isOpen={!!selectedMidwifeId}
          size="4xl"
          onClose={() => {
            setSelectedMidwifeId(undefined)
          }}
          overlayHeader
          render={() => (
            <Box p={0}>
              <MidwifeInfo id={selectedMidwifeId} />
            </Box>
          )}
        />
      </VStack>
    </PageContainer>
  )
}
