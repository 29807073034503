import { Box, Center, Flex, Text } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useProfile } from '../../../../../../contexts'
import { useResizeObserver } from '../../../../../../hooks/useResizeObserver'
import { AssessmentPreview } from '../../../../../Assessments'
import { AssessmentNote } from '../../../../../Assessments/AssessmentNote'
import { Loading } from '../../../../../Loading'
import { AssessmentAuthorizations } from '../../../Authorizations/AssessmentAuthorizations'
import { AssessmentFiles } from '../../../Files/AssessmentFiles'
import { AssessmentPayments } from '../../../Payments'
import { CardsView } from '../Containers/CardsView'
import { PreviewBox } from '../Containers/PreviewBox'

export const PregnancyControlPanel = () => {
  const { selectedAssessment, user, assessmentId, goTo: onTabSelect } = useProfile()
  const bodyRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(bodyRef, 'content')
  if (!assessmentId) {
    return (
      <Center w="100%">
        <Text fontSize="lg">No pregnancy selected</Text>
      </Center>
    )
  }
  return (
    <Flex ref={bodyRef} w="100%" h="100%" flexFlow="column">
      <CardsView
        width={width}
        elements={[
          <PreviewBox maxH={400} title="Assessment">
            {user && assessmentId ? (
              <AssessmentPreview
                id={assessmentId}
                onSelect={() =>
                  onTabSelect({
                    entity: { type: 'pregnancy', id: assessmentId },
                    tab: 'assessment',
                  })
                }
                user={user}
                assessment={selectedAssessment.assessmentData}
              />
            ) : (
              <Loading />
            )}
          </PreviewBox>,
          <PreviewBox
            onGo={() =>
              onTabSelect({
                entity: { type: 'pregnancy', id: assessmentId },
                tab: 'payments',
              })
            }
            title="Payments">
            <AssessmentPayments preview />
          </PreviewBox>,
          <PreviewBox
            onGo={() =>
              onTabSelect({
                entity: { type: 'pregnancy', id: assessmentId },
                tab: 'authorizations',
              })
            }
            title="Authorizations">
            <Box w="100%" p={2}>
              <AssessmentAuthorizations />
            </Box>
          </PreviewBox>,
          <PreviewBox
            onGo={() =>
              onTabSelect({
                entity: { type: 'pregnancy', id: assessmentId },
                tab: 'files',
              })
            }
            title="Files">
            <AssessmentFiles preview />
          </PreviewBox>,
          <></>,
          <PreviewBox title="Shared Note">
            <AssessmentNote />
          </PreviewBox>,
        ]}
      />
    </Flex>
  )
}
