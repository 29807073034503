import { EmailIcon } from '@chakra-ui/icons'
import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { colors, getDateTimeString } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ProfileContext } from '../../../../contexts'

export const SignOnRemindersView = () => {
  const { selectedAssessment } = useContext(ProfileContext)
  const { snippetData } = selectedAssessment ?? {}
  const { signOnReminders } = snippetData ?? {}
  const { text, bg } = useMemo(() => {
    const hasSignOnReminders = !!(signOnReminders?.length ?? 0)
    const bg = hasSignOnReminders ? colors.green.hex : 'gray.400'
    const text = hasSignOnReminders
      ? `Sign-on reminders sent:\n${(signOnReminders && Array.isArray(signOnReminders)
          ? signOnReminders
          : []
        )
          .map(reminder => getDateTimeString(reminder.sentOn, 'long'))
          .join('\n')}`
      : 'No sign-on reminders sent'
    return { text, bg }
  }, [signOnReminders])
  return (
    <Tooltip
      boxShadow="1px 1px 4px #00000088"
      whiteSpace="pre-wrap"
      hasArrow
      textShadow="1px 1px 2px #00000088"
      label={text}
      placement="top-end"
      bg={bg}>
      <Flex
        boxShadow="1px 1px 4px #00000055"
        px={2}
        py={1.5}
        cursor="pointer"
        borderRadius={3}
        color="white"
        bg={bg}
        align="center">
        <EmailIcon filter="drop-shadow(1px 1px 3px #00000088)" />
        <Text
          lineHeight={1}
          fontWeight={600}
          ml={1.5}
          fontSize="sm"
          textShadow="1px 1px 3px #00000088">
          {signOnReminders?.length ?? 0}
        </Text>
      </Flex>
    </Tooltip>
  )
}
