import {
  backupDeliveryPlanField,
  currentPregnancyField,
  deliveryPlanDetailsField,
  deliveryPlanField,
  existingVisitEndTimeField,
  existingVisitStartTimeField,
  FieldMap,
  gravidaField,
  historyDetailsField,
  historyReviewField,
  keywordsField,
  newVisitDateField,
  newVisitEndTimeField,
  newVisitStartTimeField,
  notesField,
  paraField,
  riskAssessmentField,
  visitHistoryField,
  visitHospitalField,
} from '@hb/shared'

export const newVisitField: FieldMap = {
  name: 'Visit',
  children: {
    date: newVisitDateField,
    startTime: newVisitStartTimeField,
    endTime: newVisitEndTimeField,
    gravida: gravidaField,
    para: paraField,
    currentPregnancy: currentPregnancyField,
    historyReview: historyReviewField,
    history: visitHistoryField,
    historyDetails: historyDetailsField,
    deliveryPlan: deliveryPlanField,
    backupDeliveryPlan: backupDeliveryPlanField,
    hospital: visitHospitalField,
    deliveryPlanDetails: deliveryPlanDetailsField,
    // hospitalDetails: hospitalDetailsField,
    riskAssessment: riskAssessmentField,
    allergyNotes: { ...notesField, placeholder: 'Allergy Notes' },
    notes: notesField,
    keywords: keywordsField,
  },
}

export const existingVisitField: FieldMap = {
  name: 'Visit',
  children: {
    startTime: existingVisitStartTimeField,
    endTime: existingVisitEndTimeField,
    gravida: gravidaField,
    para: paraField,
    currentPregnancy: currentPregnancyField,
    historyReview: historyReviewField,
    history: visitHistoryField,
    historyDetails: historyDetailsField,
    deliveryPlan: deliveryPlanField,
    backupDeliveryPlan: backupDeliveryPlanField,
    hospital: visitHospitalField,
    deliveryPlanDetails: deliveryPlanDetailsField,
    // hospitalDetails: hospitalDetailsField,
    riskAssessment: riskAssessmentField,
    allergyNotes: { ...notesField, placeholder: 'Allergy Notes' },
    notes: notesField,
    keywords: keywordsField,
  },
} as const
