import { EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Img, Text } from '@chakra-ui/react'
import { getDateString } from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { useUpdateDoc } from '../../../../../../hooks/backend/useUpdateDoc'
import personIcon from '../../../../../../icons/person_fill.svg'
import { NameEditModal } from '../../../Patient/NameEditModal'
import { NicknameView } from '../../../Patient/NicknameView'
import { AlternatePronounsView } from '../../../Patient/PronounsView'
import { PatientInfoContact } from './PatientContact'

const DOBView = ({ dob }: { dob: number | null | undefined }) => (
  <Flex gap={2} align="center">
    <Text lineHeight={1} fontWeight={500} color="gray.600">
      DOB:
    </Text>
    <Text fontFamily="Hero-New" lineHeight={1} opacity={0.8} fontWeight={500}>
      {dob ? getDateString(dob, 'short') : 'None'}
    </Text>
  </Flex>
)

export const PatientName = () => {
  const { user, patientRef } = useContext(ProfileContext)
  const { fname, lname, middleName, dob } = user ?? {}
  const name = useMemo(
    () =>
      `${fname ?? '[Missing first name]'}${middleName ? ` ${middleName}` : ''} ${lname ?? '[Missing Last Name]'}`,
    [fname, lname, middleName],
  )

  const update = useUpdateDoc()
  const [nameModalOpen, setNameModalOpen] = useState(false)
  return (
    <Flex
      borderRadius={4}
      // border="1px solid #cdcdcd"
      // bg="whiteAlpha.500"
      gap={1}
      w="100%"
      position="relative"
      px={2}
      pt={2}
      pb={1}
      flexFlow="column">
      <Flex align="center" w="100%">
        <Img src={personIcon} w="18px" opacity={0.8} mr={1} />
        <Text lineHeight={1} fontSize="md" fontWeight={500} opacity={0.8}>
          Patient
        </Text>
      </Flex>
      <Flex gap={1} pb={1} px={0.5} flexFlow="column">
        <Text fontSize="lg" fontFamily="Hero-New" lineHeight={1} fontWeight={500} color="gray.700">
          {name ?? 'No name entered'}
        </Text>
        <DOBView dob={dob} />
        <NicknameView />
        <AlternatePronounsView />
      </Flex>
      <IconButton
        position="absolute"
        borderRadius="full"
        w={7}
        h={7}
        right={1}
        top={1}
        variant="ghost"
        _hover={{ bg: 'blackAlpha.100' }}
        ml="auto"
        size="sm"
        aria-label="Edit"
        icon={<EditIcon />}
        onClick={() => setNameModalOpen(true)}
      />
      <PatientInfoContact />
      <NameEditModal
        userType="patient"
        value={user}
        isOpen={nameModalOpen}
        onClose={() => setNameModalOpen(false)}
        onSubmit={v => {
          return update(patientRef, '', v)
        }}
      />
    </Flex>
  )
}
