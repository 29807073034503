import { AnyObject } from '../types/utils/common'
import { Collection, CombinedCollectionEntry, FirestoreCollection } from './types'

export const getBaseCollection = <Data extends AnyObject = AnyObject>(
  collection: Collection<Data> | CombinedCollectionEntry<Data>,
): FirestoreCollection<Data> => {
  let curr: Collection<Data> | CombinedCollectionEntry<Data> = collection

  while (curr._type !== 'firestoreCollection') {
    switch (curr._type) {
      case 'derivedCollection':
        curr = curr.baseCollection
        break
      case 'combinedCollection':
        curr = curr.index as Collection<Data> | CombinedCollectionEntry<Data>
        break
      default:
    }
  }
  return curr as FirestoreCollection<Data>
}

function checksum(s: string) {
  let chk = 0x12345678
  const len = s.length
  for (let i = 0; i < len; i += 1) {
    chk += s.charCodeAt(i) * (i + 1)
  }

  return (chk & 0xffffffff).toString(16)
}
export const getCollectionId = <Data extends AnyObject>(
  collection: Collection<Data> | CombinedCollectionEntry<Data>,
) => {
  if (!collection) console.trace({ collection })
  return checksum(JSON.stringify(collection))
  // switch (collection._type) {
  //   case 'firestoreCollection':
  //     return getFirestoreCollectionId(collection)
  //   case 'derivedCollection':
  //     return getDerivedCollectionId(collection)
  //   case 'combinedCollection':
  //     return getCombinedCollectionId(collection)
  //   default:
  //     return ''
  // }
}
