import { CustomElement } from '@hb/shared'
import { MouseEvent, PointerEvent, useCallback, useEffect, useMemo } from 'react'
import { Editor, Path, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { CustomEditor } from '../../types/editor'

export const useNonEditable = (
  editor: CustomEditor,
  node: CustomElement,
  mode: 'View' | 'Edit',
) => {
  const { selection } = editor
  const { isSelected, nodePath } = useMemo(() => {
    const nPath = ReactEditor.findPath(editor, node)
    if (selection) {
      return {
        isSelected: Path.isParent(nPath, editor.path(selection)),
        nodePath: nPath,
      }
    }
    return { isSelected: false, nodePath: nPath }
  }, [selection, editor, node])

  const onDelete = useCallback(() => {
    Transforms.removeNodes(editor, { at: nodePath })
  }, [editor, nodePath])
  useEffect(() => {
    if (!isSelected || mode !== 'Edit') return () => {}
    const onKeyPress = (event: KeyboardEvent) => {
      event.stopPropagation()
      // select next location
      if (event.key === 'ArrowLeft') {
        const before = Editor.before(editor, nodePath)
        if (before) editor.select(before)
      } else if (event.key === 'Delete' || event.key === 'Backspace') {
        onDelete()
      } else {
        const after = Editor.after(editor, nodePath)
        if (after) editor.select(after)
        else {
          const afterPath = Path.next(nodePath)
          editor.insertNode(
            {
              type: 'span',
              children: [{ text: '' }],
            },
            { at: afterPath, select: true },
          )
          editor.select(afterPath)
        }
      }
    }

    window.addEventListener('keydown', onKeyPress)
    return () => {
      window.removeEventListener('keydown', onKeyPress)
    }
  }, [mode, isSelected, editor, nodePath, onDelete])

  const onPointerDown = useCallback(
    (event: PointerEvent) => {
      event.preventDefault()
      if (editor.hasPath(nodePath)) {
        Transforms.select(editor, nodePath)
      }
    },
    [editor, nodePath],
  )

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (editor.hasPath(nodePath)) {
        Transforms.select(editor, nodePath)
      }
    },
    [editor, nodePath],
  )

  return useMemo(
    () => ({
      onPointerDown,
      isSelected,
      onDelete,
      onClick,
    }),
    [onPointerDown, isSelected, onDelete, onClick],
  )
}
