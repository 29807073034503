import { CircularProgress, HStack, Text } from '@chakra-ui/react'
import { colors, ThreadType } from '@hb/shared'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../../contexts'
import { ThreadPopover } from '../../../Messaging/ThreadPopover'

export const AssessmentThreadView = () => {
  const { assessmentId } = useContext(ProfileContext)
  return assessmentId ? (
    <ThreadPopover id={assessmentId} threadType={ThreadType.ASSESSMENT} />
  ) : (
    <HStack>
      <CircularProgress color={colors.green.hex} isIndeterminate size={4} />
      <Text fontSize="sm" color="gray.500">
        Loading assessment thread...
      </Text>
    </HStack>
  )
}
