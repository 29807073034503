import { Flex, Text } from '@chakra-ui/react'
import { FieldMapValue, isInfoStage, OnUploadProgress } from '@hb/shared'
import { FORM_ERROR, FormApi } from 'final-form'
import React, { useCallback, useContext, useMemo } from 'react'
import { SolidActionButton } from '../../Buttons/ActionButton'
import { GenericEditModal } from '../../DataView/GenericEditModal'
import { DBTextViewModal } from '../DBTextStage'
import { FormWizardContext } from './context'
import { useStageState } from './hooks'

const DefaultReadOnlyFooter = () => (
  <Flex bg="blackAlpha.100" py={3} px={6}>
    <Text>This information cannot be edited after submission</Text>
  </Flex>
)

export const StageModal = () => {
  const {
    close,
    stageId,
    stage,
    onSubmitStage,
    data: formData,
    corrections: formCorrections,
    form,
    storagePath,
    nextStage,
    adminView,
    ReadOnlyFooter,
    readOnly,
  } = useContext(FormWizardContext)

  const { value: data, corrections } = useStageState(
    form,
    stage,
    formData,
    formCorrections,
    stageId,
  )

  const handleSubmit = useCallback(
    async (submitted: FieldMapValue, onUploadProgress: OnUploadProgress, formApi: FormApi) => {
      try {
        const res = await onSubmitStage(submitted, onUploadProgress, formApi)
        if (res?.error) return { error: res.error }
        if (res?.[FORM_ERROR]) return { error: res[FORM_ERROR] }
      } catch (e: any) {
        console.error(e)
        return { error: e?.message ?? 'An error occurred' }
      }
      return { success: 'Saved successfully' }
    },
    [onSubmitStage],
  )
  const ReadOnlyFooterComponent = ReadOnlyFooter ?? DefaultReadOnlyFooter
  const infoStage = useMemo(() => stage && isInfoStage(stage), [stage])
  return infoStage ? (
    <DBTextViewModal
      isCentered
      isOpen={!!stage}
      onClose={close}
      value={stage && isInfoStage(stage) ? stage.data : []}
      footerProps={{ p: 2, bg: '#eeeeee', borderTop: '1px solid #cdcdcd' }}
      renderFooter={() => (
        <Flex>
          <SolidActionButton onClick={() => onSubmitStage(undefined, () => {})}>
            Next
          </SolidActionButton>
        </Flex>
      )}
    />
  ) : (
    <GenericEditModal
      isOpen={!!stage}
      closeOnSubmit={false}
      canSubmitClean
      readOnly={readOnly}
      baseStoragePath={storagePath}
      adminView={adminView}
      corrections={corrections}
      footerProps={{ p: 0, borderTop: '1px solid #cdcdcd' }}
      renderFooter={readOnly ? () => <ReadOnlyFooterComponent /> : undefined}
      onClose={close}
      onSubmit={handleSubmit}
      field={stage && !isInfoStage(stage) ? stage : undefined}
      data={data}
      submitText={!nextStage ? 'Finish' : 'Next'}
    />
  )
}
