import { CheckIcon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { FieldTypes, getFullName, TextField } from '@hb/shared'
import { updateDoc } from 'firebase/firestore'

import React, { useCallback, useContext, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { ProfileContext } from '../../../contexts/ProfileContext'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { DeleteButton } from '../../Buttons/DeleteButton'
import { ColorSelect } from '../../ColorSelect'
import Input from '../../forms/Input'
import { SortColorCircle } from '../UrgentBadge'

export const SortColorPopoverContent = ({
  title,
  label = 'Sort Color',
  onSubmit,
  color,
  notes,
  notesLabel,
}: {
  title: string
  onSubmit: (v: Partial<{ color: string; notes: string }>) => Promise<void>
  color?: string | null
  label?: string
  notes?: string
  notesLabel?: string
}) => {
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) ?? {}
  const onSubmitNotes = useCallback(
    (updated: string | null) =>
      onSubmit({
        notes: updated ?? '',
      })
        .then(() => ({ success: `Updated ${label.toLowerCase()}` }))
        .catch(() => ({ error: 'Failed' })),
    [onSubmit, label],
  )
  return (
    <ThemeContext.Provider value={{ theme: 'basic', placeholderAbove: true }}>
      <PopoverHeader fontSize="sm" fontWeight={600}>
        <Flex align="center">
          <ColorSelect
            style={{ zIndex: 2, borderRadius: '100%' }}
            withAdmins
            setColor={v =>
              onSubmit({
                color: v ?? undefined,
                notes: notes ?? '',
              })
                .then(() => ({ success: 'Updated' }))
                .catch(() => ({ error: 'Failed' }))
            }
            color={color}
          />
          <Text color="gray.600" fontFamily="Comfortaa" ml={2}>
            {title ?? ''}
          </Text>
        </Flex>

        <PopoverCloseButton size="sm" bg="blackAlpha.300" color="white" />
      </PopoverHeader>
      {notesLabel ? (
        <PopoverBody p={0}>
          <Form
            onSubmit={({ notes: updated }) => onSubmitNotes(updated)}
            initialValues={{
              notes: notes ?? '',
            }}>
            {({ handleSubmit, submitting }) => (
              <Flex px={2} align="flex-start">
                <Flex py={2} flexGrow={1}>
                  <Field name="notes">
                    {props => (
                      <Input
                        {...props}
                        field={
                          {
                            type: FieldTypes.TEXT,
                            placeholder: notesLabel,
                          } as TextField
                        }
                      />
                    )}
                  </Field>
                </Flex>
                <Flex pt={1} ml="auto">
                  <IconButton
                    variant="ghost"
                    size="xs"
                    aria-label="delete"
                    icon={<CheckIcon />}
                    onClick={handleSubmit}
                    isLoading={submitting}
                  />
                  <DeleteButton itemName="Sort notes" onDelete={() => onSubmitNotes(null)} />
                </Flex>
              </Flex>
            )}
          </Form>
        </PopoverBody>
      ) : null}
    </ThemeContext.Provider>
  )
}

export const ProfileSortColorPopover = () => {
  const { selectedAssessment, user } = useContext(ProfileContext)
  const { populated, practiceRef } = selectedAssessment ?? {}
  const { sortColor } = populated ?? {}
  const fullName = useMemo(() => getFullName(user), [user])
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <SortColorCircle withAdmins color={sortColor} />
      </PopoverTrigger>
      <PopoverContent>
        <SortColorPopoverContent
          title={fullName}
          color={sortColor}
          onSubmit={async args => {
            if (!practiceRef) throw new Error('No practiceRef')
            await updateDoc(practiceRef, { sortColor: args.color ?? null })
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
