import { FieldTypes } from '../../constants/FieldTypes'
import { A4_DIMS_PDF_UNITS } from '../../editor/constants'
import {
  ExternalPdfFieldType,
  InlineFilePlacement,
  TemplateAttachment,
} from '../../types/templates'

const defaultFieldSize: Record<ExternalPdfFieldType, [number, number]> = {
  [FieldTypes.TEXT]: [100, 32],
  [FieldTypes.DATE]: [193, 30],
  [FieldTypes.SIGNATURE]: [240, 80],
  [FieldTypes.INITIALS]: [58, 24],
}

const defaultValueSize: Record<ExternalPdfFieldType, [number, number]> = {
  [FieldTypes.TEXT]: [100, 32],
  [FieldTypes.DATE]: [95, 30],
  [FieldTypes.SIGNATURE]: [160, 40],
  [FieldTypes.INITIALS]: [44, 22],
}

export const getInlineFieldSize = (attachment: TemplateAttachment<InlineFilePlacement>) => {
  switch (attachment.type) {
    case 'input':
      return defaultFieldSize[attachment.field.type as ExternalPdfFieldType]
    case 'file':
      return A4_DIMS_PDF_UNITS
  }
}

export const getInlineValueSize = (attachment: TemplateAttachment<InlineFilePlacement>) => {
  switch (attachment.type) {
    case 'input':
      return defaultValueSize[attachment.field.type as ExternalPdfFieldType]
    case 'file':
      return A4_DIMS_PDF_UNITS
  }
}
