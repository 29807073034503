import {
  CheckboxField,
  emailField,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  fnameField,
  formatPhoneNumber,
  getCorrectedValue,
  getDateString,
  insuranceProviderField,
  lnameField,
  makeFieldOptional,
  medicaidInsuranceProviderField,
  objectToArray,
  phoneField,
  TextAreaField,
  UpdateCallback,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo } from 'react'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useUserAssessments } from '../../../hooks'
import { useAppModals, useAuth } from '../../../store'
import { SimpleForm } from '../../forms'
import { DefaultModal } from '../DefaultModal'
import { contactUs } from './contactUs'

const isMedicaidField: CheckboxField = {
  placeholder: 'Plan is Medicaid',
  type: FieldTypes.CHECKBOX,
}

const contactForm: FieldMap = {
  name: 'Contact Us',
  children: {
    fname: fnameField,
    lname: lnameField,
    email: emailField,
    phone: makeFieldOptional(phoneField),
    edd: {
      type: FieldTypes.DATE,
      placeholder: 'Expected Date of Delivery (optional)',
      optional: true,
    },
    // insurer:
    note: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Questions / Comments',
    },
    isMedicaid: isMedicaidField,
    insurer: {
      ...insuranceProviderField,
      optional: true,
      condition: values => !values?.isMedicaid,
    },
    medicaidInsurer: {
      ...medicaidInsuranceProviderField,
      optional: true,
      condition: values => !!values?.isMedicaid,
    },
  },
}

export const ContactFormModal = ({ id = 'root' }: { id?: string }) => {
  const { close, openId, props } = useAppModals(s => s.contactForm)

  const { autofill = {}, noteHint } = props ?? {}
  const { processResponse } = useContext(PopUpMessageContext)
  const me = useAuth(s => s.user)

  const { data: assessments } = useUserAssessments(me?.id)

  const activeAssessments = useMemo(
    () => objectToArray(assessments ?? {}).sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1)),
    [assessments],
  )

  const fieldWithHints = useMemo<FieldMap>(() => {
    if (!noteHint) return contactForm
    return {
      ...contactForm,
      children: {
        ...contactForm.children,
        note: {
          ...contactForm.children.note,
          hints: [{ text: noteHint }],
        } as TextAreaField,
      },
    }
  }, [noteHint])

  const assessment = activeAssessments[0]

  const initValues = useMemo(() => {
    const res: Record<string, string | boolean> = {}
    if (me?.fname) res.fname = me?.fname
    if (me?.lname) res.lname = me?.lname
    if (me?.email) res.email = me?.email
    const phone = me?.phone
    if (phone) res.phone = phone
    const edd = getCorrectedValue(assessment, 'delivery.edd')
    if (edd) res.edd = edd

    const planIsMedicaid = getCorrectedValue(assessment, 'insurance-info.option') === 'medicaid'
    res.isMedicaid = !!planIsMedicaid

    const insuranceProviderId = getCorrectedValue(
      assessment,
      'insurance-info.primaryCoverage.insuranceProviderId',
    )
    if (insuranceProviderId) res.insurer = insuranceProviderId
    const medicaidInsuranceProviderId = getCorrectedValue(
      assessment,
      'insurance-info.medicaidCoverage.insuranceProviderId',
    )
    if (medicaidInsuranceProviderId) {
      res.medicaidInsurer = medicaidInsuranceProviderId
    }
    return { ...res, ...autofill }
  }, [me, assessment, autofill])

  const handleSubmit = useCallback(
    (values: FieldMapValue) => {
      const submitted = { ...values }
      if (submitted.phone) {
        submitted.phone = formatPhoneNumber(values.phone)
      }
      if (submitted.edd) {
        submitted.edd = getDateString(submitted.edd, 'short')
      }

      return contactUs(submitted).then(res => {
        close()
        return processResponse(res.data as UpdateCallback)
      })
    },
    [processResponse, close],
  )

  return openId === id ? (
    <DefaultModal
      isCentered
      onClose={close}
      isOpen
      overlayHeader
      render={() => (
        <>
          <SimpleForm
            value={initValues}
            theme="detailed"
            boxProps={{ bg: 'gray.50', pt: 4 }}
            field={fieldWithHints}
            onSubmit={handleSubmit}
          />
        </>
      )}
    />
  ) : null
}
