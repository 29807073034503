import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { getFullName } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { updateAdminColorFunction } from '../../backend'
import { useApp } from '../../contexts'
import { SortColorPopoverContent } from '../Users/Profile/SortColorPopover'
import { SortColorCircle } from '../Users/UrgentBadge'
import { AdminProfileContext } from './context'

export const AdminColorPopover = () => {
  const { user } = useContext(AdminProfileContext)
  const fullName = useMemo(() => getFullName(user), [user])
  const { appName } = useApp()
  const { adminColor } = user ?? {}
  return (
    <Popover isLazy trigger="hover">
      <PopoverTrigger>
        <SortColorCircle color={adminColor} />
      </PopoverTrigger>
      <PopoverContent>
        <SortColorPopoverContent
          onSubmit={v => {
            if (!user?.id) throw new Error('No user')
            return updateAdminColorFunction({
              id: user.id,
              ...v,
              practiceId: null,
              appName,
            }).then(() => {})
          }}
          label="Admin Color"
          title={fullName}
          color={user?.adminColor}
          notes={user?.adminColorNotes}
        />
      </PopoverContent>
    </Popover>
  )
}
