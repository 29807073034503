// indigo 002FAA

export interface Color {
  name: string
  hex: string
}
export const colors = {
  black: {
    name: 'Black',
    hex: '#000000',
  },
  gray: {
    name: 'Gray',
    hex: '#808080',
  },
  red: {
    name: 'Red',
    hex: '#C53030',
  },
  orange: {
    name: 'Orange',
    hex: '#ff8000',
  },
  green: {
    name: 'Green',
    hex: '#43AD9E',
  },
  neongreen: {
    name: 'Neon Green',
    hex: '#39ff14',
  },
  cyan: {
    name: 'Cyan',
    hex: '#00ffee',
  },
  blue: {
    name: 'Blue',
    hex: '#0099ff',
  },
  indigo: {
    name: 'Indigo',
    hex: '#002FAA',
  },
  purple: {
    name: 'Purple',
    hex: '#8a2be2',
  },
  pink: {
    name: 'Pink',
    hex: '#febcad',
  },
  yellow: {
    name: 'Yellow',
    hex: '#fffb00',
  },
  lime: {
    name: 'Lime',
    hex: '#00FF00',
  },
  maroon: {
    name: 'Maroon',
    hex: '#800000',
  },
  navy: {
    name: 'Navy',
    hex: '#000080',
  },
  olive: {
    name: 'Olive',
    hex: '#808000',
  },
  teal: {
    name: 'Teal',
    hex: '#008080',
  },
  silver: {
    name: 'Silver',
    hex: '#C0C0C0',
  },
  gold: {
    name: 'Gold',
    hex: '#FFD700',
  },
  skyblue: {
    name: 'Sky Blue',
    hex: '#87CEEB',
  },
  lightgreen: {
    name: 'Light Green',
    hex: '#90EE90',
  },
  lightyellow: {
    name: 'Light Yellow',
    hex: '#FFFFE0',
  },
  lightblue: {
    name: 'Light Blue',
    hex: '#ADD8E6',
  },
  lightpink: {
    name: 'Light Pink',
    hex: '#FFB6C1',
  },
  lightpurple: {
    name: 'Light Purple',
    hex: '#9370DB',
  },
} as const

export type ColorName = keyof typeof colors
