import { AnyObject, Preview, WithId } from '@hb/shared'
import { createContext } from 'react'
import { DataColumns } from '../types/data'

// gg
export interface GridContext<Snippet extends AnyObject, Populated extends AnyObject> {
  columns: DataColumns<Snippet>
  width: number
  height: number
  itemHeight: number | ((item: WithId<Snippet>) => number)
  data: Record<string, WithId<Snippet>> | null
  collection: string
  display: (message: string) => void
  reverseName?: string
  clearMessage: () => void
  preview: Preview | null
  openPreview: (preview: Preview) => void
  closePreview: () => void
  item: WithId<Populated> | null
  scrollTop: number
}

export const DataGridContext = createContext<
  GridContext<any, any>
  //  PopulatedAssessment | Claim>
>({
  columns: {},
  display: () => {},
  clearMessage: () => {},
  collection: '',
  preview: null,
  openPreview: () => {},
  closePreview: () => {},
  height: 0,
  width: 0,
  scrollTop: 0,
  reverseName: undefined,
  itemHeight: 0,
  data: null,
  item: null,
})
