import React, { useMemo } from 'react'
import { Navigate, useParams } from 'react-router'

export const FormsViewRedirect = () => {
  const params = useParams<{
    assessmentId?: string
  }>()
  const toPath = useMemo(() => {
    const { assessmentId } = params
    let p = '/pregnancy'
    if (!assessmentId) return p
    p += `/${assessmentId}`

    return p
  }, [params])
  return <Navigate replace to={toPath} />
}
