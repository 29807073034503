import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  CheckboxField,
  Collection,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  FileField,
  OnUploadProgress,
  Template,
  TemplateKey,
  TextField,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import React, { FC, useCallback, useContext, useState } from 'react'
import { processFieldMapData } from '../../backend'
import { useCollection } from '../../collections/hooks/useCollection'
import { ScreenContext } from '../../contexts/ScreenContext'
import { TemplateContext } from '../../contexts/TemplateContext'
import { ActionButton } from '../Buttons'
import EditCollection, { AddItemProps, BaseOnAddItem } from '../EditCollection/EditCollection'
import { SimpleForm } from '../forms'
import { TemplatePreview } from './TemplatePreview'
import { addExternalPdfTemplate } from './utils'

const nameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Template Name',
}

const isExternalPdfField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  inputType: 'switch',
  yesText: 'External PDF',
  noText: 'Custom Template',
  placeholder: 'External PDF',
}

const externalPdfField: FileField = {
  type: FieldTypes.FILE,
  placeholder: 'External PDF',
  fileType: 'pdf',
  name: 'externalPdf',
  condition: v => !!v?.isExternalPdf,
}

const field: FieldMap = {
  name: 'Template',
  children: {
    name: nameField,
    isExternalPdf: isExternalPdfField,
    externalPdf: externalPdfField,
  },
}

const AddWithExternalPdf: FC<AddItemProps> = ({ itemName, loading, onAdd }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async (v: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      const now = new Date().toISOString()
      try {
        const processed = await processFieldMapData(
          `temp/templates/${now}`,
          field,
          v,
          null,
          onUploadProgress,
        )
        await onAdd(processed)
        setIsOpen(false)
      } catch (e: any) {
        console.error(e)
        return { [FORM_ERROR]: e.message }
      }
    },
    [onAdd],
  )

  return (
    <Box p={2}>
      <Popover
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        closeOnBlur={false}
        strategy="fixed"
        isLazy
        placement="right-start">
        <PopoverTrigger>
          <ActionButton
            borderRadius="full"
            gap={1.5}
            isLoading={loading}
            size="sm"
            variant="outline">
            <AddIcon w={3} h={3} />
            <Text>{`Add ${itemName}`}</Text>
          </ActionButton>
        </PopoverTrigger>
        <PopoverContent bg="gray.50" w="350px" borderRadius={10}>
          <PopoverArrow bg="gray.50" />
          <PopoverBody p={0}>
            <Flex w="100%" flexFlow="column">
              <Flex
                bg="white"
                borderTopRadius={10}
                px={2}
                borderBottom="1px solid #cdcdcd"
                w="100%"
                py={1.5}>
                <Text px={1} fontSize="sm" fontFamily="Hero-New" color="gray.500">
                  New Template
                </Text>
              </Flex>
              <SimpleForm
                boxProps={{ bg: 'transparent', boxShadow: 'none', p: 0 }}
                field={field}
                onSubmit={handleSubmit}
              />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export const TemplatesSection: React.FC<{
  collection: Collection<Template>
  templateType: TemplateKey
  canBeExternalPdf?: boolean
}> = ({ collection, templateType }) => {
  const { name } = collection
  const collectionData = useCollection(collection)
  const { contentWidth } = useContext(ScreenContext)

  const onAdd = useCallback(
    async (data: FieldMapValue, fallback: BaseOnAddItem) => {
      if (templateType === 'consentForm' && data.isExternalPdf) {
        try {
          await addExternalPdfTemplate({
            templateType,
            name: data.name,
            isExternalPdf: data.isExternalPdf,
            externalPdf: data.externalPdf,
          })
          return { success: 'Template added' }
        } catch (e: any) {
          console.error(e)
          return { error: e.message }
        }
      }
      return fallback(data)
    },
    [templateType],
  )

  const { select } = useContext(TemplateContext)
  return collectionData ? (
    <EditCollection<Template>
      data={collectionData}
      width={contentWidth}
      collection={collection}
      onOpenItem={id => {
        if (select) select({ collection, templateId: id, templateType })
      }}
      ItemComponent={
        templateType === 'consentForm'
          ? props => TemplatePreview({ ...props, collection, templateType })
          : undefined
      }
      alwaysExpanded={true}
      onAdd={onAdd}
      name={name}
      RenderAddItem={templateType === 'consentForm' ? AddWithExternalPdf : undefined}
      itemPlaceholder="Template Name"
      itemName="Template"
    />
  ) : null
}
