import { ArrowForwardIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Center, Flex, HStack, IconButton, Text, Tooltip, VStack } from '@chakra-ui/react'
import { colors, PopulatedThreadMessage, toSearchString } from '@hb/shared'
import React, {
  forwardRef,
  JSX,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SearchBarContext } from '../../../contexts/SearchBarContext'
import { useDebounce } from '../../../hooks/useDebounce'
import { SearchBar } from '../../DataList/SearchBar'
import { Loading } from '../../Loading'
import { CollapseHorizontal } from '../../shared'
import { THREAD_HEADER_HEIGHT } from '../constants'
import { useThreadMessages } from '../hooks'
import { ThreadViewContext } from './contexts'
import { ThreadMessageView } from './ThreadMessageView'

interface ThreadSearchContextData {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  searchQuery: string
  updateQuery: (s: string) => void
}

const ThreadSearchContext = React.createContext<ThreadSearchContextData>({
  isOpen: false,
  onClose: () => {},
  onOpen: () => {},
  searchQuery: '',
  updateQuery: () => {},
})

const MessageSearchResult = ({
  message,
  onClick,
}: {
  message: PopulatedThreadMessage
  onClick: () => void
}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <HStack
      onClick={onClick}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      cursor="pointer"
      py={1}
      borderRadius={6}
      _hover={{ bg: 'blackAlpha.100' }}
      w="100%">
      <Box minW="0" flex={1}>
        <ThreadMessageView isPreview horizontal message={message} />
      </Box>
      <CollapseHorizontal width={40} in={hovered}>
        <Center w="100%">
          <ArrowForwardIcon color="gray.500" />
        </Center>
      </CollapseHorizontal>
    </HStack>
  )
}

export const ThreadSearch = forwardRef<HTMLInputElement>((_, ref) => {
  const { isOpen, onClose } = useContext(ThreadSearchContext)
  const { threadId, threadType, handleMessageSelect, bodyHeight } = useContext(ThreadViewContext)
  const [searchQuery, setSearchQuery] = useState('')
  const [resultsPage, setResultsPage] = useState(0)
  const debounced = useDebounce(searchQuery, 300)
  const contextData = useMemo(
    () => ({
      query: searchQuery,
      updateQuery: (s: string) => {
        setSearchQuery(s)
        setResultsPage(0)
      },
      searchQuery,
    }),
    [searchQuery],
  )

  const { data: messages, loading: loadingMessages } = useThreadMessages(
    threadType,
    isOpen && searchQuery ? threadId : '',
    true,
  )

  const results = useMemo(
    () => messages.filter(m => toSearchString(m?.text ?? '').includes(toSearchString(searchQuery))),
    [messages, searchQuery],
  )
  const displayedResults = useMemo(
    () => results.slice(resultsPage * 10, (resultsPage + 1) * 10),
    [results, resultsPage],
  )
  const handleClose = useCallback(() => {
    setSearchQuery('')
    onClose()
  }, [onClose])

  // close on escape and prevent default escape behavior
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        handleClose()
      }
    }
    window.addEventListener('keydown', handleEscape)
    return () => window.removeEventListener('keydown', handleEscape)
  }, [handleClose])

  const numPages = useMemo(() => Math.ceil(results.length / 10), [results])

  let body: JSX.Element | null = null
  if (loadingMessages || debounced !== searchQuery) {
    body = (
      <Center w="100%">
        <Loading text="Loading results..." />
      </Center>
    )
  } else if (displayedResults.length) {
    body = (
      <Flex direction="column" w="100%">
        <Center px={2}>
          {numPages > 1 ? (
            <IconButton
              aria-label="Prev"
              size="sm"
              onClick={() => setResultsPage(p => Math.max(0, p - 1))}
              isDisabled={resultsPage === 0}>
              <ArrowForwardIcon transform="rotate(180deg)" />
            </IconButton>
          ) : null}
          <Text flex={1} textAlign="center" color="gray.500">
            {numPages > 1
              ? `Showing results ${resultsPage * 10 + 1} - ${Math.min((resultsPage + 1) * 10, results.length)} of ${results.length}`
              : `${results.length} results`}
          </Text>
          {numPages > 1 ? (
            <IconButton
              aria-label="Next"
              size="sm"
              onClick={() => setResultsPage(p => Math.min(numPages - 1, p + 1))}
              isDisabled={resultsPage === numPages - 1}>
              <ArrowForwardIcon />
            </IconButton>
          ) : null}
        </Center>
        {displayedResults.map(m => (
          <MessageSearchResult
            message={m}
            key={`${m.createdOn}`}
            onClick={() => {
              handleClose()
              handleMessageSelect(`${m.createdOn}`, true)
            }}
          />
        ))}
      </Flex>
    )
  } else {
    body = (
      <Center py={2}>
        <Text color="gray.500">No matches found</Text>
      </Center>
    )
  }

  return (
    <VStack
      top={`${THREAD_HEADER_HEIGHT}px`}
      bg="blackAlpha.500"
      position="absolute"
      transition="all 300ms"
      height={`${bodyHeight}px`}
      pointerEvents={isOpen ? 'auto' : 'none'}
      opacity={isOpen ? 1 : 0}
      w="100%"
      p={2}>
      <HStack
        bg="gray.100"
        borderRadius={6}
        boxShadow="1px 1px 3px #00000077"
        spacing={0}
        pr={2}
        pl={3}
        width="100%">
        <Box flex={1}>
          <SearchBarContext.Provider value={contextData}>
            <SearchBar
              ref={ref}
              onClear={handleClose}
              flexProps={{ px: 0 }}
              autoSubmit
              itemName="message"
            />
          </SearchBarContext.Provider>
        </Box>
      </HStack>
      <VStack
        w="100%"
        py={2}
        flex={1}
        minH="0"
        px={1}
        overflowY="auto"
        opacity={isOpen && searchQuery ? 1 : 0}
        transition="all 300ms"
        borderRadius={6}
        bg="gray.100"
        boxShadow="1px 1px 3px #00000077">
        {body}
      </VStack>
    </VStack>
  )
})

export const ThreadSearchButton = () => {
  const { onClose, isOpen, onOpen } = useContext(ThreadSearchContext)
  // const debounced = useDebounce(searchQuery, 500)

  const { addingMembers } = useContext(ThreadViewContext)
  return (
    <Box>
      <Tooltip hasArrow label="Search messages" aria-label="Search messages" placement="right">
        <IconButton
          icon={<Search2Icon position="relative" bottom="2px" width={4} height={4} />}
          transition="all 300ms"
          bg={isOpen ? colors.green.hex : 'gray.50'}
          color={isOpen ? 'white' : 'gray.500'}
          borderRadius={6}
          alignItems="center"
          justifyContent="center"
          opacity={addingMembers ? 0 : 1}
          filter="drop-shadow(1px 1px 3px #00000077)"
          variant="unstyled"
          onClick={isOpen ? onClose : onOpen}
          aria-label="Search"
        />
      </Tooltip>
    </Box>
  )
}

export const ThreadSearchProvider = ({
  isOpen,
  onOpen,
  onClose,
  children,
}: PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}>) => {
  const [searchQuery, setSearchQuery] = useState('')
  const contextData = useMemo(
    () => ({
      isOpen,
      onClose: () => {
        setSearchQuery('')
        onClose()
      },
      onOpen: () => {
        onOpen()
      },
      searchQuery,
      updateQuery: (s: string) => setSearchQuery(s),
    }),
    [isOpen, searchQuery, onOpen, onClose],
  )
  return <ThreadSearchContext.Provider value={contextData}>{children}</ThreadSearchContext.Provider>
}
