import { ArrowForwardIcon, DeleteIcon, EmailIcon } from '@chakra-ui/icons'
import { Button, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import { getFullName, getInitials, getUserGroup } from '@hb/shared'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useCachedUser } from '../../collections/hooks/cached'
import { useApp } from '../../contexts/AppContext'
import { ProfilePicturePopover } from '../Admins/ProfilePicturePopover'
import { UserBadge } from './UserBadge'

const isSmall = (size: 'xs' | 'sm' | 'md' | 'lg') => size === 'xs' || size === 'sm'

export const UserPreview = ({
  userId,
  size = 'md',
  onDelete,
  noEmail,
  goTo,
  noWrap,
  withProfilePicture,
}: {
  userId: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  onDelete?: () => void
  goTo?: boolean
  noWrap?: boolean
  noEmail?: boolean
  withProfilePicture?: boolean
}) => {
  const { data: user } = useCachedUser(userId)
  const { appName } = useApp()
  const { name } = useMemo(() => {
    if (user) {
      const fullName = getFullName(user)
      const i = getInitials(fullName)
      return { name: fullName, initials: i }
    }
    return {}
  }, [user])
  const profilePath = useMemo(
    () => (appName === 'providers-app' ? `/patients/${userId}` : `/admin/patients/${userId}`),
    [userId, appName],
  )
  const small = useMemo(() => isSmall(size), [size])
  const group = useMemo(() => getUserGroup(user), [user])
  return (
    <VStack
      spacing={0}
      bg={small ? 'transparent' : 'white'}
      p={1}
      boxShadow={small ? 'none' : 'md'}
      borderRadius={4}
      w="100%">
      <HStack borderTopRadius={4} w="100%" px={1}>
        {withProfilePicture ? (
          <ProfilePicturePopover
            noOnlineIndicator={size === 'xs'}
            size={5}
            userId={userId}
            noPopover
          />
        ) : null}
        {small || withProfilePicture ? null : <UserBadge userGroup={group} userId={userId} />}
        <Text whiteSpace={noWrap ? 'nowrap' : 'normal'} fontFamily="Open Sans" fontSize={size}>
          {name}
        </Text>
        {goTo || onDelete ? (
          <Flex align="center" ml="auto">
            {goTo ? (
              <Link to={profilePath}>
                <Button
                  gap={1}
                  size="xs"
                  aria-label="Go to user"
                  pr={1}
                  color="white"
                  bg="green.500">
                  <Text>VIEW PROFILE</Text>
                  <ArrowForwardIcon w={4} h={4} filter="drop-shadow(1px 1px 3px #00000066)" />
                </Button>
              </Link>
            ) : null}
            {onDelete ? (
              <IconButton
                ml="auto"
                size="xs"
                aria-label="Remove user"
                color="red.600"
                icon={<DeleteIcon />}
                onClick={onDelete}
              />
            ) : null}
          </Flex>
        ) : null}
      </HStack>
      {noEmail ? null : (
        <VStack w="100%" align="flex-start" px={2}>
          <HStack spacing={small ? 1 : 2}>
            <EmailIcon color="gray.500" />
            <Text fontSize="sm">{user?.email}</Text>
          </HStack>
          {/* <Text>{user?}</Text> */}
        </VStack>
      )}
    </VStack>
  )
}
