import { AnyObject } from '@hb/shared'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { db } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { addMetadata } from '../../utils'
import { useDocument } from './useDocument'

export interface UseOptimisticUpdate<T extends AnyObject> {
  data: T | null
  loading: boolean
  error: string | null
  update: (updated: Partial<T>) => Promise<{ success?: string; error?: string }>
}

export const useOptimisticUpdate = <T extends AnyObject>(
  collectionId: string,
  documentId?: string | null,
): UseOptimisticUpdate<T> => {
  const { data, loading, error } = useDocument<T>(collectionId, documentId)
  const [temp, setTemp] = useState<T | null>(null)
  const { appName } = useApp()
  useEffect(() => {
    setTemp(data)
  }, [data])

  const update = useCallback(
    async (updated: Partial<T>) => {
      if (!documentId) return { error: 'No documentId' }
      setTemp(prev => addMetadata(prev ? { ...prev, ...updated } : (updated as T), appName, !prev))
      const docRef = doc(db, collectionId, documentId)
      const curr = await getDoc(docRef)
      const exists = curr.exists()
      const submitted = addMetadata(updated, appName, !exists)
      const updateFunc = exists ? updateDoc : setDoc
      return updateFunc(docRef, submitted)
        .then(() => ({ success: 'Updated successfully' }))
        .catch(() => ({ error: 'Failed to update' }))
    },
    [collectionId, documentId, appName],
  )

  return useMemo(
    () => ({
      data: temp ?? data,
      loading,
      error,
      update,
    }),
    [temp, data, loading, error, update],
  )
}
