import {
  AnyObject,
  Field,
  FieldMap,
  FieldMapValue,
  isField,
  ListField,
  UploadProgress,
} from '@hb/shared'
import { FormApi, FORM_ERROR, ValidationErrors } from 'final-form'
import { useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'

export const useFormSubmit = <T extends AnyObject>(
  field: Field | FieldMap | ListField,
  onSubmit: (
    submitted: any,
    onUploadProgress: (progress: Record<string, UploadProgress>) => void,
    form: FormApi<T>,
  ) => Promise<ValidationErrors>,
  setUploads: (updated: Record<string, UploadProgress> | null) => void,
  closeOnSuccess?: boolean,
  onClose?: () => void,
) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const onUploadProgress = useCallback(
    (updated: Record<string, UploadProgress>) => {
      setUploads({ ...updated })
    },
    [setUploads],
  )
  const submit = useCallback(
    async (updated: FieldMapValue, form: FormApi<T>): Promise<ValidationErrors> => {
      try {
        const updatedValue = isField(field) ? updated.value : updated
        const res = await onSubmit(updatedValue, onUploadProgress, form)
        if (!res || res.success) {
          if (res?.success) processResponse({ success: res.success })
          if (closeOnSuccess && onClose) onClose()
          setUploads(null)
          return undefined
        }
        if (res.error) {
          processResponse({ error: res.error })
        }
        return res
      } catch (e: any) {
        console.error(e)
        processResponse({ error: e?.message ?? 'An error occurred' })
        return { [FORM_ERROR]: e?.message ?? 'An error occurred' }
      }
    },
    [field, onUploadProgress, onSubmit, processResponse, setUploads, closeOnSuccess, onClose],
  )

  return submit
}
