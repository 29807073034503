import { medicaidProvidersCollection, providersCollection } from '../../collections'
import { FieldTypes } from '../../constants'
import {
  DropdownField,
  DynamicDropdownField,
  Field,
  FieldCondition,
  FieldMap,
  FormElement,
  InsuranceCoverage,
  InsuranceProvider,
} from '../../types'
import { createFileField } from '../../utils/fields'
import { callIn } from '../callIn'
import { addressField, fnameField, lnameField, notesField } from '../shared'
import { insuranceCardField } from './insuranceCard'
import { insuranceProviderField, medicaidInsuranceProviderField } from './insuranceProvider'

export const selfPolicyOwnerDetails: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Marital Status',
  labels: [{ text: 'Please choose one' }],
  condition: v => v?.policyOwnerRelationship === 'self' || v?.policyOwnerRelationship === 'parent',
  options: [
    { text: 'I am legally married', id: 'married' },
    { text: 'I have a domestic partnership', id: 'domestic-partnership' },
    { text: 'I am single', id: 'single' },
  ],
}

export const partnerPolicyOwnerDetails: DropdownField = {
  type: FieldTypes.DROPDOWN,
  condition: v => v?.policyOwnerRelationship === 'partner',
  placeholder: 'Marital Status',
  labels: [{ text: 'Please choose one' }],
  options: [
    { text: 'I am legally married', id: 'married' },
    { text: 'I have a domestic partnership', id: 'domestic-partnership' },
  ],
}

const inNetworkCoinsuranceField: Field = {
  type: FieldTypes.PERCENTAGE,
  placeholder: 'In Network Coinsurance',
  labels: [{ text: 'What is your annual in network coinsurance?' }],
}

const inNetworkDeductibleField: Field = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'In Network Deductible',
  labels: [{ text: 'What is your annual in network deductible?' }],
}

export const policyOwnerRelationshipField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Policy Owner Relationship',
  labels: [{ text: 'What is the relationship between you and the policy owner?' }],
  options: [
    {
      text: 'Myself',
      id: 'self',
    },
    { text: 'Partner', id: 'partner' },
    { text: 'Parent', id: 'parent' },
  ],
}

export const parentPartnerCondition: FieldCondition = v =>
  ['partner', 'parent'].includes(v?.policyOwnerRelationship)
export const policyOwnerSexField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Policy Owner Sex',
  hints: [{ text: 'To submit claims to your insurer, you must pick one' }],
  options: [
    { text: 'Male', id: 'M' },
    { text: 'Female', id: 'F' },
  ],
}

export const policyOwnerField: FieldMap = {
  name: 'Policy Owner',
  condition: parentPartnerCondition,
  children: {
    policyOwnerName: {
      name: 'Policy Owner Name',
      children: {
        fname: fnameField,
        lname: lnameField,
      },
    },
    policyOwnerSex: policyOwnerSexField,
    policyOwnerDob: {
      type: FieldTypes.DATE,
      placeholder: 'Policy owner DOB',
      labels: [{ text: 'What is the date of birth of the policy owner?' }],
    },
    policyOwnerSameAddress: {
      optional: true,
      type: FieldTypes.CHECKBOX,
      labels: [{ text: 'What is the address of the policy owner?' }],
      placeholder: 'Policy owner address same as patient address',
    },
    policyOwnerAddress: {
      condition: v => !v?.policyOwnerSameAddress,
      ...addressField,
      name: 'Policy Owner Address',
      labels: [
        {
          text: "What is the policy owner's address (as listed with the insurer)?",
        },
      ],
    },
    policyOwnerPhone: {
      type: FieldTypes.PHONE,
      labels: [{ text: "What is the policy owner's phone number?" }],
      placeholder: 'Policy Owner Phone Number',
    },
    policyOwnerEmail: {
      type: FieldTypes.EMAIL,
      labels: [{ text: "What is the policy owner's email?" }],
      placeholder: 'Policy Owner Email',
    },
  },
}

export const policyOwnerFields: Record<string, FormElement> = {
  policyOwnerRelationship: policyOwnerRelationshipField,
  policyOwnerInfo: policyOwnerField,
}

export const policyOwnerFieldMap: FieldMap = {
  name: 'Policy Owner',
  children: policyOwnerFields,
}

export const getInsuranceCoverageField = (
  medicaid?: boolean,
  withCallIn?: boolean,
  withPolicyOwner?: boolean,
): FieldMap => {
  const res: FieldMap = {
    name: 'Insurance Coverage',
    children: {},
  }

  // if (!medicaid) {
  res.children.insuranceProviderId = {
    ...(medicaid ? medicaidInsuranceProviderField : insuranceProviderField),
    labels: [
      {
        text: `Who is your ${medicaid ? 'medicaid ' : ''}insurer? (see above for clarification)`,
      },
    ],
  }
  // }
  if (medicaid) {
    // TODO: DELETE THIS ONCE LIVE IS UPDATED
    // res.children.medicaidPlanName = {
    //   labels: [
    //     {
    //       text: 'What is the name of your
    // Medicaid plan? (Please look at your insurance card if you have one)',
    //     },
    //   ],
    //   type: FieldTypes.TEXT,
    //   placeholder: 'Medicaid plan name',
    //   optional: true,
    // }

    res.children.currentlyOnMedicaidPlan = {
      type: FieldTypes.BOOLEAN,
      labels: [{ text: 'Are you currently covered by this Medicaid plan?' }],
      placeholder: 'Currently on Medicaid plan',
      yesText: 'I am currently on this Medicaid plan',
      noText: 'I will be on this plan at a future date',
    }

    res.children.medicaidStartDate = {
      // condition: (v) => v?.currentlyOnMedicaidPlan === false,
      type: FieldTypes.DATE,
      optional: true,
      labels: [
        {
          text: 'When does your coverage start? (This could be a past or future date)',
        },
      ],
      hints: [
        {
          text: "If you are unable to quickly find your start date, and you are currently covered by the plan, you can enter today's date.",
        },
      ],
      placeholder: 'Medicaid coverage start date',
      // minDate: 'now',
    }
  } else {
    res.children.initialServiceDate = {
      type: FieldTypes.DATE,
      optional: true,
      labels: [
        {
          text: 'When does your coverage start? (This could be a past or future date)',
        },
      ],
      hints: [
        {
          text: "If you are unable to quickly find your start date, and you are currently covered by the plan, you can enter today's date.",
        },
      ],
      placeholder: 'Coverage Start Date',
    }
  }

  res.children.terminationDate = {
    type: FieldTypes.DATE,
    optional: true,
    labels: [{ text: 'If applicable, when does your coverage end?' }],
    hints: [{ text: 'If your insurance is ongoing, leave this blank' }],
    placeholder: 'Coverage End Date',
  }

  res.children.memberId = {
    labels: [{ text: 'What is your insurance member ID?' }],
    type: FieldTypes.TEXT,
    placeholder: 'Member ID',
    condition: v => !v?.noMemberId,
  }

  res.children.noMemberId = {
    // condition: (v) => !v?.memberId,
    type: FieldTypes.CHECKBOX,
    placeholder: "I don't have a member ID yet",
  }

  res.children.insuranceProviderNumber = {
    type: FieldTypes.PHONE,
    placeholder: 'Provider Services Phone Number',
    labels: [
      { text: 'What is the phone number for ' },
      { text: 'provider services', style: { fontWeight: 500 } },
      { text: ' for your insurance?' },
    ],
    hints: [{ text: 'This number is on the back of your insurance card' }],
    condition: v => !v?.noMemberId,
  }

  res.children.planName = {
    labels: [{ text: 'Which plan do you have?' }],
    type: FieldTypes.DYNAMIC_DROPDOWN,
    placeholder: 'Plan Name',
    notApplicableText: 'Not applicable for your coverage',
    collection: medicaid ? medicaidProvidersCollection : providersCollection,
    baseId: 'insuranceProviderId',
    allowOther: true,
    optional: true,
    condition: v => !v?.noMemberId,
    listId: 'plans',
  } as DynamicDropdownField<InsuranceProvider>

  if (!medicaid && !withCallIn) {
    res.children.inNetworkDeductible = inNetworkDeductibleField
    res.children.inNetworkCoinsurance = inNetworkCoinsuranceField
  }

  res.children = {
    ...res.children,
    insuranceCard: insuranceCardField,
    proofOfFutureCoverage: createFileField({
      placeholder: 'Proof of Future Coverage',
      labels: [
        {
          text: 'Please upload confirmation of your future coverage date from NYS or insurer (screenshot is ok)',
        },
      ],
      // fileType: 'pdf',
      name: 'proof-of-future-coverage',
      condition: v => !!v?.noMemberId,
    }),
  }
  if (withCallIn) {
    res.children = {
      ...res.children,
      ...callIn.stages,
    }
  }

  if (withPolicyOwner) {
    res.children = {
      ...res.children,
      policyOwnerRelationship: policyOwnerRelationshipField,
      selfPolicyOwnerDetails,
      partnerPolicyOwnerDetails,
      policyOwnerInfo: policyOwnerField,
    }
  }

  return res
}

export const coverageRequestField: FieldMap = {
  name: 'Coverage Request',
  children: {
    withCallInForm: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Request Call-In Questionnaire (only applies to non-Medicaid plans)',
    },
    sendEmail: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Send email to patient',
    },
    message: {
      ...notesField,
      optional: false,
      placeholder: 'Message to patient',
    },
  },
}

export const basicPrimaryInsuranceCoverageField = getInsuranceCoverageField()
export const basicMedicaidCoverageField = getInsuranceCoverageField(true)
export const getSecondaryCoverageField = (isMedicaid: boolean) =>
  getInsuranceCoverageField(isMedicaid, false, true)

export const getBasicInfoField = (medicaid?: boolean): FieldMap => ({
  ...getInsuranceCoverageField(medicaid),
  name: 'Coverage Summary',
})

export const getCompleteCoverageField = (medicaid?: boolean): FieldMap =>
  getInsuranceCoverageField(medicaid, true, true)

export const getAdditionalCoverageField = (
  additionalCoverage: InsuranceCoverage | undefined,
): FieldMap =>
  getInsuranceCoverageField(
    additionalCoverage?.isMedicaid,
    !!Object.keys(additionalCoverage?.callInRequests ?? {}).length,
    true,
  )

export const policyOwnerInfo: FieldMap = {
  name: 'Policy Owner Details',
  condition: parentPartnerCondition,
  children: policyOwnerFields,
}
