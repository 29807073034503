import {
  FieldMap,
  FieldTypes,
  ReferenceNumberData,
  TextAreaField,
  TextField,
  UpdateCallback,
} from '@hb/shared'
import React, { useCallback, useContext } from 'react'
import { ProfileContext } from '../../../contexts/ProfileContext'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { DataViewList } from '../../DataView/DataView'

const refNumberField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Reference #',
  optional: true,
}

const refNumberNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Notes',
  optional: true,
}

const referenceNumberField: FieldMap = {
  name: 'Reference Number',
  initExpanded: false,
  toName: v => `${v?.referenceNumber}${v?.notes ? ` - ${v?.notes}` : ''}`,
  children: {
    referenceNumber: refNumberField,
    notes: refNumberNotesField,
  },
}

export const ReferenceNumbersEdit = ({
  id,
}: {
  id: 'claimsReferenceNumbers' | 'authReferenceNumbers'
}) => {
  const {
    selectedAssessment: { populated: selectedAssessment, adminRef },
  } = useContext(ProfileContext)
  const { [id]: value } = selectedAssessment ?? {}
  const onUpdate = useUpdateDoc()
  const me = useMe()
  const handleUpdate = useCallback(
    async (data: Array<ReferenceNumberData>): Promise<UpdateCallback> => {
      if (!adminRef) return { error: 'No adminRef' }
      const uid = me?.uid
      if (!uid) return { error: 'No uid' }
      return onUpdate(adminRef, id, data)
    },
    [id, onUpdate, adminRef, me],
  )
  return (
    <DataViewList
      alwaysExpanded
      noArrow
      stackProps={{
        px: 3,
        py: 2,
        shadow: 'md',
        borderRadius: 4,
      }}
      value={value}
      onSubmit={handleUpdate}
      itemField={referenceNumberField}
      itemName="Additional Reference Number"
    />
  )
}
