import { ProfileBaseItemType, ProfileItemType } from '@hb/shared'
import { V1ProfileTabName } from './types'

export const v1adminProfileTabNames: Array<V1ProfileTabName> = [
  'patient',
  'pregnancy',
  'payments',
  'auth',
  'claims',
  'files',
  'visits',
]

export const v1practiceProfileTabNames: Array<V1ProfileTabName> = [
  'patient',
  'pregnancy',
  'files',
  'visits',
]

export const profileBaseItemTypes: Array<ProfileBaseItemType> = [
  'patients',
  'pregnancies',
  'claims',
  'visits',
]

export const isProfileBaseItemType = (type: string): type is ProfileBaseItemType =>
  profileBaseItemTypes.includes(type as ProfileBaseItemType)

export const profileItemTypes: Array<ProfileItemType> = ['patient', 'pregnancy', 'visit', 'claim']
export const isProfileItemType = (type: string): type is ProfileItemType =>
  profileItemTypes.includes(type as ProfileItemType)

export const toProfileItemType = (type: ProfileBaseItemType): ProfileItemType => {
  switch (type) {
    case 'pregnancies':
      return 'pregnancy'
    case 'claims':
      return 'claim'
    case 'visits':
      return 'visit'
    case 'patients':
      return 'patient'
    default:
      throw new Error(`Invalid base item type: ${type}`)
  }
}
