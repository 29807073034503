import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useProfile } from '../../../../contexts'
import { DraftView } from '../Documents/DraftView'
import { PatientConsentForms } from './PatientConsentForms'

export const ProfileConsentForms = () => {
  const { userId, user } = useProfile()
  const { drafts } = user ?? {}
  if (!userId) return null
  return (
    <Flex gap={2} p={2} flexFlow="column" w="100%">
      {userId ? (
        <DraftView
          templateKey="consentForm"
          draft={drafts?.consentFormTemplates}
          name="Consent forms"
        />
      ) : null}
      {userId ? <PatientConsentForms adminView patientId={userId} /> : null}
    </Flex>
  )
}
