import { CloseIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Center,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  BasePresetFilter,
  CollectionFilter,
  colors,
  DropdownField,
  DropdownPresetFilter,
  FieldTypes,
  isDropdownPresetFilter,
  PresetFilter,
} from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { DataListContext, ScreenContext } from '../../contexts'
import filterIcon from '../../icons/tune_green.svg'

import { StandaloneInput } from '../forms/Input'

const PresetFilterButton = ({
  filter,
  filters,
  toggleFilter,
  isChild,
}: {
  filter: BasePresetFilter
  filters: CollectionFilter[]
  toggleFilter: (toggled: CollectionFilter) => void
  isChild?: boolean
}) => {
  const { label } = filter
  const isActive = filters.some(f => f === filter.filter)
  return (
    // <Button
    //   borderRadius="full"
    //   w='100%'
    //   size="xs"
    //   fontWeight={500}
    //   fontFamily="Hero-New"
    //   onClick={() => (isActive ? removeFilter(filter.filter[0]) : filterBy(filter.filter))
    //   }
    //   bg={isActive ? colors.green.hex : 'gray.200'}
    //   color={isActive ? 'white' : 'gray.800'}
    //   _hover={{ bg: isActive ? 'gray.300' : 'green.300' }}
    // >
    //   {label}
    // </Button>
    <VStack w="100%" spacing={0} align="flex-end">
      {isChild ? null : (
        <Text fontSize="sm" color="gray.500" fontWeight={600}>
          {label}
        </Text>
      )}
      <Checkbox
        onChange={() => toggleFilter(filter.filter)}
        colorScheme="green"
        isChecked={isActive}
        textAlign="right"
        gap={2}
        color="gray.500"
        flexFlow="row-reverse">
        {label}
      </Checkbox>
    </VStack>
  )
}

const DropdownFilterButton = ({
  filter,
  filters,
  toggleFilter,
}: {
  filter: DropdownPresetFilter
  filters: CollectionFilter[]
  toggleFilter: (toggled: CollectionFilter) => void
}) => {
  const { label, searchable } = filter
  const selectedOption = useMemo(
    () => filter.filters.find(f => filters.includes(f.filter)),
    [filters, filter.filters],
  )

  const field = useMemo<DropdownField>(
    () => ({
      placeholder: label,
      searchable: !!filter.searchable,
      optional: true,
      type: FieldTypes.DROPDOWN,
      options: filter.filters.map(f => ({ text: f.label, id: f.label })),
    }),
    [filter, label],
  )

  return (
    <VStack alignItems="flex-end" spacing={0} w="100%">
      <Text fontSize="sm" color="gray.500" fontWeight={600}>
        {label}
      </Text>
      {searchable ? (
        <Box maxW="160px">
          <StandaloneInput
            value={selectedOption?.label}
            theme="detailed"
            style={{ padding: '0.25rem 0.5rem', fontSize: '0.9rem' }}
            field={field}
            onChange={value => {
              if (selectedOption && value && selectedOption.label !== value.label)
                toggleFilter(selectedOption.filter)
              if (!value && selectedOption) toggleFilter(selectedOption.filter)
              const selectedFilter = filter.filters.find(f => f.label === value)
              if (selectedFilter) {
                toggleFilter(selectedFilter.filter)
              }
            }}
          />
        </Box>
      ) : (
        <>
          <VStack spacing={0} alignItems="flex-end">
            {filter.filters.map(f => (
              <PresetFilterButton
                filters={filters}
                toggleFilter={toggleFilter}
                key={f.filter.join('-')}
                isChild
                filter={f}
              />
            ))}
          </VStack>
        </>
      )}
    </VStack>
  )
}

const FilterButton = ({
  filter,
  filters,
  toggleFilter,
}: {
  filter: PresetFilter
  filters: CollectionFilter[]
  toggleFilter: (toggled: CollectionFilter) => void
}) =>
  isDropdownPresetFilter(filter) ? (
    <DropdownFilterButton filters={filters} toggleFilter={toggleFilter} filter={filter} />
  ) : (
    <PresetFilterButton filters={filters} toggleFilter={toggleFilter} filter={filter} />
  )

export const PresetFilters = ({
  presetFilters,
  toggleFilter,
  currentFilters,
}: {
  presetFilters: PresetFilter[]
  toggleFilter: (filter: CollectionFilter) => void
  currentFilters: CollectionFilter[]
}) => {
  const { isMobile } = useContext(ScreenContext)
  const activePresetFilters = useMemo(() => {
    if (!presetFilters) return []
    return presetFilters.reduce<{ text: string; filter: CollectionFilter }[]>((acc, filter) => {
      if (isDropdownPresetFilter(filter)) {
        const selectedOption = filter.filters.find(f => currentFilters.includes(f.filter))
        if (selectedOption) {
          acc.push({
            text: selectedOption.label,
            filter: selectedOption.filter,
          })
        }
      } else if (currentFilters.includes(filter.filter)) {
        acc.push({ text: filter.label, filter: filter.filter })
      }
      return acc
    }, [])
  }, [currentFilters, presetFilters])
  return presetFilters?.length ? (
    <HStack spacing={1}>
      {activePresetFilters.map(filter => (
        <Badge
          py="0.25rem"
          px={2}
          borderRadius="full"
          bg={colors.green.hex}
          color="white"
          fontFamily="Open Sans"
          fontWeight={600}
          fontSize="xs"
          key={filter.text}>
          <HStack spacing={1}>
            <Text lineHeight={1}>{filter.text}</Text>
            <IconButton
              size="xs"
              width="16px"
              height="16px"
              minW="0"
              variant="unstyled"
              bg="transparent"
              color="gray.100"
              borderRadius="full"
              aria-label="Remove filter"
              icon={
                <Center>
                  <CloseIcon width="9px" />
                </Center>
              }
              onClick={() => toggleFilter(filter.filter)}
            />
          </HStack>
        </Badge>
      ))}
      <Popover
        closeOnBlur={false}
        placement="left-start"
        trigger={isMobile ? 'click' : 'hover'}
        strategy="fixed">
        <PopoverTrigger>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="Filters"
            icon={
              <Image
                width="20px"
                filter={`grayscale(${currentFilters.length ? 0 : 100}%)`}
                src={filterIcon}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent borderRadius={6} w="220px" bg="gray.50">
          <PopoverArrow bg="gray.50" />
          <VStack spacing={2} w="100%" py={2} px={3}>
            <HStack justify="center" w="100%" borderBottom="1px solid #cdcdcd">
              <Text fontSize="sm" color="gray.500" fontWeight={600}>
                FILTERS
              </Text>
            </HStack>
            {presetFilters.map(filter => (
              <FilterButton
                toggleFilter={toggleFilter}
                filters={currentFilters}
                key={filter.label}
                filter={filter}
              />
            ))}
          </VStack>
        </PopoverContent>
      </Popover>
    </HStack>
  ) : null
}
export const DataListPresetFilters = () => {
  const { tab, removeFilter, userFilters, filterBy } = useContext(DataListContext)
  const { presetFilters } = tab ?? {}

  return (
    <PresetFilters
      presetFilters={presetFilters && Array.isArray(presetFilters) ? presetFilters : []}
      toggleFilter={filter =>
        userFilters.includes(filter) ? removeFilter(filter[0]) : filterBy(filter)
      }
      currentFilters={userFilters}
    />
  )
}
