import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Center, Flex, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import useResizeObserver from '@react-hook/resize-observer'
import React, { RefObject, useCallback, useMemo, useRef } from 'react'
import { VariableSizeGrid, VariableSizeList } from 'react-window'

export const DataListFooter = ({
  onGoNext,
  onGoPrev,
  goToPage,
  canGoNext,
  canGoPrev,
  pageNum,
  items,
  mobileLayout,
  loading,
  onHeightChange,
  totalPages,
  numItems,
  queryCount,
  gridRef,
  isGrid,
}: {
  onGoNext: () => void
  onGoPrev: () => void
  goToPage: (page: number) => void
  onHeightChange: (height: number) => void
  canGoNext: boolean
  canGoPrev: boolean
  pageNum: number
  numItems: number
  mobileLayout: boolean
  loading: boolean
  items: Record<string, any> | null
  totalPages: number
  queryCount: number | null
  isGrid?: boolean
  gridRef: RefObject<VariableSizeGrid | VariableSizeList>
}) => {
  const pageIndicators = useMemo(() => {
    const indicators: Array<boolean> = []
    for (let i = 0; i < totalPages; i += 1) {
      indicators.push(pageNum - 1 === i)
    }
    return indicators.length > 1 ? indicators : []
  }, [totalPages, pageNum])

  const goNext = useCallback(() => {
    if (gridRef.current) {
      if (isGrid) {
        ;(gridRef as RefObject<VariableSizeGrid>).current?.scrollTo({
          scrollTop: 0,
        })
      } else {
        ;(gridRef as RefObject<VariableSizeList>).current?.scrollTo(0)
      }
    }
    onGoNext()
  }, [onGoNext, gridRef, isGrid])

  const goPrev = useCallback(() => {
    if (gridRef.current) {
      if (isGrid) {
        ;(gridRef as RefObject<VariableSizeGrid>).current?.scrollTo({
          scrollTop: 0,
        })
      } else {
        ;(gridRef as RefObject<VariableSizeList>).current?.scrollTo(0)
      }
    }
    onGoPrev()
  }, [onGoPrev, gridRef, isGrid])

  const itemsLength = items ? Object.keys(items).length : 0
  const totalItemsLength = Math.max(itemsLength, queryCount ?? 0)

  const nextButton = (
    <IconButton
      onClick={goNext}
      cursor={canGoNext ? 'pointer' : 'default'}
      pointerEvents={canGoNext ? 'auto' : 'none'}
      opacity={canGoNext ? 1 : 0.5}
      // size='sm'
      variant="ghost"
      size={mobileLayout ? 'xs' : 'sm'}
      aria-label="next"
      icon={<ChevronRightIcon width="20px" height="20px" />}
    />
  )

  const prevButton = (
    <IconButton
      onClick={goPrev}
      cursor={canGoPrev ? 'pointer' : 'default'}
      pointerEvents={canGoPrev ? 'auto' : 'none'}
      opacity={canGoPrev ? 1 : 0.5}
      // size='sm'
      size={mobileLayout ? 'xs' : 'sm'}
      variant="ghost"
      aria-label="previous"
      icon={<ChevronLeftIcon width="20px" height="20px" />}
    />
  )

  const stackRef = useRef<HTMLDivElement>(null)

  const handleHeightChange = useCallback(
    (entry: ResizeObserverEntry) => {
      onHeightChange(entry.target.clientHeight)
    },
    [onHeightChange],
  )

  useResizeObserver(stackRef, handleHeightChange)

  return (
    <Stack
      ref={stackRef}
      w="100%"
      align="center"
      justify="center"
      spacing={mobileLayout ? 0 : 4}
      direction={mobileLayout ? 'column' : 'row'}
      p={1}>
      {mobileLayout ? null : prevButton}
      {queryCount !== null ? (
        <Stack
          flex={mobileLayout ? 1 : 'unset'}
          flexFlow={mobileLayout ? 'row' : 'column'}
          justify="center"
          spacing={1}
          minW="350px"
          align="center">
          {pageNum && pageIndicators.length > 1 ? (
            <HStack maxW="80%" overflow="hidden" pt={2}>
              {pageIndicators.map((isActive, i) => (
                <Box
                  key={`page-${i}`}
                  cursor="pointer"
                  onClick={() => goToPage(i)}
                  border={`1px solid ${isActive ? '#ababab' : '#cdcdcd'}`}
                  width="14px"
                  position="relative"
                  height="14px"
                  transition="all 300ms"
                  background={isActive ? 'green.500' : '#efefef'}>
                  {i !== pageIndicators.length - 1 ? (
                    <Box
                      position="absolute"
                      top="5px"
                      right="-12px"
                      width="11px"
                      height="2px"
                      background="#cdcdcd"
                    />
                  ) : null}
                </Box>
              ))}
            </HStack>
          ) : null}
          <Flex
            // height="100%"
            flexFlow="row"
            align="center"
            width="100%"
            p={1}
            justify="space-between">
            <Text lineHeight={1} fontSize="xs">
              Page {pageNum}/{totalPages}
            </Text>
            {itemsLength ? (
              <Text lineHeight={1} fontSize="xs">
                Items {(pageNum - 1) * numItems + (numItems ? 1 : 0)} -{' '}
                {Math.min(pageNum * numItems, totalItemsLength)} of {totalItemsLength}
              </Text>
            ) : (
              <Text lineHeight={1} fontSize="xs">
                No items
              </Text>
            )}
          </Flex>
        </Stack>
      ) : (
        <Center flex={1}>
          <Text color="gray.500" size="xs">
            {loading ? 'LOADING...' : 'NO ITEMS'}
          </Text>
        </Center>
      )}
      {mobileLayout ? null : nextButton}
      {mobileLayout ? (
        <Flex w="100%" justify="space-between">
          {prevButton}
          {nextButton}
        </Flex>
      ) : null}
    </Stack>
  )
}
