import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  AssessmentDocument,
  colors,
  ConsentForm,
  FieldMap,
  getDateTimeString,
  getFieldsFromText,
  getFullName,
  isConsentForm,
  isField,
  TemplateNodeFieldMap,
  UploadProgress,
} from '@hb/shared'
import { FORM_ERROR, FormApi, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { CSSProperties, PropsWithChildren, useMemo, useState } from 'react'
import { Form, useFormState } from 'react-final-form'
import { useSlate } from 'slate-react'
import { useCachedUser } from '../../collections/hooks/cached'
import { ThemeContext, ThemeContextData } from '../../contexts'
import { useMe } from '../../hooks/backend/useMe'
import { SolidActionButton } from '../Buttons'
import { CollapseError } from '../CollapseError'
import { useFormSubmit } from '../forms/FinalForm/hooks/useFormSubmit'
import { useFormValidate } from '../forms/FinalForm/hooks/useFormValidate'
import { UploadProgressView } from '../forms/FinalForm/UploadProgressView'
import { usePdfView } from '../PdfView/context'
import { DocumentFormContext, DocumentFormContextValue, useDocumentForm } from './context'

const defaultOnSubmit = async () => ({
  [FORM_ERROR]: 'No onSubmit function provided',
})

const TextEditorFormFooter = ({
  uploads,
  field,
  document,
  handleSubmit,
}: {
  uploads: Record<string, UploadProgress> | null
  field: FieldMap
  document?: AssessmentDocument | ConsentForm
  handleSubmit: () => void
}) => {
  const { submitting, errors } = useFormState({
    subscription: { submitting: true, errors: true },
  })
  const { readOnly } = useDocumentForm()

  const firstErrorText = useMemo(() => {
    const [firstError] = Object.keys(errors ?? {})
    if (!firstError) return ''
    const firstErrorField = field.children[firstError]
    return firstErrorField && isField(firstErrorField)
      ? `${firstErrorField.placeholder}: ${errors?.[firstError]}`
      : `Error: ${errors?.[firstError]}`
  }, [errors, field])

  const { data: signedByUser } = useCachedUser((document as ConsentForm)?.signedBy ?? null)
  const { data: awaitingSignatureByUser } = useCachedUser(
    (document as ConsentForm)?.toBeSignedByUser ?? null,
  )

  const statusText = useMemo(() => {
    if (!document || !isConsentForm(document)) return ''
    if (document.signedOn)
      return `Signed at ${getDateTimeString(document.signedOn)} by ${getFullName(signedByUser)}`
    if (document.toBeSignedByUser)
      return `Awaiting signature from ${getFullName(awaitingSignatureByUser)}`
    return 'Unsigned'
  }, [document, signedByUser, awaitingSignatureByUser])

  return (
    <Flex bg="gray.50" borderTop="1px solid #cdcdcd" w="100%" direction="column">
      <UploadProgressView uploads={uploads} />
      <Flex align="center" gap={2} w="100%" p={2}>
        <Box flex={1}>
          <CollapseError
            style={{
              background: 'transparent',
              color: '#cc0000',
              justifyContent: 'flex-start',
            }}
            error={firstErrorText}
          />
        </Box>
        {readOnly ? (
          <Flex align="center" gap={1}>
            {document && isConsentForm(document) && document.signedOn ? (
              <CheckIcon color={colors.green.hex} />
            ) : null}
            <Text color={colors.green.hex} fontFamily="Open Sans" fontWeight={600} fontSize="sm">
              {statusText}
            </Text>
          </Flex>
        ) : (
          <SolidActionButton
            type="submit"
            pointerEvents={firstErrorText ? 'none' : 'auto'}
            opacity={firstErrorText ? 0.5 : 1}
            isLoading={submitting}
            onClick={handleSubmit}>
            Submit
          </SolidActionButton>
        )}
      </Flex>
    </Flex>
  )
}

const textEditorTheme: ThemeContextData = {
  theme: 'detailed',
  placeholderAbove: false,
  tooltipError: true,
}

export type OnSubmitForm = (
  submitted: any,
  onUploadProgress: (progress: Record<string, UploadProgress>) => void,
  form: FormApi,
) => Promise<ValidationErrors>

export interface DocumentFormProps {
  readOnly?: boolean
  document?: AssessmentDocument | ConsentForm
  height: number
  onFormSubmit?: OnSubmitForm
  style?: CSSProperties
}

export const DocumentFormProvider = ({
  children,
  document,
  style,
  onFormSubmit: onSubmit,
  height,
  field,
  // readOnly,
}: PropsWithChildren<DocumentFormProps & { field: FieldMap }>) => {
  const initData = useMemo(
    () => (document && isConsentForm(document) ? (document.formData ?? {}) : {}),
    [document],
  )

  const [uploads, setUploads] = useState<Record<string, UploadProgress> | null>(null)
  const validate = useFormValidate(field)
  const usedValidate = useMemo(() => {
    if (!document || !isConsentForm(document)) return undefined
    return document.signedOn ? undefined : validate
  }, [validate, document])
  const submit = useFormSubmit(field, onSubmit ?? defaultOnSubmit, setUploads)
  const me = useMe()
  const { toBeSignedById, toBeSignedByMe } = useMemo(() => {
    if (!document || !isConsentForm(document)) {
      return { toBeSignedById: null, toBeSignedByMe: false }
    }
    return {
      toBeSignedById: document.toBeSignedByUser,
      toBeSignedByMe: document.toBeSignedByUser === me?.uid,
    }
  }, [document, me])

  const { data: toBeSignedByUser, loading: signerLoading } = useCachedUser(toBeSignedById)
  const contextData = useMemo<DocumentFormContextValue>(() => {
    return { field, readOnly: !onSubmit, toBeSignedByUser, document, signerLoading, toBeSignedByMe }
  }, [field, onSubmit, toBeSignedByUser, signerLoading, document, toBeSignedByMe])
  const usedHeight = useMemo(() => (typeof height === 'number' ? `${height}px` : height), [height])
  return (
    <DocumentFormContext.Provider value={contextData}>
      <ThemeContext.Provider value={textEditorTheme}>
        <Form
          mutators={{ ...arrayMutators }}
          initialValues={initData}
          validate={usedValidate}
          onSubmit={submit}>
          {({ handleSubmit }) => (
            <Flex h={usedHeight} w="auto" direction="column" style={style}>
              <Box position="relative" minH="0" flex={1} w="auto">
                {children}
              </Box>
              {onSubmit ? (
                <TextEditorFormFooter
                  document={document}
                  field={field}
                  handleSubmit={handleSubmit}
                  uploads={uploads}
                />
              ) : null}
            </Flex>
          )}
        </Form>
      </ThemeContext.Provider>
    </DocumentFormContext.Provider>
  )
}

export const TextEditorDocumentFormProvider = (props: PropsWithChildren<DocumentFormProps>) => {
  const { children: text } = useSlate()
  const { readOnly } = props
  // if readonly, collect all fields from templateText
  const field = useMemo<TemplateNodeFieldMap>(
    () =>
      readOnly
        ? {
            name: '',
            children: getFieldsFromText(text),
          }
        : {
            name: '',
            children: {},
          },
    [text, readOnly],
  )

  return <DocumentFormProvider field={field} {...props} />
}

export const PdfDocumentFormProvider = (props: PropsWithChildren<DocumentFormProps>) => {
  const { attachments } = usePdfView()

  const field = useMemo<FieldMap>(
    () => ({
      name: '',
      children: Object.values(attachments).reduce<FieldMap['children']>((acc, attachment) => {
        if (attachment.type === 'input') {
          return {
            ...acc,
            [attachment.id]: attachment.field,
          }
        }
        return acc
      }, {}),
    }),
    [attachments],
  )

  return <DocumentFormProvider field={field} {...props} />
}
