import { AssessmentDocument, ConsentForm, EditorVersion, isConsentForm } from '@hb/shared'
import React from 'react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorModalContainer } from '../RichText/EditorModalContainer'
import { ReadOnlyEditor } from '../RichText/TextEditor'
import { ReadOnlyEditorProps } from '../RichText/types'
import { ConsentFormPdfView } from './ExternalPdf/ExternalPdfView'

export const DocumentViewModal = ({
  ...props
}: Omit<ReadOnlyEditorProps, 'width' | 'baseZoom' | 'height'> &
  Omit<DefaultModalProps, 'render'> & {
    version: EditorVersion
    document?: AssessmentDocument | ConsentForm
  }) => {
  const { decorators, value, withDownload, document, version, onFormSubmit, ...modalProps } = props

  return (
    <EditorModalContainer
      {...modalProps}
      render={editorSize =>
        document && isConsentForm(document) && document.isExternalPdf ? (
          <ConsentFormPdfView
            consentForm={document}
            editorSize={editorSize}
            onFormSubmit={onFormSubmit}
          />
        ) : (
          <ReadOnlyEditor
            version={version}
            {...editorSize}
            document={document}
            decorators={decorators}
            onFormSubmit={onFormSubmit}
            withDownload={withDownload}
            value={value}
          />
        )
      }
    />
  )
}
