import { Box, Button, Collapse, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { PatientContact } from '../../../Patient/PatientContact'

export const PatientInfoContact = () => {
  const [visible, setVisible] = useState(false)
  return (
    <Flex w="100%" flexFlow="column">
      <Button
        justifyContent="flex-end"
        size="sm"
        color="gray.600"
        onClick={() => setVisible(!visible)}
        variant="link">
        {visible ? '- Hide Contact Info' : '+ Show Contact Info'}
      </Button>
      <Collapse in={visible} unmountOnExit style={{ width: '100%' }}>
        <Box w="100%" mt={1} border="1px solid #cdcdcd" bg="whiteAlpha.500" borderRadius={4} p={2}>
          <PatientContact />
        </Box>
      </Collapse>
    </Flex>
  )
}
