import { Collection, Template, TemplateKey } from '@hb/shared'
import { createContext, Ref } from 'react'
import { ReactEditor } from 'slate-react'

export interface SelectedTemplate {
  collection: Collection<Template>
  templateType: TemplateKey
  templateId: string
}
export const TemplateContext = createContext<
  Partial<{
    selected: SelectedTemplate | null
    templateId?: string
    ref: Ref<ReactEditor>
    select: (selected: SelectedTemplate) => void
    deselect: () => void
  }>
>({})
