import {
  PopulatedUser,
  PracticeVisitDraft,
  SubmittedVisitData,
  toSearchString,
  User,
  UserRoleItem,
} from '@hb/shared'

export const populateVisitDraft = (
  draft: SubmittedVisitData,
  practiceId: string | null,
  patient: User | UserRoleItem | PopulatedUser,
): PracticeVisitDraft => {
  const patientFirst = patient.fname ?? ''
  const patientLast = patient.lname ?? ''
  if (!patientFirst) {
    throw new Error('Patient first name is required')
  }
  if (!patientLast) {
    throw new Error('Patient last name is required')
  }
  if (!practiceId) {
    throw new Error(
      "Practice ID is not selected. For now, add a practice to the patient's pregnancy",
    )
  }

  const keywordsSearchString = draft.keywords
    ? draft.keywords
        .split(',')
        .map(k => k.trim())
        .join('|')
    : ''
  return {
    ...draft,
    practiceId,
    patientFirst,
    patientLast,
    searchString: toSearchString(
      `${patientFirst}|${patientLast}|${draft.date}|${draft.startTime}${keywordsSearchString ? `|${keywordsSearchString}` : ''}`,
    ),
  }
}
