import { Box, Tooltip } from '@chakra-ui/react'
import {
  colors,
  Field,
  getFullName,
  Mode,
  TemplateFieldElement,
  TemplateNodeFieldMap,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { Path } from 'slate'
import { ReactEditor, RenderElementProps } from 'slate-react'
import { CustomRenderElementProps } from '../../../types/editor'
import { FormElement } from '../../forms'
import { useNonEditable } from '../../Templates/useNonEditable'
import { useDocumentForm } from '../context'
import { useElementStyle } from '../hooks'

const pathsEqual = (path1: Path, path2: Path) => {
  if (path1.length !== path2.length) return false
  for (let i = 0; i < path1.length; i += 1) {
    if (path1[i] !== path2[i]) return false
  }
  return true
}

export const TemplateFieldElementView = ({
  field,
  name,
  mode,
  isSelected,
  onSelect,
}: {
  field: Field
  name: string
  mode: Mode
  isSelected: boolean
  onSelect: (event: React.MouseEvent) => void
}) => {
  const { readOnly, signerLoading, toBeSignedByUser, toBeSignedByMe } = useDocumentForm()

  const tooltip = useMemo(() => {
    if (!toBeSignedByMe) {
      return signerLoading
        ? 'Loading signer data...'
        : `To be entered by ${toBeSignedByUser ? getFullName(toBeSignedByUser) : 'ERROR: User not found'}`
    }
    return ''
  }, [signerLoading, toBeSignedByMe, toBeSignedByUser])

  const {
    meta: { error },
  } = useField(name)
  const borderColor = useMemo(() => {
    let bc = '#dedede'
    if (readOnly && error) bc = '#ff5555'
    else if (isSelected && mode === 'Edit') bc = colors.blue.hex
    return bc
  }, [readOnly, error, isSelected, mode])
  return (
    <Box onClick={onSelect} position="relative">
      <Tooltip hasArrow placement="top" label={tooltip}>
        <div>
          <div
            style={{
              pointerEvents: toBeSignedByMe ? 'auto' : 'none',
            }}>
            <FormElement
              field={field}
              readOnly={readOnly}
              name={name}
              style={{
                border: `1px solid ${borderColor}`,
                borderRadius: '6px',
              }}
              // name={node.id}
            />
          </div>
        </div>
      </Tooltip>
    </Box>
  )
}

export const EditorFieldElementView = ({
  children,
  element,
  attributes,
  version,
  editor,
  mode,
}: RenderElementProps & CustomRenderElementProps) => {
  const node = element as TemplateFieldElement
  const { field, readOnly } = useDocumentForm<TemplateNodeFieldMap>()
  const style = useElementStyle(mode, node, version)

  const path = ReactEditor.findPath(editor, element)

  const id = useMemo(
    () =>
      Object.values(field?.children ?? {}).find(f => pathsEqual(f.path, path))?.id ?? '__NONE__',
    [field, path],
  )

  const { onClick, isSelected } = useNonEditable(editor, node, mode)

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={{
        position: 'relative',
        cursor: readOnly ? 'default' : 'pointer',
        alignItems: 'center',
        userSelect: 'none',
        ...style,
      }}>
      <TemplateFieldElementView
        onSelect={onClick}
        isSelected={isSelected}
        mode={mode}
        field={node.field}
        name={id}
      />
      <span
        style={{
          display: 'none',
        }}>
        {children}
      </span>
    </div>
  )
}
