import { AnyObject, SortKey, WithId } from '../utils'

export interface DataListProps<Snippet extends AnyObject> {
  selectUser: (userId: string) => void
  sortBy: (k: SortKey<Snippet>) => void
  items?: Record<string, Snippet> | null
  active: boolean
}

export interface Preview {
  id: string
  item: string
  position: { x: number; y: number }
  cell: { rowIndex: number; columnIndex: number }
}

export type TransformData<InData, OutData> = (data: InData) => OutData
export interface ListMonthDivider {
  _type: 'divider'
  type: 'month'
  propPath: string
}

export interface ListDividerItem {
  _type: 'divider'
  label: string
}

export const isListDividerItem = (item: WithId<any> | ListDividerItem): item is ListDividerItem =>
  item._type === 'divider'

export type ListDivider = ListMonthDivider
export type ListDividerType = ListDivider['type']
