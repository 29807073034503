import { WarningIcon } from '@chakra-ui/icons'
import { Checkbox, Flex, Text } from '@chakra-ui/react'
import { adminRoles, capitalizeFirstLetter, getFullName, SubmittedAuthentication } from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useAuth } from '../../../../store'
import { UserBadge } from '../../UserBadge'

import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../../../contexts/PracticeAccess/PracticeAccessProvider'
import { ScreenContext } from '../../../../contexts/ScreenContext'
import { useAppGroup } from '../../../../hooks/backend/user/useAppGroup'
import signIcon from '../../../../icons/signature.svg'
import { SvgIcon } from '../../../../icons/SvgIcon'
import { SolidActionButton } from '../../../Buttons/ActionButton'

export const AuthenticateCheckbox = ({
  itemName,
  onChange,
  checked,
}: {
  itemName: string
  onChange: (val: boolean) => void
  checked: boolean
}) => (
  <Flex gap={1} w="100%" flexFlow="column">
    <Flex gap={2} w="100%" align="center">
      <Checkbox
        onChange={e => {
          onChange(e.target.checked)
        }}
        isChecked={checked}
        colorScheme="green">
        <Text fontSize="sm" fontWeight={600} color="gray.600">
          I certify that the above {itemName} is accurate
        </Text>
      </Checkbox>
    </Flex>
  </Flex>
)

export const AuthenticateAndSubmit = ({
  authChecked,
  onChange,
  isReauth,
  itemName,
  practiceId,
  onSubmit,
}: {
  authChecked: boolean
  isReauth?: boolean
  onChange: (val: boolean) => void
  onSubmit: (auth: SubmittedAuthentication) => Promise<void>
  itemName: string
  practiceId: string | null
}) => {
  const { isMobile } = useContext(ScreenContext)
  const authUser = useAuth(s => s.authUser)
  const user = useAuth(s => s.user)
  const { practiceAccess } = usePracticeAccess()
  const canSign = useMemo(() => {
    if (!practiceId) return false
    const practiceRole = practiceAccess?.[practiceId]
    if (!practiceRole) return false
    return !!adminRoles.includes(practiceRole)
  }, [practiceAccess, practiceId])

  const appGroup = useAppGroup()
  const { processResponse, showMessage } = useContext(PopUpMessageContext)
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = useCallback(async () => {
    if (!authUser) {
      processResponse({
        error: 'Please log in to authenticate this visit',
      })
      return
    }
    if (!practiceId) {
      processResponse({
        error: 'Practice ID is missing',
      })
      return
    }
    if (!user) {
      processResponse({ error: 'No user found' })
      return
    }
    if (!authChecked) {
      processResponse({
        error: `Please certify that the above ${itemName} is accurately reported`,
      })
      return
    }
    const { lname } = user
    const { fname } = user
    if (!lname || !fname) {
      processResponse({
        error: 'Please fill out your first and last name before signing authentications',
      })
      return
    }
    setSubmitting(true)
    try {
      await onSubmit({
        practiceId,
        userId: authUser.uid,
      })
      processResponse({ success: `Signed and submitted ${itemName}!` })
    } catch (err: any) {
      showMessage({
        type: 'error',
        text: 'Error signing pdf',
        subText: err.message,
      })
    }
    setSubmitting(false)
  }, [authChecked, authUser, onSubmit, showMessage, user, processResponse, practiceId, itemName])

  if (!authUser) {
    return (
      <Flex p={2} w="100%" flexFlow="row">
        <WarningIcon color="gray.600" />
        <Text fontSize="sm" color="gray.500">
          Please log in to authenticate and submit this {itemName}
        </Text>
      </Flex>
    )
  }

  if (!canSign) {
    return (
      <Flex align="center" gap={1} p={2} w="100%" flexFlow="row">
        <WarningIcon color="gray.600" />
        <Text fontSize="sm" color="gray.500">
          You must be an admin of the practice to sign and submit this {itemName}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex gap={2} p={2} w="100%" flexFlow="column">
      <Flex gap={2} position="relative" w="100%" justify="center">
        <Flex position="relative" top="9px" flex={1} borderTop="1px solid #cdcdcd" />
        <SvgIcon src={signIcon} />
        <Text lineHeight={1} color="gray.600">
          Authenticate and Submit
        </Text>
        <Flex position="relative" top="9px" flex={1} borderTop="1px solid #cdcdcd" />
      </Flex>
      <Flex gap={2} align="center" w="100%">
        <Text fontWeight={600} color="gray.600" fontSize="sm">
          {isReauth ? 'Re-' : ''}Authenticating as:
        </Text>
        <UserBadge userGroup={appGroup} userId={authUser.uid} />
        <Text fontSize="sm" color="gray.600">
          {getFullName(user)}
        </Text>
      </Flex>
      <Flex
        bg="white"
        border="1px solid #cdcdcd"
        gap={2}
        py={2}
        px={3}
        borderRadius={6}
        flexFlow={isMobile ? 'column' : 'row'}
        w="100%"
        align="center">
        <Flex flex={1}>
          <AuthenticateCheckbox onChange={onChange} checked={authChecked} itemName={itemName} />
        </Flex>
        <SolidActionButton
          isLoading={submitting}
          onClick={handleSubmit}
          isDisabled={!authChecked}
          gap={2}>
          <SvgIcon
            filter="invert(100%) brightness(200%) drop-shadow(1px 1px 3px #00000077)"
            src={signIcon}
          />
          <Text>Sign and Submit {capitalizeFirstLetter(itemName)}</Text>
        </SolidActionButton>
      </Flex>
    </Flex>
  )
}
