import { Button, Flex, Stack, Text } from '@chakra-ui/react'
import {
  CallInRequest,
  colors,
  InsuranceCoverageRequest,
  InsuranceProvider,
  LogEntry,
  PopulatedAssessment,
  PopulatedUser,
  providersCollection,
  WithId,
} from '@hb/shared'
import { updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useCollection } from '../../../../collections/hooks/useCollection'
import { useApp } from '../../../../contexts/AppContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useMe } from '../../../../hooks'
import { Loading } from '../../../Loading'
import { ClaimNextActions } from './ClaimNextActions'
import { LogEntryModal, LogEntryView } from './LogEntry'
import { PlanNextActions } from './PlanNextActions'

const getRequestLogItem = (request: InsuranceCoverageRequest): LogEntry => ({
  text: 'Insurance Coverage Request',
  updatedBy: request.requestedBy,
  updatedByGroup: 'admin',
  updatedOn: request.requestedOn,
  color: colors.indigo.hex,
  isInjected: true,
})

const getCallInRequestsLogItem = (request: CallInRequest): LogEntry => ({
  text: 'Call-In Request',
  updatedBy: request.by,
  updatedOn: request.on,
  createdBy: request.by,
  createdOn: request.on,
  updatedByGroup: 'admin',
  color: colors.indigo.hex,
  isInjected: true,
})

const injectLogItems = (
  user: PopulatedUser | null,
  assessment: PopulatedAssessment | null,
  insurers: WithId<InsuranceProvider>[],
) => {
  const now = Date.now()
  const { insurancePlans: plans } = user ?? {}
  const {
    additional: additionalPlans,
    requests: coverageRequests,
    primary,
    secondary,
  } = plans ?? {}
  const { log, signedOnDate, patientId, createdByGroup, createdOn, createdBy } = assessment ?? {}
  const { joinedOn } = user ?? {}
  const injected = { ...log }
  if (signedOnDate) {
    injected[signedOnDate] = {
      text: 'Patient signed on',
      createdBy: patientId,
      color: colors.green.hex,
      updatedBy: patientId,
      updatedOn: signedOnDate,
      createdOn: signedOnDate,
      createdByGroup: 'patient',
      isInjected: true,
    }
  }

  // const primaryTerminationDate = primaryCoverage?.terminationDate
  // const medicaidTerminationDate = medicaidCoverage?.terminationDate
  const primaryTerminationDate = primary?.terminationDate
  if (primaryTerminationDate && primaryTerminationDate < now) {
    injected[primaryTerminationDate] = {
      text: 'Primary insurance coverage terminated',
      updatedBy: patientId,
      updatedByGroup: 'patient',
      updatedOn: primaryTerminationDate,
      color: colors.red.hex,
      isInjected: true,
    }
  }
  const setAsPrimaryHistory = primary?.history?.filter(h => h.type === 'setAsPrimary') ?? []
  const lastSetAsPrimary = setAsPrimaryHistory[setAsPrimaryHistory.length - 1]
  if (lastSetAsPrimary) {
    injected[lastSetAsPrimary.on] = {
      text: 'Primary insurance set',
      updatedBy: lastSetAsPrimary.by,
      updatedByGroup: 'admin',
      updatedOn: lastSetAsPrimary.on,
      color: colors.green.hex,
      isInjected: true,
    }
  }

  const secondaryTerminationDate = secondary?.terminationDate
  if (secondaryTerminationDate && secondaryTerminationDate < now) {
    injected[secondaryTerminationDate] = {
      text: 'Medicaid coverage terminated',
      updatedBy: patientId,
      updatedByGroup: 'patient',
      updatedOn: secondaryTerminationDate,
      color: colors.red.hex,
      isInjected: true,
    }
  }

  Object.values(additionalPlans ?? {}).forEach(plan => {
    Object.values(plan.callInRequests ?? {}).forEach(req => {
      injected[req.on] = getCallInRequestsLogItem(req)
    })
    if (plan.fromRequest) {
      injected[plan.fromRequest.requestedOn] = getRequestLogItem(plan.fromRequest)
    }
    if (plan.createdOn) {
      const insurer = insurers.find(i => i.id === plan.insuranceProviderId)
      injected[plan.createdOn] = {
        text: `Additional Plan Created${insurer ? `\n${insurer.name} | ${plan.memberId ?? 'No member ID'}` : ''}`,
        createdBy: plan.createdBy,
        updatedByGroup: 'admin',
        updatedOn: plan.createdOn,
        createdOn: plan.createdOn,
        color: colors.green.hex,
        isInjected: true,
      }
    }
    if (plan.terminationDate && plan.terminationDate < now) {
      injected[plan.terminationDate] = {
        text: 'Additional plan terminated',
        updatedBy: patientId,
        updatedByGroup: 'patient',
        updatedOn: plan.terminationDate,
        color: colors.red.hex,
        isInjected: true,
      }
    }
  })

  Object.values(coverageRequests ?? {}).forEach(req => {
    injected[req.requestedOn] = getRequestLogItem(req)
  })

  if (joinedOn) {
    injected[joinedOn] = {
      text: 'User joined (first sign in)',
      updatedBy: patientId,
      updatedByGroup: 'patient',
      updatedOn: joinedOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  if (createdOn && createdOn !== signedOnDate) {
    injected[createdOn] = {
      text: 'Pregnancy created',
      updatedBy: createdBy,
      createdBy,
      updatedByGroup: createdByGroup ?? 'patient',
      updatedOn: createdOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  if (assessment?.submittedOn) {
    injected[assessment.submittedOn] = {
      text: 'Questionnaire submitted',
      updatedByGroup: assessment.submittedByGroup ?? 'patient',
      updatedBy: assessment.submittedBy ?? 'none',
      updatedOn: assessment.submittedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.correctionsUpdatedBy && assessment.correctionsUpdatedOn) {
    injected[assessment.correctionsUpdatedOn] = {
      text: 'Answers corrected',
      updatedBy: assessment.correctionsUpdatedBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.correctionsUpdatedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.draftSavedOn && assessment.draftSavedBy) {
    injected[assessment.draftSavedOn] = {
      text: 'Assessment draft saved',
      updatedBy: assessment.draftSavedBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.draftSavedOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }

  if (assessment?.previousResults && assessment.previousResults.length > 0) {
    assessment.previousResults.forEach(res => {
      const { insuranceProviderId } = res
      const insurer = insurers.find(i => i.id === insuranceProviderId)
      if (res.sentOn && res.sentBy) {
        injected[res.sentOn] = {
          text: res.insuranceProviderId
            ? `Previous${insurer ? ` ${insurer.name}` : ''} assessment sent`
            : 'Previous assessment sent',
          updatedBy: res.sentBy,
          updatedByGroup: 'admin',
          updatedOn: res.sentOn,
          color: colors.indigo.hex,
          isInjected: true,
        }
      }
    })
  }

  if (assessment?.sentBy && assessment.sentOn) {
    const insurer = insurers.find(i => i.id === assessment.resultsInsurerId)
    injected[assessment.sentOn] = {
      text: insurer ? `${insurer.name} Assessment Sent` : 'Assessment sent',
      updatedBy: assessment.sentBy,
      updatedByGroup: 'admin',
      updatedOn: assessment.sentOn,
      color: colors.indigo.hex,
      isInjected: true,
    }
  }
  if (assessment?.resultsViewedOn) {
    const insurer = insurers.find(i => i.id === assessment.resultsInsurerId)
    injected[assessment.resultsViewedOn] = {
      text: insurer ? `${insurer.name} Assessment Viewed` : 'Assessment viewed',
      updatedBy: assessment.patientId ?? '',
      updatedByGroup: 'patient',
      updatedOn: assessment.resultsViewedOn,
      color: colors.green.hex,
      isInjected: true,
    }
  }
  return injected
}

export const AssessmentLog: React.FC<{
  maxHeight: number
}> = ({ maxHeight }) => {
  const data = useContext(ProfileContext)
  const me = useMe()
  const {
    user,
    selectedAssessment: { populated: selectedAssessment, adminRef },
    claims: { data: claims },
  } = data
  const { appName } = useApp()
  const [selectedLogId, setSelectedLogId] = useState<number | undefined>(undefined)

  const { items: insurers } = useCollection(providersCollection)
  const injectedLog = useMemo(
    () => injectLogItems(user, selectedAssessment ?? null, insurers),
    [user, selectedAssessment, insurers],
  )

  const sortedKeys = useMemo(
    () =>
      injectedLog
        ? Object.keys(injectedLog)
            .map(k => parseInt(k, 10))
            .sort((a, b) => b - a)
        : [],
    [injectedLog],
  )
  const hasClaims = useMemo(() => Object.keys(claims ?? {}).length > 0, [claims])

  const handleUpdateLog = useCallback(
    async (id: string, newEntry: LogEntry) =>
      adminRef
        ? updateDoc(adminRef, `log.${id}`, newEntry)
            .then(() => ({ success: 'Updated!' }))
            .catch((err: any) => {
              console.error(err)
              return { error: err.message }
            })
        : { error: 'No adminRef' },
    [adminRef],
  )

  if (!selectedAssessment) {
    return <Loading text="Loading pregnancy..." />
  }

  return (
    <Flex
      background="gray.50"
      flexGrow={1}
      overflow="hidden"
      // width={`${width}px`}
      width="100%"
      maxH={maxHeight}
      direction="column">
      {appName === 'app' ? (
        <Flex
          // px={2}
          width="100%"
          align="center"
          direction="column"
          borderBottom="1px solid #dedede">
          {hasClaims ? <ClaimNextActions /> : null}
          <PlanNextActions />
        </Flex>
      ) : null}
      <Flex direction="column" w="100%" flex={1} minH="0">
        <Flex px={3} borderBottom="1px solid #cdcdcd" align="center" w="100%">
          <Text py={1} color="#777" fontWeight="600">
            Assessment Log
          </Text>
          {appName === 'app' ? (
            <Flex ml="auto">
              <Button size="xs" variant="outline" onClick={() => setSelectedLogId(-1)}>
                + New Entry
              </Button>
              <LogEntryModal
                isOpen={selectedLogId === -1}
                onClose={() => setSelectedLogId(undefined)}
                placement="left"
                createdOn={selectedLogId}
                onSubmit={newEntry => handleUpdateLog(`${Date.now()}`, newEntry)}
              />
            </Flex>
          ) : null}
        </Flex>
        <Flex
          direction="column"
          overflowY="auto"
          flex={1}
          width="100%"
          minH="0"
          bg="white"
          w="100%">
          <Stack spacing={0} w="100%">
            {injectedLog ? (
              sortedKeys.map((e, i) => {
                const logEntry = injectedLog[e]
                if (!logEntry) return null
                return (
                  <LogEntryView
                    placement="left"
                    update={
                      logEntry.isInjected ||
                      logEntry.createdBy !== me?.uid ||
                      '' ||
                      logEntry.updatedBy !== me?.uid
                        ? undefined
                        : updated => handleUpdateLog(`${e}`, updated)
                    }
                    index={i}
                    key={e}
                    select={() => setSelectedLogId(e)}
                    deselect={() => setSelectedLogId(undefined)}
                    selected={selectedLogId === e}
                    {...logEntry}
                    createdOn={e}
                  />
                )
              })
            ) : (
              <Text p={2} pl={4} pr={4} color="#777">
                <i>No log entries yet</i>
              </Text>
            )}
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  )
}
