import { Text } from '@chakra-ui/react'
import { getPracticeUsersCollectionPath, UserRoleItem } from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useApp } from '../../contexts/AppContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { ScreenContext } from '../../contexts/ScreenContext'
import { SearchBarProvider } from '../../contexts/SearchBarContext'
import { DataListTab } from '../../types/data'
import { adminColumns } from '../DataList/columns/users'
import { DataList } from '../DataList/DataList'
import { PageContainer } from '../Views/PageContainer'
import { AdminProfile } from './AdminProfile'
import { AdminProfileTabName } from './types'

const adminTabs: Record<string, DataListTab<UserRoleItem>> = {
  Admins: {
    role: ['admin', 'super-admin'],
    collection: 'user-roles',
    defaultSortKey: 'lname',
    searchStringPath: 'stringified',
    columns: adminColumns,
    itemName: 'Admin',
  },
}

export const AdminsView = () => {
  const { adminId, displayedItem } = useParams()
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()

  const tabs = useMemo(() => {
    if (appName === 'app') return adminTabs
    if (selectedPracticeId) {
      return {
        Admins: {
          ...adminTabs.Admins,
          collection: getPracticeUsersCollectionPath(selectedPracticeId),
        },
      }
    }
    return null
  }, [appName, selectedPracticeId])
  const basePath = useMemo(() => (appName === 'app' ? '/admin/admins' : '/admins'), [appName])

  const { contentHeight } = useContext(ScreenContext)
  const navigate = useNavigate()
  if (!selectedPracticeId && appName === 'providers-app')
    return (
      <Text py={2} px={3} bg="whitesmoke" color="gray.500" borderRadius={6} boxShadow="md">
        You are not an admin of any practice
      </Text>
    )
  if (!tabs)
    return (
      <Text py={2} px={3} bg="whitesmoke" color="gray.500" borderRadius={6} boxShadow="md">
        Invalid app name
      </Text>
    )
  return (
    <PageContainer>
      <SearchBarProvider>
        <DataList height={contentHeight - 50} rootPath={basePath} tabs={tabs} />
        <AdminProfile
          onClose={() => {
            navigate(basePath)
          }}
          tab={displayedItem as AdminProfileTabName}
          onTabSelect={tabName => {
            navigate(`${basePath}/${adminId}/${tabName}`)
          }}
          userId={adminId}
        />
      </SearchBarProvider>
    </PageContainer>
  )
}
