export const THREAD_LIST_HEIGHT = 530
export const THREAD_HEADER_HEIGHT = 42

import { colors, NotificationSoundId } from '@hb/shared'
import bikebell from '../../sounds/bikebell.mp3'
import chime from '../../sounds/chime.mp3'
import ding from '../../sounds/ding.mp3'
import sleighbell from '../../sounds/sleighbell.mp3'

export const notificationSounds: Record<NotificationSoundId, string> = {
  ding,
  'bike-bell': bikebell,
  chime,
  'sleigh-bell': sleighbell,
}

export const notificationSoundGradients: Record<NotificationSoundId, string> = {
  // green
  ding: `linear-gradient(60deg, ${colors.green.hex} 0%, ${colors.lightgreen.hex} 100%)`,
  // blue
  chime: `linear-gradient(60deg, ${colors.blue.hex} 0%, ${colors.lightblue.hex} 100%)`,
  // purple
  'bike-bell': `linear-gradient(60deg, ${colors.purple.hex} 0%, ${colors.lightpurple.hex} 100%)`,
  // christmas
  'sleigh-bell': `linear-gradient(60deg, ${colors.red.hex} 0%, ${colors.green.hex} 100%)`,
}

export const defaultNotificationSound: NotificationSoundId = 'ding'
