import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { signOut, useAuth } from '../../store'
import { ActionButton } from '../Buttons/ActionButton'
import { DefaultModal } from '../Modals/DefaultModal'
import { SignInContent } from './SignInContent'

// const UserPanelTwoFactorView = ({ factors }: { factors: MultiFactorInfo[] | null }) => (
//   <HStack px={2} py="0.25rem" bg={factors?.length ? 'green.600' : 'yellow.500'} w="100%">
//     <Text fontSize="xs" color="white" fontWeight="medium" fontFamily="hero-new">
//       TWO-FACTOR AUTHENTICATION
//     </Text>
//     <Button
//       ml="auto"
//       onClick={() => useAppModals.getState().multiFactorAuth.open()}
//       color="white"
//       borderRadius="full"
//       size="xs"
//       bg="blackAlpha.200"
//       _hover={{ bg: 'blackAlpha.300' }}>
//       OPEN
//     </Button>
//   </HStack>
// )

// const UserPanelPushNotificationsView = () => {
//   const appRole = useAppRole()
//   const { data: roleItem } = useMyRoleItem()
//   const { webPushNotificationsToken } = roleItem ?? {}

//   if (appRole === 'user') return null

//   return (
//     <HStack px={2} py="0.25rem" bg={webPushNotificationsToken ? 'green.600' : 'gray.400'} w="100%">
//       <Text fontSize="xs" color="white" fontWeight="medium" fontFamily="hero-new">
//         PUSH NOTIFICATIONS
//       </Text>
//       <Button
//         ml="auto"
//         onClick={() => useAppModals.getState().pushNotificationsSettings.open()}
//         color="white"
//         borderRadius="full"
//         size="xs"
//         bg="blackAlpha.200"
//         _hover={{ bg: 'blackAlpha.300' }}>
//         OPEN
//       </Button>
//     </HStack>
//   )
// }

// const UserPronounsPreview = ({ pronouns }: { pronouns: string }) => {
//   return (
//     <Box bg="gray.50" w="100%" px={2} py={1}>
//       <Text fontSize="xs" color="gray.500" fontWeight="medium" fontFamily="hero-new">
//         PRONOUNS
//       </Text>
//       <Text fontSize="sm" color="gray.600" fontWeight="medium" lineHeight={1}>
//         {pronouns}
//       </Text>
//     </Box>
//   )
// }

export const UserPanel: React.FC<{ noSignOut?: boolean }> = ({ noSignOut }) => {
  const { user, authUser } = useAuth()

  const navigate = useNavigate()
  const handleSignOut = useCallback(() => {
    navigate('/')
    signOut()
  }, [navigate])

  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    if (authUser?.emailVerified) {
      setModalIsOpen(false)
    }
  }, [authUser])

  return (
    <Flex w="100%" direction="column" borderBottomRadius={4}>
      {noSignOut ? null : (
        <Flex
          borderTop={authUser ? '1px solid #dededeff' : '0px solid #dedede00'}
          transition="all 300ms"
          w="100%"
          bg={authUser ? 'gray.100' : 'transparent'}>
          <ActionButton
            border="none"
            w="100%"
            boxShadow="none"
            justifyContent="center"
            id="sign-in-button"
            onClick={
              !user
                ? () => {
                    setModalIsOpen(true)
                  }
                : handleSignOut
            }
            borderRadius={4}
            background={colors.green.hex}
            py={2}
            _hover={{ background: 'green.300' }}
            size="none"
            color="white"
            fontSize="sm"
            height={authUser ? '30px' : '50px'}
            mt={0}>
            <HStack filter="drop-shadow(1px 1px 4px #00000066)">
              <Text>{authUser ? 'Sign out' : 'Sign in / Register'}</Text>
              {authUser ? <ArrowForwardIcon /> : null}
            </HStack>
          </ActionButton>
        </Flex>
      )}
      <DefaultModal
        overlayHeader
        isOpen={modalIsOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          setModalIsOpen(false)
        }}
        closeOnEsc={false}
        render={() => (
          <SignInContent
            onClose={() => {
              setModalIsOpen(false)
            }}
          />
        )}
      />
    </Flex>
    // </Container>
  )
}
