import {
  defaultStageValidate,
  FieldMap,
  FieldMapValue,
  Form,
  InfoStage,
  isField,
  isInfoStage,
  OnUploadProgress,
  StageStatus,
} from '@hb/shared'
import { FormApi, ValidationErrors } from 'final-form'
import { FC, useCallback, useMemo, useState } from 'react'

import { get as nestedGet, set as nestedSet } from 'nested-property'
import { processFieldMapData } from '../../../backend/db'
import { FormWizardContextData } from './context'

export const useFormWizardData = (
  form: Form,
  data: FieldMapValue | undefined,
  corrections: (FieldMapValue & { correctionsShared?: any | null }) | undefined | null,
  onSubmit: (submitted: FieldMapValue) => Promise<ValidationErrors>,
  stageIdProp?: string | null,
  selectStage?: (selected: string | null) => void,
  readOnly?: boolean,
  ReadOnlyFooter?: FC,
  // baseStoragePath?: string,
  getStoragePath?: (stageId: string) => string | null,
  adminView?: boolean,
): FormWizardContextData => {
  const [_stageId, setStageId] = useState<string | null>(null)
  const stageId = useMemo(
    () => (stageIdProp !== undefined ? stageIdProp : _stageId),
    [_stageId, stageIdProp],
  )
  const { nextStage } = useMemo(() => {
    const keys = Object.keys(form?.stages ?? {})
    let next: string | null = null
    if (stageId) {
      const currIdx = keys.indexOf(stageId)
      next = keys[currIdx + 1] ?? null
    }
    return { stageKeys: keys, nextStage: next }
  }, [stageId, form])

  const goToStage = useCallback(
    (goToId: string | null) => {
      if (selectStage) selectStage(goToId)
      else setStageId(goToId)
    },
    [selectStage],
  )

  const storagePath = useMemo(() => {
    if (!stageId) return null
    if (!getStoragePath) return null
    return getStoragePath(stageId)
  }, [getStoragePath, stageId])
  const stage = useMemo(() => (form && stageId ? form.stages[stageId] : null), [stageId, form])

  const onSubmitStage = useCallback(
    async (
      submitted: FieldMapValue | undefined,
      onUploadProgress: OnUploadProgress,
      formApi?: FormApi,
    ): Promise<ValidationErrors> => {
      if (!stage || isInfoStage(stage)) {
        goToStage(nextStage)
        return { success: 'Info stage' }
      }

      if (!stageId) {
        return { error: 'No selected' }
      }
      if (!submitted) {
        goToStage(nextStage)
        return { error: 'No data' }
      }
      const path = `${form?.path ? `${form.path}.` : ''}${stageId}`

      try {
        const processed = await processFieldMapData(
          storagePath ?? '',
          stage,
          submitted,
          data,
          onUploadProgress,
        )
        const updated = {}
        nestedSet(updated, path, processed)
        await onSubmit(updated)
        if (formApi) formApi.restart()
        goToStage(nextStage)
        return { success: 'Updated successfully!' }
      } catch (e: any) {
        return { error: e.message }
      }
    },
    [form, nextStage, stageId, stage, goToStage, onSubmit, data, storagePath],
  )
  return useMemo(
    () => ({
      nextStage,
      form,
      open: (openedId: string) => goToStage(openedId),
      close: () => goToStage(null),
      stage,
      stageId,
      onSubmitStage,
      storagePath,
      data: data ?? {},
      adminView,
      corrections: corrections ?? null,
      readOnly,
      ReadOnlyFooter,
    }),
    [
      nextStage,
      form,
      goToStage,
      stage,
      stageId,
      corrections,
      onSubmitStage,
      storagePath,
      adminView,
      data,
      readOnly,
      ReadOnlyFooter,
    ],
  )
}

export const getStageStatus = (
  stage: InfoStage | FieldMap | null | undefined,
  value?: FieldMapValue,
  es?: ValidationErrors,
): StageStatus => {
  if (stage && isInfoStage(stage)) return 'info'
  const validate = stage?.validate ?? defaultStageValidate(stage, true)
  let errors
  if (!es) errors = validate(value)
  else errors = es
  if (!stage || !value) return 'incomplete'
  if (errors) {
    const numErrors = Object.keys(errors).length
    const numRequired = Object.keys(stage.children).filter(childName => {
      const child = stage.children[childName]
      if (isInfoStage(child)) return false
      if (isField(child)) {
        return !child.optional
      }
      return true
    }).length
    if (numErrors) {
      if (numErrors === numRequired) {
        return 'incomplete'
      }
      return 'in progress'
    }
  }
  return 'complete'
}

export function useStageState(
  form: Form,
  stage: FieldMap | InfoStage | undefined | null,
  formData: FieldMapValue | undefined | null,
  formCorrections: (FieldMapValue & { correctionsShared?: any | null }) | null | undefined,
  stagePath?: string | null,
) {
  const data = useMemo(
    () =>
      stagePath || form.path
        ? nestedGet(formData, `${form.path ? `${form.path}.` : ''}${stagePath ?? ''}`)
        : formData,
    [formData, stagePath, form],
  )

  const corrections = useMemo(() => {
    const data =
      stagePath || form.path
        ? nestedGet(formCorrections, `${form.path ? `${form.path}.` : ''}${stagePath ?? ''}`)
        : undefined
    const correctionsShared =
      (data?.correctionsShared || formCorrections?.correctionsShared) ?? null
    return { ...data, correctionsShared }
  }, [formCorrections, stagePath, form])

  const errors = useMemo(() => {
    if (!stage) return undefined
    if (isInfoStage(stage)) return undefined
    const validate = stage.validate ?? defaultStageValidate(stage, true)
    return validate(data)
  }, [data, stage])

  const status = useMemo<StageStatus>(
    () => getStageStatus(stage, data, errors),
    [stage, errors, data],
  )

  return useMemo(
    () => ({
      value: data,
      corrections,
      errors,
      status,
    }),
    [data, errors, status, corrections],
  )
}
