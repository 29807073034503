import { colors, Field } from '@hb/shared'
import { useContext, useMemo } from 'react'
import { FieldMetaState } from 'react-final-form'
import { ThemeContext } from '../../../../contexts'

export const useStatusColor = (field: Field, meta: FieldMetaState<any>) => {
  const { error, touched, active } = meta ?? {}
  const { theme } = useContext(ThemeContext)
  return useMemo(() => {
    if (theme !== 'detailed') return undefined
    let color = '#777'
    if (error && touched) color = colors.red.hex
    else if (active) color = colors.indigo.hex
    return `0 0 2px ${color}`
  }, [theme, error, touched, active])
}
