import { AddExternalPdfTemplateArgs, Template, TemplateKey } from '@hb/shared'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../backend'

export const addExternalPdfTemplate = async <Key extends TemplateKey>(
  data: AddExternalPdfTemplateArgs<Key>,
): Promise<Template> => {
  const addTemplateFunc = httpsCallable<AddExternalPdfTemplateArgs<Key>, Template>(
    functions,
    'addExternalPdfTemplate',
  )
  const { data: template } = await addTemplateFunc(data)
  return template
}
