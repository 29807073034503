import { EditIcon } from '@chakra-ui/icons'
import { Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import {
  formatDollarValue,
  objectToArray,
  PracticeInvoiceAdditionalPatient,
  PracticeInvoiceLineItem,
  UpdateCallback,
  WithId,
} from '@hb/shared'

import React, { useCallback, useMemo, useState } from 'react'
import {
  addLineItemToAssessmentInvoice,
  removeAdditionalPatientFromInvoice,
  removeLineItemFromAssessmentInvoice,
} from '../../../backend/invoices'
import { DeleteButton } from '../../Buttons'
import { getRowBackground } from '../../DataView'
import { Expandable } from '../../Expandable'
import { FeeBadge } from '../FeeBadge'
import { IndexCircle } from '../IndexCircle'
import { EditableLineItemView } from '../LineItems/EditableLineItemView'
import { LineItemPopover } from '../LineItems/LineItemPopover'
import { EditAdditionalPatientModal } from './EditAdditionalPatientModal'

export const InvoiceAdditionalPatientView = ({
  patient,
  invoiceId,
  index,
}: {
  patient: WithId<PracticeInvoiceAdditionalPatient>
  invoiceId: string
  index: number
}) => {
  const totalAmount = useMemo(
    () => Object.values(patient.lineItems).reduce((total, item) => total + item.amount, 0),
    [patient],
  )

  const lineItemsArr = useMemo(() => objectToArray(patient.lineItems ?? {}), [patient])

  const addLineItem = useCallback(
    async (
      value: PracticeInvoiceLineItem & { lineItemId: string | null },
    ): Promise<UpdateCallback> => {
      try {
        const res = await addLineItemToAssessmentInvoice({
          ...value,
          invoiceId,
          assessmentId: null,
          additionalPatientId: patient.id,
        })
        return res.data
      } catch (e: any) {
        return { error: e?.message ?? 'Error adding line item' }
      }
    },
    [patient, invoiceId],
  )

  const removeLineItem = useCallback(
    async (lineItemId: string): Promise<UpdateCallback> => {
      try {
        const res = await removeLineItemFromAssessmentInvoice({
          invoiceId,
          assessmentId: null,
          lineItemId,
          additionalPatientId: patient.id,
        })
        return res.data
      } catch (err: any) {
        console.error(err)
        return { error: err?.message ?? 'Error removing line item' }
      }
    },
    [patient, invoiceId],
  )

  const onRemovePatient = useCallback(async () => {
    try {
      const res = await removeAdditionalPatientFromInvoice({
        additionalPatientId: patient.id,
        invoiceId,
      })
      return res.data
    } catch (err: any) {
      console.error(err)
      return { error: err?.message ?? 'Error removing additional patient' }
    }
  }, [patient, invoiceId])

  const totalReceived = useMemo(
    () =>
      Object.values(patient.lineItems).reduce(
        (total, item) => total + (item.receivedAmount ?? 0),
        0,
      ),
    [patient],
  )

  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <Expandable
        style={{ background: getRowBackground(index) }}
        header={() => (
          <HStack
            spacing={2}
            // bg={`${colors.green.hex}77`}
            py={2}
            px={2}
            key={patient.id}
            w="100%">
            <Flex alignItems="center" flex={1}>
              <IndexCircle index={index} />
              <Text
                whiteSpace="nowrap"
                pl={2}
                color="#666"
                fontFamily="Comfortaa"
                fontSize="sm"
                fontWeight={600}>
                {patient.lname}, {patient.fname}
              </Text>
              <IconButton
                ml={2}
                aria-label="Edit additional patient"
                size="xs"
                variant="ghost"
                icon={<EditIcon />}
                onClick={e => {
                  e.stopPropagation()
                  setIsEditing(true)
                }}
              />
              <DeleteButton
                ml={1}
                itemName={`additional patient ${patient.fname} ${patient.lname}`}
                onDelete={onRemovePatient}
              />
            </Flex>
            <HStack width="120px" spacing={1}>
              {totalReceived ? (
                <Text px={1} fontSize="sm" fontWeight={600} fontFamily="Open Sans" color="gray.500">
                  {formatDollarValue(totalReceived)}
                </Text>
              ) : null}
            </HStack>
            <HStack width="80px" spacing={1}>
              <FeeBadge color="green.600" amount={totalAmount} />
            </HStack>
          </HStack>
        )}>
        <VStack spacing={0} w="100%">
          {lineItemsArr.map(lineItem => (
            <EditableLineItemView
              id={lineItem.id}
              key={lineItem.id}
              value={lineItem}
              onSubmit={addLineItem}
              onDelete={() => removeLineItem(lineItem.id)}
            />
          ))}
          <LineItemPopover
            buttonText={`ADD LINE ITEM FOR ${patient.fname.toUpperCase()} ${patient.lname.toUpperCase()}`}
            onSubmit={addLineItem}
          />
        </VStack>
      </Expandable>
      {isEditing ? (
        <EditAdditionalPatientModal
          onClose={() => setIsEditing(false)}
          invoiceId={invoiceId}
          patient={patient}
          patientId={patient.id}
        />
      ) : null}
    </>
  )
}
