import { createContext, useContext } from 'react'
import { FaxesViewData } from './types'

export const FaxesViewContext = createContext<FaxesViewData>({
  viewFax: () => {},
  viewedFax: null,
  onChangeTab: () => {},
  tabIndex: 0,
  viewedAssessmentId: null,
  viewAssessment: () => {},
  closeFax: () => {},
  faxAssignment: {
    assigningToFaxId: null,
    assigningToPatientId: null,
    submitting: false,
    error: null,
    assignToFax: () => {},
    assignToPatient: () => {},
    onSubmit: async () => ({ error: 'Must be in Fax View Context' }),
    unassignAssessmentFromFax: async () => ({
      error: 'Must be in Fax View Context',
    }),
  },
  faxId: null,
  loading: false,
  faxData: null,
})

export const useFaxesView = () => useContext(FaxesViewContext)
