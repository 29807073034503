import { get as nestedGet } from 'nested-property'
import { FieldTypes } from '../constants'
import {
  callInField,
  getBasicInfoField,
  policyOwnerFieldMap,
  primaryCoverageCondition,
} from '../fields'
import {
  AdditionalCoverageId,
  AssessmentSnippet,
  BaseInsuranceCoverage,
  ConfirmCoverageStatus,
  CoverageStageStatus,
  CoverageWithMetadata,
  DropdownField,
  FieldMapValue,
  InsuranceCoverage,
  InsuranceCoverageHistory,
  InsuranceCoverageId,
  InsuranceCoverageRequest,
  InsuranceCoverageType,
  InsuranceProvider,
  isInsuranceCoverageRequest,
  NextAction,
  PatientNextActions,
  PopulatedInsurancePlans,
  User,
  UserInsurancePlans,
  WithInsuranceCoverageId,
} from '../types'
import { getUniqueRandomId, parseNextActionDate } from './data'
import { defaultStageValidate } from './fields'

export const getAdditionalPlansRequested = (
  user: User | null | undefined,
  // assessment: Assessment | PopulatedAssessment | undefined,
) => {
  const { insurancePlans } = user ?? {}
  const { additional } = insurancePlans ?? {}
  return Object.values(additional ?? {}).some(p => {
    if (isInsuranceCoverageRequest(p)) return true
    if (p.isMedicaid) return false
    const planCallInErrors = defaultStageValidate(callInField, true)(p)
    return !!planCallInErrors && !!Object.keys(p.callInRequests ?? {}).length
  })
}

const getRequestStatus = ({ withCallInForm }: InsuranceCoverageRequest) => {
  const res: CoverageStageStatus = {
    incomplete: ['basic-info'],
    required: ['basic-info'],
  }
  if (withCallInForm) {
    res.incomplete.push('call-in')
    res.required.push('call-in')
  }
  res.incomplete.push('policy-owner')
  res.required.push('policy-owner')
  return res
}
export const getCoverageStatus = (
  type: InsuranceCoverageType,
  coverage: BaseInsuranceCoverage | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
): CoverageStageStatus => {
  if (coverage && isInsuranceCoverageRequest(coverage)) return getRequestStatus(coverage)
  const isMedicaid = !!coverage?.isMedicaid
  const basicInfoField = getBasicInfoField(isMedicaid)
  const basicInfoComplete = !defaultStageValidate(basicInfoField, true)(coverage ?? undefined)

  const hasCallInRequests =
    !!Object.keys(coverage?.callInRequests ?? {}).length || request?.withCallInForm
  const callInRequired = !isMedicaid && ((!optional && type === 'primary') || hasCallInRequests)

  const callInComplete =
    !callInRequired || !defaultStageValidate(callInField, true)(coverage ?? undefined)

  // const policyOwnerRequired = type === 'primaryCoverage' || type === 'medicaidCoverage' || callInRequired

  const policyOwnerComplete = !defaultStageValidate(
    policyOwnerFieldMap,
    true,
  )(coverage ?? undefined)

  const res: CoverageStageStatus = { incomplete: [], required: ['basic-info'] }
  if (!basicInfoComplete) res.incomplete.push('basic-info')
  if (callInRequired) {
    res.required.push('call-in')
    if (!callInComplete) res.incomplete.push('call-in')
  }

  // if (policyOwnerRequired) {
  res.required.push('policy-owner')
  if (!policyOwnerComplete) res.incomplete.push('policy-owner')
  // }

  return res
}

export const getAdditionalPlanStatus = (
  p: BaseInsuranceCoverage | undefined,
  request?: InsuranceCoverageRequest,
) => getCoverageStatus('additional', p, false, request)

export const getNewAdditionalPlanId = (
  additionalPlans: Record<string, BaseInsuranceCoverage> | undefined,
) => {
  return getUniqueRandomId(Object.keys(additionalPlans ?? {}))
}

export const getAdditionalPlansStatus = (
  additionalPlans: Record<string, CoverageWithMetadata> | undefined,
): Record<AdditionalCoverageId, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce<Record<AdditionalCoverageId, CoverageStageStatus>>(
    (acc, [id, plan]) => ({
      ...acc,
      [id as AdditionalCoverageId]: getAdditionalPlanStatus(plan),
    }),
    {},
  )
}

export const getRequestsStatus = (
  additionalPlans: Record<string, InsuranceCoverageRequest> | undefined,
): Record<string, CoverageStageStatus> => {
  if (!additionalPlans) return {}
  return Object.entries(additionalPlans).reduce(
    (acc, [id, plan]) => ({
      ...acc,
      [id]: getAdditionalPlanStatus(undefined, plan),
    }),
    {},
  )
}

// export const separateAdditionalPlansAndRequests = (
//   additionalPlans: Assessment['additionalPlans'],
// ) => {
//   const { plans, requests } = Object.entries(additionalPlans ?? {}).reduce(
//     (acc, [id, plan]) => {
//       if (isInsuranceCoverageRequest(plan)) {
//         acc.requests[id] = plan
//         return acc
//       }
//       acc.plans[`additionalPlans.${id}`] = plan
//       return acc
//     },
//     {
//       plans: {} as Record<InsuranceCoverageId, InsuranceCoverage>,
//       requests: {} as Record<string, InsuranceCoverageRequest>,
//     },
//   )

//   return { plans, requests }
// }

export const getCoverageRequiresCallIn = (
  coverage: WithInsuranceCoverageId<BaseInsuranceCoverage> | undefined | null,
  optional?: boolean,
  request?: InsuranceCoverageRequest,
) => {
  if (!coverage) return false
  const type = getCoverageType(coverage.id)
  if (coverage?.isMedicaid) return false
  if (request?.withCallInForm) return true
  if (type === 'primary' && !optional) return true
  if (type === 'additional') return !!Object.keys(coverage?.callInRequests ?? {}).length
  return false
}

export const getCoverageType = (id: InsuranceCoverageId): InsuranceCoverageType => {
  if (id === 'primary') return 'primary'
  if (id === 'secondary') return 'secondary'
  return 'additional'
}

export const getCoverageLabel = (id: InsuranceCoverageId) => {
  const type = getCoverageType(id)
  if (type === 'primary') return 'Primary'
  if (type === 'secondary') return 'Secondary'
  return 'Potential'
}

export const getConfirmCoverageStatus = (user: User | null | undefined): ConfirmCoverageStatus => {
  // const primaryCoverage = getPrimaryCoverage(assessment?.data)
  // const hasPrimaryCoverage = !getOnlyOnMedicaidPlan(assessment?.data)
  // const { additionalPlans: additionalPlansAndRequests } = assessment ?? {}

  const { insurancePlans } = user ?? {}
  const { secondary, additional, requests } = insurancePlans ?? {}
  const requiresMainCoverage = primaryCoverageCondition(user?.insuranceInfo)

  return {
    primaryCoverage: getCoverageStatus(
      'primary',
      user?.insurancePlans?.primary,
      requiresMainCoverage,
    ),
    secondaryCoverage: secondary ? getCoverageStatus('secondary', secondary) : null,
    additionalPlans: getAdditionalPlansStatus(additional),
    requests: getRequestsStatus(requests),
  }
}

export const getCoverageComplete = (status: ConfirmCoverageStatus) => {
  const mainComplete = !status.primaryCoverage?.incomplete?.length
  const secondaryComplete = !status.secondaryCoverage?.incomplete.length
  const additionalComplete = !Object.keys(status.additionalPlans ?? {}).find(
    p => !!status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length,
  )
  return mainComplete && secondaryComplete && additionalComplete
}

// export const getPlanPropPath = (adminView: boolean, id: InsuranceCoverageId) => {
//   // const dataPath = adminView ? 'corrections' : 'data'
//   if (id === 'primaryCoverage') return `${dataPath}.insurance-info.primaryCoverage`
//   if (id === 'medicaidCoverage') return `${dataPath}.insurance-info.medicaidCoverage`
//   return id
// }
export const getHasMedicaidCoverage = (mergedData?: FieldMapValue) =>
  mergedData?.['insurance-info']?.option === 'medicaid' ||
  (mergedData?.['insurance-info']?.option === 'two-plans' &&
    mergedData?.['insurance-info']?.onePlanIsMedicaid)

export const getEdd = (mergedData?: FieldMapValue) => mergedData?.delivery?.edd

export const getInsurancePlan = <T extends BaseInsuranceCoverage = BaseInsuranceCoverage>(
  insurancePlans: UserInsurancePlans<T>,
  id: InsuranceCoverageId,
): InsuranceCoverage | undefined | null => nestedGet(insurancePlans, id) ?? null

export const getCoveragePath = (id: InsuranceCoverageId) => {
  if (id === 'primary') return 'insurancePlans.primary'
  if (id === 'secondary') return 'insurancePlans.secondary'
  return `insurancePlans.${id}`
}

export const getCoverageNextAction = (
  nextActions: PatientNextActions,
  id: InsuranceCoverageId,
): NextAction | null => {
  if (id === 'primary') return nextActions.primaryCoverage ?? null
  if (id === 'secondary') return nextActions.secondaryCoverage ?? null
  return nextActions.additionalPlans?.[id.split('.')[1] ?? ''] ?? null
}

export const getPlansArray = <T extends BaseInsuranceCoverage>(
  plans: UserInsurancePlans<T>,
): (T & { id: InsuranceCoverageId })[] => {
  const plansArray: (T & { id: InsuranceCoverageId })[] = []
  if (plans.primary) plansArray.push({ ...plans.primary, id: 'primary' })
  if (plans.secondary) plansArray.push({ ...plans.secondary, id: 'secondary' })
  plansArray.push(
    ...Object.entries(plans.additional ?? {})
      .filter(([, p]) => !isInsuranceCoverageRequest(p))
      .map(([id, plan]) => ({
        ...plan,
        id: `additionalPlans.${id}` as InsuranceCoverageId,
      })),
  )
  return plansArray
}

export const getPlansInsurerIds = <T extends BaseInsuranceCoverage>(
  plans: UserInsurancePlans<T>,
): string[] => {
  const insurerIds: string[] = []
  const plansArray = getPlansArray(plans)
  plansArray.forEach(p => {
    if (p.insuranceProviderId && !insurerIds.includes(p.insuranceProviderId)) {
      insurerIds.push(p.insuranceProviderId)
    }
  })
  return insurerIds
}

export const getPlanNextActionPath = (id: InsuranceCoverageId) => {
  if (id === 'primary') return 'nextActions.primaryCoverage'
  if (id === 'secondary') return 'nextActions.secondaryCoverage'
  return `nextActions.additionalPlans.${id.split('.')[1] ?? ''}`
}

export const getPregnancyPlanNextActionPath = (id: InsuranceCoverageId) => {
  if (id === 'primary') return 'patientNextActions.primaryCoverage'
  if (id === 'secondary') return 'patientNextActions.secondaryCoverage'
  return `patientNextActions.additionalPlans.${id}`
}

export const getPlanNextAction = (nextActions: PatientNextActions, id: InsuranceCoverageId) => {
  if (id.startsWith('additional.')) {
    const parsedId = id.split('.')[1]
    if (!parsedId) return undefined
    return nextActions?.additionalPlans?.[parsedId]
  }
  if (id === 'primary') return nextActions.primaryCoverage
  if (id === 'secondary') return nextActions.secondaryCoverage
  return undefined
}

export const getCoverageNextActions = (assessment: AssessmentSnippet) => {
  const { patientPlans: plans, patientNextActions: nextActions } = assessment
  // const { isMedicaid } = plans ?? {}

  const nextActionsArr: {
    coverage: InsuranceCoverage | undefined | null
    id: InsuranceCoverageId
    date: number
    nextAction: NextAction | null | undefined
  }[] = []
  nextActionsArr.push({
    id: 'primary',
    coverage: plans?.primary,
    date: parseNextActionDate(nextActions?.primaryCoverage?.text),
    nextAction: nextActions?.primaryCoverage,
  })
  if (nextActions?.secondaryCoverage) {
    nextActionsArr.push({
      id: 'secondary',
      coverage: plans?.secondary,
      date: parseNextActionDate(nextActions?.secondaryCoverage?.text),
      nextAction: nextActions?.secondaryCoverage,
    })
  }
  if (nextActions?.additionalPlans) {
    Object.entries(nextActions.additionalPlans).forEach(([nextActionId, nextAction]) => {
      nextActionsArr.push({
        id: `additional.${nextActionId}`,
        coverage: plans?.additional?.[nextActionId],
        date: parseNextActionDate(nextAction?.text),
        nextAction,
      })
    })
  }
  return nextActionsArr.sort((a, b) => a.date - b.date)
}

export const getCoverageNextActionsCount = (assessment: AssessmentSnippet) => {
  let count = 1
  const { patientNextActions } = assessment ?? {}
  if (patientNextActions?.secondaryCoverage) count += 1
  if (patientNextActions?.additionalPlans)
    count += Object.keys(patientNextActions.additionalPlans).length
  return count
}

export const getCoverageText = (
  coverage: BaseInsuranceCoverage | null | undefined,
  insurer: InsuranceProvider | undefined | null,
) => {
  const insuranceProviderName: string = insurer?.name ?? '(No Insurer)'

  const { isMedicaid } = coverage ?? {}
  // const isMedicaid = getCurrentlyOnMedicaidPlan(assessment.mergedData)

  const planName = coverage?.planName === "Other / I don't know" ? 'Other' : coverage?.planName
  if (isMedicaid) {
    if (planName && insurer?.plans?.find(p => p.name === planName)) {
      return `${insuranceProviderName} Medicaid - ${planName}`
    }
    return `${insuranceProviderName} Medicaid`
  }
  if (planName && insurer?.plans?.find(p => p.name === planName)) {
    return `${insuranceProviderName} - ${planName}`
  }
  return insuranceProviderName
}

export const getInsurancePlansField = (
  plans: PopulatedInsurancePlans | undefined,
  optional?: boolean,
): DropdownField => {
  const plansArray = getPlansArray(plans ?? {}).filter(p => !!p.insuranceProviderId)
  return {
    type: FieldTypes.DROPDOWN,
    options: plansArray.map(p => ({
      id: p.id,
      text: p ? getCoverageText(p, p.insuranceProvider) : 'No coverage',
    })),
    optional: !!optional,
    placeholder: 'Select Coverage',
  }
}

export const getHasMultiplePlans = (plans: UserInsurancePlans) => {
  let numPlans = 1
  const { secondary, additional } = plans
  if (secondary) numPlans += 1
  numPlans += Object.keys(additional ?? {}).length
  return numPlans > 1
}

// removes populated properties from coverage (eg insuranceProvider, id)
export const sanitizeInsuranceCoverage = ({
  insuranceProvider: _ins,
  id: _id,
  ...rest
}: InsuranceCoverage): CoverageWithMetadata => ({
  ...rest,
  memberId: rest.noMemberId ? '' : (rest.memberId ?? ''),
})

export const addCoverageHistory = <T extends BaseInsuranceCoverage>(
  coverage: T,
  history: InsuranceCoverageHistory[],
): T => {
  const newHistory = [
    ...(coverage.history && Array.isArray(coverage.history) ? coverage.history : []),
    ...history,
  ]
  return { ...coverage, history: newHistory }
}
