import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { UpdateCallback } from '@hb/shared'
import React, { FC, useCallback, useContext, useRef, useState } from 'react'
import { PopUpMessageContext } from '../../contexts'
import { SolidActionButton } from '../Buttons/ActionButton'
import { CancelButton } from '../Buttons/CancelButton'

interface ActionConfirmProps {
  isOpen: boolean
  onClose: () => void
  colorScheme?: string
  header?: string | null
  body: React.ReactNode
  willUnmount?: boolean
  confirmText: string
  onConfirm: (() => Promise<UpdateCallback>) | (() => void)
}
export const ActionConfirm: FC<ActionConfirmProps> = ({
  isOpen,
  onClose,
  colorScheme,
  onConfirm,
  header,
  body,
  confirmText,
  willUnmount,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { processResponse } = useContext(PopUpMessageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleConfirm = useCallback(async () => {
    setIsSubmitting(true)
    const res = await onConfirm()
    onClose()
    if (!willUnmount) setIsSubmitting(false)
    if (res) return processResponse(res)
    return { success: 'Success' }
  }, [processResponse, onClose, willUnmount, onConfirm])
  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {header ? (
            <AlertDialogHeader fontFamily={'Comfortaa'} pb={2} fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
          ) : null}
          {body ? <AlertDialogBody>{body}</AlertDialogBody> : null}
          <AlertDialogFooter>
            <CancelButton opacity={isSubmitting ? 0 : 1} ref={cancelRef} onClick={onClose}>
              Cancel
            </CancelButton>
            <SolidActionButton
              size="sm"
              isLoading={isSubmitting}
              colorScheme={colorScheme ?? 'green'}
              onClick={handleConfirm}
              ml={3}>
              {confirmText}
            </SolidActionButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
